import { hover, mobile, mobileSmall, tv } from 'app/device';
import history from 'app/history';
import Routes from 'app/routes';
import Theme from 'app/theme';
import { IconCross } from 'components/ui';
import { withFocusable } from 'helpers';
import { useDispatch, useSelector } from 'react-redux';
import {
    initCreateProfileMode,
    initPassCheck,
    passwordCheckSelector,
} from 'slices';
import styled from 'styled-components';
import css from 'styled-components/css';
import * as S from '../profile/selection-manager/profile/Profile.style';
import { useTranslation } from 'react-i18next';

export const AddProfileButton = () => {
    const dispatch = useDispatch();
    const { checkPassed } = useSelector(passwordCheckSelector);
    const { t } = useTranslation();

    const doPassCheck = () => {
        if (!checkPassed) {
            dispatch(
                initPassCheck({
                    from: Routes.PROFILE_SELECTION,
                    to: Routes.PROFILE_EDITOR,
                })
            );
            history.push(Routes.PASSWORD_CHECK);
        } else {
            history.push(Routes.PROFILE_EDITOR);
        }

        dispatch(initCreateProfileMode());
    };

    return (
        <S.SwiperSlideWrapper onClick={doPassCheck}>
            <S.Wrapper>
                <S.ProfileAvatarWrapper>
                    <IconWrapper>
                        <IconCross />
                    </IconWrapper>
                </S.ProfileAvatarWrapper>
                <S.Tag>
                    <S.Text>{t('buttons.add_profile')}</S.Text>
                </S.Tag>
            </S.Wrapper>
        </S.SwiperSlideWrapper>
    );
};

const IconWrapper = withFocusable(styled.div`
    display: flex;
    box-sizing: border-box;
    position: relative;
    background-color: rgba(250, 250, 250, 0.35);
    border-radius: 25px;
    box-shadow: 0px 0px 0px 6px rgba(255, 255, 255, 0);
    width: 132px;
    height: 132px;
    outline: none;
    cursor: pointer;
    -webkit-touch-callout: none;

    ${tv(css`
        border-width: 5px;
        border-radius: 32px;
    `)};

    ${mobile(css`
        height: 108px;
        width: 108px;
    `)};

    ${mobileSmall(css`
        width: 78px;
        height: 78px;
    `)};

    ${tv(css`
        width: 232px !important;
        height: 232px !important;
    `)}

    &:focus {
        box-shadow: 0px 0px 0px 6px ${Theme.COLORS.PRIMARY_L2};
        /* border-color: ${Theme.COLORS.PRIMARY_L2}; */
    }

    ${hover(css`
        &:hover {
            box-shadow: 0px 0px 0px 6px ${Theme.COLORS.PRIMARY_L2};
            /* border-color: ${Theme.COLORS.PRIMARY_L2}; */
        }
    `)}
    &:active {
        box-shadow: 0px 0px 0px 6px ${Theme.COLORS.PRIMARY_L3};
        /* border-color: ${Theme.COLORS.PRIMARY_L3}; */
    }

    svg {
        width: 50%;
        margin: auto;
    }
`);
