import { useTranslation } from 'react-i18next';
import { ActionButtons, BlurBox, Input, Logo } from 'components/ui';
import {
    RecoveryBoxWrapper,
    ErrorWrapper,
    TitleWrapper,
} from 'components/account-recovery';
import { SharedElement } from 'components';
import * as S from 'components/account-recovery/RecoveryStep.style';
import { useGetMethod } from 'hooks/recovery';

export function StepGetMethod({setShowSupport}) {
    const { t } = useTranslation();
    const {
        onInputLoginChange,
        onSubmit,
        register,
        recoveryError,
        cancelStep,
    } = useGetMethod();

    return (
        <SharedElement id="recovery-box">
            <BlurBox>
                <RecoveryBoxWrapper>
                    <SharedElement id="recovery-logo" zIndex={2} scale>
                        <Logo />
                    </SharedElement>
                    <TitleWrapper
                        error={recoveryError}
                        title={t('recovery.step_get_method')}
                    />
                    <S.ContentWrapper>
                        <ErrorWrapper error={recoveryError} />
                        <S.InputsWrapper>
                            <Input
                                focusOnMount
                                ref={register({ required: true })}
                                name="username"
                                placeholder={t('recovery.username')}
                                onChange={(e) =>
                                    onInputLoginChange(e.target.value)
                                }
                            />
                        </S.InputsWrapper>
                    </S.ContentWrapper>
                    <ActionButtons
                        buttons={[
                            {
                                type: 'primary',
                                text: t('buttons.ok'),
                                callback: onSubmit,
                            },
                            {
                                type: 'regular',
                                text: t('buttons.cancel'),
                                callback: cancelStep,
                            },
                            {
                                type: 'neutral',
                                text: t('buttons.help'),
                                callback: () => {
                                    setShowSupport(true);
                                },
                            },
                        ]}
                    />
                </RecoveryBoxWrapper>
            </BlurBox>
        </SharedElement>
    );
}
