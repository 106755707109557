import { idToSound } from 'helpers';
import { useSelector } from 'react-redux';
import { soundSettingsSelector } from 'slices';
import useSound from 'use-sound';
import { soundsVolume } from 'helpers';
import { useMemo } from 'react';

export function useAppSound(soundId, params) {
    const { sound, volume: soundVolume, type } = idToSound[soundId];
    const { uiSound, uiSoundVolume, atmosSoundVolume } = useSelector(
        soundSettingsSelector
    );

    // each sound may have its own base volume, use the default if unspecified
    let volume = soundVolume || soundsVolume.default;

    // then apply global volume settings
    if (type === 'ui') {
        volume *= uiSoundVolume / 100;
    } else if (type === 'atmos') {
        volume *= atmosSoundVolume / 100;
    }

    const soundParams = useMemo(
        () => ({
            volume,
            interrupt: type === 'ui', // avoid sounds overlap. /!\ trigger a .stop() before .play() .
            soundEnabled: type !== 'ui' || uiSound, //global stop sound, encountered some issues with a sample with params loop:true. had to stop it manually, see useGlobalSound.js
            html5: true,
            ...params,
        }),
        [params, type, uiSound, volume]
    );

    return useSound(sound, soundParams);
}
