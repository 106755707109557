import {
    SpatialNavProvider,
    SpatialNavSection,
    withInputDispatcherProvider,
} from 'context';
import { useEffect } from 'react';
import * as S from './MessageBoxWrapper.style';
import { useAppSound } from 'hooks';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const Overlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.3;
    background: black;
`;

export const MessageBoxWrapper = withInputDispatcherProvider(
    ({ children, blurOverlap, ...props }) => {
        const [playNotification] = useAppSound('notification');

        useEffect(() => {
            playNotification();
        }, [playNotification]);

        return (
            <>
                <SpatialNavProvider>
                    <SpatialNavSection focusOnMount enterTo="default-element">
                        <S.Wrapper {...props}>
                            <S.BlurredWallpaper />
                            <S.MessageBox blurOverlap={blurOverlap}>{children}</S.MessageBox>
                        </S.Wrapper>
                    </SpatialNavSection>
                </SpatialNavProvider>
                {createPortal(<Overlay />, document.body)}
            </>
        );
    }
);
