import { isTV } from 'app/device';
import { GapAnimation, QuitWrapper } from 'components';
import { GamesWrapper, Grid, NoGamesFound } from 'components/games';
import * as S from 'components/games/categories';
import { GameWindowSwiper } from 'components/games/game-window-swiper/GameWindowSwiper';
import { ColumnLayout, RowLayout } from 'components/ui';
import { CategoryTitle } from 'components/ui/category/category-title/CategoryTitle';
import { If } from 'helpers';
import { useCategories, useGamesSwiper } from 'hooks';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Games } from 'services';

export function CategoriesScreen() {
    const {
        currentTab,
        columns,
        games,
        // setNbPlayers,
        // availableMultiNbPlayers,
    } = useCategories();
    const gamesSwiper = useGamesSwiper(games);
    const { t } = useTranslation();
    const blurRef = useRef();

    const tab = currentTab.subTab;

    return (
        <>
            <QuitWrapper disabled={gamesSwiper.visible} />
            <ColumnLayout alignItems="center" fullSize>
                <If condition={gamesSwiper.visible}>
                    <GameWindowSwiper
                        gamesSwiper={gamesSwiper}
                        selectedIndex={gamesSwiper.selectedIndex}
                        prev={gamesSwiper.prev}
                        next={gamesSwiper.next}
                        games={games}
                    />
                </If>
                <GamesWrapper minTop={isTV ? 350 : 250} key={tab}>
                    <If condition={games.length <= 0}>
                        <NoGamesFound>{t('categories.no_games')}</NoGamesFound>
                    </If>
                    <If condition={games.length > 0}>
                        <ColumnLayout fullWidth>
                            <GapAnimation
                                isGapOpen={gamesSwiper.gapOpen}
                                lastFocused={gamesSwiper.lastFocused}
                            >
                                <RowLayout>
                                    <CategoryTitle key={tab} blurRef={blurRef}>
                                        <If condition={tab !== 'multiplayer'}>
                                            <S.TitleFilter>
                                                <div>
                                                    {Games.isDiscoveryOffer()
                                                        ? ''
                                                        : t(
                                                              `categories.${tab.toLowerCase()}`
                                                          )}
                                                </div>
                                            </S.TitleFilter>
                                        </If>
                                    </CategoryTitle>
                                </RowLayout>
                            </GapAnimation>
                            <Grid
                                isGapOpen={gamesSwiper.gapOpen}
                                lastFocused={gamesSwiper.lastFocused}
                                onItemClicked={gamesSwiper.open}
                                columns={columns}
                            />
                        </ColumnLayout>
                    </If>
                </GamesWrapper>
            </ColumnLayout>
        </>
    );
}
