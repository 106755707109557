export function IconTrailer(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 17.368 17.32"
            {...props}
        >
            <path d="M14.403 10.449h-.985v-.59A1.318 1.318 0 0012.1 8.541H2.253A1.318 1.318 0 00.935 9.859v6.143a1.318 1.318 0 001.318 1.318h9.845a1.318 1.318 0 001.318-1.318v-1.023h1.082l2.873 1.849V8.541zM11.284 0a3.759 3.759 0 00-3.367 2.092h-.789a3.76 3.76 0 10-3.366 5.431h7.575a3.76 3.76 0 00-.052-7.52zM3.762 4.471a.831.831 0 11.831-.831.831.831 0 01-.831.831zm7.522 0a.831.831 0 11.831-.831.83.83 0 01-.831.831z" />
        </svg>
    );
}
