import { isTV, isChrome, mobile, mobileSmall, tv } from 'app/device';
import { BlurBox } from 'components/ui/blur-box';
import { BlurBoxFallback } from 'components/ui/blur-box/BlurBoxFallback';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    z-index: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    ${mobile(css`
        align-items: flex-end;
        bottom: 0;
        margin-bottom: 10px;
    `)}

    ${mobileSmall(css`
        margin-bottom: 3px;
    `)}
`;

const WindowBase = css`
    width: 90vw;
    max-width: 1280px;

    --gameWindowMaxHeight: 500px;
    --gameWindowHeight: 30vw;

    ${mobile(css`
        width: 98%;
        --gameWindowHeight: 85vh;
    `)}

    ${mobileSmall(css`
        width: 98%;
        --gameWindowHeight: 80vh;
    `)}

    ${tv(css`
        --gameWindowMaxHeight: 600px;
        --gameWindowHeight: 600px;
        max-width: 1700px;
    `)}
    
    height: var(--gameWindowHeight);
    max-height: var(--gameWindowMaxHeight);
`;

const WindowBlurBox = styled(BlurBox)`
    ${WindowBase}
`;

const WindowBlurBoxFallback = styled(BlurBoxFallback)`
    ${WindowBase}
`;

// issue backdrop-filter and transform /!\ (chrome)
export const Window = isTV || isChrome ? WindowBlurBoxFallback : WindowBlurBox;
