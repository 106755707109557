import React from 'react';

export const IconQuickmatch = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 33 21"
            {...props}
        >
            <path d="M22.5 9A4.5 4.5 0 1018 4.5 4.481 4.481 0 0022.5 9zm-12 0A4.5 4.5 0 106 4.5 4.481 4.481 0 0010.5 9zm0 3C7.005 12 0 13.755 0 17.25V21h21v-3.75C21 13.755 13.995 12 10.5 12zm12 0c-.435 0-.93.03-1.455.075A6.33 6.33 0 0124 17.25V21h9v-3.75C33 13.755 25.995 12 22.5 12z"></path>
        </svg>
    );
};
