import { SpatialNavSection } from 'context';
import { assetURL } from 'helpers';
import { useMultiItemsPerViewSwiper } from 'hooks';
import * as S from './NewGamesNotification.style';

export function NewGamesListPlain({ list, listItemSelected }) {
    const { focusActiveItem } = useMultiItemsPerViewSwiper({ nbSlides: list.length });

    return (
        <SpatialNavSection focusOnMount enterTo="last-focused">
            <S.NewGamesWrapper>
                {list.map((game, i) => {
                    const commonProps = {
                        key: game.alias,
                        items: list.length,
                        isPlain: true,
                        onClick: listItemSelected(game.alias),
                        onFocus: focusActiveItem,
                    };
                    return game.assets?.thumb ? (
                        <S.NewGame
                            {...commonProps}
                            srcImage={assetURL(game.assets.thumb)}
                        />
                    ) : (
                        <S.NewGameNoThumbnail {...commonProps}>
                            <span>{game.assets.title}</span>
                        </S.NewGameNoThumbnail>
                    );
                })}
            </S.NewGamesWrapper>
        </SpatialNavSection>
    );
}
