export const IconSound = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 64 64"
        {...props}
    >
        <g transform="matrix(2.02645,0,0,2.02645,-35.4291,-31.2476)">
            <path d="M36.118,42.824C35.462,43.125 35.011,42.721 34.536,42.349C32.364,40.499 30.202,38.653 28.051,36.813C27.717,36.502 27.273,36.337 26.817,36.354L21.628,36.354C21.365,36.37 21.101,36.37 20.837,36.354C20.179,36.317 19.658,35.764 19.659,35.104C19.643,34.889 19.643,34.672 19.659,34.456L19.659,27.132C19.698,25.977 20.149,25.55 21.296,25.55L26.698,25.55C27.321,25.575 27.93,25.353 28.391,24.933C29.862,23.636 31.365,22.37 32.852,21.089C33.492,20.543 34.109,19.974 34.766,19.452C34.997,19.264 35.267,19.129 35.557,19.056C35.619,19.045 35.682,19.039 35.745,19.039C36.249,19.039 36.683,19.406 36.767,19.903C36.804,20.165 36.817,20.429 36.806,20.694L36.806,41.321C36.822,41.93 36.767,42.516 36.118,42.824ZM43.885,39.178C43.798,39.296 43.719,39.415 43.624,39.526C43.237,39.997 42.547,40.097 42.042,39.755C41.547,39.436 41.395,38.771 41.702,38.268C42.153,37.422 42.707,36.631 43.086,35.761C44.185,33.319 44.4,30.569 43.696,27.986C43.33,26.567 42.709,25.226 41.861,24.031C41.813,23.952 41.75,23.873 41.702,23.794C41.356,23.284 41.471,22.583 41.963,22.212C42.157,22.058 42.397,21.974 42.644,21.974C43.004,21.974 43.342,22.152 43.545,22.449C44.319,23.431 44.941,24.525 45.388,25.692C46.085,27.428 46.445,29.28 46.448,31.15C46.402,34.019 45.51,36.812 43.885,39.178ZM40.397,36.497C40.181,36.726 39.879,36.856 39.563,36.856C38.934,36.856 38.416,36.338 38.416,35.709C38.416,35.556 38.447,35.404 38.507,35.263C38.641,34.954 38.823,34.669 38.966,34.369C40.105,32.073 39.997,29.347 38.681,27.148C38.222,26.309 38.293,25.645 38.934,25.273C39.575,24.901 40.223,25.147 40.706,25.969C41.605,27.511 42.088,29.262 42.106,31.047C42.077,32.775 41.618,34.469 40.769,35.974C40.661,36.154 40.536,36.324 40.397,36.481L40.397,36.497Z" />
        </g>
    </svg>
);
