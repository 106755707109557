export const IconSoundMute = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 64 64"
        {...props}
    >
        <g transform="matrix(1.43447,0,0,1.43447,-13.2578,-13.5678)">
            <path d="M31.56,45.3C30.79,45.65 30.26,45.18 29.75,44.74C27.22,42.58 24.68,40.41 22.17,38.22C21.774,37.861 21.254,37.668 20.72,37.68L13.74,37.68C12.973,37.665 12.341,37.047 12.31,36.28C12.295,36.03 12.295,35.78 12.31,35.53L12.31,26.96C12.36,25.6 12.88,25.08 14.23,25.08L20.55,25.08C21.287,25.126 22.011,24.865 22.55,24.36C24.28,22.84 26.03,21.36 27.77,19.86C28.52,19.22 29.24,18.56 30.01,17.95C30.299,17.714 30.643,17.556 31.01,17.49C31.084,17.476 31.159,17.469 31.235,17.469C31.828,17.469 32.337,17.905 32.43,18.49C32.455,18.796 32.455,19.104 32.43,19.41L32.43,43.5C32.38,44.26 32.33,44.94 31.56,45.3ZM50.16,35.86L47.16,38.86L42.74,34.44L38.33,38.86L35.28,35.86L39.69,31.44L35.23,27L38.28,24L42.69,28.41L47.11,24L50.11,27.05L45.69,31.46L50.16,35.86Z" />
        </g>
    </svg>
);
