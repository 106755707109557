import i18n from 'i18next';
import moment from 'moment';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// cannot import directly from helpers at this point for some reason
import { mergeObjects } from 'helpers/helpers';

// consolidate i18n resources from main set + customer-specific overrides in assets
// this constructs a map between a language key and its consolidated resource
export const supportedLanguages = [
    'de',
    'en',
    'es',
    'fr',
    'it',
    'pt',
    'sl',
    'zt',
];

export const resources = {};
// tizen < 6 does not have Object.fromEntries
supportedLanguages
    .map((l) => {
        // backend uses zt key for Taiwan Chinese, but the i18n files shared with the Qt
        // version need to use the official zh-TW codename
        const fileL = l === 'zt' ? 'zh-TW' : l;
        // see https://webpack.js.org/guides/dependency-management/#require-with-expression
        const main = require(`./locales/main_${fileL}.json`);
        const override = require(`assets/i18n/override_${fileL}.json`);
        // moment's locales other than en need to be imported explicitly otherwise they get removed from the build
        if (l !== 'en') {
            require(`moment/locale/${fileL.toLocaleLowerCase()}.js`);
        }
        return [l, { main: mergeObjects(main, override) }];
    })
    .forEach(([key, value]) => {
        resources[key] = value;
    });

// consistency check of localization files in dev mode
if (process.env.NODE_ENV === 'development') {
    let errorObject = {};

    const logError = (path, value, object = errorObject) => {
        if (path.length === 1) {
            object[path[0]] = value;
            return;
        }
        object[path[0]] = object[path[0]] || {};
        logError(path.slice(1), value, object[path[0]]);
    };

    const checkField = (language, main, other, path = []) => {
        // report entirely missing or untranslated entries
        if (other === undefined || other === '') {
            console.log(`${language}: missing entry ${path.join('.')}`);
            logError([language].concat(path), main);
            return;
        }
        // string: report mismatches between number of variables in translation
        if (typeof main === 'string') {
            const mainNbVariables = main.match(/\{\{/g)?.length || 0;
            const otherNbVariables = other.match(/\{\{/g)?.length || 0;
            if (mainNbVariables !== otherNbVariables) {
                console.log(
                    `${language}: mismatched variables ${main} vs ${other}`
                );
                logError([language].concat(path), main);
            }
            return;
        }
        // object: check all fields
        for (const name in main) {
            checkField(language, main[name], other[name], path.concat([name]));
        }
    };

    // check all languages against en as reference
    console.groupCollapsed('i18n consistency check');
    const main = resources.en;
    supportedLanguages.forEach((l) => {
        const other = resources[l];
        console.log('Checking', l);
        checkField(l, main, other);
    });
    console.log(errorObject);
    console.groupEnd();
}

const chineseDetector = {
    name: 'zhMapper',
    lookup(options) {
        // map all zh codes to zt
        const { language } = navigator;
        if (language === 'zh' || language.startsWith('zh-')) {
            return 'zt';
        }
        // pass on to other detectors
        return undefined;
    },
};

const languageDetector = new LanguageDetector();

languageDetector.addDetector(chineseDetector);

i18n.use(languageDetector)
    .use(initReactI18next)
    .init({
        // FIXME: use ConfigFront.fallbackLng
        fallbackLng: 'en',
        debug: process.env.NODE_ENV === 'development',
        defaultNS: 'main',
        // FIXME: use ConfigFront.GetSupportedLanguages()
        supportedLngs: supportedLanguages,
        resources,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        detection: {
            order: ['localStorage', 'zhMapper', 'navigator'],
            caches: ['localStorage'],
        },
    });

// backend uses unofficial zt key for Taiwan Chinese
moment.locale(i18n.language === 'zt' ? 'zh-tw' : i18n.language);

export default i18n;
