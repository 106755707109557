import { tablet } from 'app/device';
import styled, { css } from 'styled-components';

// Desktop base
export const Wrapper = styled.div`
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    ${tablet(css`
        bottom: 0.5rem;
        justify-content: flex-end;
        > div {
            margin-right: 20px;
        }
    `)};
`;
