import styled from 'styled-components';
import Theme from 'app/theme';

export const Icon = styled.div`
    margin: 0 auto 1rem auto;
    width: 2.5rem;
    height: 2.5rem;

    svg {
        fill: white;
    }
`;

export const Title = styled.div`
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
`;

export const Subtitle = styled.div`
    font-size: 0.75rem;
    margin-top: 1rem;
`;

export const Message = styled.div`
    line-height: 1.5rem;
`;

export const Text = styled.div`
    font-size: 1rem;
`;

export const MessageWrapper = styled.div`
    text-align: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${Text} {
        align-self: auto;
        text-align: center;
    }
`;

export const BoxTitleWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
`;

export const IconTitle = styled.div`
    width: 1.25rem;
    display: inline-block;
    margin-right: 4px;

    svg {
        fill: white;
    }
`;

export const Tick = styled.div`
    margin-left: 0.5rem;
    background-color: white;
    color: ${Theme.COLORS.PRIMARY_L2};
    padding: 2px 5px;
    border-radius: 25px;
    font-size: 0.75rem;
`;

export const ButtonWrapper = styled.div`
    margin-top: 1.5rem;
`;
