import { useSpatialNavContext } from 'context';
import { createRef, useCallback, useEffect, useRef, useState } from 'react';

/* This tracks which slides are actually visible and provides custom
  prevSlide and nextSlide functions that will only trigger a swipe
  effect when necessary.
  The "current" slide is indicated by activeIndex, which is updated
  at the end of the change - i.e. after the transition ends, or immediately
  when there is no transition.
  You may also specify transitionEndCallback to get called at the end of
  the transitions. 
*/
export function useSwiper({ nbSlides, transitionEndCallback, selectedIndex }) {
    const [swiperRef, setSwiperRef] = useState();
    const [activeIndex, setActiveIndex] = useState();
    const [firstVisibleIndex, setFirstVisibleIndex] = useState(0);
    const [slideRefs] = useState(() =>
        Array.from({ length: nbSlides }, () => createRef())
    );
    const { refreshTree } = useSpatialNavContext();
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const firstRender = useRef(true);
    // for some reason, swiperRef.activeIndex may be incorrect at the end of
    // the transition, so we keep the expected index in a ref instead
    const nextActiveIndex = useRef(undefined);
    const [swiperTransitionActive, setSwiperTransitionActive] = useState(false);

    const onSlideChangeTransitionStart = () => {
        setSwiperTransitionActive(true);
    };

    useEffect(() => {
        if (swiperRef) {
            swiperRef.update(); //swiper has to be updated on first render to get the right size see : https://github.com/nolimits4web/swiper/issues/2958
        }
    }, [swiperRef]);

    // display first slide directly on mount if selectedIndex (only when GameWindowSwiper)
    // sync activeIndex and selectedIndex
    // selectedIndex = index from alias (see selected index in useGamesSwiper.js)
    // activeIndex = current index used for focus in gamewindow
    useEffect(() => {
        if (!swiperRef) return;

        if (firstRender.current) {
            if (selectedIndex) {
                setActiveIndex(selectedIndex);
                swiperRef?.slideTo(selectedIndex, 0);
            } else {
                setActiveIndex(0);
            }
            firstRender.current = false;
        }
    }, [swiperRef, selectedIndex, setActiveIndex]);

    /* Prev & Next handlers
    swiperRef may be undefined if we are not actually showing the swiper, in that
    case just change the activeIndex
    these return false so they can be used as override callbacks for focusable items 
    */
    const prevSlide = useCallback(() => {
        if (!swiperRef) return;

        if (activeIndex !== 0) {
            const prevIndex = activeIndex - 1;
            if (
                swiperRef.params.slidesPerView === 1 ||
                (swiperRef.params.slidesPerView > 1 &&
                    activeIndex === firstVisibleIndex)
            ) {
                // set the next index and swipe
                nextActiveIndex.current = prevIndex;
                swiperRef.slidePrev();
                setFirstVisibleIndex(prevIndex);
            } else {
                // no transition, change active index now
                setActiveIndex(prevIndex);
            }
        }
        return false;
    }, [activeIndex, swiperRef, firstVisibleIndex]);

    const nextSlide = useCallback(() => {
        if (!swiperRef) return;

        if (activeIndex !== nbSlides - 1) {
            const nextIndex = activeIndex + 1;

            if (
                swiperRef.params.slidesPerView === 1 ||
                (swiperRef.params.slidesPerView > 1 &&
                    activeIndex ===
                        firstVisibleIndex +
                            Math.floor(swiperRef.params.slidesPerView) -
                            1)
            ) {
                // set the next index and swipe
                nextActiveIndex.current = nextIndex;
                swiperRef.slideNext();
                setFirstVisibleIndex(
                    nextIndex - Math.floor(swiperRef.params.slidesPerView) + 1
                );
            } else {
                // no transition, change active index now
                setActiveIndex(activeIndex + 1);
            }
        }
        return false;
    }, [activeIndex, nbSlides, swiperRef, firstVisibleIndex]);

    const onSlideChangeTransitionEnd = useCallback(
        (...params) => {
            setSwiperTransitionActive(false);
            refreshTree(true);

            // change active index after transition
            //index can be 0 /!\
            if (nextActiveIndex.current !== (null || undefined)) {
                setActiveIndex(nextActiveIndex.current);
            }

            transitionEndCallback?.(...params);
        },
        [transitionEndCallback, refreshTree]
    );

    return {
        prevSlide,
        nextSlide,
        prevRef,
        nextRef,
        slideRefs,
        swiperTransitionActive,
        onSlideChangeTransitionStart,
        onSlideChangeTransitionEnd,
        swiperRef,
        setSwiperRef,
        activeIndex,
    };
}
