import { isTV, mobile, tablet } from 'app/device';
import styled, { css } from 'styled-components';
import { LogoBase } from './Logo';

const LogoLargeBase = styled(LogoBase)`
    width: 320px;
    height: 270px;
`;

const LogoLargeResponsive = styled(LogoLargeBase)`
    ${tablet(css`
        width: calc((30vh * 320) / 270);
        height: 30vh;
    `)}

    ${mobile(css`
        width: calc((25vh * 320) / 270);
        height: 25vh;
    `)}
`;

const LogoLargeTV = styled(LogoLargeBase)`
    width: 162px;
    height: 142px;
`;

export const LogoLarge = isTV ? LogoLargeTV : LogoLargeResponsive;
