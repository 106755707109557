import styled, { css } from 'styled-components';
import { isMobile, mobile } from 'app/device';
import { Wrapper as ActionButtonsWrapperRoot } from 'components/ui/buttons/action-buttons/ActionButtons.style';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const GameMode = styled.div`
    width: 40%;
    border-radius: 15px;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;

    ${mobile(css`
        width: 45%;
        padding: 0.75rem;
    `)}
`;

export const Quickmatch = styled(GameMode)``;

export const Introduction = styled.div`
    margin: 1rem 0;

    ${mobile(css`
        margin: 0.5rem 0;
    `)}
`;

export const Local = styled(GameMode)``;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;

    svg {
        display: block;
        width: 25px;
        max-height: 35px;
        margin-right: 0.5rem;

        path {
            fill: #fff;
        }
    }
`;

export const Title = styled.div`
    font-weight: bold;
`;

export const Description = styled.div`
    flex-grow: 1;
    margin: 0.5rem 0;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;

    > div {
        display: inline-block;
    }
`;

const ActionButtonsWrapperBase = styled(ActionButtonsWrapperRoot)`
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
`;

const ActionButtonsWrapperMobile = styled(ActionButtonsWrapperRoot)`
    margin-top: 8px;
`;

export const ActionButtonsWrapper = isMobile
    ? ActionButtonsWrapperMobile
    : ActionButtonsWrapperBase;
