import { hasTouch } from 'app/device';
import { Games } from 'services';

// each section of helper tips has an ID as key and is represented by
// an array of tips
// each structure tip is defined by
// - an ID, to match those used in the <Helper> component
// - title and text keys to the i18n dictionaries
// - props for positioning the global helper bubble relatively to its parent
//   (horizontalShift, ty) as well as the direction of the entry/exit animation (easeUp),
//   and the handle relatively to the bubble itself
//   each set of props can include an optional 'mobile' override
const baseHelpersSections = {
    global: [
        {
            id: 'highlights_helper',
            titleKey: 'helpers.global.highlights.title',
            textKey: 'helpers.global.highlights.content',
            helperProps: {
                verticalPlacement: '15%',
            },
            handleProps: {
                verticalPlacement: 'top',
                horizontalPlacement: 'left',
            },
        },
        {
            id: 'categories_helper',
            titleKey: 'helpers.global.categories.title',
            textKey: 'helpers.global.categories.content',
            helperProps: {
                verticalPlacement: '15%',
            },
            handleProps: {
                verticalPlacement: 'top',
                horizontalPlacement: 'left',
            },
        },
        {
            id: 'favorites_helper',
            titleKey: 'helpers.global.favorites.title',
            textKey: 'helpers.global.favorites.content',
            helperProps: {
                horizontalShift: '-29%',
                verticalPlacement: '70%',
                mobile: {
                    horizontalShift: '-29%',
                    verticalPlacement: '70%',
                    // verticalPlacement: 'bottom',
                },
            },
            handleProps: {
                horizontalPlacement: 'right',
                verticalPlacement: '-15px',
            },
        },
        {
            id: 'settings_helper',
            titleKey: 'helpers.global.settings.title',
            textKey: 'helpers.global.settings.content',
            helperProps: {
                horizontalShift: '-40%',
                verticalPlacement: '70%',
            },
            handleProps: {
                mobile: {
                    horizontalPlacement: 'right0',
                },
                horizontalPlacement: 'right',
            },
        },
    ],
};

const multiTabHelper = {
    id: 'multiplayer_helper',
    titleKey: 'helpers.global.multiplayer.title',
    textKey: 'helpers.global.multiplayer.content',
    helperProps: {
        verticalPlacement: '15%',
    },
    handleProps: {
        verticalPlacement: 'top',
        horizontalPlacement: 'left',
    },
};

const mobileTabHelper = {
    id: 'mobile_helper',
    titleKey: 'helpers.global.mobile.title',
    textKey: 'helpers.global.mobile.content',
    helperProps: {
        // verticalPlacement: '15%',
        mobile: {
            horizontalShift: '-2%',
            verticalPlacement: '10%',
        },
    },
    handleProps: {
        verticalPlacement: 'top',
        horizontalPlacement: 'left',
    },
};

const virtualGamepadHelper = {
    id: 'virtual_gamepad_helper',
    titleKey: 'helpers.mobile.virtual_gamepad.title',
    textKey: 'helpers.mobile.virtual_gamepad.content',
    helperProps: {
        mobile: {
            horizontalShift: '-15%',
            verticalPlacement: '65%',
        },
    },
    handleProps: {
        horizontalPlacement: 'left',
    },
};

const trailerHelper = {
    id: 'trailer_helper',
    titleKey: 'helpers.game.trailer.title',
    textKey: 'helpers.game.trailer.content',
    helperProps: {
        horizontalShift: '45%',
        verticalPlacement: '60%',
        mobile: {
            horizontalShift: '5%',
            verticalPlacement: '-80%',
        },
    },
    handleProps: {
        verticalPlacement: 'bottom',
        horizontalPlacement: 'left',
        mobile: {
            verticalPlacement: 'bottom',
            horizontalShift: 'left',
        },
    },
};

const touchscreenHelper = {
    id: 'touchscreen_helper',
    titleKey: 'helpers.mobile.touchscreen.title',
    textKey: 'helpers.mobile.touchscreen.content',
    helperProps: {
        mobile: {
            horizontalShift: '-15%',
            verticalPlacement: '70%',
        },
    },
    handleProps: {
        horizontalPlacement: 'left',
    },
};

export const getSections = () => {
    const result = JSON.parse(JSON.stringify(baseHelpersSections));

    if (
        hasTouch() &&
        (Games.GetCategoryGames('touchscreen').length ||
            Games.GetCategoryGames('virtual_gamepad').length) > 0
    ) {
        // extra section between main menu and settings
        result.global.splice(2, 0, mobileTabHelper);
        result.mobileVirutalGamepad = [{ ...virtualGamepadHelper }];
        result.mobileTouchscreen = [{ ...touchscreenHelper }];
        result.trailer = [{ ...trailerHelper }];
    } else {
        result.global.splice(2, 0, multiTabHelper);
        result.trailer = [{ ...trailerHelper }];
    }

    console.log('result', result);

    return result;
};
