export const throttle = (callbacks, maxParallelCallbacks, maxTime) => {
    // each callback is going to be wrapped in a promise which can be started on demand
    // this is so we can provide the results as an array without waiting for everything
    // to be called
    // this array will contain the start callbacks for each promise
    const start = [];

    // create the promise wrappers
    const promises = callbacks.map((c) =>
        new Promise((resolve) => {
            // store the promise starter: the then() section below that actually
            // executes the callback will only start once this resolve gets called
            start.push(resolve);
        }).then(c)
    );

    const nbSlices = Math.ceil(callbacks.length / maxParallelCallbacks);
    for (let i = 0; i < nbSlices; i++) {
        // start each batch of concurrent tasks at regular intervals
        setTimeout(() => {
            for (
                let j = i * maxParallelCallbacks;
                j < Math.min((i + 1) * maxParallelCallbacks, callbacks.length);
                j++
            ) {
                start[j]();
            }
        }, i * maxTime);
    }

    return promises;
};
