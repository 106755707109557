import { SharedElement } from 'components';
import * as S from './ProfileEditorScreen.style';
import * as P from 'components/profile/editor';
import {
    BlurBox,
    ConfirmationBox,
    IconWarning,
    RowLayout,
} from 'components/ui';
import { If } from 'helpers';
import { useProfileEditor } from 'hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ParentalControlScreen } from 'screens/parental-control/ParentalControlScreen';
import { parentalControlSelector } from 'slices';

export function ProfileEditorScreen() {
    const { t } = useTranslation();
    const {
        state,
        callbacks,
        profileNameRef,
        pinCodeRef,
        avatarSelection,
        nicknameSuggestions,
    } = useProfileEditor();
    const { showParentalControl } = useSelector(parentalControlSelector);

    return (
        <>
            <If condition={state.deleteConfirmation}>
                <ConfirmationBox
                    IconComponent={IconWarning}
                    title={t('profile.delete_confirmation_title')}
                    message={t('profile.delete_confirmation')}
                    buttons={[
                        {
                            type: 'danger',
                            text: t('buttons.delete_profile'),
                            callback: callbacks.onDelete,
                        },
                        {
                            type: 'primary',
                            text: t('buttons.keep_profile'),
                            callback: () =>
                                callbacks.onDeleteConfirmation(false),
                        },
                    ]}
                    backPressCallback={() =>
                        callbacks.onDeleteConfirmation(false)
                    }
                />
            </If>
            <If condition={showParentalControl}>
                <ParentalControlScreen />
            </If>
            <If condition={!showParentalControl}>
                <RowLayout alignItems="center" justifyContent="center" fullSize>
                    <SharedElement
                        id="profile-editor"
                        key={avatarSelection.key}
                    >
                        <BlurBox>
                            <If condition={avatarSelection.visible}>
                                <P.AvatarSelection
                                    avatars={avatarSelection.avatars}
                                    onCancel={avatarSelection.cancel}
                                    onSelected={avatarSelection.onSelected}
                                />
                            </If>
                            <If condition={!avatarSelection.visible}>
                                <S.Wrapper>
                                    <S.Heading>
                                        {state.mode === 'create'
                                            ? t('profile.profile_creation')
                                            : t('profile.profile_edition')}
                                    </S.Heading>
                                    <S.Content>
                                        <S.MainContent>
                                            <S.LeftPan>
                                                <P.ProfileAvatar
                                                    onClick={() => {
                                                        pinCodeRef.clear();
                                                        profileNameRef.clear();
                                                        avatarSelection.show();
                                                    }}
                                                    avatar={state.avatar}
                                                />
                                                <S.ProfileNameInput
                                                    ref={
                                                        profileNameRef.firstRef
                                                    }
                                                    name="profile_name"
                                                    value={state.profileName}
                                                    placeholder={t(
                                                        'profile.profile_name'
                                                    )}
                                                    onChange={
                                                        callbacks.onProfileName
                                                    }
                                                />
                                            </S.LeftPan>
                                            <S.RightPan>
                                                <S.RowLayout fullWidth>
                                                    <P.ContentRating
                                                        defaultValue={
                                                            state.contentRating
                                                        }
                                                        onChange={
                                                            callbacks.onContentRatingChange
                                                        }
                                                    />
                                                    <P.ParentalControl />
                                                </S.RowLayout>
                                                <P.ProfileLock
                                                    ref={pinCodeRef.firstRef}
                                                    onLockChange={
                                                        callbacks.onProfileLockChange
                                                    }
                                                    locked={state.locked}
                                                    onPinChange={
                                                        callbacks.onPINChange
                                                    }
                                                    defaultPIN={state.pinCode}
                                                />
                                            </S.RightPan>
                                        </S.MainContent>
                                        <S.NicknameSuggestions
                                            isVisible={
                                                nicknameSuggestions.length > 0
                                            }
                                        >
                                            {t(
                                                'profile.profile_name_already_taken'
                                            )}{' '}
                                            {nicknameSuggestions
                                                .slice(0, 3)
                                                .map(
                                                    (suggestion, index) =>
                                                        (index ? ', ' : '') +
                                                        suggestion
                                                )}
                                        </S.NicknameSuggestions>
                                    </S.Content>

                                    {state.mode === 'create' && (
                                        <P.CreateButtons
                                            state={state}
                                            callbacks={callbacks}
                                        />
                                    )}
                                    {state.mode === 'update' && (
                                        <P.UpdateButtons
                                            state={state}
                                            callbacks={callbacks}
                                        />
                                    )}
                                </S.Wrapper>
                            </If>
                        </BlurBox>
                    </SharedElement>
                </RowLayout>
            </If>
        </>
    );
}
