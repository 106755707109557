import { NeutralButton, PrimaryButton } from 'components/ui/buttons';
import { Checkbox } from 'components/ui/checkbox/Checkbox';
import { RowLayout } from 'components/ui/index';
import { MessageBoxWrapper } from 'components/ui/message-box';
import { encrypt } from 'helpers';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Authentication, LocalStore } from 'services';
import * as S from './ResetPasswordBox.style';

export function ResetPasswordBox({ onAccept, onDecline }) {
    const { t } = useTranslation();
    const [bypassBox, setBypassBox] = useState(true);

    const storeRemindMe = () => {
        const username = Authentication.GetUsername();
        const bypassWarnings =
            JSON.parse(LocalStore.Get('bypass_first_connection_warning')) || {};
        bypassWarnings[encrypt(username, 'username')] = bypassBox;
        LocalStore.Set(
            'bypass_first_connection_warning',
            JSON.stringify(bypassWarnings)
        );
    };

    // Note: nested backdrop-filters does not work
    return createPortal(
        <MessageBoxWrapper>
            <S.MessageWrapper>
                {t('recovery.first_connection')}
            </S.MessageWrapper>
            <S.Wrapper>
                <Checkbox
                    onChange={() => {
                        setBypassBox((prevRemindMe) => !prevRemindMe);
                    }}
                    defaultChecked={bypassBox}
                />
                <S.Text>{t('recovery.do_not_remind')}</S.Text>
            </S.Wrapper>
            <RowLayout>
                <PrimaryButton
                    style={{ marginRight: '1rem' }}
                    onClick={() => {
                        storeRemindMe();
                        onAccept();
                    }}
                    bold
                >
                    {t('buttons.reset')}
                </PrimaryButton>
                <NeutralButton
                    onClick={() => {
                        storeRemindMe();
                        onDecline();
                    }}
                    bold
                >
                    {t('buttons.continue')}
                </NeutralButton>
            </RowLayout>
        </MessageBoxWrapper>,
        document.body
    );
}
