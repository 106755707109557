export const IconTouchscreen = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="23"
            fill="none"
            viewBox="0 0 17 23"
            {...props}
        >
            <path
                fill="#FDFDFD"
                d="M15.421 11.526A1.124 1.124 0 0014.3 12.65v.682a.237.237 0 11-.474 0v-1.91a1.123 1.123 0 10-2.243 0v1.604a.237.237 0 01-.474 0v-3.01a1.122 1.122 0 00-2.244 0v3.006a.237.237 0 01-.473 0V5.995a1.123 1.123 0 10-2.244 0v10.088c0 .09-.05.174-.132.214a.237.237 0 01-.249-.027l-3.932-3.012a1.147 1.147 0 00-1.497 1.729L6.9 21.382a3.243 3.243 0 002.276.935h2.355a5.017 5.017 0 005.01-5.01V12.65a1.123 1.123 0 00-1.12-1.123z"
            ></path>
            <path
                stroke="#FDFDFD"
                strokeLinecap="round"
                strokeWidth="1.083"
                d="M.955 7.209S.955 1 7.165 1c6.209 0 6.209 6.209 6.209 6.209"
            ></path>
            <path
                stroke="#FDFDFD"
                strokeLinecap="round"
                strokeWidth="1.08"
                d="M2.866 7.686s0-4.776 4.298-4.776c4.299 0 4.299 4.776 4.299 4.776"
            ></path>
        </svg>
    );
};
