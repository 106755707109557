import { hasTouch, isHP } from 'app/device';
import Routes from 'app/routes';
import { Games } from 'services';

// MAIN

// CHARTS DELETED FROM MAIN MENU
// const CHARTS = {
//     name: 'charts',
//     route: Routes.GAMES.CHARTS,
// };

const HIGHLIGHTS = {
    name: 'highlights',
    route: Routes.GAMES.HIGHLIGHTS,
};

const SUBSCRIBE = {
    name: 'subscribe',
    route: Routes.SUBSCRIBE,
    titleKey: 'navigation.upgrade',
};

const TOUCH = {
    name: 'touch',
    route: Routes.GAMES.TOUCH,
    subMenu: ['touchscreen', 'virtual_gamepad'],
};

const MULTI = {
    name: 'multiplayer',
    route: Routes.GAMES.MULTI,
};

const CATEGORIES_BASE = {
    name: 'categories',
    route: Routes.GAMES.CATEGORIES,
};

// ASIDE
const SEARCH = {
    name: 'search',
    route: Routes.GAMES.SEARCH,
};

const FAVORITES = {
    name: 'favorites',
    route: Routes.GAMES.FAVORITES,
};

const SETTINGS = {
    name: 'settings',
    route: '',
};

// BASE_NAVIGATON
export const BASE_NAVIGATION = {
    main: [],
    aside: [SETTINGS],
};

export const getNavigation = () => {
    // Deep clone using JSON method, don't spread... ! we should include DeepClone from loadash in the future for more flexibility.
    // see differences between JSON method and DeepClone from loadash : https://www.samanthaming.com/tidbits/70-3-ways-to-clone-objects/#shallow-clone-vs-deep-clone
    let navigation = JSON.parse(JSON.stringify(BASE_NAVIGATION));

    // categories may change depending on user profile
    const CATEGORIES = {
        ...CATEGORIES_BASE,
        // subMenu: [...Games.GetFilteredCategories().map((c) => c.name)],
        subMenu: ['action', 'adventure', 'sports', 'strategy', 'kids', 'rpg'],
    };

    /**
     * Multi categ is added if its not mobile
     */
    const addTouchOrMulti = () => {
        // add touch device category
        if (
            hasTouch() &&
            (Games.GetCategoryGames('touchscreen')?.length ||
                Games.GetCategoryGames('virtual_gamepad')?.length) > 0
        ) {
            if (process.env.NODE_ENV === 'development')
                console.log('NAVIGATION --> DISCOVERY AND TOUCH');

            navigation.main.push(TOUCH);
        } else if (Games.GetCategoryGames('multiplayer').length > 0) {
            navigation.main.push(MULTI);
        }
    };

    /**
     * DISCOVERY NAVIGATION
     */
    if (Games.isDiscoveryOffer()) {
        if (process.env.NODE_ENV === 'development')
            console.log('NAVIGATION --> DISCOVERY');

        // in discovery mode, the highlights page is shown with a different name
        const _HIGHLIGHTS = {
            ...HIGHLIGHTS,
            titleKey: 'navigation.games',
        };

        // add highlights
        navigation.main.splice(0, 0, _HIGHLIGHTS);

        addTouchOrMulti();

        // add subscribe/offers
        if (Games.GetSubscribableOffers().length > 0) {
            navigation.main.push(SUBSCRIBE);
        }
    } else if (isHP) {
        /**
         * HP NAVIGATION
         */
        if (process.env.NODE_ENV === 'development')
            console.log('NAVIGATION --> HOPSITLATY');

        // add categories first place of main menu
        navigation.main.splice(0, 0, CATEGORIES);

        // add search to main nav
        navigation.main.push(SEARCH);
    } else {
        /**
         * REGULAR NAVIGATION
         */
        if (process.env.NODE_ENV === 'development')
            console.log('NAVIGATION --> DEFAULT');

        // add highlights
        navigation.main.splice(0, 0, HIGHLIGHTS);

        // add categorires
        navigation.main.push(CATEGORIES);

        // add search - 1st
        navigation.aside.splice(0, 0, SEARCH);

        // add favorites  - 2nd
        // TODO: Games.FavoritesEnabled()
        navigation.aside.splice(1, 0, FAVORITES);

        addTouchOrMulti();
    }

    return navigation;
};
