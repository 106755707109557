import { HoursRange } from 'components/parental-control/hours-range';
import { ActionButtons } from 'components/ui';
import {
    SpatialNavProvider,
    SpatialNavSection,
    useSpatialNavContext,
    withInputDispatcherProvider,
} from 'context';
import { If } from 'helpers';
import { useBackPress } from 'hooks';
import { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { TimeAmount } from '../daily-schedule/daily-time/time-amount/TimeAmount';
import * as S from './TimeBox.style';

// can't use MessageBoxWrapper here
export const TimeBox = withInputDispatcherProvider(
    ({
        enabled = null,
        amount = null,
        onAmountChange = null,
        range = null,
        onValidate,
        onReset,
        lastFocusedRef,
        title,
    }) => {
        const [editing, setEditing] = useState(false);
        const { setFocus } = useSpatialNavContext();

        const { t } = useTranslation();

        const _onValidate = useCallback(() => {
            if (editing) {
                return;
            }
            // onValidate may be undefined
            onValidate?.();
            setFocus(lastFocusedRef.current);
        }, [editing, lastFocusedRef, onValidate, setFocus]);

        const _onReset = () => {
            if (editing) {
                return;
            }
            onReset?.();
        };

        useBackPress(_onValidate);

        const timeBox = (
            <SpatialNavProvider>
                <SpatialNavSection focusOnMount enterTo="default-element">
                    <S.Wrapper>
                        <S.Content>
                            <S.Title>{title}</S.Title>
                            <S.HoursWrapper>
                                <If condition={range}>
                                    <HoursRange
                                        format="hour"
                                        range={range}
                                        onEditingChange={setEditing}
                                    />
                                </If>
                                <If condition={amount !== null}>
                                    <TimeAmount
                                        defaultElement
                                        format="hr"
                                        enabled={enabled}
                                        amount={amount}
                                        onAmountChange={onAmountChange}
                                        onEditingChange={setEditing}
                                    />
                                </If>
                            </S.HoursWrapper>
                            <ActionButtons
                                disabled={editing}
                                buttons={[
                                    {
                                        type: 'primary',
                                        text: t('buttons.validate'),
                                        callback: _onValidate,
                                    },
                                    {
                                        type: 'regular',
                                        text: t('buttons.reset'),
                                        callback: _onReset,
                                    },
                                ]}
                            />
                        </S.Content>
                    </S.Wrapper>
                </SpatialNavSection>
            </SpatialNavProvider>
        );

        return ReactDOM.createPortal(timeBox, document.body);
    }
);
