import { GameItem } from 'components/games';
import { RowLayout } from 'components/ui';
import { SpatialNavSection, useSpatialNavContext } from 'context';
import { MultiView, View } from 'context/multi-view';
import { useActionTopPress } from 'hooks';
import { useToggleFavorite } from 'hooks/games/useToggleFavorite';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { Games } from 'services';
import * as S from './GameWindowMinimized.style';
import { DescriptionView } from './views/description/DescriptionView';
import { MoreView } from './views/more/MoreView';

export const GameWindowMinimized = React.forwardRef(
    ({ game, ...props }, forwardedRef) => {
        const favorite = useToggleFavorite();
        const wrapperRef = useRef();
        const playButtonRef = useRef();
        const { refreshTree } = useSpatialNavContext();

        useEffect(() => {
            // refresh tree otherwise can create navigation issue (elements positions saved by spatialNav can be wrong after transition)
            const onDetailWindowAnimationEnd = () => {
                refreshTree(true);
            };

            // capture wrapperRef.current when the effect is executed
            const wrapper = wrapperRef.current;
            wrapper.addEventListener(
                'transitionend',
                onDetailWindowAnimationEnd
            );

            // cleanup function will use the value captured at the time the effect was first run
            return () => {
                wrapper.removeEventListener(
                    'transitionend',
                    onDetailWindowAnimationEnd
                );
            };
        }, [refreshTree]);

        //use external and internal ref cf : https://medium.com/vimeo-engineering-blog/handling-internal-and-external-refs-to-the-same-element-with-useimperativehandle-in-react-746ff6d377fe
        useImperativeHandle(forwardedRef, () => wrapperRef.current);

        useActionTopPress(() => {
            if (!Games.FavoritesEnabled()) {
                return;
            }
            //check if focus is on game window minimized
            if (wrapperRef.current.contains(document.activeElement)) {
                favorite.toggleGame(game.alias);
            }
        });

        return (
            <SpatialNavSection enterTo="default-element" animatable={true}>
                <S.Wrapper ref={wrapperRef}>
                    <RowLayout fullSize>
                        <S.Cover>
                            <GameItem game={game} src={game.assets.cover} />
                        </S.Cover>
                        <MultiView defaultView="description">
                            <View
                                name="description"
                                component={DescriptionView}
                                wrapperRef={wrapperRef}
                                playButtonRef={playButtonRef}
                                gridRef={forwardedRef}
                                game={game}
                                {...props}
                            />
                            <View
                                name="moreView"
                                component={MoreView}
                                playButtonRef={playButtonRef}
                                game={game}
                                {...props}
                            />
                        </MultiView>
                    </RowLayout>
                </S.Wrapper>
            </SpatialNavSection>
        );
    }
);
