import styled from 'styled-components';
import Theme from 'app/theme';

export const BlurMenuBase = styled.div`
    backdrop-filter: ${(props) =>
        `blur(${Theme.BLUR_AMOUNT}px)
        brightness(${Theme.BLUR_BRIGHTNESS + (props.isMain ? 10 : 0)}%)
        contrast(${Theme.BLUR_CONTRAST}%)
        saturate(${Theme.BLUR_SATURATE}%)
    `};
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 25px;
`;
