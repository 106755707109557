import { isAndroid } from 'app/device';
import { QuitWrapper } from 'components';
import { GamesWrapper, Grid, NoGamesFound } from 'components/games';
import { GameWindowSwiper } from 'components/games/game-window-swiper/GameWindowSwiper';
import {
    MostSearched,
    SearchBox,
    SearchBoxWrapper,
} from 'components/games/search';
import { ColumnLayout } from 'components/ui';
import { BigTitleWrapper } from 'components/ui/glass-title/GlassTitle.style';
import { useSpatialNavContext } from 'context';
import { If } from 'helpers';
import { useGamesSwiper, useSearch, useTransitionComplete } from 'hooks';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Games } from 'services';

export function SearchScreen() {
    const {
        games: searchGames,
        columns,
        setCurrentCue,
        cues,
        currentCue,
        isMostSearchedVisible,
        onFocusSearchBox,
        onBlurSearchBox,
        searchInputRef,
    } = useSearch();
    const [games, setGames] = useState(searchGames);
    const gamesSwiper = useGamesSwiper(games);
    const cuesRef = useRef();
    const { refreshTree, setFocus } = useSpatialNavContext();
    const { t } = useTranslation();
    const suggestedGames = Games.getSuggestedGames().slice(0, 5);

    const mostSearchedRef = useTransitionComplete(() => {
        refreshTree();
    }, []);

    const openWindowWithGameList = (gameList) => (e, alias) => {
        setGames(gameList);
        gamesSwiper.open(e, alias);
    };

    return (
        <>
            <QuitWrapper disabled={gamesSwiper.visible} />
            <ColumnLayout alignItems="center" fullSize>
                <If condition={gamesSwiper.visible}>
                    <GameWindowSwiper
                        gamesSwiper={gamesSwiper}
                        selectedIndex={gamesSwiper.selectedIndex}
                        games={games}
                    />
                </If>
                <GamesWrapper>
                    <ColumnLayout fullWidth>
                        <SearchBoxWrapper>
                            <BigTitleWrapper isSearch={true}>
                                <SearchBox
                                    ref={mostSearchedRef}
                                    currentCue={currentCue}
                                    onFocus={onFocusSearchBox}
                                    onBlur={onBlurSearchBox}
                                    isGapOpen={gamesSwiper.gapOpen}
                                    lastFocused={gamesSwiper.lastFocused}
                                    searchInputRef={searchInputRef}
                                    onMoveDown={() => {
                                        return cuesRef.current
                                            ? setFocus(cuesRef.current)
                                            : true;
                                    }}
                                    onSearch={setCurrentCue}
                                />
                                {/*
                                    If NOT Android always display MostSearched otherwise check with isMostSearchedVisible (checking if android virtual keyboard is opened or not) to
                                    prevent layout shift
                                */}
                                <If
                                    condition={
                                        !isAndroid || isMostSearchedVisible
                                    }
                                >
                                    <MostSearched
                                        ref={cuesRef}
                                        cues={cues}
                                        onCueSelected={setCurrentCue}
                                        isGapOpen={gamesSwiper.gapOpen}
                                        lastFocused={gamesSwiper.lastFocused}
                                        suggestedGames={suggestedGames}
                                        onItemClicked={openWindowWithGameList(
                                            suggestedGames
                                        )}
                                    />
                                </If>
                            </BigTitleWrapper>
                        </SearchBoxWrapper>
                        <If
                            condition={
                                searchGames.length <= 0 && currentCue !== ''
                            }
                        >
                            <NoGamesFound small={true}>
                                {t('search.no_games')}
                            </NoGamesFound>
                        </If>
                        <If condition={searchGames.length > 0}>
                            <Grid
                                isGapOpen={gamesSwiper.gapOpen}
                                lastFocused={gamesSwiper.lastFocused}
                                onItemClicked={openWindowWithGameList(
                                    searchGames
                                )}
                                columns={columns}
                                key={currentCue}
                            />
                        </If>
                    </ColumnLayout>
                </GamesWrapper>
            </ColumnLayout>
        </>
    );
}
