import { isMobileOrTablet } from 'app/device';
import { IconButton, IconTrailer, NeutralButton } from 'components/ui';
import { withInputDispatcherProvider } from 'context';
import { If } from 'helpers';
import { useSoundAction } from 'hooks';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trailer } from './Trailer';

const initialState = { visible: false, muted: false };

export const TrailerButton = withInputDispatcherProvider(
    forwardRef(({ game, ...props }, ref) => {
        const { t } = useTranslation();
        const [trailerStatus, setTrailerStatus] = useState(initialState);

        const closeWithSound = useSoundAction('back', () => {
            setTrailerStatus(initialState);
        });

        const closeWithoutSound = () => {
            setTrailerStatus(initialState);
        };

        const start = useSoundAction('regularBtn', (event) => {
            // on mobile devices, auto playback of videos is only allowed if muted, unless
            // it results from a direct user action (trusted event created by the browser)
            // https://webkit.org/blog/6784/new-video-policies-for-ios
            // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
            setTrailerStatus({
                visible: true,
                muted: isMobileOrTablet || !event.isTrusted,
            });
        });

        const { visible, muted } = trailerStatus;

        return (
            <>
                <If condition={visible}>
                    <Trailer
                        game={game}
                        muted={muted}
                        closeWithSound={closeWithSound}
                        closeWithoutSound={closeWithoutSound}
                    />
                </If>
                <IconButton
                    {...props}
                    ref={ref}
                    button={NeutralButton}
                    icon={IconTrailer}
                    onClick={start}
                >
                    {t('buttons.trailer')}
                </IconButton>
            </>
        );
    })
);
