import { useSharedElementCloneContext, useSpatialNavContext } from 'context';
import { useSwiper } from 'hooks';
import { useCallback, useEffect } from 'react';

// initially : customisation of the swiper for profiles list
// now: customisation of the swiper for lists (with multiple items in the same view!)
export const useMultiItemsPerViewSwiper = (...params) => {
    const swiper = useSwiper(...params);
    const { activeIndex, slideRefs } = swiper;
    const { setFocus } = useSpatialNavContext();

    // the prev and next callbacks will eventually triger a change of the active
    // index, and we set focus from that
    // because we are managing focus manually on the slides, this is also
    // called on the slides' onFocus to override the default focus from
    // the nav system's geometrical computations (emulates last-focused)
    // /!\ the profiles list may be cloned inside a SharedElement, we must only change
    // the focus for the main element because the clone will be unmounted and then we
    // would lose focus
    const isClone = useSharedElementCloneContext();
    const focusActiveItem = useCallback(() => {
        if (!isClone && slideRefs[activeIndex]?.current) {
            setFocus(slideRefs[activeIndex].current);
        }
    }, [activeIndex, isClone, slideRefs, setFocus]);
    useEffect(focusActiveItem, [focusActiveItem]);

    return { ...swiper, focusActiveItem };
};
