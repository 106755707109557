import { useSelector } from 'react-redux';
import { gameLobbySelector, LOBBY_STATE } from 'slices';
import { LobbyHeader } from './lobby-header/LobbyHeader';
import * as S from './Lobby.style';
import { Player } from './player/Player';

export function Lobby({ game }) {
    const { players, ownerUID, lobbyState } = useSelector(gameLobbySelector);

    return (
        <S.Wrapper>
            <LobbyHeader game={game} />
            <S.PlayersWrapper>
                {[...Array(game.nb_players_online_multi)].map((_, index) => (
                    <Player
                        key={index}
                        index={index}
                        player={index < players.length && players[index]}
                        lobbyConnected={
                            lobbyState.id > LOBBY_STATE.CONNECTING.id
                        }
                        isOwner={
                            index < players.length &&
                            players[index].uid === ownerUID
                        }
                    />
                ))}
            </S.PlayersWrapper>
        </S.Wrapper>
    );
}
