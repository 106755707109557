import { useGamesWallpapers } from 'app/config';
import { transitionDuration } from 'components/gap-animation/GapAnimation';
import { useSpatialNavContext, useThemeContext } from 'context';
import { useAppSound } from 'hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Games } from 'services';
import { hideGameWindow, showGameWindow } from 'slices';

export function useGamesSwiper(games) {
    const [visible, setVisible] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();
    const [selectedAlias, setSelectedAlias] = useState('');
    const dispatch = useDispatch();
    const lastFocused = useRef();
    const defaultView = useRef('');
    const currentGameWindowRef = useRef();
    const [playGameWindowOpen] = useAppSound('gameWindow');
    const { setFocus, refreshTree } = useSpatialNavContext();
    const [isClosing, setIsClosing] = useState(false);
    const { startWallpaperLoop } = useThemeContext();
    const [gapOpen, setGapOpen] = useState();
    const refocusDelayRef = useRef(0);
    const { pushParallax, popParallax } = useThemeContext();
    const [marketingWindow, setMarketingWindow] = useState(false);

    const open = useCallback(
        (e, alias) => {
            // e is unused, but this can be called by something expecting a generic callback that
            // *does* take an event as first argument
            // see use of GameItem in Column.js
            clearInterval(refocusDelayRef.current);
            playGameWindowOpen();
            lastFocused.current = document.activeElement;
            // in some screens (charts, search), the game list may be changed just before the window
            // is opened so the index cannot be set directly, otherwise there would be a short time
            // with a mismatch (or no match) between the games list and the requested index
            // instead we set the selected alias, and the effect below will set the correct index
            // from the alias and games list once both are set, then make the window visible
            setSelectedAlias(alias);
            dispatch(showGameWindow());
            setGapOpen(true);
            pushParallax(0);
        },
        [dispatch, playGameWindowOpen, setGapOpen, pushParallax]
    );

    const quickMatch = useCallback(
        (alias) => {
            defaultView.current = 'multi';
            open(null, alias);
        },
        [open]
    );

    const close = useCallback(() => {
        if (useGamesWallpapers) {
            // restart default wallaper loop
            startWallpaperLoop();
        }

        // WEIRD: alias *must* be reset before setting visible to false otherwise it causes
        // multiple effect reruns when closing the game window using a backpress, see notes in MEWW-563
        setSelectedAlias(null);
        setVisible(false);
        setGapOpen(false);

        //retriving last focused element in grid with current selected alias
        //quick patch for case when not in grid e.g: mostSearched thumbnails
        //TODO: refocus the right cover after prev or next within mostSearched
        lastFocused.current =
            document.querySelector(
                `.grid-game-item[data-alias="${selectedAlias}"]`
            ) || lastFocused.current;

        dispatch(hideGameWindow());
        popParallax();
        defaultView.current = '';
        setIsClosing(true);
    }, [dispatch, popParallax, startWallpaperLoop, selectedAlias, setGapOpen]);

    // computes the selected index from the set of games and selected alias
    // and shows the window
    useEffect(() => {
        if (games.length === 0) {
            setVisible(false);
            return;
        }
        const index = games.indexOf(Games.GetGameFromAlias(selectedAlias));
        setSelectedIndex(index);

        if (index < 0) {
            if (selectedAlias && visible) {
                // index may be -1 if the list of games changes and doesn't contain the selected alias
                // e.g. unfavorite the current game in the favorites screen
                // in that case select the game at the current location, or the last one if we're out
                // of the new list
                const newIndex =
                    selectedIndex < games.length
                        ? selectedIndex
                        : games.length - 1;
                setSelectedAlias(games[newIndex].alias);
            } else {
                // no index because no alias - ensure the swiper is not visible
                setVisible(false);
            }
        } else {
            setVisible(true);
        }
    }, [games, selectedAlias, selectedIndex, visible]);

    useEffect(() => {
        if (isClosing) {
            refocusDelayRef.current = setTimeout(() => {
                refreshTree(true);
                setIsClosing(false);
                if (!marketingWindow) {
                    setFocus(lastFocused.current);
                } else {
                    const marketingActiveSlide = document.querySelector(
                        '.marketing-swiper .swiper-slide-active'
                    );

                    const activeSlideFirstBtn =
                        marketingActiveSlide.querySelector(
                            '[data-type="action-button-wrapper"] [data-btn="details"]'
                        );

                    setFocus(activeSlideFirstBtn);
                    setMarketingWindow(false);
                }
            }, transitionDuration); //transition duration export from GapAnimation.js to match animation
        }
    }, [setFocus, refreshTree, isClosing, marketingWindow]);

    // because the index is computed from the alias, it should not be set directly
    // from outside the hook. These two helpers correctly compute the previous and
    // next aliases from the current state and must be used instead to switch between games
    const prev = useCallback(() => {
        setSelectedAlias((prevAlias) => {
            const index = games.indexOf(Games.GetGameFromAlias(prevAlias));
            if (index > 0) {
                return games[index - 1].alias;
            }
            return prevAlias;
        });
    }, [games]);

    const next = useCallback(() => {
        setSelectedAlias((prevAlias) => {
            const index = games.indexOf(Games.GetGameFromAlias(prevAlias));

            if (index < games.length - 1) {
                return games[index + 1].alias;
            }
            return prevAlias;
        });
    }, [games]);

    return {
        visible,
        defaultView,
        open,
        close,
        quickMatch,
        next,
        prev,
        selectedIndex,
        lastFocused,
        gapOpen,
        currentGameWindowRef,
        marketingWindow,
        setMarketingWindow,
    };
}
