import Theme from 'app/theme';
import styled from 'styled-components';

export const ArrowBase = styled.div`
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 10;
    width: 40px;
    height: 40px !important;
    cursor: pointer;
    display: ${(props) => (props.visible ? 'flex' : 'none')};
    background-color: ${Theme.COLORS.PRIMARY_L2};
`;

export const ArrowLeft = styled(ArrowBase)`
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    position: absolute;
    left: -55px;

    &:after {
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-right: 15px solid white;
    }
`;

export const ArrowRight = styled(ArrowBase)`
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    right: -55px;

    &:after {
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;

        border-left: 15px solid white;
    }
`;
