import { isHP } from 'app/device';
import history from 'app/history';
import Routes from 'app/routes';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideGlobalNavigation, initPassCheck } from 'slices';

export function useQuit() {
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();

    const callbacks = useMemo(
        () => ({
            doQuit: () => {
                if (isHP) {
                    dispatch(hideGlobalNavigation());
                    dispatch(
                        initPassCheck({
                            from: history.location,
                            to: 'quit',
                        })
                    );

                    history.push(Routes.PASSWORD_CHECK);
                    setVisible(false);
                } else {
                    window.close();
                }
                // tizen object is not available when the app is remotely distributed
                //if (window.tizen)
                //    window.tizen.application.getCurrentApplication().exit();
            },

            decline: () => {
                setVisible(false);
            },

            show: () => {
                setVisible(true);
            },
        }),
        [dispatch]
    );

    return { visible, callbacks };
}
