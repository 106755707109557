import Routes from 'app/routes';
import { useAppSound } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { LocalStore } from 'services';
import { soundSettingsSelector } from 'slices';
import { mergeObjects } from 'helpers';

export function useGlobalSound() {
    let location = useLocation();
    const { atmosSound, atmosSoundVolume, uiSound, uiSoundVolume } =
        useSelector(soundSettingsSelector);
    const [loginLoopIsPlaying, setLoginLoopIsPlaying] = useState(false);
    const [atmosLoopIsPlaying, setAtmosLoopIsPlaying] = useState(false);

    //isPlaying has been deleted, you now have to listen for howl events onplay/onend and create your own state https://github.com/joshwcomeau/use-sound/commit/6be141d6af2892f0be0ca75f496ad711cd1f7bca
    const loginLoopParams = useMemo(
        () => ({
            loop: true,
            onplay: () => setLoginLoopIsPlaying(true),
            onstop: () => setLoginLoopIsPlaying(false),
        }),
        []
    );
    const [playLoginLoop, loginLoop] = useAppSound(
        'atmosLogin',
        loginLoopParams
    );

    const atmosLoopParams = useMemo(
        () => ({
            loop: true,
            onplay: () => setAtmosLoopIsPlaying(true),
            onstop: () => setAtmosLoopIsPlaying(false),
        }),
        []
    );
    const [playAtmosLoop, atmosLoop] = useAppSound(
        'atmosLoop',
        atmosLoopParams
    );

    // update settings in LocalStorage when changed
    useEffect(() => {
        const currentSettings = {
            settings: {
                sound: {
                    uiSound,
                    uiSoundVolume,
                    atmosSound,
                    atmosSoundVolume,
                },
            },
        };

        // merge with stored settings or create new if nothing stored
        const storedSettings = JSON.parse(LocalStore.Get('settings'));
        const newSettings = storedSettings
            ? mergeObjects(storedSettings, currentSettings)
            : currentSettings;

        LocalStore.Set('settings', JSON.stringify(newSettings));
    }, [uiSound, atmosSound, uiSoundVolume, atmosSoundVolume]);

    useEffect(() => {
        const atmosLoopRoutes = [
            Routes.GAMES.HIGHLIGHTS,
            Routes.GAMES.CHARTS,
            Routes.GAMES.CATEGORIES,
            Routes.GAMES.SEARCH,
            Routes.GAMES.FAVORITES,
        ];

        const storedSettings = JSON.parse(LocalStore.Get('settings'));

        if (atmosSound) {
            // if atmos loop should be played in current root
            if (atmosLoopRoutes.includes(location.pathname)) {
                if (
                    !atmosLoopIsPlaying &&
                    storedSettings.settings.sound.atmosSound
                ) {
                    playAtmosLoop();
                }
                if (loginLoopIsPlaying) {
                    loginLoop.stop();
                }
            }
        } else {
            atmosLoop.stop();
            loginLoop.stop();
        }
    }, [
        atmosSound,
        location.pathname,
        atmosLoopIsPlaying,
        loginLoopIsPlaying,
        playLoginLoop,
        playAtmosLoop,
        atmosLoop,
        loginLoop,
    ]);
}
