import { If } from 'helpers';
import { NewGamesNotification } from './new-game/NewGamesNotification';
import {
    OfferExpirationNotification,
    useOfferExpirationNotification,
} from './offer-expiration';

export const TodaysNotifications = () => {
    const { shownThisSession } = useOfferExpirationNotification();

    return (
        <>
            <OfferExpirationNotification />
            <If condition={!shownThisSession}>
                <NewGamesNotification />
            </If>
        </>
    );
};
