import { ParentalControlScheduler } from 'components/parental-control/scheduler';
import { RowLayout } from 'components/ui';
import { MultiStep, Step } from 'context/multi-step';
import { If } from 'helpers';
import { useSelector } from 'react-redux';
import { parentalControlSelector } from 'slices';
import { StepDailyUsage, StepFinished, StepHours, StepRange } from './wizard';

export function ParentalControlScreen() {
    const { needWizard } = useSelector(parentalControlSelector);

    // array of components for multiStep, /!\ must be in the right order
    const stepComponents = [
        <StepDailyUsage />,
        <StepHours />,
        <StepRange />,
        <StepFinished />,
    ];

    return (
        <RowLayout alignItems="center" justifyContent="center" fullSize>
            <If condition={needWizard}>
                <MultiStep defaultStep={1}>
                    {stepComponents.map((component, i) => (
                        <Step
                            key={`step${i}`}
                            name={i + 1}
                            component={component}
                        />
                    ))}
                </MultiStep>
            </If>
            <If condition={!needWizard}>
                <ParentalControlScheduler />
            </If>
        </RowLayout>
    );
}
