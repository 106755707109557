import { isHP } from 'app/device';
import history from 'app/history';
import { useSpatialNavContext, useThemeContext } from 'context';
import { useHomePress } from 'hooks';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    globalNavigationSelector,
    hideGameWindow,
    profileSelector,
    setCurrentAsideTab,
    setCurrentSubTab,
    setCurrentTab,
} from 'slices';

export function useGlobalNavigation() {
    const { pathname } = useLocation();
    const {
        navigation,
        currentTab,
        currentAsideTab,
        menuVisible,
        gameWindowOpen,
    } = useSelector(globalNavigationSelector);
    const dispatch = useDispatch();
    const { setStaticWallpaper, startWallpaperLoop } = useThemeContext();
    const { resetLastFocused } = useSpatialNavContext();
    const { currentProfile } = useSelector(profileSelector);

    const isNavigationInitialized = useMemo(
        () => navigation && Object.keys(navigation).length > 0,
        [navigation]
    );

    const onTabSelected = useCallback(
        (tab) => {
            // Route to target tabpuse
            history.push(tab.route);
            dispatch(setCurrentTab(tab.name));
            dispatch(hideGameWindow()); // hide gameWindow to display submenus
            //if tab has subMenu, set currentSubTab to first item of subMenu
            if (tab.subMenu) {
                dispatch(setCurrentSubTab(tab.subMenu[0]));
                dispatch(setCurrentAsideTab(''));
            }
        },
        [dispatch]
    );

    // automatically select tab from direct route changes
    useEffect(() => {
        const tab = navigation.main.find((t) => t.route === pathname);
        if (tab) {
            onTabSelected(tab);
        }
    }, [navigation.main, onTabSelected, pathname]);

    useHomePress(
        useCallback(() => {
            if (isHP && currentProfile) {
                // prevent home btn on profile selection screen (even if selection profile screen is skipped in HP)
                resetLastFocused();
                onTabSelected('categories');
            }
        }, [currentProfile, onTabSelected, resetLastFocused])
    );

    useEffect(() => {
        if (menuVisible) {
            startWallpaperLoop();
        } else {
            setStaticWallpaper('login');
        }
    }, [setStaticWallpaper, startWallpaperLoop, menuVisible]);

    const onSubTabSelected = (item) => {
        dispatch(setCurrentSubTab(item));
    };

    return {
        navigation,
        currentTab,
        currentAsideTab,
        menuVisible,
        onTabSelected,
        onSubTabSelected,
        gameWindowOpen,
        isNavigationInitialized,
    };
}
