import { If } from 'helpers';
import React from 'react';
import * as S from './MessageBoxTitle.style';

export function MessageBoxTitle({
    title,
    Icon,
    title2,
    alignItems,
    titleCenter = 'initial',
    title2Center = 'initial',
}) {
    return (
        <S.BoxTitleWrapper style={{ alignItems: alignItems || 'flex-start' }}>
            <S.Wrapper>
                <If condition={Icon}>
                    <S.IconTitle>
                        <Icon />
                    </S.IconTitle>
                </If>
                <If condition={title}>
                    <S.BoxTitle style={{ textAlign: titleCenter }}>
                        {title}
                    </S.BoxTitle>
                </If>
            </S.Wrapper>

            <If condition={title2}>
                <S.BoxTitle2 style={{ textAlign: titleCenter }}>
                    {title2}
                </S.BoxTitle2>
            </If>
        </S.BoxTitleWrapper>
    );
}
