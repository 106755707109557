import { EulaButton, GameCopyright } from 'components/games';
import { ActionButtons, RowLayout } from 'components/ui';
import { useMultiViewContext } from 'context/multi-view';
import { useBackPress } from 'hooks';
import { useTranslation } from 'react-i18next';
import * as View from '../View.style';
import * as S from './MoreView.style';

export function MoreView({ game, playButtonRef }) {
    const { t } = useTranslation();
    const { setView } = useMultiViewContext();

    const close = () => {
        setView('description');
        playButtonRef.current?.focus();
    };

    useBackPress(close);

    const actionButtons = (
        <ActionButtons
            vertical
            overrideMoveDown={close}
            defaultElement
            Wrapper={S.ActionButtonsWrapper}
            buttons={[
                {
                    type: 'primary',
                    text: t('buttons.ok'),
                    callback: close,
                    props: {
                        focusOnMount: true,
                        game,
                        disableMoveRight: true,
                    },
                },
                {
                    component: EulaButton,
                    props: {
                        game,
                        disableMoveRight: true,
                    },
                },
            ]}
            style={{ justifyContent: 'start' }}
        />
    );

    return (
        <RowLayout fullSize>
            <View.LeftPan>{actionButtons}</View.LeftPan>
            <View.RightPan>
                <S.Content>
                    <GameCopyright game={game} />
                </S.Content>
            </View.RightPan>
        </RowLayout>
    );
}
