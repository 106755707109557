import { assetURL, If } from 'helpers';
import { Fragment } from 'react';
import { Games } from 'services';
import * as S from './Header.style';

export function Header({ offer, ...props }) {
    return (
        <>
            <If condition={offer.assets.packshot}>
                {/* if we have packshot */}
                <S.ImageCover srcImage={assetURL(offer.assets.packshot)} />
            </If>
            <If condition={!offer.assets.packshot}>
                <S.HeaderWrapper>
                    {Games.GetGamesFromSubscribableOfferAlias(offer.alias)
                        .slice(0, 3)
                        .map((game, i) => {
                            return (
                                <Fragment key={game.alias}>
                                    <If condition={game.assets?.cover}>
                                        <S.ImageSquare
                                            srcImage={assetURL(
                                                game.assets?.cover
                                            )}
                                        />
                                    </If>
                                    <If condition={!game.assets?.cover}>
                                        <S.ImageSquarePlaceholder>
                                            <S.ImageSquarePlaceholderName>
                                                {game.name}
                                            </S.ImageSquarePlaceholderName>
                                        </S.ImageSquarePlaceholder>
                                    </If>
                                </Fragment>
                            );
                        })}
                </S.HeaderWrapper>
            </If>
        </>
    );
}
