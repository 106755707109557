import { IconArrowLeft, IconArrowRight, NavButton } from 'components/ui';
import { AppSwiper } from 'components/ui/swiper/AppSwiper';
import { assetURL, If } from 'helpers';
import { useMultiItemsPerViewSwiper } from 'hooks';
import { SwiperSlide } from 'swiper/react';
import * as S from './NewGamesNotification.style';

const slidesPerView = 3;

export function NewGamesListSwiper({ list, listItemSelected }) {
    const {
        prevSlide,
        nextSlide,
        swiperRef,
        setSwiperRef,
        prevRef,
        nextRef,
        onSlideChangeTransitionEnd,
        slideRefs,
        activeIndex,
        focusActiveItem,
    } = useMultiItemsPerViewSwiper({ nbSlides: list.length });

    return (
        <S.SwiperWrapper>
            <If condition={swiperRef?.params.slidesPerView < list.length}>
                <NavButton onClick={prevSlide} isDisabled={activeIndex === 0}>
                    <IconArrowLeft fill="white" />
                </NavButton>
            </If>
            <AppSwiper
                style={S.SwiperStyle}
                setSwiperRef={setSwiperRef}
                slidesPerView={slidesPerView}
                prevRef={prevRef}
                nextRef={nextRef}
                onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
                isVirtual={false}
                spaceBetween={50}
                preloadImages={true}
            >
                {list.map((game, index) => {
                    const commonProps = {
                        key: game.alias,
                        ref: slideRefs[index],
                        onClick: listItemSelected(game.alias),
                        overrideMoveLeft: prevSlide,
                        overrideMoveRight: nextSlide,
                        onFocus: focusActiveItem,
                    };

                    return (
                        <SwiperSlide
                            key={game.alias}
                            virtualIndex={index}
                            style={S.SwiperSlide}
                        >
                            {game.assets?.thumb ? (
                                <S.NewGame
                                    {...commonProps}
                                    srcImage={assetURL(game.assets.thumb)}
                                />
                            ) : (
                                <S.NewGameNoThumbnail {...commonProps}>
                                    <span>{game.assets.title}</span>
                                </S.NewGameNoThumbnail>
                            )}
                        </SwiperSlide>
                    );
                })}
            </AppSwiper>
            <If condition={swiperRef?.params.slidesPerView < list.length}>
                <NavButton
                    onClick={nextSlide}
                    isDisabled={activeIndex >= list.length - 1}
                >
                    <IconArrowRight fill="white" />
                </NavButton>
            </If>
        </S.SwiperWrapper>
    );
}
