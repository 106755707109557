import styled, { css } from 'styled-components';
import { isTV, tv } from 'app/device';
import Theme from 'app/theme';
import { withFocusable } from 'helpers';

export const Switch = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.2);
    transition: 0.4s;
    border-radius: 20px;

    &:before {
        position: absolute;
        content: '';
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        border-radius: 50%;
        transition: 0.4s;
        box-shadow: 1px 0px 2px 2px rgba(0, 0, 0, 0.25);
    }

    ${tv(css`
        &:before {
            height: 28px;
            width: 28px;
            left: 3px;
            bottom: 3px;
        }
    `)}
`;

export const Checkbox = styled.input.attrs(() => ({ type: 'checkbox' }))`
    opacity: 0;
    width: 0;
    height: 0;
`;

export const Wrapper = withFocusable(styled.div`
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
    outline: none;

    ${Checkbox}:checked + ${Switch} {
        background-color: ${Theme.COLORS.PRIMARY_L1_FOCUS};
    }

    &:focus {
        ${Checkbox} + ${Switch} {
            box-shadow: 0 0 0 ${isTV ? '2px' : '1px'} ${Theme.COLORS.PRIMARY_L2};
            background: ${Theme.COLORS.PRIMARY_L2};
        }
    }

    // checked animation
    ${Checkbox}:checked + ${Switch}:before {
        transform: translateX(14px);

        ${tv(css`
            transform: translateX(20px);
        `)}
    }

    ${tv(css`
        width: 54px;
        height: 34px;
    `)}
`);
