import { isTV } from 'app/device';
import { PlayButton } from 'components';
import { ActionButtons, QrCodeBox, RegularButton, Tags } from 'components/ui';
import { If, openLink } from 'helpers';
import i18n from 'i18n';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Games } from 'services';
import { descriptionNavBtn, NavButtons } from '../../nav-buttons/NavButtons';
import * as S from './Content.style';

export const Content = ({
    index,
    content,
    isSingle,
    nextSlide,
    prevSlide,
    marketingContentLength,
    onExpandClicked,
    onQuickMatch,
}) => {
    const { t } = useTranslation();
    const [qrCodeVisible, setQrCodeVisible] = useState();

    const game =
        (content.type === 'game' && Games.GetGameFromAlias(content.alias)) ||
        null;

    const playEnabled = game && game?.release_status !== 'coming_soon';

    const title = Games.getAsset(
        content,
        isMobile ? 'title_small' : 'title_large'
    );

    const description = Games.getAsset(
        content,
        isMobile ? 'description_small' : 'description_large'
    );

    // we must have ref to GamesWrapper to do "scrollTo" (check const buttons below) otherwise navigation conflict between marketingContent window and main menu
    const elToScroll = document.querySelector('#GamesWrapper');

    const url = Games.getAsset(content, 'url');

    // reset scrollBehavior when we are focusing outside marketing slide. check Column.js focusDetailWindow()
    const commonProps = {
        onBlur: () => {
            elToScroll.style.scrollBehavior = 'smooth';
        },
    };

    const buttons = [
        {
            isVisible: playEnabled,
            component: PlayButton,
            props: {
                game,
                disabled: game?.release_status === 'coming_soon',
                ...commonProps,
            },
        },
        {
            // multiplayer button when online is available
            isVisible: !!game?.hasQuickMatch,
            text: t('buttons.multiplayer'),
            type: 'regular',
            callback: () => onQuickMatch(game),
            props: {
                ...commonProps,
            },
        },
        {
            isVisible: content.type === 'game',
            component: RegularButton,
            text: t('marketing_news.details'),
            callback: (e) => onExpandClicked(e, game),
            props: {
                'data-btn': 'details',
                game,
                ...commonProps,
            },
        },
        {
            isVisible: !!(content.type === 'info' && url),
            component: RegularButton,
            text:
                content?.assets[i18n.language]?.button_url ||
                t('marketing_news.link'),
            callback: () => {
                if (isTV) {
                    setQrCodeVisible(true);
                } else {
                    openLink(url);
                }
            },
            props: {
                ...commonProps,
            },
        },
    ];

    // btn.isVisible can be false depending on the current render, test if there is at least one visible btn
    const firstButton = buttons.filter((btn) => btn.isVisible)[0];
    if (firstButton) {
        // add disableMoveProps to first visible button
        firstButton.props.disableMoveLeft = true;
    }

    const prev = () => {
        // focus prev button unless we'll reach the first element, in that case focus next instead
        prevSlide(
            index - 1 > 0 ? descriptionNavBtn.PREV : descriptionNavBtn.NEXT
        );
    };

    const next = () => {
        // focus next button unless we'll reach the last element, in that case focus prev instead
        nextSlide(
            index + 1 < marketingContentLength - 1
                ? descriptionNavBtn.NEXT
                : descriptionNavBtn.PREV
        );
    };

    //TODO: QR CODE BOX TEXT

    return (
        <S.Wrapper>
            <If condition={qrCodeVisible}>
                <QrCodeBox
                    title={title}
                    url={url}
                    onClose={() => {
                        setQrCodeVisible(false);
                    }}
                />
            </If>
            <S.Left>
                <S.HeaderWrapper>
                    <S.Title>{title}</S.Title>
                    <If condition={game && !isMobile}>
                        <S.TagsWrapper>
                            <Tags game={game} />
                        </S.TagsWrapper>
                    </If>
                </S.HeaderWrapper>
                <If condition={!isMobile}>
                    <S.Subtitle>
                        {Games.getAsset(content, 'subtitle')}
                    </S.Subtitle>
                </If>
                <S.Description>{description}</S.Description>
                <ActionButtons
                    buttons={buttons}
                    wrapperProps={{
                        hasQuickMatch: game?.hasQuickMatch,
                    }}
                    navSection={false}
                />
            </S.Left>
            <If condition={!isSingle && !isMobile}>
                <S.Right>
                    <NavButtons
                        onPrev={prev}
                        onNext={next}
                        index={index}
                        marketingContentLength={marketingContentLength}
                    />
                </S.Right>
            </If>
        </S.Wrapper>
    );
};
