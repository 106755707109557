import { isHP } from 'app/device';
import Routes from 'app/routes';
import { useProfileLoader } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FrontEndHelper, Games, Profile, TermsAndConditions } from 'services';
import {
    doLogout,
    hideGlobalNavigation,
    profileSelector,
    setEulaApprovedGames,
    setInitialProfiles,
    showGlobalMessageBox,
} from 'slices';

export function useMegaLoader() {
    const [progress, setProgress] = useState(0);
    const history = useHistory();
    const dispatch = useDispatch();
    const { profiles } = useSelector(profileSelector);
    const { onProfileSelected } = useProfileLoader();
    const { t } = useTranslation();
    //hide globalNavigation
    useEffect(() => {
        dispatch(hideGlobalNavigation());
    }, [dispatch]);

    // Critical promises, these are basically http requests (get_apps...)
    // For any fail, we route the user to the LoginScreen
    const waitCriticalPromises = useCallback(
        (criticalPromises, optionalPromises) => {
            let aborted = false;
            let completed = 0;

            criticalPromises.forEach((promise) => {
                promise
                    .then(() => {
                        if (aborted) return;

                        ++completed;

                        // Update progress
                        let progress =
                            (completed / criticalPromises.length) *
                            (criticalPromises.length * 10);
                        setProgress(progress);

                        // Critical promises completed, wait optional promises
                        if (completed === criticalPromises.length)
                            waitOptionalPromises(optionalPromises, progress);
                    })
                    .catch((err) => {
                        console.error(err); // Critical
                        if (!aborted) {
                            aborted = true;
                            dispatch(doLogout()).then(() => {
                                history.push(Routes.LOGIN);
                            });
                        }
                    });
            });
        },
        [dispatch, history]
    );

    const startLoading = useCallback(() => {
        let optionalPromises = [];

        waitCriticalPromises(
            [
                // Preload FrontEndHelper Service
                FrontEndHelper.Preload(optionalPromises),

                // Preload Profiles
                Profile.Preload((profiles) =>
                    dispatch(setInitialProfiles(profiles))
                ),

                // Preload Games
                Games.Preload(
                    optionalPromises,
                    (approvedGames) =>
                        dispatch(setEulaApprovedGames(approvedGames)),
                    (noOffers) => {
                        if (!noOffers) return;

                        dispatch(doLogout()).then(() => {
                            history.push(Routes.LOGIN);
                        });
                        dispatch(
                            showGlobalMessageBox({
                                type: 'error',
                                message: t('login.no_offers'),
                            })
                        );
                    }
                ),
            ],
            optionalPromises
        );
    }, [dispatch, waitCriticalPromises, history, t]);

    // Optional promises, mainly images preload
    const waitOptionalPromises = (
        optionalPromises,
        criticalPromisesProgress
    ) => {
        // Wait all promises to resolve/reject
        let completed = 0;
        optionalPromises.forEach((promise) => {
            promise
                .finally(() => {
                    // Increment completed promises
                    ++completed;

                    // All resolved/rejected, redirect to terms and conditions
                    if (completed === optionalPromises.length) setProgress(100);
                    else {
                        // Increment progress
                        let progress =
                            (completed / optionalPromises.length) *
                            (100 - criticalPromisesProgress);
                        setProgress(criticalPromisesProgress + progress);
                    }
                })
                .catch((e) => console.error(e));
        });
    };

    const onProgressComplete = useCallback(() => {
        // Route to profile selection if already signed
        if (TermsAndConditions.Signed()) {
            if (!isHP) {
                history.push(Routes.PROFILE_SELECTION);
            } else {
                // auto login with first profile
                if (profiles.length >= 1) {
                    onProfileSelected(profiles[0]);
                }
            }
        } else history.push(Routes.TERMS_AND_CONDITIONS);
    }, [history, onProfileSelected, profiles]);

    return { progress, onProgressComplete, startLoading };
}
