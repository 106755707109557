import { getNavigation } from 'app/navigation';
import Routes from 'app/routes';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FrontEndHelper, Games } from 'services';
import {
    initPinCodeCheck,
    setCurrentProfile,
    setGlobalNavigation,
    setPinCode,
} from 'slices';
import { useStartNavigation } from '../global-navigation';

export function useProfileLoader() {
    const history = useHistory();
    const dispatch = useDispatch();
    const startNavigation = useStartNavigation();

    const onProfileSelected = useCallback(
        (profile) => {
            // Set selected profile
            // NOTE: at this point only the information available from the global profiles API has been loaded,
            // extra information available only in the full profile API will be available later - see useStartNavigation
            dispatch(setCurrentProfile(profile));

            // Set Games Minimum age filter
            Games.Filter(
                FrontEndHelper.GetMinimumAge(profile.contentRating.alias)
            );

            // Fill menu
            dispatch(setGlobalNavigation(getNavigation()));

            if (profile.locked) {
                dispatch(
                    initPinCodeCheck({
                        profileUID: profile.uid,
                    })
                );
                history.push(Routes.PIN_CODE_CHECK);
            } else {
                dispatch(setPinCode('')); // clear code potentially stored from a previous profile use
                startNavigation(profile.uid);
            }
        },
        [dispatch, history, startNavigation]
    );

    return { onProfileSelected };
}
