import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMultiStepContext } from 'context/multi-step';
import { recoverySelector, updateCode } from 'slices';
import { useForm } from 'react-hook-form';
import { doVerifyCode } from 'slices/recovery';

export function useVerifyCode() {
    const dispatch = useDispatch();
    const { setStep, cancelStep } = useMultiStepContext();
    const { step3Done, code, username, recoveryError, selectedMethod } =
        useSelector(recoverySelector);
    const { handleSubmit } = useForm();

    useEffect(() => {
        if (step3Done) {
            setStep('step4');
        }
    }, [setStep, step3Done]);

    // On input change update code in store
    const onInputCodeChange = (val) => {
        dispatch(updateCode(val));
    };

    // Handle on submit
    const onSubmit = handleSubmit(() => {
        // usage of register({required:true}) in UI appears to be broken
        // prevent query if the code is missing instead
        if (code) {
            dispatch(
                doVerifyCode({
                    username: username,
                    code: code,
                })
            );
        }
    });

    return {
        onSubmit,
        onInputCodeChange,
        recoveryError,
        selectedMethod,
        cancelStep,
    };
}
