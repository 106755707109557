export function IconMultiplayer(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 17 17"
            {...props}
        >
            <path
                fill="#fff"
                d="M13.662 7.92c-.77.454-1.644.731-2.594.731-.231 0-.488-.025-.719-.05-.18.328-.41.63-.668.908 1.978.832 3.39 2.75 3.467 4.969H17v-2.346c0-2.043-1.412-3.733-3.338-4.212zM10.965 6.23c0 .403-.051.782-.154 1.16h.231c2.08 0 3.75-1.665 3.75-3.683A3.72 3.72 0 0011.067 0c-1.31 0-2.466.656-3.133 1.665 1.772.781 3.03 2.522 3.03 4.565zM11.864 14.654c0-2.017-1.412-3.732-3.338-4.187-.77.454-1.644.732-2.594.732s-1.849-.277-2.594-.732C1.412 10.947 0 12.637 0 14.654V17h11.864v-2.346z"
            ></path>
            <path
                fill="#fff"
                d="M5.932 2.522c-2.08 0-3.75 1.665-3.75 3.683 0 2.043 1.696 3.682 3.75 3.682 2.08 0 3.75-1.665 3.75-3.682 0-2.018-1.67-3.683-3.75-3.683z"
            ></path>
        </svg>
    );
}
