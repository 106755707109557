import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
`;

export const Text = styled.span`
    font-size: 0.75rem;
    padding-left: 8px;
`;
