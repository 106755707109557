import { isTV, tablet } from 'app/device';
import styled, { css } from 'styled-components';

const WrapperBase = styled.div`
    margin-top: 16px;

    ${tablet(css`
        margin-top: 0;
    `)}
`;

const WrapperTV = styled(WrapperBase)`
    margin-top: 25px;
`;

export const Wrapper = isTV ? WrapperTV : WrapperBase;
