import { Profile } from 'components/profile/selection-manager/profile/Profile';
import { SpatialNavSection } from 'context';
import { useSoundAction } from 'hooks';
import 'swiper/swiper.scss';
import * as S from './ProfileListSwiper.style';
import { AddProfileButton } from 'components/profile/AddProfileButton';

export function ProfileListPlain({ list, listItemSelected }) {
    const handleSelected = useSoundAction('primaryBtn', listItemSelected);

    return (
        <SpatialNavSection focusOnMount enterTo="last-focused">
            <S.Wrapper listLength={list.length}>
                {list.map((profile) => (
                    <Profile
                        key={profile.uid}
                        data={profile}
                        count={list.length}
                        onClick={() => handleSelected(profile)}
                    />
                ))}
                <AddProfileButton />
            </S.Wrapper>
        </SpatialNavSection>
    );
}
