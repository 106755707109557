import { GapAnimation, QuitWrapper } from 'components';
import {
    GamesWrapper,
    Grid,
    NoGamesFound,
    TitleFilter,
} from 'components/games';
import { GameWindowSwiper } from 'components/games/game-window-swiper/GameWindowSwiper';
import { ColumnLayout, RowLayout } from 'components/ui';
import { CategoryTitle } from 'components/ui/category/category-title/CategoryTitle.style';
import { If } from 'helpers';
import { useGamesSwiper, useMulti } from 'hooks';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export function MultiScreen() {
    const { games, columns } = useMulti();
    const gamesSwiper = useGamesSwiper(games);
    const { t } = useTranslation();
    const upperElementRef = useRef();
    const gridRef = useRef();
    const blurRef = useRef();

    return (
        <>
            <QuitWrapper disabled={gamesSwiper.visible} />
            <ColumnLayout alignItems="center" fullSize>
                <If condition={games.length > 0 && gamesSwiper.visible}>
                    <GameWindowSwiper
                        gamesSwiper={gamesSwiper}
                        selectedIndex={gamesSwiper.selectedIndex}
                        prev={gamesSwiper.prev}
                        next={gamesSwiper.next}
                        games={games}
                    />
                </If>
                <GamesWrapper>
                    <If condition={games.length <= 0}>
                        <NoGamesFound>{t('games.empty_list')}</NoGamesFound>
                    </If>
                    <If condition={games.length > 0}>
                        <ColumnLayout fullWidth>
                            <GapAnimation
                                isGapOpen={gamesSwiper.gapOpen}
                                lastFocused={gamesSwiper.lastFocused}
                            >
                                <RowLayout>
                                    <CategoryTitle blurRef={blurRef}>
                                        <TitleFilter>
                                            <div>
                                                {t('categories.multiplayer')}
                                            </div>
                                        </TitleFilter>
                                    </CategoryTitle>
                                </RowLayout>
                            </GapAnimation>
                            <Grid
                                ref={gridRef}
                                isGapOpen={gamesSwiper.gapOpen}
                                lastFocused={gamesSwiper.lastFocused}
                                onItemClicked={gamesSwiper.open}
                                columns={columns}
                                upperElementRef={upperElementRef}
                            />
                        </ColumnLayout>
                    </If>
                </GamesWrapper>
            </ColumnLayout>
        </>
    );
}
