import { BlurBox } from 'components/ui/blur-box';
import styled from 'styled-components';

const Wrapper = styled(BlurBox)`
    width: 100%;
    height: 100%;
    z-index: 999;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const BlurOverlay = ({ children, ...props }) => {
    return <Wrapper {...props}>{children}</Wrapper>;
};
