import { isHP } from 'app/device';
import { Video } from 'components/games/trailer/Trailer.style';
import { If } from 'helpers';
import { useDisableAtmosSound, useHospitalityAd } from 'hooks';
import * as S from './Hospitality.style';

const Ad = () => {
    const hospitalityAd = useHospitalityAd();
    useDisableAtmosSound();
    return (
        <S.Wrapper>
            <Video autoPlay muted loop onClick={(e) => e.preventDefault()}>
                <source type="video/mp4" src={hospitalityAd.url} />
            </Video>
        </S.Wrapper>
    );
};

export function HospitalityAd(props) {
    const hospitalityAd = useHospitalityAd();

    return (
        <If
            condition={
                (isHP && process.env.NODE_ENV !== 'development') || props.debug
            }
        >
            <If condition={hospitalityAd.isVisible && hospitalityAd.url}>
                <Ad />
            </If>
        </If>
    );
}
