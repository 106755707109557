import { isTV, isSafari } from 'app/device';

// Bear in mind that strict mode can fuck this up by fire localStorage set / get twice
export const isGamepadWarningVisible = () => {
    if (isTV || !isSafari) return false; // warning shown only on Safari (caution, Tizen browser reports as Safari)

    //if warning doesnt exist in local storage, create it
    if (!localStorage.getItem('gamepad_warning_safari')) {
        return true;
    }

    const storedDate = Date.parse(
        localStorage.getItem('gamepad_warning_safari')
    );
    const dateNow = new Date();
    const diff = Math.round((dateNow - storedDate) / 1000 / 60);

    // if more than 3 hours
    if (diff > 4) {
        return true;
    }

    return false;
};
