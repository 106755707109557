import { isTV } from 'app/device';
import { If } from 'helpers';
import * as S from './BuildVersion.style';

export function BuildVersion() {
    let buildVersion = `Build version : ${process.env.REACT_APP_VERSION}`;

    // Append branch/commit if defined
    if (process.env.REACT_APP_BRANCH_COMMIT)
        buildVersion += ` - ${process.env.REACT_APP_BRANCH_COMMIT}`;

    console.log(buildVersion);

    return (
        <If
            condition={
                !isTV && process.env.REACT_APP_SHOW_BUILD_VERSION === 'true'
            }
        >
            <S.BuildVersion>{buildVersion}</S.BuildVersion>
        </If>
    );
}
