import { IconWarning } from 'components/ui';
import { useTranslation } from 'react-i18next';
import * as S from './GameCopyright.style';
import * as ContentRatingIcons from 'assets/images/content_rating';
import { FrontEndHelper } from 'services';

export function GameCopyright({ game }) {
    const { t } = useTranslation();

    const contentRating = FrontEndHelper.GetContentRating();
    const gameRating = contentRating.find((cr) =>
        game.content_ratings.some((alias) => alias === cr.alias)
    );

    return (
        <S.ScrollWrapper>
            <S.Copyright>{game.copyright}</S.Copyright>
            <S.ContentRatingWrapper>
                {gameRating && gameRating.rated ? (
                    <S.ContentRatingIcon
                        src={ContentRatingIcons[gameRating.alias]}
                    />
                ) : null}
                <S.WarningWrapper>
                    <S.WarningHeader>
                        <S.WarningHeaderIcon>
                            <IconWarning />
                        </S.WarningHeaderIcon>
                        <S.WarningHeaderText>
                            {t('games.content_rating_warning.header')}
                        </S.WarningHeaderText>
                    </S.WarningHeader>
                    <S.WarningMessage>
                        {t('games.content_rating_warning.message')}
                    </S.WarningMessage>
                </S.WarningWrapper>
            </S.ContentRatingWrapper>
        </S.ScrollWrapper>
    );
}
