// import { Sound } from './BaseButton';
import Theme from 'app/theme';
import styled from 'styled-components';
import { BaseButtonTransform } from './BaseButton';
import { SoundButton } from './sound-button/SoundButton';

const PrimaryButton = styled(SoundButton)`
    background-color: ${(props) =>
        props.disabled ? Theme.COLORS.GRAY_L2 : Theme.COLORS.PRIMARY_L1};
    color: ${Theme.COLORS.BLACK};
    font-weight: ${(props) => (props.disabled ? 'regular' : 'bold')};
    cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};

    &:focus,
    &:hover {
        background-color: ${(props) =>
            props.disabled ? Theme.COLORS.GRAY_L2 : Theme.COLORS.PRIMARY_L2};
        color: ${(props) => (props.disabled ? 'black' : Theme.COLORS.WHITE)};
        transform: ${(props) =>
            props.disabled ? 'none' : BaseButtonTransform};
        font-weight: ${(props) => (props.disabled ? 'regular' : 'bold')};
    }

    &:active {
        background-color: ${(props) =>
            props.disabled ? Theme.COLORS.GRAY_L2 : Theme.COLORS.PRIMARY_L3};
        color: ${(props) => (props.disabled ? 'black' : Theme.COLORS.WHITE)};
    }
`;

PrimaryButton.defaultProps = {
    sound: 'regularBtn',
};

export { PrimaryButton };
