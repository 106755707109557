class KeyboardListener {
    downKeys = {};

    Start(dispatchCallback) {
        this.dispatchCallback = dispatchCallback;
        window.addEventListener('keydown', this.KeyDownListener);
        window.addEventListener('keyup', this.KeyUpListener);
    }

    Stop() {
        window.removeEventListener('keydown', this.KeyDownListener);
        window.removeEventListener('keyup', this.KeyUpListener);
    }

    KeyDownListener = (e) => {
        const { keyCode } = e;
        const keyWasUp = !this.downKeys[keyCode];
        //eslint-disable-next-line default-case
        switch (keyCode) {
            // Arrow Left
            case 37:
                this.dispatchCallback('left');
                e.preventDefault();
                break;

            // Arrow Up
            case 38:
                this.dispatchCallback('up');
                e.preventDefault();
                break;

            // Arrow Right
            case 39:
                this.dispatchCallback('right');
                e.preventDefault();
                break;

            // Arrow Down
            case 40:
                this.dispatchCallback('down');
                e.preventDefault();
                break;

            // Enter
            case 13:
                if (keyWasUp) {
                    this.dispatchCallback('select');
                    e.preventDefault();
                }
                break;

            // Space (acting like Enter, cf: PROD-405, ask Nicolas)
            case 32:
                if (keyWasUp && e.target.type !== 'text') {
                    this.dispatchCallback('select');
                    e.preventDefault();
                }
                break;

            // Escape
            case 27:
                if (keyWasUp) {
                    console.log('----> BACK ESC');
                    this.dispatchCallback('back');
                    e.preventDefault();
                }
                break;

            // Tizen Remote Control Special cases
            // Back
            case 10009:
                if (keyWasUp) {
                    this.dispatchCallback('back');
                    e.preventDefault();
                }
                break;
        }
        this.downKeys[keyCode] = true;
    };

    KeyUpListener = (e) => {
        delete this.downKeys[e.keyCode];
    };
}

export default KeyboardListener;
