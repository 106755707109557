import { useShowResetPassword } from 'hooks';
import { useTranslation } from 'react-i18next';
import * as S from './ForgotPassword.style';

export function ForgotPassword(props) {
    const { t } = useTranslation();
    const showResetPassword = useShowResetPassword();

    return (
        <S.Text onClick={showResetPassword} {...props}>
            {t('login.forget_password')}
        </S.Text>
    );
}
