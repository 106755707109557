import SGXSessionTracker from 'sgxsessiontracker';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    gameSessionSelector,
    sessionRunning,
    sessionTerminated,
    setSessionError,
} from 'slices';

export function useSessionTracker() {
    const { sessionData, sessionState } = useSelector(gameSessionSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        let tracker = new SGXSessionTracker({
            server: sessionData.ipTracker,
            session_id: sessionData.sessionId,
            reconnect_attempts: 5,
            ssl: sessionData.useSsl,
            use_plain_websocket: false, // GS internal, set to true to use plain ws instead of go-glue socket
        });

        tracker.on('debug', (debugString) => {
            console.debug('[TRACKER DEBUG]: ' + debugString);
        });

        tracker.on('connected', () => {
            console.log('[TRACKER LOG]: Tracker connected');
        });

        const errorsToMessage = {
            [SGXSessionTracker.States.SessionCrashed]:
                'session_tracker.session_crashed',
            [SGXSessionTracker.States.SessionFailedToStart]:
                'session_tracker.session_failed_to_start',
            [SGXSessionTracker.States.SessionConnectionLost]:
                'session_tracker.session_connection_lost',
            [SGXSessionTracker.States.SessionConnectTimeout]:
                'session_tracker.session_connection_timeout',
            [SGXSessionTracker.States.SessionInactiveTimeout]:
                'session_tracker.session_inactive_timeout',
            [SGXSessionTracker.States.SessionDurationLimitReached]:
                'session_tracker.session_duration_reached',
            [SGXSessionTracker.States.SessionReconnectTimeout]:
                'session_tracker.session_reconnection_timeout',
            [SGXSessionTracker.States.SessionNotFound]:
                'session_tracker.session_not_found', // Should never happen
        };

        tracker.on('connection_failed', (remaining_attempts) => {
            if (remaining_attempts === 0) {
                dispatch(
                    setSessionError({
                        type: 'error',
                        message: 'session_tracker.session_connection_timeout',
                    })
                );
                console.log('[TRACKER LOG] Network error');
            }
        });

        tracker.on('connection_lost', () => {
            dispatch(
                setSessionError({
                    type: 'error',
                    message: 'session_tracker.session_connection_lost',
                })
            );
            console.log('[TRACKER LOG] Connection lost');
        });

        tracker.on('session_response', (response) => {
            switch (response.state) {
                case SGXSessionTracker.States.SessionPending:
                    console.log('[TRACKER LOG]: Session pending');
                    break;

                case SGXSessionTracker.States.SessionStarted:
                    dispatch(sessionRunning());
                    console.log('[TRACKER LOG]: Session started');
                    break;

                case SGXSessionTracker.States.SessionTerminated:
                    dispatch(sessionTerminated());
                    console.log('[TRACKER LOG]: Session gracefully terminated');
                    break;

                case SGXSessionTracker.States.SessionCrashed:
                case SGXSessionTracker.States.SessionFailedToStart:
                case SGXSessionTracker.States.SessionConnectionLost:
                case SGXSessionTracker.States.SessionConnectTimeout:
                case SGXSessionTracker.States.SessionInactiveTimeout:
                case SGXSessionTracker.States.SessionDurationLimitReached:
                case SGXSessionTracker.States.SessionReconnectTimeout:
                case SGXSessionTracker.States.SessionNotFound: // Should never happen
                    dispatch(
                        setSessionError({
                            type: 'error',
                            message: errorsToMessage[response.state],
                        })
                    );
                    dispatch(sessionTerminated());
                    console.log(
                        '[TRACKER LOG]: Terminated, State = ' + response.state
                    );
                    break;

                default:
                    console.log('[TRACKER LOG]: ' + response.state);
                    break;
            }
        });

        tracker.run();

        return () => tracker.terminate();
    }, [
        dispatch,
        sessionData.ipTracker,
        sessionData.sessionId,
        sessionData.useSsl,
    ]);

    return { sessionState };
}
