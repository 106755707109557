export const IconInfo = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25.5"
            height="25.5"
            viewBox="0 0 25.5 25.5"
            {...props}
        >
            <path
                id="Icon_awesome-info-circle"
                data-name="Icon awesome-info-circle"
                d="M13.313.563a12.75,12.75,0,1,0,12.75,12.75A12.752,12.752,0,0,0,13.313.563Zm0,5.655a2.159,2.159,0,1,1-2.159,2.159A2.159,2.159,0,0,1,13.313,6.218Zm2.879,13.058a.617.617,0,0,1-.617.617H11.05a.617.617,0,0,1-.617-.617V18.042a.617.617,0,0,1,.617-.617h.617v-3.29H11.05a.617.617,0,0,1-.617-.617V12.284a.617.617,0,0,1,.617-.617h3.29a.617.617,0,0,1,.617.617v5.141h.617a.617.617,0,0,1,.617.617Z"
                transform="translate(-0.563 -0.563)"
                fill="#fff0ed"
            />
        </svg>
    );
};
