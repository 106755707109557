import { useEffect, useRef, useState } from 'react';

export function useCountdown(endDate, interval = 1000) {
    const intervalRef = useRef();
    const _endDate = useRef(new Date(endDate).getTime()).current;
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        if (!_endDate) return;

        const updateDate = () => {
            // number of remainings days etc
            let seconds = (_endDate - new Date().getTime()) / 1000;
            if (seconds < 0) {
                stop();
                return;
            }
            const days = Math.floor(seconds / (3600 * 24));
            seconds %= 3600 * 24;
            const hours = Math.floor(seconds / 3600);
            seconds %= 3600;
            const minutes = Math.floor(seconds / 60);

            setDays(days);
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);
        };

        // update once at start and set interval
        updateDate();
        intervalRef.current = setInterval(updateDate, interval);

        return () => stop();
    }, [_endDate, interval]);

    const stop = () => {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
    };

    return {
        days,
        hours,
        minutes,
        seconds,
        stop,
    };
}
