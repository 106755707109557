import { useSpatialNavContext, withInputDispatcherProvider } from 'context';
import { If } from 'helpers';
import { useBackPress } from 'hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigFront } from 'services';
import * as S from './MultiPlayerFilter.style';

export const MultiplayerFilter = withInputDispatcherProvider(
    ({ availableNbPlayers, onFilterChange, blurRef }) => {
        const { setFocus, refreshTree } = useSpatialNavContext();
        const [showNbPlayersSelection, setShowNbPlayersSelection] =
            useState(false);
        const [nbPlayers, setNbPlayers] = useState(2);
        const filterRef = useRef();
        const selectedNbPlayersRef = useRef();
        const { t } = useTranslation();

        const backPress = useCallback(() => {
            setShowNbPlayersSelection(false);
            setFocus(filterRef.current);
        }, [setFocus]);
        useBackPress(
            showNbPlayersSelection === 'ready' ? undefined : backPress
        );

        const selectNbPlayers = (n) => {
            setNbPlayers(n);
            onFilterChange?.(n);
            setShowNbPlayersSelection(false);
            setFocus(filterRef.current);
        };

        const startNbPlayersSelection = () => {
            if (showNbPlayersSelection) {
                return;
            }
            if (availableNbPlayers.length < 2) {
                selectNbPlayers(availableNbPlayers);
            } else {
                setShowNbPlayersSelection(true);
            }
        };

        useEffect(() => {
            if (showNbPlayersSelection) {
                // the replacement of the nb players badge with the selectors
                // requires a refresh otherwise the previous computed width of
                // the label overlaps the selectors
                refreshTree();
                setFocus(selectedNbPlayersRef.current);
            }
        }, [refreshTree, showNbPlayersSelection, setFocus]);

        const badge = (
            <S.NbPlayersBadge>
                <S.IconPlayer />
                &nbsp;
                {nbPlayers}
            </S.NbPlayersBadge>
        );

        return (
            <S.Wrapper ref={blurRef}>
                <S.Filter
                    ref={filterRef}
                    onClick={startNbPlayersSelection}
                    defaultElement
                >
                    {t(
                        ConfigFront.GetMultiplayer()
                            ? 'games.nb_players_selection'
                            : 'games.nb_players_selection_local'
                    )}
                    <If condition={!showNbPlayersSelection}>{badge}</If>
                </S.Filter>
                <If condition={showNbPlayersSelection}>
                    {availableNbPlayers.map((n, i) => (
                        <S.FilterItem
                            onClick={() => {
                                selectNbPlayers(n);
                            }}
                            ref={
                                n === nbPlayers
                                    ? selectedNbPlayersRef
                                    : undefined
                            }
                            disableMoveUp
                            disableMoveDown
                            disableMoveLeft={i === 0}
                            disableMoveRight={
                                i === availableNbPlayers.length - 1
                            }
                            key={n}
                        >
                            {n}
                        </S.FilterItem>
                    ))}
                </If>
            </S.Wrapper>
        );
    }
);
MultiplayerFilter.displayName = 'MultiplayerFilter';
