import { isMobile } from 'app/device';
import { JoinLobbyButton } from 'components/games';
import { descriptionActionBtn } from 'components/games/game-window/views/description/DescriptionView';
import { ActionButtons } from 'components/ui';
import { useMultiViewContext } from 'context';
import { If } from 'helpers';
import { useBackPress, useSoundAction } from 'hooks';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { gameLobbySelector, LOBBY_STATE } from 'slices';
import { ViewContainer } from '../ViewContainer';
import { Lobby } from './lobby/Lobby';
import * as S from './QuickMatchView.style';

export function QuickMatchView({
    game,
    index,
    setCurrentGameWindowView,
    selectedIndex,
    setLastFocusedEl,
}) {
    const { t } = useTranslation();
    const { view, setView } = useMultiViewContext();

    const close = useSoundAction(
        'back',
        useCallback(() => {
            setLastFocusedEl({ el: descriptionActionBtn.MULTIPLAYER });
            setView('description');
        }, [setLastFocusedEl, setView])
    );
    useBackPress(close);

    const { lobbyState } = useSelector(gameLobbySelector);
    // disableMoves are set below to prevent moving out of the join/quit pair and accidentally quitting the lobby once a session is starting
    // check on isMobile is to account for the vertical/horizontal layouts on mobile vs desktop/tv
    const pending = lobbyState !== LOBBY_STATE.INITIAL;

    useEffect(() => {
        setLastFocusedEl({ el: descriptionActionBtn.QUICKMATCH });
    }, [setLastFocusedEl]);

    // set view info in swiper for the current window only
    useEffect(() => {
        if (index === selectedIndex) {
            setCurrentGameWindowView(view);
        }
    }, [index, selectedIndex, setCurrentGameWindowView, view]);

    const actionButtons = (
        <ActionButtons
            vertical={isMobile}
            Wrapper={S.ActionButtonsWrapper}
            buttons={[
                {
                    component: JoinLobbyButton,
                    props: {
                        game,
                        focusOnMount: true,
                        disableMoveUp: pending,
                        disableMoveLeft: pending,
                        disableMoveRight: pending && !isMobile,
                    },
                },
                {
                    type: 'regular',
                    text: t('buttons.exit'),
                    callback: close,
                    props: {
                        disableMoveUp: pending && !isMobile,
                        disableMoveRight: true,
                    },
                },
            ]}
        />
    );

    return (
        <ViewContainer game={game} actionButtons={actionButtons}>
            <S.Content>
                <Lobby game={game} />
                <If condition={!isMobile}>{actionButtons}</If>
            </S.Content>
        </ViewContainer>
    );
}
