// simple reversible xor-based encryption

// generate random key once
const randomKey = Array.from({ length: 32 }, () =>
    Math.floor(Math.random() * 256)
);

const stringToKey = (entry) => {
    if (typeof entry !== 'string') {
        return entry;
    }
    return [...entry].map((c) => 128 + (c.charCodeAt(0) % 256));
};

export function encrypt(text, key = randomKey) {
    // disabled in dev mode
    if (process.env.NODE_ENV === 'development') {
        return text;
    }
    key = stringToKey(key);
    // two bytes per char to support UTF-16
    const bytes = [...text].map((c) => {
        const code = c.charCodeAt(0);
        return [Math.floor(code / 256), code % 256];
    });
    // Array.flat() is apparently not available on all Tizen devices
    const flatBytes = [].concat(...bytes);
    const xorBytes = flatBytes.map((b, i) => b ^ key[i % key.length]);
    const encryptedText = String.fromCharCode(...xorBytes);
    return encryptedText;
}

export function decrypt(encryptedText, key = randomKey) {
    // disabled in dev mode
    if (process.env.NODE_ENV === 'development') {
        return encryptedText;
    }
    key = stringToKey(key);
    const xorBytes = [...encryptedText].map((c) => c.charCodeAt(0));
    const bytes = xorBytes.map((b, i) => b ^ key[i % key.length]);
    let text = '';
    // recompose UTF-16 from byte pairs
    for (let i = 0; i < bytes.length; i += 2) {
        const code = bytes[i] * 256 + bytes[i + 1];
        text += String.fromCharCode(code);
    }
    return text;
}
