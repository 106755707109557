import { useGamesWallpapers } from 'app/config';
import { isTV } from 'app/device';
// import entryWallpaper from 'assets/images/wallpapers/entry.jpg';
// for some reasons, needs a minimal bg to transition from
import initialBg from 'assets/images/wallpapers/initial_bg.jpg';
import loginWallpaper from 'assets/images/wallpapers/login.jpg';
import wallpaper1 from 'assets/images/wallpapers/wallpaper_01.jpg';

import { assetURL, preloadImage } from 'helpers';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Games } from 'services';

const basicWallpaperURLs = [
    wallpaper1,
];
const defaultInterval = 2 * 60 * 1000; // 2 minutes

const staticWallpapers = {
    login: wallpaper1,
    entry: wallpaper1,
};

// wallpaper management - do not use this hook directly in components, the actual application
// wallpaper depends on the ThemeContext so you need to go through useThemeContext instead,
// otherwise you will modify a wallpaper that nothing is watching
export function useWallpaper() {
    const [wallpaper, setWallpaper] = useState(initialBg);
    const wallpaperRef = useRef();
    const intervalRef = useRef();
    const timeoutRef = useRef();
    const allGames = Games.GetGames(); // may change on change of profile

    const allWallpaperURLs = useMemo(
        () =>
            allGames
                .map((g) => g.assets.wallpapers)
                .flat(2)
                .map(assetURL),
        [allGames]
    );

    const stopWallpaperLoop = useCallback(() => {
        // stop loop
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }, []);

    const doSetWallpaper = useCallback((url) => {
        if (url === wallpaperRef.current) {
            // nothing to do
            return;
        }
        // ensure image is acually loaded before changing
        preloadImage(url)
            .then(() => {
                wallpaperRef.current = url;
                setWallpaper(url);
            })
            .catch(() => {
                // ignore preload errors
            });
    }, []);

    const startWallpaperLoop = useCallback(
        (params) => {
            const defaultWallpaperURLs = useGamesWallpapers
                ? allWallpaperURLs
                : basicWallpaperURLs;
            let { wallpaperURLs, interval } = {
                wallpaperURLs: defaultWallpaperURLs,
                interval: defaultInterval,
                ...params,
            };
            if (wallpaperURLs?.length === 0) {
                // use default wallpapers if none specified/available, e.g. game with no wallpapers
                wallpaperURLs = defaultWallpaperURLs;
                if (defaultWallpaperURLs.length === 0) {
                    // really nothing to set
                    return;
                }
            }

            // Disable wallpaper cycling on TV
            if (isTV) return;

            // stop any current loop
            stopWallpaperLoop();
            doSetWallpaper(
                wallpaperURLs[Math.floor(Math.random() * wallpaperURLs.length)]
            );

            if (wallpaperURLs.length === 1) {
                // nothing to cycle through, no need to set interval
                return;
            }

            // set random wallpaper
            intervalRef.current = setInterval(() => {
                // ensure we select a different wallpaper
                let nextWallpaper = undefined;
                do {
                    nextWallpaper =
                        wallpaperURLs[
                            Math.floor(Math.random() * wallpaperURLs.length)
                        ];
                } while (wallpaperRef.current === nextWallpaper);
                doSetWallpaper(nextWallpaper);
            }, interval);
        },
        [allWallpaperURLs, doSetWallpaper, stopWallpaperLoop]
    );

    const setStaticWallpaper = useCallback(
        (paper) => {
            stopWallpaperLoop();
            doSetWallpaper(staticWallpapers[paper] || loginWallpaper);
        },
        [doSetWallpaper, stopWallpaperLoop]
    );

    return {
        wallpaper,
        setStaticWallpaper,
        startWallpaperLoop,
        stopWallpaperLoop,
    };
}
