import { isMobile, isTV } from 'app/device';
import Theme from 'app/theme';
import { IconArrowLeft, IconArrowRight } from 'components/ui';
import { NavIconButton } from 'components/ui/buttons';
import { SpatialNavSection, useSpatialNavContext } from 'context';
import { If } from 'helpers';
import {
    useSoundAction,
    useTriggerBottomLeftPress,
    useTriggerBottomRightPress,
} from 'hooks';
import { forwardRef, useEffect, useRef } from 'react';
import * as S from './NavButtons.style';

export const descriptionNavBtn = {
    PREV: 'prev',
    NEXT: 'next',
};

export const NavButtons = forwardRef(
    (
        { onClose, onNext, onPrev, index, gamesListLength, selectedIndex },
        forwardedRef
    ) => {
        const { setEnabled, setFocus } = useSpatialNavContext();
        const prevRef = useRef();
        const nextRef = useRef();

        const prevDisabled = index === 0;
        const nextDisabled = index === gamesListLength - 1;

        useEffect(() => {
            setEnabled(prevRef.current, !prevDisabled);
            setEnabled(nextRef.current, !nextDisabled);
        }, [nextDisabled, prevDisabled, setEnabled, setFocus]);

        const handleOnNext = useSoundAction('next', onNext);
        const handleOnPrev = useSoundAction('next', onPrev);

        useTriggerBottomLeftPress(() => {
            if (index === selectedIndex) {
                handleOnPrev();
            }
        });

        useTriggerBottomRightPress(() => {
            if (index === selectedIndex) {
                handleOnNext();
            }
        });

        return (
            <SpatialNavSection ref={forwardedRef} enterTo="default-element">
                <S.Wrapper>
                    <If condition={!isTV}>
                        <NavIconButton
                            defaultElement={nextDisabled && prevDisabled}
                            onClick={onClose}
                            fill={Theme.COLORS.WHITE}
                            opacity={0.35}
                            disableMoveRight={true} // do not move out of window (to settings)
                            //disableMoveUp={true} // do not move out of window (to menu bar)
                            overrideMoveUp={() => {
                                onClose();
                                return true;
                            }}
                            IconComponent={S.IconClose}
                        />
                    </If>
                    <If condition={gamesListLength > 1}>
                        <NavIconButton
                            ref={prevRef}
                            defaultElement={nextDisabled && !prevDisabled}
                            onClick={handleOnPrev}
                            data-btn={descriptionNavBtn.PREV}
                            disableMoveRight={true} // do not move out of window (to settings)
                            disableMoveUp={isTV} // do not move out of window (to menu bar)
                            disableMoveDown={isMobile && nextDisabled} // do not move to action buttons (on mobile)
                            fill={Theme.COLORS.WHITE}
                            style={
                                prevDisabled && {
                                    opacity: '0.35',
                                    filter: 'saturate(0)',
                                    pointerEvents: 'none',
                                }
                            }
                            IconComponent={IconArrowLeft}
                        />
                        <NavIconButton
                            ref={nextRef}
                            defaultElement={!nextDisabled}
                            onClick={handleOnNext}
                            data-btn={descriptionNavBtn.NEXT}
                            disableMoveRight={true} // do not move out of window (to settings)
                            disableMoveDown={isMobile} // do not move to action buttons (on mobile)
                            fill={Theme.COLORS.WHITE}
                            style={
                                nextDisabled && {
                                    opacity: '0.35',
                                    filter: 'saturate(0)',
                                    pointerEvents: 'none',
                                }
                            }
                            IconComponent={IconArrowRight}
                        />
                    </If>
                </S.Wrapper>
            </SpatialNavSection>
        );
    }
);
