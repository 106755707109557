/* eslint-disable */
// temporary disabled while some components are commented and not used
import {
    ActionButtonsWrapper,
    Checkbox,
    IconSettings,
    IconSound,
    IconSoundMute,
    MessageBoxTitle,
    MessageBoxWrapper,
    PrimaryButton,
    Slider,
} from 'components/ui';
import { useSpatialNavContext, withInputDispatcherProvider } from 'context';
import { useBackPress } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    disableAtmosSound,
    disableUiSound,
    enableAtmosSound,
    enableUiSound,
    setAtmosSoundVolume,
    setUiSoundVolume,
    soundSettingsSelector,
} from 'slices';
import * as S from './AudioVideoSettings.style';

const getVideoDevices = navigator.mediaDevices
    ?.enumerateDevices()
    .then((devices) =>
        devices
            .filter(({ kind }) => kind === 'videoinput')
            .map((item) => ({
                value: item.deviceId,
                label: item.label,
            }))
    );

export const AudioVideoSettings = withInputDispatcherProvider(({ onClose }) => {
    const { atmosSound, uiSound, uiSoundVolume, atmosSoundVolume } =
        useSelector(soundSettingsSelector);
    const [atmosVolume, setAtmosVolume] = useState(atmosSoundVolume);
    const [uiVolume, setUiVolume] = useState(uiSoundVolume);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { setFocus } = useSpatialNavContext();
    const musicCheckboxRef = useRef();
    const uiSoundCheckboxRef = useRef();
    const cameraCheckboxRef = useRef();
    const [videoDeviceId, setVideoDeviceId] = useState(null);
    const [videoDevices, setVideoDevices] = useState(getVideoDevices);
    const videoConstraints = {
        facingMode: 'user',
    };

    useBackPress(onClose);

    const handleOnchangeAtmos = (value) => {
        value ? dispatch(enableAtmosSound()) : dispatch(disableAtmosSound());
    };

    const handleOnChangeUiSound = (value) => {
        value ? dispatch(enableUiSound()) : dispatch(disableUiSound());
    };

    useEffect(() => {
        dispatch(setAtmosSoundVolume(atmosVolume));
    }, [atmosVolume]);

    useEffect(() => {
        dispatch(setUiSoundVolume(uiVolume));
    }, [uiVolume]);

    const box = (
        <MessageBoxWrapper>
            <MessageBoxTitle
                title={t('settings.audio_video.title')}
                Icon={IconSettings}
            />
            <S.ContentWrapper>
                {/* --- SETTINGS WEBCAM, DO NOT DELETE COMING SOON --- */}
                {/* <S.LeftPanel>
                    <S.CameraStream>
                        <Webcam
                            videoConstraints={{
                                deviceId: '',
                                // ...videoConstraints,
                            }}
                            style={S.videoStyle}
                        />
                    </S.CameraStream>
                </S.LeftPanel> */}
                <S.RightPanel>
                    {/* --- SETTINGS WEBCAM, DO NOT DELETE COMING SOON --- */}
                    {/* <S.SettingCol>
                        <S.SettingTitle>Camera Source</S.SettingTitle>
                        <S.RowWrapper>
                            <Select data={videoDevices} />
                            <Checkbox
                                ref={cameraCheckboxRef}
                                onClick={() =>
                                    cameraCheckboxRef.current.focus()
                                }
                            />
                        </S.RowWrapper>
                    </S.SettingCol> */}
                    {/* <S.SettingCol>
                        <S.SettingTitle>Headphone Level</S.SettingTitle>
                        <S.RowWrapper>
                            <Slider />
                            <Checkbox
                                ref={cameraCheckboxRef}
                                onClick={() =>
                                    cameraCheckboxRef.current.focus()
                                }
                            />
                        </S.RowWrapper>
                    </S.SettingCol> */}
                    <S.SettingRow>
                        <S.RowTitle>
                            {t('settings.audio_video.enable_atmos')}
                        </S.RowTitle>
                        <S.InnerRow>
                            <S.CheckboxWrapper>
                                <Checkbox
                                    ref={musicCheckboxRef}
                                    defaultChecked={atmosSound}
                                    onChange={handleOnchangeAtmos}
                                />
                                <S.Label>
                                    {t(
                                        atmosSound
                                            ? 'buttons.on'
                                            : 'buttons.off'
                                    )}
                                </S.Label>
                            </S.CheckboxWrapper>
                            <S.SliderWrapper>
                                <Slider
                                    sliderMinWidth={S.SliderMinWidth}
                                    isDisabled={!atmosSound}
                                    value={atmosSound ? atmosVolume : 0}
                                    Icon={
                                        atmosSound ? IconSound : IconSoundMute
                                    }
                                    onValueChange={setAtmosVolume}
                                    overrideMoveLeft={() => {
                                        // not clear why the explicit setFocus is necessary here
                                        // but the nav just doesn't work without it
                                        setFocus(musicCheckboxRef.current);
                                    }}
                                />
                            </S.SliderWrapper>
                        </S.InnerRow>
                    </S.SettingRow>
                    <S.SettingRow>
                        <S.RowTitle>
                            {t('settings.audio_video.enable_ui')}
                        </S.RowTitle>
                        <S.InnerRow>
                            <S.CheckboxWrapper>
                                <Checkbox
                                    ref={uiSoundCheckboxRef}
                                    defaultChecked={uiSound}
                                    onChange={handleOnChangeUiSound}
                                />
                                <S.Label>
                                    {t(uiSound ? 'buttons.on' : 'buttons.off')}
                                </S.Label>
                            </S.CheckboxWrapper>
                            <S.SliderWrapper>
                                <Slider
                                    sliderMinWidth={S.SliderMinWidth}
                                    isDisabled={!uiSound}
                                    value={uiSound ? uiVolume : 0}
                                    Icon={uiSound ? IconSound : IconSoundMute}
                                    onValueChange={setUiVolume}
                                    overrideMoveLeft={() => {
                                        // not clear why the explicit setFocus is necessary here
                                        // but the nav just doesn't work without it
                                        setFocus(uiSoundCheckboxRef.current);
                                    }}
                                />
                            </S.SliderWrapper>
                        </S.InnerRow>
                    </S.SettingRow>
                </S.RightPanel>
            </S.ContentWrapper>
            <ActionButtonsWrapper>
                <PrimaryButton onClick={onClose}>
                    {t('buttons.close')}
                </PrimaryButton>
            </ActionButtonsWrapper>
        </MessageBoxWrapper>
    );

    return ReactDOM.createPortal(box, document.body);
});
