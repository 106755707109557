import { If, openLink, withFocusable } from 'helpers';
import { useTranslation } from 'react-i18next';
import { SupportLink } from 'components/ui/message-box/support-message-box/SupportMessageBox.style';

const DefaultComponent = SupportLink;

export const Link = ({ Component = DefaultComponent, ...props }) => {
    const { t } = useTranslation();
    const Comp = withFocusable(Component);

    return (
        <Comp
            onClick={(e) => {
                e.preventDefault();
                openLink(props.href);
            }}
            {...props}
        >
            {/* default text if no chlidren specfied */}
            <If condition={!props.children}>{t('support.link')}</If>
        </Comp>
    );
};
