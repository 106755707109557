import { useTranslation } from 'react-i18next';
import { Checkbox } from 'components/ui/checkbox/Checkbox';
import * as S from './EnableSchedule.style';

export const EnableSchedule = ({ enabled, onClick, ...props }) => {
    const { t } = useTranslation();
    return (
        <S.EnableSchedule {...props}>
            <Checkbox defaultChecked={!enabled} onClick={onClick} />
            {t('profile.parental_control.unlimited_everyday')}
        </S.EnableSchedule>
    );
};
