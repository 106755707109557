import { formatToHrs, If } from 'helpers';
// import * as S from './TimeAmount.style';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeBox } from '../../range-box/TimeBox';
import * as S from './DailyTime.style';

export function DailyTime({ enabled, hoursPerDay, onHoursPerDayChange }) {
    const { t } = useTranslation();
    const lastFocused = useRef();

    const [showTimeBox, setShowTimeBox] = useState(false);

    const timeAmountClicked = () => {
        if (!enabled) {
            return;
        }
        lastFocused.current = document.activeElement;
        setShowTimeBox(true);
    };

    return (
        <S.TimeWrapper>
            <If condition={showTimeBox}>
                <TimeBox
                    enabled={enabled}
                    amount={hoursPerDay}
                    onAmountChange={onHoursPerDayChange}
                    lastFocusedRef={lastFocused}
                    onValidate={() => setShowTimeBox(false)}
                    onReset={() => {
                        onHoursPerDayChange(Infinity);
                    }}
                    title={t('profile.parental_control.daily_hours')}
                />
            </If>

            <S.TimeAmount onClick={timeAmountClicked}>
                <S.TimeAmountInput>
                    {formatToHrs(hoursPerDay)}
                </S.TimeAmountInput>
            </S.TimeAmount>
            <S.RangeMode>{t(`profile.parental_control.within`)}</S.RangeMode>
        </S.TimeWrapper>
    );
}
