import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    > div {
        margin-bottom: 1rem;
    }
`;

export const NavButtonMobileBase = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
`;

export const NavButtonMobileLeft = styled(NavButtonMobileBase)`
    left: 10px;
`;

export const NavButtonMobileRight = styled(NavButtonMobileBase)`
    right: 10px;
`;
