import { GamesWrapper, Grid } from 'components/games';
import { Trailer } from 'components/games/trailer/Trailer';
import { OffersSwiper } from 'components/offers';
import { CloseGridButtonWrapper } from 'components/subscribe';
import { ColumnLayout, PrimaryButton } from 'components/ui';
import { If } from 'helpers';
import { useSubscribe } from 'hooks';
import { useTranslation } from 'react-i18next';

export function SubscribeScreen() {
    const { t } = useTranslation();
    const {
        gridVisible,
        setOfferSelected,
        currentGame,
        columns,
        closeGrid,
        trailerStatus,
        startGameTrailer,
        closeGameTrailer,
        closeGameTrailerWithSound,
        offers,
    } = useSubscribe();

    return (
        <ColumnLayout alignItems="center" justifyContent="center" fullSize>
            <If condition={gridVisible}>
                <If condition={trailerStatus.visible && currentGame}>
                    <Trailer
                        game={currentGame}
                        muted={trailerStatus.muted}
                        closeWithSound={closeGameTrailerWithSound}
                        closeWithoutSound={closeGameTrailer}
                    />
                </If>
                <GamesWrapper>
                    <CloseGridButtonWrapper>
                        <PrimaryButton onClick={closeGrid}>
                            {t('buttons.back_to_offer')}
                        </PrimaryButton>
                    </CloseGridButtonWrapper>
                    <Grid
                        noDetail
                        columns={columns}
                        onItemClicked={startGameTrailer}
                    />
                </GamesWrapper>
            </If>
            <OffersSwiper
                isVisible={!gridVisible}
                offers={offers}
                setOfferSelected={setOfferSelected}
            />
        </ColumnLayout>
    );
}
