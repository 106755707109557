import { createSlice } from '@reduxjs/toolkit';
import { doCreateProfileReducer } from './doCreateProfile';
import { doUpdateProfileReducer } from './doUpdateProfile';
import { doDeleteProfileReducer } from './doDeleteProfile';

const initialStateParentalControl = {
    showParentalControl: false,
    needWizard: true,
    wizard: {
        enabled: false,
        hoursPerDay: 1,
        globalRange: { start: 8, end: 20 },
    },
    full: undefined,
    // schema
    // {
    //     enabled: false,
    //     globalRange: { start: 8, end: 20 },
    //     days: Array.from({ length: 7 }, (_, i) => ({
    //         dayIndex: i, // 0 is Monday
    //         hoursPerDay: 1,
    //         range: { start: 8, end: 20 },
    //     })),
    // },
};

const initialState = {
    profileName: '',
    uid: null,
    isWorking: false,
    isDeleting: false,
    contentRating: null,
    locked: null,
    pinCode: '',
    error: null,
    profileCreated: false,
    profileUpdated: false,
    avatar: null,
    mode: 'create',
    oldState: {},
    deleteConfirmation: false,
    parentalControl: {
        ...initialStateParentalControl,
    },
};

// Slice
const profileEditorSlice = createSlice({
    name: 'profileEditor',

    initialState,

    reducers: {
        updateProfileName: (state, action) => {
            state.profileName = action.payload;
        },

        updateContentRating: (state, action) => {
            state.contentRating = action.payload;
        },

        updateProfileLocked: (state, action) => {
            state.locked = action.payload;
        },

        updatePINCode: (state, action) => {
            state.pinCode = action.payload;
        },

        updateAvatar: (state, action) => {
            state.avatar = action.payload;
        },

        clearProfileError: (state) => {
            state.error = null;
        },

        showDeleteProfileConfirmation: (state, action) => {
            state.deleteConfirmation = action.payload;
        },

        setProfileEditionMode: (state, action) => {
            state.mode = action.payload;
        },

        initCreateProfileMode: (state) => {
            state.mode = 'create';
        },

        initUpdateProfileMode: (state, action) => {
            const { parentalControl } = action.payload;
            return {
                ...initialState,
                ...action.payload,
                mode: 'update',
                parentalControl: {
                    ...initialStateParentalControl,
                    needWizard: !parentalControl,
                    full: parentalControl,
                },
                oldState: { ...action.payload },
            };
        },

        resetProfileEditor: () => initialState,

        // Parental Control
        startParentalControlEdition: (state) => {
            state.parentalControl.showParentalControl = true;
        },
        endParentalControlEdition: (state) => {
            state.parentalControl.showParentalControl = false;
        },

        // Parental Control Wizard
        updateWizardParentalControlEnabled: (state, action) => {
            state.parentalControl.wizard.enabled = action.payload;
        },
        updateWizardHoursPerDay: (state, action) => {
            state.parentalControl.wizard.hoursPerDay = action.payload;
        },
        updateWizardGlobalRange: (state, action) => {
            state.parentalControl.wizard.globalRange = action.payload;
        },

        // Parental Control Scheduler
        startWizard: (state, action) => {
            state.parentalControl.needWizard = true;
            state.parentalControl.wizard = initialStateParentalControl.wizard;
        },
        updateParentalControl: (state, action) => {
            state.parentalControl.needWizard = false;
            state.parentalControl.full = action.payload;
        },
    },

    extraReducers: {
        ...doCreateProfileReducer,
        ...doUpdateProfileReducer,
        ...doDeleteProfileReducer,
    },
});

// Actions
export const {
    updateProfileName,
    updateContentRating,
    updateProfileLocked,
    updatePINCode,
    updateAvatar,
    setProfileEditionMode,
    showDeleteProfileConfirmation,
    clearProfileError,
    initCreateProfileMode,
    initUpdateProfileMode,
    resetProfileEditor,
    startParentalControlEdition,
    endParentalControlEdition,
    updateWizardParentalControlEnabled, //step1
    updateWizardHoursPerDay, //step2
    updateWizardGlobalRange, //step3
    startWizard,
    updateParentalControl,
} = profileEditorSlice.actions;

// Selector
export const profileEditorSelector = (state) => state.profileEditor;
export const parentalControlSelector = (state) =>
    state.profileEditor.parentalControl;
export const parentalControlWizardSelector = (state) =>
    state.profileEditor.parentalControl.wizard;
export const parentalControlScheduleSelector = (state) =>
    state.profileEditor.parentalControl.full;

// Reducer
export const profileEditorReducer = profileEditorSlice.reducer;

// Thunks
export { doCreateProfile } from './doCreateProfile';
export { doUpdateProfile } from './doUpdateProfile';
export { doDeleteProfile } from './doDeleteProfile';
