import Theme from 'app/theme';
import { withFocusable } from 'helpers';
import styled from 'styled-components';

export const MessageWrapper = styled.div`
    width: 100%;
`;

export const Text = styled.div`
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
`;

export const LangSelectionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${(props) =>
        props.nbLanguages < 3 ? 'flex-start' : 'space-between'};
    flex-wrap: wrap;
    margin-top: -30px;
    min-width: ${(props) => (props.nbLanguages < 3 ? '650px' : '')};

    > div {
        margin-right: ${(props) => (props.nbLanguages < 3 ? '1.5rem' : '0')};
    }
`;

export const LangWrapper = withFocusable(styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;
    width: 30%;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    padding: 5px;
    transition: background-color 250ms ease-out;
    position: relative;

    &:focus {
        outline: none;
        background-color: ${Theme.COLORS.PRIMARY_L2};
    }
`);

export const Lang = styled.div`
    flex-grow: 1;
    text-align: center;
`;
