import { useTimedCallback } from 'hooks/helpers';
import { useCallback, useState } from 'react';

export function useMultiDisclaimer() {
    const [visible, setVisible] = useState(false);
    const { createTimedCallback, clearTimedCallback, intervalTick } =
        useTimedCallback();
    // const callbackRef = useRef();

    const accepted = useCallback(
        (timedout) => {
            if (!timedout) {
                clearTimedCallback();
            }

            setVisible(false);
            // callbackRef.current();
        },
        [clearTimedCallback]
    );

    const showDisclaimer = useCallback(
        (callback) => {
            // callbackRef.current = callback || null;
            setVisible(true);
            createTimedCallback(3, accepted);
        },
        [createTimedCallback, accepted]
    );

    return {
        showDisclaimer,
        visible,
        setVisible,
        accepted,
        tick: intervalTick,
    };
}
