import { ArrowLeft, ArrowRight } from 'components/ui';
import { withInputDispatcherProvider } from 'context';
import { formatToHour, formatToHrs, If } from 'helpers';
import { useLeftPress, useRightPress } from 'hooks';
import * as S from './HourInput.style';

export const HourInput = withInputDispatcherProvider(
    ({
        editing,
        increment,
        decrement,
        value,
        WrapperComponent,
        InputComponent,
        format,
        ...props
    }) => {
        useLeftPress(editing ? decrement : undefined);
        useRightPress(editing ? increment : undefined);
        return (
            <WrapperComponent>
                <S.TimeWrapper>
                    <ArrowLeft
                        style={{ height: '100%' }}
                        visible={editing}
                        onClick={(e) => {
                            decrement();
                            e.preventDefault();
                        }}
                    />
                    <InputComponent editing={editing} {...props}>
                        <If condition={format === 'hr'}>
                            {formatToHrs(value)}
                        </If>
                        <If condition={format === 'hour'}>
                            {formatToHour(value)}
                        </If>
                    </InputComponent>
                    <ArrowRight
                        style={{ height: '100%' }}
                        visible={editing}
                        onClick={(e) => {
                            increment();
                            e.preventDefault();
                        }}
                    />
                </S.TimeWrapper>
            </WrapperComponent>
        );
    }
);
