import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Games } from 'services';
import { profileSelector } from 'slices';
import differenceInDays from 'date-fns/differenceInDays';

import { expirationReminders } from 'app/config';
import { encrypt } from 'helpers';

let shownThisSession = false;

export const useOfferExpirationNotification = () => {
    const { currentProfile } = useSelector(profileSelector);
    const profileUID = currentProfile.uid;
    const availableOffers = Games.GetAvailableOffers();

    // check first discovery offer
    const discoveryOffer =
        availableOffers.filter((o) => o.is_discovery_offer)[0] || {};
    const { alias, endDate } = discoveryOffer;
    const hasOtherOffers =
        availableOffers.filter(
            (o) => o !== discoveryOffer && o.can_be_subscribed
        ).length > 0;

    const [visible, setVisible] = useState(false);

    const { days, expirationWarnings } = useMemo(() => {
        // get or create necessary data, will be updated in-place and re-stored
        const expirationWarnings =
            JSON.parse(localStorage.getItem('expiration_warnings')) || {};
        // for each profile: { offerAlias: { endDate: 'date', lastWarningDate: 'date' } }
        const encryptedProfileUID = encrypt(profileUID, 'profileUID');
        const currentWarnings = expirationWarnings[encryptedProfileUID] || {};
        expirationWarnings[encryptedProfileUID] = currentWarnings;
        const warnings = currentWarnings[alias] || {};
        currentWarnings[alias] = warnings;

        // end date changed (extended?), reinitialize
        if (warnings.endDate !== endDate) {
            warnings.endDate = endDate;
            delete warnings.lastWarningDate;
        }

        let show = false;
        let days = 0;
        // endDate is valid only if we do have a discovery offer
        // and we do not warn if there are also active subcribed offers
        if (endDate && !hasOtherOffers) {
            days = differenceInDays(new Date(endDate), Date.now());
            const { lastWarningDate } = warnings;
            const lastWarningDays = lastWarningDate
                ? differenceInDays(new Date(endDate), new Date(lastWarningDate))
                : Infinity;
            // show if at least one reminder is greater than the remaining days
            // and is not past the last reminder
            show = expirationReminders.some(
                (d) => days <= d && d < lastWarningDays
            );
        }

        if (show) {
            setVisible(true);
            shownThisSession = true;
            warnings.lastWarningDate = new Date(Date.now());
        }

        return { days, expirationWarnings };
    }, [alias, endDate, hasOtherOffers, profileUID]);

    const onClose = useCallback(() => {
        setVisible(false);
        // update stored information
        localStorage.setItem(
            'expiration_warnings',
            JSON.stringify(expirationWarnings)
        );
    }, [expirationWarnings]);

    return { shownThisSession, visible, days, onClose };
};
