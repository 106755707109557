import styled, { css } from 'styled-components';
import { isTV, mobile } from 'app/device';

// Desktop base
const WizardBoxWrapperBase = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    max-width: calc(100vw - 20px);
    min-width: 450px;
`;

// Mobile
const WizardBoxWrapperResponsive = styled(WizardBoxWrapperBase)`
    ${mobile(css`
        width: 450px;
        padding: 25px;
    `)}
`;

// TV
const WizardBoxWrapperTV = styled(WizardBoxWrapperBase)`
    width: 710px;
    padding: 50px;
`;

export const WizardBoxWrapper = isTV
    ? WizardBoxWrapperTV
    : WizardBoxWrapperResponsive;
