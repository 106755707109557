import { useMultiStepContext } from 'context/multi-step';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { recoverySelector } from 'slices';
import { doAskCode } from 'slices/recovery';

export function useAskCode() {
    const [radioButtonStatus, setRadioButtonStatus] = useState('');
    const [formError, setFormError] = useState('');
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();
    const { step2Done, username, recoveryError, availableMethods } =
        useSelector(recoverySelector);
    const { setStep, cancelStep } = useMultiStepContext();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (step2Done) {
            setStep('step3');
        }
    }, [setStep, step2Done]);

    // Handle on submit
    const onSubmit = handleSubmit(() => {
        if (radioButtonStatus === '') {
            setFormError('recovery.step_ask_code_error');
            return;
        }

        dispatch(
            doAskCode({
                username: username,
                method: radioButtonStatus,
                language: i18n.language,
            })
        );
    });

    return {
        onSubmit,
        register,
        availableMethods,
        radioButtonStatus,
        setRadioButtonStatus,
        formError,
        setFormError,
        recoveryError,
        cancelStep,
    };
}
