import Theme from 'app/theme';
import { withFocusable } from 'helpers';
import styled, { css } from 'styled-components';
import { mobile } from 'app/device';

// eslint-disable-next-line no-unused-vars
import { RoundedBar } from '../time-bar/TimeBar.style';

export const TimeWrapper = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 15%;
    margin: 0 1rem;

    ${mobile(css`
        margin: 0 0.25rem;
    `)}
`;

export const RangeMode = styled.RoundedBar`
    flex-grow: 1;
    font-size: 0.75rem;
    border-radius: 0 50px 50px 0;
    outline: none;
`;

export const TimeAmount = styled.div`
    width: 50%;
`;

export const TimeAmountInput = withFocusable(styled.RoundedBar`
    width: 100%;
    outline: none;
    border-style: none;
    border-right-style: dotted;
    border-radius: 50px 0 0 50px;
    border-color: ${Theme.COLORS.GRAY_L3};
    border-width: 4px;
    transition: background-color ease-out 250ms;
    background-color: ${(props) =>
        props.editing
            ? Theme.COLORS.PRIMARY_L2
            : css`rgba(255, 255, 255, 0.1)`};
    color: 'white';
    &:focus,
    &:hover {
        background-color: ${Theme.COLORS.PRIMARY_L2};
    }
`);
