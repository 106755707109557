import { GameWindowSwiper } from 'components/games/game-window-swiper/GameWindowSwiper';
import {
    MessageBoxTitle,
    MessageBoxWrapper,
    PrimaryButton,
} from 'components/ui';
import { BlurOverlay } from 'components/ui/overlay/blur-overlay/BlurOverlay';
import { ItemList } from 'components/ui/swiper/ItemList';
import { If } from 'helpers';
import { useNewGamesNotification } from 'hooks';
import { useTranslation } from 'react-i18next';
import { NewGamesListPlain } from './NewGamesListPlain';
import { NewGamesListSwiper } from './NewGamesListSwiper';
import * as S from './NewGamesNotification.style';

export function NewGamesNotification() {
    const { t } = useTranslation();
    const { onClose, gamesSwiper, newGames, visible } =
        useNewGamesNotification();

    const openWindowWithGameList = (game) => (e) => {
        gamesSwiper.open(e, game);
    };

    return (
        <If condition={visible}>
            <BlurOverlay>
                <If condition={gamesSwiper.visible}>
                    <GameWindowSwiper
                        gamesSwiper={gamesSwiper}
                        selectedIndex={gamesSwiper.selectedIndex}
                        games={newGames}
                    />
                </If>
                <If condition={!gamesSwiper.visible}>
                    <MessageBoxWrapper zIndex={901}>
                        <S.ContentWrapper>
                            <MessageBoxTitle
                                alignItems="center"
                                title={t('games.newly.header')}
                                title2={t('games.newly.message')}
                            />
                            <S.Text>{t('games.newly.sub_message')}</S.Text>
                            <ItemList
                                list={newGames}
                                listItemSelected={openWindowWithGameList}
                                ListPlainComp={NewGamesListPlain}
                                ListSwiperComp={NewGamesListSwiper}
                            />
                            {/* TODO: Check if need to checkbox "don't show again" */}
                            <PrimaryButton onClick={onClose}>
                                {t('buttons.close')}
                            </PrimaryButton>
                        </S.ContentWrapper>
                    </MessageBoxWrapper>
                </If>
            </BlurOverlay>
        </If>
    );
}
