import { hasTouch } from 'app/device';
import { maxCols } from 'helpers';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Games } from 'services';

const nbGamesPerColumn = 10;

// filter out games to not show in charts, then sort by highest session count and take the N first ones
const filterAndSortGames = (games) =>
    games
        .filter((game) => game.release_status !== 'coming_soon')
        .sort((a, b) => b.sessionCount - a.sessionCount);

// sum of session for a set of games
const totalSessions = (games) =>
    games.reduce((total, g) => total + g.sessionCount, 0);

export function useCharts() {
    const { t } = useTranslation();
    const games = Games.GetGames(); // order may change when language is changed

    // Init columns
    const [columns, colsTitles] = useMemo(() => {
        const columns = Array(maxCols);
        const colsTitles = Array(maxCols);
        let nbCols = maxCols;
        let lastColumnIndex = maxCols - 1;

        // 'all' comes first
        columns[0] = filterAndSortGames(games).slice(0, nbGamesPerColumn);
        colsTitles[0] = t('charts.all_stars');
        nbCols--;

        const finalCategories = [];
        // multi always goes last if available and non empty
        if (Games.GetCategoryGames('multiplayer').length > 0) {
            finalCategories.push('multiplayer');
        }
        // touch always goes second to last if available and non empty
        if (hasTouch()) {
            finalCategories.push('touch');
        }
        finalCategories.forEach((c) => {
            const categorygames = Games.GetCategoryGames(c);
            if (categorygames.length > 0) {
                columns[lastColumnIndex] = filterAndSortGames(
                    categorygames
                ).slice(0, nbGamesPerColumn);
                colsTitles[lastColumnIndex] = t('categories.' + c);
                lastColumnIndex--;
                nbCols--;
            }
        });

        // keep as many remaining categories as necessary to fill the columns, ordered
        // by the total count of sessions on *all* games in that category
        Games.GetCategories()
            .filter((c) => !finalCategories.includes(c.name))
            .map(({ name, games }) => ({
                name,
                games: filterAndSortGames(games),
            }))
            .sort((a, b) => totalSessions(b.games) - totalSessions(a.games))
            .slice(0, nbCols)
            .forEach((c, i) => {
                columns[i + 1] = c.games.slice(0, nbGamesPerColumn);
                colsTitles[i + 1] = t('categories.' + c.name);
            });

        return [columns, colsTitles];
    }, [games, t]);

    return { columns, colsTitles };
}
