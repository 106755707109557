import { isMobileOrTablet } from 'app/device';
import {
    MessageBoxTitle,
    MessageBoxWrapper,
    PrimaryButton,
} from 'components/ui';
import { Message } from 'components/ui/message-box/MessageBox.style';
import { withInputDispatcherProvider } from 'context';
import { If, qrCode } from 'helpers';
import { useBackPress } from 'hooks';
import { useSupportInfo } from 'hooks/support/useSupportInfo';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ActionButtonsWrapper } from '..';
import * as S from './SupportMessageBox.style';

export const SupportMessageBox = withInputDispatcherProvider(
    ({ onClose, supportMethods }) => {
        const { t } = useTranslation();
        useBackPress(onClose);
        const ref = useRef(null);

        useEffect(() => {
            if (!supportMethods) return;

            supportMethods.forEach((el) => {
                if (el.url) {
                    qrCode.append(ref.current);
                    qrCode.update({ data: el['url'] });
                }
            });
        }, [supportMethods]);

        const methods = Object.fromEntries(
            supportMethods.map((method, i) => {
                const methodName = Object.keys(method)[0];
                const methodValue = method[methodName];
                return [[methodName], methodValue];
            })
        );

        const message = t('support.message', {
            telcoMail: methods.email,
            telcoLink: methods.url,
        });

        const device = useSupportInfo();

        const box = (
            <MessageBoxWrapper style={{ textAlign: 'center' }}>
                <MessageBoxTitle title={t('support.title')} />
                <Message>
                    <If condition={methods.email || methods.url}>{message}</If>
                    <p>{t('support.message2')}</p>
                </Message>

                <S.BuildVersion>
                    {t('support.version')} {process.env.REACT_APP_VERSION}
                    {' | '}
                    <If condition={device}>
                        {device.type} - {device.browser}
                    </If>
                    <If condition={isMobileOrTablet}> - {device.os}</If>
                </S.BuildVersion>
                <ActionButtonsWrapper>
                    <PrimaryButton onClick={onClose}>
                        {t('buttons.close')}
                    </PrimaryButton>
                </ActionButtonsWrapper>
            </MessageBoxWrapper>
        );
        return ReactDOM.createPortal(box, document.body);
    }
);
