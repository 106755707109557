import { maxCols } from 'helpers';

import { useEffect, useRef, useMemo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Games } from 'services';
import { hideGlobalNavigation, showGlobalNavigation } from 'slices';
import { useSpatialNavContext } from 'context';
import { isMobileOrTablet } from 'app/device';
import { useSoundAction } from 'hooks';

const initialTrailerState = { visible: false, muted: false };

export function useSubscribe() {
    const dispatch = useDispatch();
    const { setFocus, refreshTree } = useSpatialNavContext();
    const offers = Games.GetSubscribableOffers();

    const [gridVisible, setGridVisible] = useState(false);
    const [offerSelected, setOfferSelected] = useState();
    const [currentGame, setCurrentGame] = useState();
    const [trailerStatus, setTrailerStatus] = useState(initialTrailerState);

    const lastFocusedElRef = useRef();

    const openGrid = useCallback(() => {
        dispatch(hideGlobalNavigation());
        lastFocusedElRef.current = document.activeElement;
        setGridVisible(true);
    }, [dispatch]);

    useEffect(() => {
        if (offerSelected) openGrid();
    }, [offerSelected, openGrid]);

    const closeGrid = () => {
        setGridVisible(false);
        dispatch(showGlobalNavigation());
        setOfferSelected(null);
    };

    useEffect(() => {
        if (lastFocusedElRef.current && !gridVisible) {
            setFocus(lastFocusedElRef.current);
            lastFocusedElRef.current = null;
            refreshTree();
        }
    }, [gridVisible, setFocus, refreshTree]);

    // Init columns
    const columns = useMemo(() => {
        //create cols
        const result = [...Array(maxCols)].map(() => []);

        if (!offerSelected) return result;

        // Fill columns
        Games.GetGamesFromSubscribableOfferAlias(offerSelected).forEach(
            (game, index) => result[index % maxCols].push(game)
        );

        return result;
    }, [offerSelected]);

    const startGameTrailer = useSoundAction('regularBtn', (event, alias) => {
        const game = Games.GetGameFromAlias(alias);

        if (!game.assets.trailer) return;

        setCurrentGame(game);
        // on mobile devices, auto playback of videos is only allowed if muted, unless
        // it results from a direct user action (trusted event created by the browser)
        // https://webkit.org/blog/6784/new-video-policies-for-ios
        // https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
        setTrailerStatus({
            visible: true,
            muted: isMobileOrTablet && !event.isTrusted,
        });
    });

    const closeGameTrailer = useCallback(() => {
        setTrailerStatus(initialTrailerState);
        setCurrentGame(null);
    }, []);

    const closeGameTrailerWithSound = useSoundAction('back', closeGameTrailer);

    return {
        gridVisible,
        columns,
        setOfferSelected,
        offers,
        openGrid,
        closeGrid,
        currentGame,
        trailerStatus,
        startGameTrailer,
        closeGameTrailer,
        closeGameTrailerWithSound,
    };
}
