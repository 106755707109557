import { mobile, tv } from 'app/device';
import { BlurBox } from 'components/ui';
import styled, { css } from 'styled-components';

export const Wrapper = styled(BlurBox)`
    width: 80vw;
    margin-right: 1vw;
    display: flex;
    overflow: hidden;
    justify-content: right;
    --gameWindowHeight: 247px; // used in other components

    ${mobile(css`
        width: 98vw;
        margin: 0 auto;
        --gameWindowHeight: 230px;
    `)}

    ${tv(css`
        width: 65vw;
        max-width: 65vw;
        --gameWindowHeight: 320px;
    `)}

  height: var(--gameWindowHeight);
`;

export const Cover = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: var(--gameWindowHeight);
    height: 100%;
`;
