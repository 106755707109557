import history from 'app/history';
import Routes from 'app/routes';
import { useSpatialNavContext } from 'context';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetGlobalNavigation } from 'slices';

export function useChangeProfile() {
    const dispatch = useDispatch();
    const { resetLastFocused } = useSpatialNavContext();

    return useCallback(() => {
        history.push(Routes.PROFILE_SELECTION);
        // hide the nav bar
        dispatch(resetGlobalNavigation());
        // reset last focus in nav bar - only once hidden
        resetLastFocused();
    }, [dispatch, resetLastFocused]);
}
