import { mobile } from 'app/device';
import styled, { css } from 'styled-components';

export const BoxTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;
    width: 100%;

    ${mobile(css`
        margin-bottom: 1rem;
    `)}
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export const BoxTitle = styled.div`
    font-size: 1.5rem;
    font-weight: bold;
    white-space: pre-line;
`;

export const BoxTitle2 = styled(BoxTitle)``;

export const IconTitle = styled.div`
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    margin-right: 0.75rem;
    position: relative;
    height: 1.8rem;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        fill: white;
    }
`;
