import {
    StepWrapper,
    Title,
    BoxContent,
    BoxText,
} from 'components/parental-control/wizard';
import { ActionButtons } from 'components/ui';
import { useWizardFinished } from 'hooks/parental-control/wizard';
import { useTranslation } from 'react-i18next';

export function StepFinished() {
    const { onBack, onValidate } = useWizardFinished();
    const { t } = useTranslation();

    return (
        <StepWrapper>
            <Title />
            <BoxContent>
                <BoxText>{t('profile.parental_control.finish')}</BoxText>
            </BoxContent>
            <ActionButtons
                focusOnMount
                buttons={[
                    {
                        type: 'primary',
                        text: t('buttons.validate'),
                        callback: onValidate,
                    },
                    {
                        type: 'neutral',
                        text: t('buttons.back'),
                        callback: onBack,
                    },
                ]}
            />
        </StepWrapper>
    );
}
