import { isTV } from 'app/device';
import {
    GamesWrapper,
    GapAnimation,
    Grid,
    NoGamesFound,
    QuitWrapper,
} from 'components';
import { GameWindowSwiper } from 'components/games/game-window-swiper/GameWindowSwiper';
import { ColumnLayout, RowLayout } from 'components/ui';
import { CategoryTitle } from 'components/ui/category/category-title/CategoryTitle';
import { If } from 'helpers';
import { useGamesSwiper, useTouchCategories } from 'hooks';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Games } from 'services';
import { TitleFilter } from 'components/games/categories/Categories.style';

export const TouchScreen = () => {
    const blurRef = useRef();
    const { t } = useTranslation();
    const { games, columns, currentTab } = useTouchCategories();
    const gamesSwiper = useGamesSwiper(games);
    const tab = currentTab.subTab;

    return (
        <>
            <QuitWrapper disabled={gamesSwiper.visible} />
            <ColumnLayout alignItems="center" fullSize>
                <If condition={gamesSwiper.visible}>
                    <GameWindowSwiper
                        gamesSwiper={gamesSwiper}
                        selectedIndex={gamesSwiper.selectedIndex}
                        prev={gamesSwiper.prev}
                        next={gamesSwiper.next}
                        games={games}
                    />
                </If>
                <GamesWrapper minTop={isTV ? 350 : 250} key={tab}>
                    <If condition={games.length <= 0}>
                        <NoGamesFound>{t('categories.no_games')}</NoGamesFound>
                    </If>
                    <If condition={games.length > 0}>
                        <ColumnLayout fullWidth>
                            <GapAnimation
                                isGapOpen={gamesSwiper.gapOpen}
                                lastFocused={gamesSwiper.lastFocused}
                            >
                                <RowLayout>
                                    <CategoryTitle key={tab} blurRef={blurRef}>
                                        <TitleFilter>
                                            <div>
                                                {Games.isDiscoveryOffer()
                                                    ? ''
                                                    : t(
                                                          `${
                                                              currentTab.name
                                                          }.${tab.toLowerCase()}`
                                                      )}
                                            </div>
                                        </TitleFilter>
                                    </CategoryTitle>
                                </RowLayout>
                            </GapAnimation>
                            <Grid
                                isGapOpen={gamesSwiper.gapOpen}
                                lastFocused={gamesSwiper.lastFocused}
                                onItemClicked={gamesSwiper.open}
                                columns={columns}
                            />
                        </ColumnLayout>
                    </If>
                </GamesWrapper>
            </ColumnLayout>
        </>
    );
};
