import * as DeviceDetect from 'react-device-detect';

const buildTarget = process.env.REACT_APP_BUILD_TARGET?.trim();
console.log('buildTarget: ', buildTarget);
// isHp Target
export const isHP = buildTarget === 'hp';
if (isHP) console.log('HOSPITALITY Build');

// isTv Target
export const isTV = buildTarget === 'tv' || isHP;
if (isTV && !isHP) console.log('TV Build');
console.log('isTV: ', isTV, isHP, buildTarget);
// Stubs
export const landscape = () => {};
export const mobile = () => {};
export const mobileSmall = () => {};
export const tablet = () => {};
export const desktopSmall = () => {};
export const hover = () => {};
export const tv = () => {};

// Device / Navigator
export const isAndroid = DeviceDetect.isAndroid;
export const isIOS = DeviceDetect.isIOS;
export const isMacOs = DeviceDetect.isMacOs;
export const isSafari = DeviceDetect.isSafari;
export const isFirefox = DeviceDetect.isFirefox;
export const isMobile = DeviceDetect.isMobileOnly;
export const isTablet = DeviceDetect.isTablet;
export const isMobileOrTablet = DeviceDetect.isMobile;
export const isDesktop = DeviceDetect.isDesktop;
export const isChrome = DeviceDetect.isChrome;

// OS
export const osName = DeviceDetect.osName;
export const osVersion = DeviceDetect.osVersion;

// Device capabilities
export const isStandalone = () => {
    return window.matchMedia('(display-mode: standalone)').matches;
};

//Touch
export const hasTouch = () => {
    return (
        ('ontouchstart' in window ||
            navigator.maxTouchPoints > 0 ||
            navigator.msMaxTouchPoints > 0) &&
        !isTV // just in case TV with touch screen exists ¯\_(ツ)_/¯
    );
};

export let supportBackDropFilter;
try {
    supportBackDropFilter =
        !isTV && (isSafari || CSS.supports('(backdrop-filter:blur(32px))'));
} catch (e) {
    // try-catch for jest context, where CSS is not defined
}

export const hasRequiredIOSVersion = (() => {
    const minIosVersion = 14;
    //check git history for previous way to check iOS version...
    //DetectDevice.osVersion seems wrong for now
    //Here we are checking the browser version because on iOS device Safari is updated along the the device software update

    return (
        isIOS &&
        isMobileOrTablet &&
        parseInt(DeviceDetect.browserVersion) >= minIosVersion
    );
})();
