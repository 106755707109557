import { useEffect, useRef, useState } from 'react';
import { isTV, isIOS, isMobile } from '../../app/device';
import { useBackPress } from '../input-dispatch';

export function useTrailer(closeWithSound, closeWithoutSound, muted) {
    const videoRef = useRef();
    const sourceRef = useRef();
    const [isFullscreen, setIsFullscreen] = useState(false);

    useBackPress(closeWithSound);

    /**
     * get state from fullscreen mode
     */
    useEffect(() => {
        const handleFullscreenChanged = () => {
            setIsFullscreen((prevState) => !prevState);
        };
        window.addEventListener('fullscreenchange', handleFullscreenChanged);

        return () => {
            window.removeEventListener(
                'fullscreenchange',
                handleFullscreenChanged
            );
        };
    }, []);

    /**
     * Hacky way to close trailer if user hit ESC,
     * for some reason we can't get a keybind event on ESC when fullscreen (seems to be overridden and specific to escape fullscreen mode)
     **/
    useEffect(() => {
        if (isIOS) return;
        // if is not fullscreen and video playing (after ESC fullscreen from playing video)
        if (!isFullscreen && !videoRef.current.paused) closeWithSound();
    }, [isFullscreen, closeWithSound]);

    /**
     * muted  trailer
     */
    useEffect(() => {
        videoRef.current.muted = muted;
    }, [muted]);

    /**
     * Fullscreen mode on play
     **/
    useEffect(() => {
        if (!videoRef.current || isIOS) return;

        if (videoRef.current.requestFullscreen && isMobile) {
            videoRef.current.requestFullscreen();
        } else if (videoRef.current.webkitRequestFullscreen && isMobile) {
            /* Safari */
            videoRef.current.webkitRequestFullscreen();
        } else if (videoRef.current.msRequestFullscreen && isMobile) {
            /* IE11 */
            videoRef.current.msRequestFullscreen();
        }
    }, []);

    useEffect(() => {
        // Disable controls on TV
        if (isTV) videoRef.current.controls = false;

        // Video end
        videoRef.current.addEventListener('ended', () => {
            closeWithoutSound();
        });

        // Source error
        sourceRef.current.addEventListener('error', (err) => {
            console.log('sourceRef.current', sourceRef.current);
            console.error(err);
            console.log('type: ', err.type);
            closeWithoutSound();
        });
    }, [closeWithoutSound]);

    return { videoRef, sourceRef };
}
