import { isTV } from 'app/device';
import {
    Link,
    MessageBoxTitle,
    MessageBoxWrapper,
    PrimaryButton,
} from 'components/ui';
import { NeutralButton } from 'components/ui/buttons';
import { Message } from 'components/ui/message-box/MessageBox.style';
import { withInputDispatcherProvider } from 'context';
import { If, qrCode } from 'helpers';
import { useBackPress } from 'hooks';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ActionButtonsWrapper } from '..';
import * as S from './QrCodeBox.style';

export const QrCodeBox = withInputDispatcherProvider(
    ({
        title,
        message,
        onAccept,
        onDecline,
        accept,
        decline,
        onClose,
        linkMessage,
        blurOverlap,
        url,
    }) => {
        if (!onClose && !onAccept && !onDecline)
            throw new Error(
                'QrCodeBox onClose function or onAccept AND onDecline functions must be passed as props'
            );

        const { t } = useTranslation();
        const ref = useRef(null);
        useBackPress(onClose);
        const dataUrl =
            !url && process.env.NODE_ENV === 'development'
                ? 'https://google.com'
                : url;

        useEffect(() => {
            qrCode.append(ref.current);
            qrCode.update({ data: dataUrl });
        }, [dataUrl]);

        // Note: nested backdrop-filters does not work
        const box = (
            <MessageBoxWrapper blurOverlap={blurOverlap}>
                <If condition={title}>
                    <MessageBoxTitle
                        title={title}
                        alignItems={'center'}
                        titleCenter="center"
                    />
                </If>
                <If condition={message}>
                    <Message style={{ textAlign: 'center' }}>{message}</Message>
                </If>
                <If condition={linkMessage}>
                    <Message style={{ textAlign: 'center' }}>
                        {linkMessage}
                        <Link
                            defaultElement
                            href={dataUrl}
                            Component={S.RegisterUrl}
                        />
                    </Message>
                </If>
                <If condition={!linkMessage && dataUrl}>
                    <Link
                        style={{ marginTop: '1rem' }}
                        defaultElement
                        href={dataUrl}
                        Component={S.RegisterUrl}
                    />
                </If>
                <If condition={isTV}>
                    <Message>{t('login.scan_qrcode')}</Message>
                    <S.QrCode ref={ref} />
                    <S.InstructionsWrapper>
                        <Message>
                            - {t('warnings.gamepad.qrcode.step1')}
                        </Message>
                        <Message>
                            - {t('warnings.gamepad.qrcode.step2')}
                        </Message>
                        <Message>
                            - {t('warnings.gamepad.qrcode.step3')}
                        </Message>
                    </S.InstructionsWrapper>
                </If>
                {/* TODO: replace with ActionButtons.js */}
                {/* qrCodeBox can be shown as a one button option box or confirmation, check useGamepadCheck */}
                <ActionButtonsWrapper>
                    <If condition={onClose}>
                        <PrimaryButton onClick={onClose}>
                            {accept || t('buttons.cancel')}
                        </PrimaryButton>
                    </If>
                    <If condition={onAccept && onDecline}>
                        <PrimaryButton
                            style={{ marginRight: '1rem' }}
                            onClick={onAccept}
                            bold
                        >
                            {accept || t('buttons.accept')}
                        </PrimaryButton>
                        <NeutralButton onClick={onDecline} bold>
                            {decline || t('buttons.cancel')}
                        </NeutralButton>
                    </If>
                </ActionButtonsWrapper>
            </MessageBoxWrapper>
        );

        return ReactDOM.createPortal(box, document.body);
    }
);
