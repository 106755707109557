import { GameHeading, PlayButton } from 'components/games';
import { ActionButtons, PrimaryButton, RowLayout } from 'components/ui';
import { IconLocal } from 'components/ui/icons/IconLocal';
import { IconQuickmatch } from 'components/ui/icons/IconQuickmatch';
import { useMultiViewContext } from 'context';
import { If } from 'helpers';
import { useBackPress } from 'hooks';
import { useCallback, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { descriptionActionBtn } from '../description/DescriptionView';
import { ViewContainer } from '../ViewContainer';
import * as S from './MultiChoiceView.style';

export function MultiChoiceView({
    game,
    index,
    selectedIndex,
    setLastFocusedEl,
    setCurrentGameWindowView,
    setCurrentView,
}) {
    const { t } = useTranslation();
    const { view, setView } = useMultiViewContext();

    // set view info in swiper for the current window only
    useEffect(() => {
        if (index === selectedIndex) {
            setCurrentGameWindowView(view);
        }
    }, [index, selectedIndex, setCurrentGameWindowView, view]);

    const close = useCallback(() => {
        setView('description');
        setLastFocusedEl({ el: descriptionActionBtn.MULTIPLAYER });
    }, [setView, setLastFocusedEl]);

    useBackPress(close);

    const actionButtons = (
        <ActionButtons
            vertical={isMobile}
            Wrapper={S.ActionButtonsWrapper}
            buttons={[
                {
                    type: 'regular',
                    text: t('buttons.back'),
                    callback: close,
                    props: {
                        disableMoveLeft: true,
                    },
                },
            ]}
        />
    );

    return (
        <ViewContainer game={game} actionButtons={actionButtons}>
            <RowLayout fullSize>
                <S.Content>
                    <GameHeading game={game} minimized={false} />
                    <S.Introduction>{t('lobby.message')}</S.Introduction>
                    <S.Wrapper>
                        <S.Quickmatch>
                            <S.TitleWrapper>
                                <IconQuickmatch />
                                <S.Title>{t('lobby.quick-match')}</S.Title>
                            </S.TitleWrapper>
                            <S.Description>
                                {t('lobby.quick-match_disclaimer')}
                            </S.Description>
                            <S.ButtonWrapper>
                                <PrimaryButton
                                    focusOnMount
                                    onClick={() => setView('quick-match')}
                                >
                                    {t('buttons.play')}
                                </PrimaryButton>
                            </S.ButtonWrapper>
                        </S.Quickmatch>
                        <S.Local>
                            <S.TitleWrapper>
                                <IconLocal />
                                <S.Title>{t('lobby.local')}</S.Title>
                            </S.TitleWrapper>
                            <S.Description>
                                {t('lobby.local_disclaimer')}
                            </S.Description>
                            <S.ButtonWrapper>
                                <PlayButton
                                    game={game}
                                    forceGamepadCheck={true}
                                    disableMoveRight
                                />
                            </S.ButtonWrapper>
                        </S.Local>
                    </S.Wrapper>
                    <If condition={!isMobile}>{actionButtons}</If>
                </S.Content>
            </RowLayout>
        </ViewContainer>
    );
}
