export function IconArrowRight(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 76.656 76.656"
            {...props}
        >
            <path d="M51.814 38.799a4.338 4.338 0 01-1.383 2.762c-5.048 5.065-10.09 10.138-15.187 15.158-2.286 2.251-5.15 2.266-7.2.19a4.737 4.737 0 010-6.814c1.951-2.033 3.972-4 5.962-5.988 1.193-1.2 2.4-2.382 3.583-3.586 1.635-1.655 1.669-3.185.043-4.835-3.083-3.132-6.195-6.232-9.3-9.335a5.012 5.012 0 01-1.672-3.733 4.863 4.863 0 017.228-4.259 7.357 7.357 0 011.6 1.229c4.909 4.889 9.792 9.8 14.7 14.69a5.814 5.814 0 011.626 4.521z" />
            <path d="M38.328 8.517A29.811 29.811 0 118.517 38.328 29.845 29.845 0 0138.328 8.517m0-8.517a38.328 38.328 0 1038.328 38.328A38.329 38.329 0 0038.328 0z" />
        </svg>
    );
}
