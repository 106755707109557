import styled, { keyframes, css } from 'styled-components';
import { mobile, tablet, tv } from 'app/device';

const animation = keyframes`
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
`;

export const LoginInputsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 12px;
    min-height: 37px;

    & > :not(:last-child) {
        margin-right: 12px;
    }

    & > :not(:first-child) {
        flex-grow: 1;
    }

    & > * {
        animation: ${animation} 0.6s linear;
    }

    ${mobile(css`
        margin-top: 16px;
        margin-bottom: 10px;
    `)}

    ${tablet(css`
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
    `)}

    ${tv(css`
        margin-top: 56px;
        margin-bottom: 16px;

        & > :not(:last-child) {
            margin-right: 20px;
        }
    `)}
`;

export const InputsWrapper = styled(LoginInputsWrapper)`
    display: ${(props) => (props.visible ? 'flex' : 'none')};
    margin: 0;
`;
