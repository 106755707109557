import { isMobile } from 'app/device';
import { SpatialNavSection, useThemeContext } from 'context';
import { assetURL, editoAssetUrl } from 'helpers';
import i18n from 'i18n';
import { useEffect } from 'react';
import { Games } from 'services';
import { Content } from './content/Content';
import { Header } from './header/Header';
import * as S from './MarketingSlide.style';

export function MarketingSlide({ index, activeIndex, content, ...props }) {
    const showWallpapers = props.isSingle || index === activeIndex;

    const { startWallpaperLoop } = useThemeContext();

    const assets = content.assets[i18n.language];

    useEffect(() => {
        if (showWallpapers) {
            // wallpaper loop priority: editorial wallpapers, editorial game wallpapers or standard behavior
            const wallpaperURLs =
                assets?.background?.map(editoAssetUrl) ||
                Games.GetGameFromAlias(content.alias)?.assets.wallpapers.map(
                    assetURL
                );

            startWallpaperLoop(wallpaperURLs ? { wallpaperURLs } : {});
        }
    }, [showWallpapers, assets, content.alias, startWallpaperLoop]);

    return (
        <SpatialNavSection enterTo="default-element">
            <S.UpperWrapper>
                <S.Wrapper>
                    <Header
                        covers={
                            isMobile
                                ? assets?.cover_small || assets?.cover_medium
                                : assets?.cover_large || assets?.cover_medium
                        }
                        endDate={content.end_date}
                        showCountdown={content.countdown}
                    />
                    <Content index={index} content={content} {...props} />
                </S.Wrapper>
            </S.UpperWrapper>
        </SpatialNavSection>
    );
}
