import { useEffect, useRef } from 'react';

export function useAnimationComplete(completeCallback) {
    const ref = useRef();

    useEffect(() => {
        const node = ref.current;

        node.addEventListener('animationend', completeCallback);

        return () => node.removeEventListener('animationend', completeCallback);
    }, [completeCallback]);

    return ref;
}
