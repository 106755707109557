const mod = 0x7fffffff;

// simple seedable pseudo-random number generator
export class PRNG {
    generator = 0;

    seed = (s = 1103527590) => {
        this.generator = s & mod;
    };

    rand = () => {
        let result = this.generator;
        this.generator = (this.generator * 1103515245 + 12345) & mod;
        result /= mod;
        return result;
    };
}
