import { MultiStep, Step } from 'context/multi-step';
import { RowLayout, SupportMessageBox } from 'components/ui';
import {
    StepAskCode,
    StepGetMethod,
    StepResetPassword,
    StepVerifyCode,
} from './steps';
import { If } from 'helpers';
import { useState } from 'react';
import { ConfigFront } from 'services';

export function AccountRecoveryScreen() {
    const supportMethods = ConfigFront.GetSupportMethods();
    const [showSupport, setShowSupport] = useState(false);
    return (
        <RowLayout alignItems="center" justifyContent="center" fullSize>
            <If condition={showSupport}>
                <SupportMessageBox
                    onClose={() => setShowSupport(false)}
                    supportMethods={supportMethods}
                />
            </If>
            <MultiStep defaultStep="step1">
                <Step
                    name="step1"
                    component={
                        <StepGetMethod setShowSupport={setShowSupport} />
                    }
                />
                <Step
                    name="step2"
                    component={<StepAskCode setShowSupport={setShowSupport} />}
                />
                <Step
                    name="step3"
                    component={
                        <StepVerifyCode setShowSupport={setShowSupport} />
                    }
                />
                <Step
                    name="step4"
                    component={
                        <StepResetPassword setShowSupport={setShowSupport} />
                    }
                />
            </MultiStep>
        </RowLayout>
    );
}
