import { If } from 'helpers';
import { useTranslation } from 'react-i18next';
import * as S from './RecoveryStep.style';

export function ErrorWrapper({ error }) {
    const { t } = useTranslation();

    return (
        <If condition={error}>
            <S.ErrorWrapper>{t(error)}</S.ErrorWrapper>
        </If>
    );
}
