import { useTranslation } from 'react-i18next';
import { DangerButton } from '..';

export const LogoutButton = ({ onClick, ...props }) => {
    const { t } = useTranslation();

    return (
        <DangerButton onClick={onClick} {...props}>
            {t('buttons.logout')}
        </DangerButton>
    );
};
