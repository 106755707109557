import styled from 'styled-components';
import { withFocusable } from 'helpers';
import Theme from 'app/theme';

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Hour = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
`;

export const Text = styled.div`
    font-size: 1.5rem;
    margin: 8px;
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export const TimeInput = withFocusable(styled.div`
    width: 75px;
    padding: 8px;
    font-size: 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    transition-property: background-color, color;
    transition-duration: 250ms;
    transition-timing-function: ease-out;
    outline: none;
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
    pointer-events: ${(props) => (props.visible ? 'unset' : 'none')};
    background-color: ${(props) =>
        props.editing ? Theme.COLORS.PRIMARY_L2 : 'unset'};

    &:focus,
    &:hover {
        outline: none;
        background-color: ${Theme.COLORS.PRIMARY_L2};
        color: 'white';
    }
`);
