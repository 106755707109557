import { isMobile, tablet, tv } from 'app/device';
import { Wrapper as ActionButtonsWrapperRoot } from 'components/ui/buttons/action-buttons/ActionButtons.style';
import styled, { css } from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const ActionButtonsWrapperBase = styled(ActionButtonsWrapperRoot)`
    flex-direction: row;
    align-self: flex-start;
    margin-top: 10px;

    & > * {
        margin-right: 8px;
        margin-top: 12px;
        padding: 0.7rem 1rem;
        flex: 1;
    }

    ${tablet(css`
        & > * {
            padding: 0.7rem 0.5rem;
            min-width: 110px;
        }
    `)}

    ${tv(css`
        justify-content: space-between;
    `)}
`;

export const ActionButtonsWrapperMobile = styled(ActionButtonsWrapperRoot)`
    margin-top: 8px;
    height: 100%;
`;

export const ActionButtonsWrapper = isMobile
    ? ActionButtonsWrapperMobile
    : ActionButtonsWrapperBase;
