import { isTV } from 'app/device';
import { ConfirmationBox } from 'components/ui';
import { If } from 'helpers';
import { useBackPress, useQuit } from 'hooks';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const QuitWrapperTV = ({ disabled }) => {
    const { t } = useTranslation();
    const quit = useQuit();
    const lastFocused = useRef();

    const showQuit = useCallback(() => {
        if (disabled) {
            return;
        }
        lastFocused.current = document.activeElement;
        quit.callbacks.show();
    }, [quit.callbacks, disabled]);

    useBackPress(showQuit);

    return (
        <If condition={quit.visible}>
            <ConfirmationBox
                message={t('settings.quit_confirmation')}
                buttons={[
                    {
                        type: 'primary',
                        text: t('buttons.cancel'),
                        callback: () => {
                            quit.callbacks.decline();
                            lastFocused.current.focus();
                        },
                    },
                    {
                        type: 'danger',
                        text: t('buttons.exit'),
                        callback: quit.callbacks.doQuit,
                    },
                ]}
                backPressCallback={() => {
                    quit.callbacks.decline();
                    lastFocused.current.focus();
                }}
            />
        </If>
    );
};

export const QuitWrapper = (props) => (
    <If condition={isTV}>
        <QuitWrapperTV {...props} />
    </If>
);
