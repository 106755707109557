import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showGlobalMessageBox } from 'slices';
import { useTranslation } from 'react-i18next';

export function useShowError() {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const dispatchErrorMessageBox = useCallback(
        (error) => {
            const {
                message: i18nText,
                httpStatusCode,
                gsStatusCode,
                intStatusCode,
                type,
            } = error;

            let messageToDispatch, title, subtitle;
            if (
                (!httpStatusCode || !gsStatusCode) &&
                !intStatusCode &&
                i18nText
            ) {
                messageToDispatch = `${t(i18nText)}`;
            } else if (intStatusCode && i18nText) {
                // internal error status code provided
                subtitle = `${t('error.code')} W-INT-${intStatusCode}`;
            } else if (i18nText && httpStatusCode && gsStatusCode) {
                // gs status code provided
                messageToDispatch = t(i18nText);
                title = i18n.exists(`error.gsStatusCodeTitles.${gsStatusCode}`)
                    ? t(`error.gsStatusCodeTitles.${gsStatusCode}`)
                    : null;
                subtitle = `${t(
                    'error.code'
                )} W-${httpStatusCode}-${gsStatusCode}`;
            } else {
                console.error('dispatchErrorMessageBox : Error leak', error);
                messageToDispatch = t('error.internal');
            }

            dispatch(
                showGlobalMessageBox({
                    type,
                    title,
                    message: messageToDispatch,
                    subtitle,
                })
            );
        },
        [dispatch, i18n, t]
    );

    return { dispatchErrorMessageBox };
}
