import { isIOS } from 'app/device';

// one-level shallow comparison between two objects
export const shallowEqual = (a, b) =>
    Object.entries(a).every(([key, valueA]) => valueA === b[key]);

// recursive override of fields of a with fields of b
export const mergeObjects = (main, override, field) => {
    if (typeof main !== typeof override) {
        throw new Error(
            `Type mismatch between main and override ${
                field ? `for field '${field}'` : ''
            } during merge - ${typeof main} vs ${typeof override}`
        );
    }
    if (typeof main !== 'object') {
        // caution with empty strings
        if (override !== undefined) {
            return override;
        }
        return main;
    }
    const result = {};
    // copy or override fields of main
    Object.getOwnPropertyNames(main).forEach((p) => {
        if (p in override) {
            result[p] = mergeObjects(main[p], override[p], p);
        } else {
            result[p] = main[p];
        }
    });
    // add override fields not in main
    Object.getOwnPropertyNames(override).forEach((p) => {
        if (p in main) {
            return;
        }
        result[p] = override[p];
    });
    return result;
};

// split lines from blocks of text from the backend (T&C, EULA, game descriptions...)
export const splitLines = (t) =>
    t
        ?.split('\r\n')
        .map((e) => e.split('\n'))
        .reduce((acc, v) => [...acc, ...v], []) || [];

// open link to tab or same window depending on device
export const openLink = (link) => {
    // caution, open may fail if the browser prevents opening new tabs
    window.open(link, isIOS ? '_self' : '_blank')?.focus();
};

export const debugLog = (msg) => {
    if (process.env.NODE_ENV === 'development') {
        throw new Error(msg);
    } else {
        console.warn(msg);
    }
};
