import styled, { css } from 'styled-components';
import Theme from 'app/theme';
import { tv } from 'app/device';
import { withFocusable } from 'helpers';

export const Wrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    height: 26px;
    padding-top: 4px;

    ${tv(css`
        padding-top: 20px;
    `)}
`;

export const Item = withFocusable(styled.div`
    position: relative;
    font-size: 1rem;
    z-index: 3;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    outline: none;
    box-sizing: border-box;
    border: 2px solid transparent;
    transition: border-color 200ms ease-out;

    &:focus,
    &:hover {
        border-color: ${Theme.COLORS.PRIMARY_L3};
    }

    width: 26px;
    height: 26px;
    ${tv(css`
        width: 38px;
        height: 38px;
    `)}
`);

export const Text = styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const Cursor = styled.div`
    width: 26px;
    height: 26px;
    position: relative;
    z-index: 2;

    ${tv(css`
        width: 38px;
        height: 38px;
    `)}
    position: absolute;
    background-color: ${Theme.COLORS.PRIMARY_L2};
    border-radius: 50%;
    left: 0;
    opacity: 0;
    transition: all 0.2s ease-out;
`;

export const BackgroundsWrapper = styled(Wrapper)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export const ItemBackground = styled(Item)`
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
`;
