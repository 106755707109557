import * as S from './ScrollView.style';
import { If } from 'helpers';
import { useScrollView } from 'hooks';

// TODO: handle click on Scrollbar background, done: scroll, dragging scrollbar, onResize

export function ScrollView({ children }) {
    const {
        scrollViewRef,
        showCustomScrollBar,
        scrollBarWrapperRef,
        scrollBarThumbRef,
        scrollContentRef,
    } = useScrollView();

    return (
        <S.ScrollViewWrapper
            ref={scrollViewRef}
            showCustomScrollBar={showCustomScrollBar}
        >
            <If condition={showCustomScrollBar}>
                <S.ScrollBarWrapper ref={scrollBarWrapperRef}>
                    <S.ScrollBarThumb ref={scrollBarThumbRef} />
                </S.ScrollBarWrapper>
            </If>
            <S.ScrollContent
                ref={scrollContentRef}
                showNativeScrollBar={!showCustomScrollBar}
            >
                {children}
            </S.ScrollContent>
        </S.ScrollViewWrapper>
    );
}
