import { HoursRange } from 'components/parental-control/hours-range';
import {
    BoxContent,
    BoxText,
    StepWrapper,
    Title,
} from 'components/parental-control/wizard';
import { ActionButtons } from 'components/ui';
import { useMultiStepContext } from 'context/multi-step';
import { useHoursRange } from 'hooks/parental-control';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as PE from 'slices/profile/ProfileEditorSlice';

export function StepRange() {
    const { globalRange } = useSelector(PE.parentalControlWizardSelector);
    const range = useHoursRange(globalRange);
    const { setStep, step } = useMultiStepContext();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [editing, setEditing] = useState(false);

    const onValidate = () => {
        if (editing) {
            return;
        }
        dispatch(
            PE.updateWizardGlobalRange({
                start: range.start,
                end: range.end,
            })
        );
        setStep(step + 1);
    };

    const onBack = () => {
        if (editing) {
            return;
        }
        setStep(step - 1);
    };

    return (
        <StepWrapper>
            <Title />
            <BoxContent>
                <BoxText>{t('profile.parental_control.maximum_range')}</BoxText>
                <HoursRange
                    format="hour"
                    range={range}
                    onEditingChange={setEditing}
                />
            </BoxContent>
            <ActionButtons
                focusOnMount
                disabled={editing}
                buttons={[
                    {
                        type: 'primary',
                        text: t('buttons.validate'),
                        callback: onValidate,
                    },
                    {
                        type: 'neutral',
                        text: t('buttons.back'),
                        callback: onBack,
                    },
                ]}
            />
        </StepWrapper>
    );
}
