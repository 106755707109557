export const IconSettingsHands = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 57.836 62"
            {...props}
        >
            <defs>
                <clipPath id="clip_icon_settings_hand">
                    <path d="M0 0H57.836V62H0z" />
                </clipPath>
            </defs>
            <g clipPath="url(#clip_icon_settings_hand)">
                <path d="M12.571 62.005c-4.22-4.119-8.37-8.167-12.57-12.257.2-.221.335-.391.491-.546q4.234-4.22 8.468-8.435a13.748 13.748 0 017.747-3.873 13.131 13.131 0 016.833.777 8.717 8.717 0 003.3.565c3.471-.033 6.942-.016 10.411-.016a3.862 3.862 0 01.536 7.685 29.238 29.238 0 01-3.5.121c-1.5.019-3-.012-4.5.019a1.274 1.274 0 00-1.342 1.425c.012.837.581 1.344 1.572 1.348q3.264.014 6.529 0a12.1 12.1 0 001.718-.112 6.879 6.879 0 005.673-6.008 1.47 1.47 0 01.307-.77c2.192-2.5 4.364-5.022 6.626-7.459a4.019 4.019 0 016.491.823 4.065 4.065 0 01-.6 4.641c-.921 1.079-1.862 2.137-2.792 3.2q-5.268 6.034-10.534 12.072a9.2 9.2 0 01-7.1 3.276c-6.554.044-13.1.016-19.655.007a1.317 1.317 0 00-1.036.417c-.983 1.014-1.99 2.009-3.074 3.095" />
                <path d="M50.571 13.886c.76.137 1.546.281 2.33.425a2.043 2.043 0 011.837 2.172c.009.635.037 1.272.051 1.906a2.176 2.176 0 01-1.793 2.255c-.418.093-.844.158-1.269.235-.4.07-.8.137-1.165.2-.446 1.093-.874 2.134-1.339 3.269.384.556.816 1.176 1.239 1.8a2.13 2.13 0 01-.291 3.171c-.432.435-.865.877-1.309 1.3a1.983 1.983 0 01-2.641.244c-.7-.46-1.388-.953-2.074-1.428l-3.26 1.325c-.1.532-.216 1.151-.33 1.767-.053.284-.088.57-.16.849a2.064 2.064 0 01-2.02 1.583c-.76.014-1.521.012-2.278 0a1.974 1.974 0 01-1.92-1.532c-.207-.877-.349-1.769-.518-2.653l-3.253-1.358c-.5.346-1.053.73-1.6 1.121-1.481 1.044-2.3.97-3.555-.316-.36-.367-.739-.714-1.086-1.09a2.035 2.035 0 01-.214-2.8c.456-.679.928-1.346 1.376-1.99-.465-1.114-.9-2.169-1.353-3.255a654.41 654.41 0 00-2.339-.428 2.09 2.09 0 01-1.862-2.225c0-.658-.014-1.316 0-1.972a2.093 2.093 0 011.762-2.137c.8-.163 1.611-.288 2.444-.435.435-1.058.872-2.113 1.344-3.26-.388-.567-.821-1.186-1.244-1.809a2.132 2.132 0 01.308-3.218c.407-.407.807-.823 1.225-1.218a2.025 2.025 0 012.741-.26c.7.456 1.388.946 2.048 1.4l3.26-1.335c.121-.674.258-1.414.4-2.155A2.118 2.118 0 0136.509.002c.639 0 1.274-.009 1.911 0a2.018 2.018 0 011.984 1.62c.186.86.328 1.73.481 2.562 1.09.451 2.127.881 3.25 1.348.563-.388 1.181-.821 1.8-1.249a2.108 2.108 0 013.169.3c.418.423.849.835 1.26 1.265a2.045 2.045 0 01.251 2.746c-.465.7-.956 1.383-1.4 2.03.456 1.1.886 2.144 1.351 3.267m-19.165 3.562a5.783 5.783 0 005.738 5.931 5.906 5.906 0 10.237-11.809 5.788 5.788 0 00-5.975 5.878" />
            </g>
        </svg>
    );
};
