import { useMultiStepContext } from 'context/multi-step';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as PE from 'slices/profile/ProfileEditorSlice';

export function useWizardDailyUsage() {
    const { enabled } = useSelector(PE.parentalControlWizardSelector);
    const [checked, setChecked] = useState(enabled);
    const { setStep, maxSteps } = useMultiStepContext();
    const dispatch = useDispatch();

    const onValidate = useCallback(() => {
        dispatch(PE.updateWizardParentalControlEnabled(checked));

        if (checked) {
            setStep((prevStep) => prevStep + 1);
        } else {
            setStep(maxSteps);
        }
    }, [checked, dispatch, maxSteps, setStep]);

    const onBack = () => {
        dispatch(PE.endParentalControlEdition());
    };
    return { onValidate, onBack, checked, setChecked };
}
