import styled from 'styled-components';
import { isTV } from 'app/device';
import Theme from 'app/theme';
import { withFocusable } from 'helpers';

const handleSizeTV = (size) => {
    switch (size) {
        case 'small':
            return '35px';
        default:
            return '55px';
    }
};

const handleSize = (size) => {
    switch (size) {
        case 'large':
            return '50px';
        default:
            return '32px';
    }
};

const LangIconBase = styled.img`
    width: ${(props) => handleSize(props.size)};
    height: ${(props) => handleSize(props.size)};
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    flex-shrink: 0;
    box-sizing: border-box;

    &:focus {
        border: 2px solid ${Theme.COLORS.PRIMARY_L1_FOCUS};
    }
`;

const LangIconTV = styled(LangIconBase)`
    width: ${(props) => handleSizeTV(props.size)};
    height: ${(props) => handleSizeTV(props.size)};

    &:focus {
        border-width: 4px;
    }
`;

export const LangIcon = isTV ? LangIconTV : LangIconBase;

export const LangIconFocusable = withFocusable(LangIcon);
