import { tv, tablet, mobileSmall } from 'app/device';
import Theme from 'app/theme';
import { withFocusable } from 'helpers';
import styled, { css } from 'styled-components';

const handleSize = (type, size) => {
    switch (type) {
        case 'tv':
            switch (size) {
                case 'small':
                    return '55px';
                default:
                    return '114px';
            }
        case 'tablet':
            switch (size) {
                case 'small':
                    return '40px';
                default:
                    return '62px';
            }
        case 'mobile':
            switch (size) {
                case 'small':
                    return '35px';
                default:
                    return '62px';
            }
        default:
            switch (size) {
                case 'small':
                    return '40px';
                default:
                    return '62px';
            }
    }
};

export const NavButtonBase = styled.div`
    width: ${(props) => handleSize('', props.size)};
    height: ${(props) => handleSize('', props.size)};
    opacity: ${(props) => (props.opacity !== undefined ? props.opacity : 1)};
    cursor: pointer;
    outline: none;
    pointer-events: ${(props) => (props.isDisabled ? 'none' : 'inherit')};
    transition: background-color 250ms ease-out, opacity 250ms ease-out;

    ${tv(css`
        width: ${(props) => handleSize('tv', props.size)};
        height: ${(props) => handleSize('tv', props.size)};
    `)};

    ${tablet(css`
        width: ${(props) => handleSize('tablet', props.size)};
        height: ${(props) => handleSize('tablet', props.size)};
    `)}

    ${mobileSmall(css`
        width: ${(props) => handleSize('mobile', props.size)};
        height: ${(props) => handleSize('mobile', props.size)};
    `)};

    svg {
        fill: ${(props) => {
            if (props.fill) {
                return props.fill;
            } else if (props.isDisabled) {
                return Theme.COLORS.GRAY_L4;
            } else {
                return Theme.COLORS.WHITE;
            }
        }};
    }

    &:focus,
    &:hover {
        opacity: 1;

        svg {
            fill: ${Theme.COLORS.PRIMARY_L2} !important;
        }

        fill: ${Theme.COLORS.PRIMARY_L2} !important;
    }

    &:active {
        opacity: 1;

        svg {
            fill: ${Theme.COLORS.PRIMARY_L3} !important;
        }
    }
`;

export const NavButton = withFocusable(NavButtonBase);
