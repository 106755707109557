import { isAndroid, isMobile } from 'app/device';
import { maxCols } from 'helpers';
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Games, Search } from 'services';
import {
    favoritesSelector,
    globalNavigationSelector,
    hideGlobalNavigation,
    showGlobalNavigation,
} from 'slices';

export function useSearch() {
    const { favorites } = useSelector(favoritesSelector);
    const [currentCue, setCurrentCue] = useState('');
    const [isSearchFocused, _setIsSearchFocused] = useState(false);
    const [isMostSearchedVisible, _setIsMostSearchedVisible] = useState(true);
    const isSearchFocusedRef = useRef(isSearchFocused);
    const isMostSearchedVisibleRef = useRef(isMostSearchedVisible);
    const searchInputRef = useRef();
    const cues = Search.GetMostResearchedCues(favorites);
    const mostSearchedGames = useRef(Search.GetMostResearchedGames()).current;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const windowDimensions = useRef({});
    const globalNav = useSelector(globalNavigationSelector);
    const allGames = Games.GetGames(); // order may change when language is changed

    useEffect(() => {
        windowDimensions.current = {
            w: window.innerWidth,
            h: window.innerHeight,
        };
    }, []);

    const setIsSearchFocused = (data) => {
        isSearchFocusedRef.current = data;
        _setIsSearchFocused(data);
    };

    const setIsMostSearchedVisible = (data) => {
        isMostSearchedVisibleRef.current = data;
        _setIsMostSearchedVisible(data);
    };

    const onFocusSearchBox = () => {
        setIsSearchFocused(true);
        setIsMostSearchedVisible(false);
        if (isAndroid && globalNav.visible) dispatch(hideGlobalNavigation());
    };

    const onBlurSearchBox = useCallback(() => {
        setIsSearchFocused(false);
        setIsMostSearchedVisible(true);
        searchInputRef.current.blur();

        //check if nav visible because shouldUnfocus can be triggered multiple times
        if (isAndroid && !globalNav.visible) dispatch(showGlobalNavigation());
    }, [dispatch, globalNav.visible]);

    useLayoutEffect(() => {
        const shouldUnfocus = (e) => {
            /*
                backPressBtn pressed, show menu and most searched games
                note : backPressBtn is hiding virtual keyboard and provokes resize /!\
             */
            if (
                isSearchFocusedRef.current &&
                !isMostSearchedVisibleRef.current &&
                window.innerHeight >= windowDimensions.current.h
            )
                onBlurSearchBox();
        };

        if (isAndroid && isMobile) {
            window.addEventListener('resize', shouldUnfocus);
            return () => window.removeEventListener('resize', shouldUnfocus);
        }
    }, [onBlurSearchBox]);

    // recompute results when cue or games change
    const [games, columns] = useMemo(() => {
        // clear input and return empty if cue equal to nothing
        const cue = currentCue.trim();
        if (!cue) {
            if (searchInputRef.current) {
                searchInputRef.current.value = '';
            }
            return [[], []];
        }

        // Do search
        const games = Search.Find(allGames, currentCue, t);

        // Init columns
        const columns = [...Array(maxCols)].map(() => []);

        // Fill columns
        games.forEach((game, index) => columns[index % maxCols].push(game));

        return [games, columns];
    }, [allGames, currentCue, t]);

    return {
        games,
        columns,
        cues,
        setCurrentCue,
        currentCue,
        mostSearchedGames,
        isSearchFocused,
        onFocusSearchBox,
        onBlurSearchBox,
        isMostSearchedVisible,
        searchInputRef,
    };
}
