import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { resetSteps } from 'slices';

const Context = React.createContext(null);

export const useMultiStepContext = () => useContext(Context);

export function MultiStep({ children, defaultStep }) {
    const [step, setStep] = useState(defaultStep);
    const maxSteps = children.length;

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const cancelStep = () => {
        dispatch(resetSteps({ cancelled: true }));
        // go back to previous screen, or root if none specified
        // see ForgotPassword
        history.push(location.state?.from || '/');
    };
    return (
        <Context.Provider value={{ step, setStep, maxSteps, cancelStep }}>
            {children}
        </Context.Provider>
    );
}
