import { enableMacOS } from 'app/config';
import {
    hasRequiredIOSVersion,
    isIOS,
    isMacOs,
    isMobileOrTablet,
    isSafari,
    isStandalone,
    isTV,
} from 'app/device';
import { IconRotate } from 'components/ui';
import { useInputDispatcherContext } from 'context';
import { If } from 'helpers';
import { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { gameSessionSelector } from 'slices';
import * as S from './GlobalPwaInstructions.style';
import { DownloadIos } from './ios/DownloadIos';

export function GlobalPwaInstructions({ debug }) {
    const { t } = useTranslation();
    const { resumeInputDispatch, stopInputDispatch } =
        useInputDispatcherContext();
    const mounted = useRef(false);
    const { SGXConfig } = useSelector(gameSessionSelector);
    const portraitSession =
        SGXConfig?.options['screen-orientation'] === 'portrait';

    /*
     * Android related - might be useful later
     */
    //const {prompt, promptToInstall}                           = useAddToHomeScreenPrompt();
    //const [showAndroidDownloadBtn, setShowAndroidDownloadBtn] = useState(false);
    //useEffect(() => {
    //    if (prompt)
    //        setShowAndroidDownloadBtn(true);
    //}, [prompt]);

    // disable scroll and inputs when in portrait mode
    useLayoutEffect(() => {
        const handleResize = () => {
            const bodyStyle = document.body.style;
            if (
                isMobileOrTablet &&
                document.body.clientWidth < document.body.clientHeight
            ) {
                // Portrait
                stopInputDispatch();
                bodyStyle.overflow = 'hidden';
                if (!portraitSession) {
                    bodyStyle.pointerEvents = 'none';
                }
            } else {
                // Landscape
                resumeInputDispatch();
                bodyStyle.overflow = '';
                bodyStyle.pointerEvents = 'auto';
            }
        };
        // call once on first mount to ensure inputs are paused if the orientation is wrong
        if (!mounted.current) {
            handleResize();
            mounted.current = true;
        }
        // call on resize
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [stopInputDispatch, portraitSession, resumeInputDispatch]);

    // backdoor for testing on any device in production: access main URL with ?override=true
    const search = new URLSearchParams(window.location?.search);
    const override = search.get('override') === 'true';

    // /!\ iPads are recognized as MacOs and should not be allowed free access
    const allowedDesktopBrowser = enableMacOS && isMacOs && !isMobileOrTablet;
    let globalPWA;

    if (!isStandalone() && !allowedDesktopBrowser && !override) {
        // plain browser - desktop or mobile
        // show PWA installation instructions for mobile or no-access on desktop
        globalPWA = (
            <If condition={!debug}>
                <S.InstructionsWrapper>
                    <S.Box>
                        <S.LogoWrapper>
                            <S.FakeIcon />
                            <S.FakeIcon />
                            <S.LogoIcon />
                            <S.FakeIcon />
                            <S.FakeIcon />
                        </S.LogoWrapper>
                        <S.TextWrapper>
                            <S.InstallTitle>
                                {t('pwa.install', {
                                    appName: process.env.REACT_APP_TITLE,
                                })}
                            </S.InstallTitle>
                            <S.InstallText>
                                {/*Might be useful later*/}
                                {/*<If condition={isAndroid}>*/}
                                {/*    {t('pwa.install_description')}*/}
                                {/*</If>*/}
                                <If condition={isIOS}>
                                    <If condition={!hasRequiredIOSVersion}>
                                        {t('pwa.ios_version')}
                                    </If>
                                    <If condition={hasRequiredIOSVersion}>
                                        <If condition={isSafari}>
                                            {t('pwa.install_description')}
                                        </If>
                                        <If condition={!isSafari}>
                                            {t('pwa.install_safari')}
                                        </If>
                                    </If>
                                </If>
                                <If condition={!isIOS}>
                                    {t('pwa.install_unavailable')}
                                </If>
                            </S.InstallText>
                        </S.TextWrapper>
                        {/*Show install instruction for Android or iOS - commented for now, might be useful later*/}
                        {/*<If condition={isAndroid && showAndroidDownloadBtn}>*/}
                        {/*    <DownloadAndroid onClick={promptToInstall}/>*/}
                        {/*</If>*/}

                        {/*Show instruction on iOS*/}
                        <If
                            condition={
                                isIOS && hasRequiredIOSVersion && isSafari
                            }
                        >
                            <DownloadIos />
                        </If>
                    </S.Box>
                </S.InstructionsWrapper>
            </If>
        );
    } else {
        if (isMobileOrTablet && !portraitSession) {
            globalPWA = (
                <S.InstructionsWrapperLandscape>
                    <S.RotateYourPhone>
                        <S.RotateText>{t('pwa.rotate')}</S.RotateText>
                        <S.SvgWrapper>
                            <IconRotate />
                        </S.SvgWrapper>
                    </S.RotateYourPhone>
                </S.InstructionsWrapperLandscape>
            );
        } else {
            // desktop case
            globalPWA = null;
        }
    }

    return (
        <If condition={!isTV && process.env.NODE_ENV !== 'development'}>
            {globalPWA}
        </If>
    );
}
