import { enableMarketingNews } from 'app/config';
import {
    ActionButtonsWrapper,
    Checkbox,
    ColumnLayout,
    IconSettings,
    MessageBoxTitle,
    MessageBoxWrapper,
    NeutralButton,
    PrimaryButton,
} from 'components/ui';
import { useHelpersContext } from 'context';
import { If } from 'helpers';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Games } from 'services';
import {
    qaSettingsSelector,
    setResolution,
    setShowMarketingEditorials,
    setShowScores,
    videoSettingsSelector,
} from 'slices';
import * as S from '../audio-video-settings/AudioVideoSettings.style';
import * as Q from '../qa-settings/QASettings.style';

export const QASettings = ({ onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { resolution } = useSelector(videoSettingsSelector);
    const { showScores, showMarketingEditorials } =
        useSelector(qaSettingsSelector);
    const helpers = useHelpersContext();

    const box = (
        <MessageBoxWrapper>
            <MessageBoxTitle title="QA Settings" Icon={IconSettings} />
            <S.ContentWrapper>
                <ColumnLayout>
                    <Q.SettingsRow>
                        <span>Helpers</span>
                        <NeutralButton onClick={helpers.clearSettings}>
                            Reset
                        </NeutralButton>
                    </Q.SettingsRow>
                    <Q.SettingsRow>
                        <span>Expiration warnings</span>
                        <NeutralButton
                            onClick={() => {
                                localStorage.removeItem('expiration_warnings');
                            }}
                        >
                            Clear
                        </NeutralButton>
                    </Q.SettingsRow>
                    <Q.SettingsRow>
                        <span>First connection warning</span>
                        <NeutralButton
                            onClick={() => {
                                localStorage.removeItem('firstConnection');
                            }}
                        >
                            Clear
                        </NeutralButton>
                    </Q.SettingsRow>
                    <If condition={enableMarketingNews}>
                        <Q.SettingsRow>
                            <span>Show marketing editorials</span>
                            <S.CheckboxWrapper>
                                <Checkbox
                                    defaultChecked={showMarketingEditorials}
                                    onChange={(checked) => {
                                        dispatch(
                                            setShowMarketingEditorials(checked)
                                        );
                                    }}
                                />
                                <S.Label>
                                    {t(
                                        showMarketingEditorials
                                            ? 'buttons.on'
                                            : 'buttons.off'
                                    )}
                                </S.Label>
                            </S.CheckboxWrapper>
                        </Q.SettingsRow>
                    </If>
                    <Q.SettingsRow>
                        <span>Show game suggestion scores</span>
                        <S.CheckboxWrapper>
                            <Checkbox
                                defaultChecked={showScores}
                                onChange={(checked) => {
                                    dispatch(setShowScores(checked));
                                }}
                            />
                            <S.Label>
                                {t(showScores ? 'buttons.on' : 'buttons.off')}
                            </S.Label>
                        </S.CheckboxWrapper>
                    </Q.SettingsRow>
                    <Q.SettingsRow>
                        <span>Random scores</span>
                        <NeutralButton
                            onClick={() => {
                                Games.updateGameRands(true);
                                Games.SortGames();
                            }}
                        >
                            Update
                        </NeutralButton>
                    </Q.SettingsRow>
                    <Q.SettingsRow>
                        <span>1080p</span>
                        <S.CheckboxWrapper>
                            <Checkbox
                                defaultChecked={resolution === '1080p'}
                                onChange={(checked) => {
                                    dispatch(
                                        setResolution(
                                            checked ? '1080p' : '720p'
                                        )
                                    );
                                }}
                            />
                            <S.Label>
                                {t(
                                    resolution === '1080p'
                                        ? 'buttons.on'
                                        : 'buttons.off'
                                )}
                            </S.Label>
                        </S.CheckboxWrapper>
                    </Q.SettingsRow>
                </ColumnLayout>
            </S.ContentWrapper>
            <ActionButtonsWrapper>
                <PrimaryButton onClick={onClose}>
                    {t('buttons.close')}
                </PrimaryButton>
            </ActionButtonsWrapper>
        </MessageBoxWrapper>
    );

    return ReactDOM.createPortal(box, document.body);
};
