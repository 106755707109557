import React, { useCallback, useEffect, useState } from 'react';
import { useAppSound } from 'hooks';
import * as S from './Checkbox.style';
import { useRef } from 'react';

export const Checkbox = React.forwardRef(
    ({ onChange, defaultChecked, onClick, name }, forwardedRef) => {
        const [checked, setChecked] = useState(defaultChecked || false);
        const [playOn] = useAppSound('switchOn');
        const [playOff] = useAppSound('switchOff');
        const checkboxRef = useRef();

        const onWrapperClicked = useCallback(() => {
            onClick?.();
            setChecked((prev) => !prev);
            // if currently checked we are unchecking -> play "off"
            if (checked) {
                playOff();
            } else {
                playOn();
            }
        }, [checked, onClick, playOff, playOn]);

        useEffect(() => {
            checkboxRef.current.checked = checked;
            onChange?.(checked);
        }, [checked, onChange]);

        return (
            <S.Wrapper onClick={onWrapperClicked} ref={forwardedRef}>
                <S.Checkbox
                    ref={checkboxRef}
                    name={name}
                    defaultChecked={checked}
                />
                <S.Switch />
            </S.Wrapper>
        );
    }
);
