import React, { forwardRef } from 'react';
import * as S from './IconButton.style';

export const IconButton = forwardRef(
    ({ children, icon, button, ...props }, ref) => {
        const Icon = icon;
        const Button = button;
        return (
            <Button {...props} ref={ref}>
                <S.Wrapper>
                    <S.Icon>
                        <Icon />
                    </S.Icon>
                    <S.Text>{children}</S.Text>
                </S.Wrapper>
            </Button>
        );
    }
);
