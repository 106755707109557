import { isMobile } from 'app/device';
import { EulaButton, GameCopyright } from 'components/games';
import { ActionButtons } from 'components/ui';
import { useMultiViewContext } from 'context';
import { If } from 'helpers';
import { useBackPress } from 'hooks';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { descriptionActionBtn } from '../description/DescriptionView';
import { ViewContainer } from '../ViewContainer';
import * as S from './MoreView.style';

export function MoreView({
    game,
    index,
    setCurrentGameWindowView,
    selectedIndex,
    setLastFocusedEl,
}) {
    const { view, setView } = useMultiViewContext();
    const { t } = useTranslation();

    // set view info in swiper for the current window only
    useEffect(() => {
        if (index === selectedIndex) {
            setCurrentGameWindowView(view);
        }
    }, [index, selectedIndex, setCurrentGameWindowView, view]);

    const close = useCallback(() => {
        setView('description');
        setLastFocusedEl({ el: descriptionActionBtn.MORE });
    }, [setView, setLastFocusedEl]);

    useBackPress(close);

    const actionButtons = (
        <ActionButtons
            vertical={isMobile}
            Wrapper={S.ActionButtonsWrapper}
            buttons={[
                {
                    type: 'primary',
                    text: t('buttons.ok'),
                    callback: close,
                    props: {
                        focusOnMount: true,
                        overrideMoveRight: () => game.has_eula,
                    },
                },
                {
                    component: EulaButton,
                    props: {
                        game,
                    },
                },
            ]}
        />
    );

    return (
        <ViewContainer game={game} actionButtons={actionButtons}>
            <S.Content>
                <GameCopyright game={game} />
                <If condition={!isMobile}>{actionButtons}</If>
            </S.Content>
        </ViewContainer>
    );
}
