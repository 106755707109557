import { hasTouch, isTV } from 'app/device';
import { ConfirmationBox, IconGamepad, IconWarning } from 'components/ui';
import { getNumberOfGamepads } from 'context';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

/*
 * If gamepad is mandatory, we should display only the QrCodeBox with only one button (close btn)
 * Otherwise, we are display a ConfirmationBox with cancel button or play button
 *
 *  QrCodeBox.js accepted params : title, message, accept, onClose, blurOverlap, url
 * ConfirmationBox.js for gamepad params : blurOverlap, children, message, message2, onAccept, onDecline, accept, decline
 */

export const useGamepadCheck = (game, isMulti = false) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const protectedCallback = useRef();

    // const gamepadBuyURL = 'https://google.com'; // test only
    // const gamepadBuyURL = ConfigFront.gamepadBuyURL();

    // gamepad is mandatory on TV, as the remote cannot reliably act as a generic keyboard
    // games without mouse/keyboard support do require a gamepad but not necessarily a physical one
    const gamepadIsMandatory =
        game.is_gamepad === 'mandatory' ||
        isTV ||
        (game.is_mouse_and_keyboard !== 'support' && !hasTouch());

    const protect = (callback) => {
        if (
            getNumberOfGamepads() <= 0 && // No gamepads detected // And
            (gamepadIsMandatory || // Gamepad is mandatory to play // Or
                ((game.is_virtual_gamepad === 'support' ||
                    game.is_touch_friendly ||
                    game.is_touch_screen === 'support') &&
                    hasTouch())) // Virtual gamepad is supported and the screen has touch capability
        ) {
            protectedCallback.current = callback;
            return () => setVisible(true);
        } else {
            return callback;
        }
    };

    const accepted = () => {
        if (gamepadIsMandatory && getNumberOfGamepads() <= 0) return;
        protectedCallback.current();
        setVisible(false);
    };

    const close = () => {
        setVisible(false);
    };

    const GamepadMessageBox = () => {
        if (isMulti) {
            if (process.env.NODE_ENV === 'development')
                console.log('MULTIPLAYER GAMEPAD MANDATORY');
            return (
                <ConfirmationBox
                    blurOverlap={true}
                    IconComponent={IconGamepad}
                    title={t('warnings.gamepad.no_gamepad_title')}
                    message={t('warnings.gamepad.multiplayer')}
                    buttons={[
                        {
                            type: 'primary',
                            text: t('buttons.close'),
                            callback: close,
                        },
                    ]}
                />
            );
        }
        // physical gamepad required, or virtual possible but no touch
        if (gamepadIsMandatory) {
            if (process.env.NODE_ENV === 'development')
                console.log('GAMEPAD MANDATORY');
            return (
                <ConfirmationBox
                    blurOverlap={true}
                    IconComponent={IconGamepad}
                    title={t('warnings.gamepad.no_gamepad_title')}
                    message={t('warnings.gamepad.no_gamepad')}
                    buttons={[
                        {
                            type: 'primary',
                            text: t('buttons.close'),
                            callback: close,
                        },
                    ]}
                />
            );
        }
        // no physical gamepad found but virtual gamepad will be used instead
        else if (game.is_touch_friendly) {
            if (process.env.NODE_ENV === 'development')
                console.log('GAMEPAD SUPPORT');
            return (
                <ConfirmationBox
                    blurOverlap={true}
                    IconComponent={IconWarning}
                    title={t('warnings.gamepad.virtual_touch_title')}
                    message={t('warnings.gamepad.virtual_touch')}
                    buttons={[
                        {
                            type: 'danger',
                            text: t('buttons.continue'),
                            callback: accepted,
                        },
                        {
                            type: 'danger',
                            text: t('buttons.close'),
                            callback: close,
                        },
                    ]}
                />
            );
        } else if (game.is_touch_screen === 'support') {
            return (
                <ConfirmationBox
                    blurOverlap={true}
                    IconComponent={IconGamepad}
                    title={t('warnings.gamepad.virtual_touch_support_title')}
                    message={t('warnings.gamepad.virtual_touch_support')}
                    buttons={[
                        {
                            type: 'danger',
                            text: t('buttons.continue'),
                            callback: accepted,
                        },
                        {
                            type: 'danger',
                            text: t('buttons.close'),
                            callback: close,
                        },
                    ]}
                />
            );
        }
    };

    return {
        protect,
        visible,
        accepted,
        close,
        GamepadMessageBox,
    };
};
