import { Checkbox } from 'components/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './RememberMe.style';

export const RememberMe = React.forwardRef((props, forwardedRef) => {
    const { t } = useTranslation();

    return (
        <S.Wrapper>
            <Checkbox {...props} defaultChecked={true} ref={forwardedRef} />
            <S.Text onClick={(e) => e.target.previousSibling.click()}>
                {t('login.remember_me')}
            </S.Text>
        </S.Wrapper>
    );
});
