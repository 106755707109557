import { resetPasswordOnFirstConnection } from 'app/config';
import { encrypt } from 'helpers';
import { useProfileLoader } from 'hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Authentication, LocalStore } from 'services';
import { profileSelector } from 'slices';

export function useProfileSelector() {
    const { profiles } = useSelector(profileSelector);
    const username = Authentication.GetUsername();
    const { onProfileSelected } = useProfileLoader();
    const bypassWarnings =
        JSON.parse(LocalStore.Get('bypass_first_connection_warning')) || {};
    const [resetPasswordVisible, setResetPasswordVisible] = useState(
        resetPasswordOnFirstConnection &&
            !bypassWarnings[encrypt(username, 'username')]
    );

    return {
        onProfileSelected,
        profiles,
        setResetPasswordVisible,
        resetPasswordVisible,
        username,
    };
}
