import { useCallback, useEffect, useState } from 'react';

// useHoursRange provides a start-end range and four functions to
// edit the start and end values.
// The editing functions enforce the constraint min <= start < end <= max.
// The range values may also (and simultaneously) be controlled by an external range.
export function useHoursRange(
    initialRange,
    maxRange = { start: 0, end: 24 },
    resetRange
) {
    // default resetRange to maxRange if unspecified
    resetRange = resetRange || maxRange;
    const { start: initialStart, end: initialEnd } = initialRange;
    const { start: min, end: max } = maxRange;

    const [start, setStart] = useState(initialStart);
    const [end, setEnd] = useState(initialEnd);

    // update range from external source
    useEffect(() => {
        setStart(initialStart);
        setEnd(initialEnd);
    }, [initialEnd, initialStart]);

    const incrementEnd = useCallback(() => {
        setEnd((prevEnd) => Math.min(max, prevEnd + 1));
    }, [max]);

    const decrementEnd = useCallback(() => {
        setEnd((prevEnd) => Math.max(start + 1, prevEnd - 1));
    }, [start]);

    const incrementStart = useCallback(() => {
        setStart((prevStart) => Math.min(prevStart + 1, end - 1));
    }, [end]);

    const decrementStart = useCallback(() => {
        setStart((prevStart) => Math.max(min, prevStart - 1));
    }, [min]);

    const reset = useCallback(() => {
        setStart(resetRange.start);
        setEnd(resetRange.end);
    }, [resetRange]);

    return {
        start,
        incrementStart,
        decrementStart,
        end,
        incrementEnd,
        decrementEnd,
        reset,
    };
}
