import styled from 'styled-components';
import Theme from 'app/theme';

export const BlurBoxBase = styled.div`
    backdrop-filter: ${(props) =>
        `blur(${Theme.BLUR_AMOUNT}px)
        brightness(${props.blurOverlap ? Theme.BLUR_CONTRAST : Theme.BLUR_BRIGHTNESS}%)
        contrast(${Theme.BLUR_CONTRAST}%)
        saturate(${Theme.BLUR_SATURATE}%)
    `};
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 20px;
`;
