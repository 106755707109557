import Theme from 'app/theme';

export function IconSearch(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="578.19"
            height="578.19"
            data-name="Layer 1"
            viewBox="0 0 578.19 578.19"
            {...props}
        >
            <g>
                <path
                    d="M573.8 369.15c67.66-112.4 31.4-258.37-81-326s-258.36-31.39-326 81-31.4 258.36 81 326a237.55 237.55 0 00245 0l137.09 137.09 81-81zm-106-25a137.73 137.73 0 110-194.78 137.69 137.69 0 010 194.74z"
                    data-name="Path 127"
                    transform="translate(-132.71 -9.06)"
                    fill={Theme.COLORS.WHITE}
                ></path>
            </g>
        </svg>
    );
}
