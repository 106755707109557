export function IconMultiplayer2(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 26 21"
            {...props}
        >
            <path
                fill="#FDFDFD"
                d="M20.14 18.018c0-2.482-1.683-4.59-3.977-5.149-.918.558-1.958.9-3.09.9a5.82 5.82 0 01-3.09-.9 5.304 5.304 0 00-3.977 5.149v2.884H20.14v-2.884zM13.077 3.098c-2.478 0-4.467 2.047-4.467 4.529 0 2.512 2.02 4.528 4.467 4.528 2.478 0 4.467-2.047 4.467-4.528 0-2.482-1.989-4.529-4.467-4.529z"
            ></path>
            <path
                fill="#FDFDFD"
                d="M21.514 9.85c-.917.558-1.958.9-3.09.9a7.93 7.93 0 01-.856-.062c-.214.403-.49.775-.796 1.117 2.356 1.023 4.039 3.38 4.13 6.11h4.59v-2.884c0-2.513-1.683-4.591-3.978-5.18zM18.297 7.772c0 .497-.06.962-.183 1.427h.275c2.478 0 4.467-2.047 4.467-4.528 0-2.482-1.958-4.56-4.436-4.56-1.56 0-2.937.807-3.733 2.047a6.168 6.168 0 013.61 5.614zM4.199 10.065c.918.558 1.958.9 3.09.9.275 0 .58-.031.856-.062.214.403.49.775.795 1.116-2.355 1.024-4.038 3.38-4.13 6.11H.223v-2.884c0-2.512 1.683-4.59 3.977-5.18zM7.259 7.773c0 .497.061.962.184 1.427h-.276c-2.478 0-4.466-2.047-4.466-4.529C2.7 2.19 4.658.111 7.137.111c1.56 0 2.937.807 3.732 2.048a6.17 6.17 0 00-3.61 5.614z"
            ></path>
        </svg>
    );
}
