import { isMobile, supportBackDropFilter } from 'app/device';
import { Helper } from 'components/ui';
import { useSpatialNavContext } from 'context';
import { useHomePress } from 'hooks';
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { profileSelector } from 'slices';
import * as S from './Menu.style';

export function Menu({
    isMain,
    items,
    navigation,
    namespace,
    currentTab,
    onItemSelected,
    overrideMoveUp,
    preventMoveRight,
    currentAsideTab,
}) {
    const wrapperRef = useRef();
    const lastSelectedIndex = useRef(0);
    const selectedIndex = useRef(0);
    const { t } = useTranslation();
    const cursorRef = useRef();
    const { getPreviousFocusEl, refreshTree } = useSpatialNavContext();
    const fromTouchOrClick = useRef(false);
    const itemsStartIndex = supportBackDropFilter ? 1 : 2; // BlurMenuFallback adds 1 element before Cursor
    const { currentProfile } = useSelector(profileSelector);
    const [prevTab, setPrevTab] = useState(null);

    useHomePress(
        useCallback(() => {
            //clear submenu background
            if (namespace !== 'navigation' && currentProfile) {
                let itemsToClean = wrapperRef.current.children;
                for (let item of itemsToClean) {
                    item.style.backgroundColor = '';
                    item.style.boxShadow = '';
                    item.style.fontWeight = '';
                }
            }
        }, [currentProfile, namespace])
    );

    const hideCursor = useCallback(() => {
        cursorRef.current.style.opacity = 0;
    }, [cursorRef]);

    // hide cursor when element from asideNavigation selected
    useEffect(() => {
        if (currentAsideTab.name !== '') hideCursor();
    }, [currentAsideTab, hideCursor]);

    const semiHighlightItem = useCallback(
        (index, shouldReset = false) => {
            const target = wrapperRef.current.children.item(index);
            if (!target) {
                return;
            }
            cursorRef.current.style.backgroundColor =
                S.CurrentItemBackgroundColor;
            cursorRef.current.style.boxShadow = '';
            target.style.fontWeight = '';

            if (!cursorRef.current.style.width) {
                let pos = target.getBoundingClientRect();
                cursorRef.current.style.width = `${pos.width}px`;
                cursorRef.current.style.height = `${pos.height}px`;
            }
        },
        [cursorRef]
    );

    const selectTarget = () => {
        const target = wrapperRef.current.children.item(
            selectedIndex.current + itemsStartIndex
        );

        if (target === document.activeElement) {
            const pos = target.getBoundingClientRect();
            target.style.fontWeight = 'bold';

            cursorRef.current.style.backgroundColor =
                S.CursorItemBackgroundColor;
            cursorRef.current.style.boxShadow = S.CursorItemBoxShadow;
            cursorRef.current.style.opacity = 1;
            cursorRef.current.style.width = `${pos.width}px`;
            cursorRef.current.style.height = `${pos.height}px`;
        }

        // flash item menu if focus wasn't on the nav section && prevent this effect if touch or click event
        if (
            !wrapperRef.current.contains(getPreviousFocusEl()) &&
            !fromTouchOrClick.current
        ) {
            target.classList.add('flashing');

            target.onanimationend = () => {
                target.classList.remove('flashing');
            };
        }

        fromTouchOrClick.current = false;
    };

    const moveCursor = useCallback(
        (position) => {
            const prevTarget = wrapperRef.current.children.item(
                lastSelectedIndex.current + itemsStartIndex
            );
            if (prevTarget) {
                prevTarget.style.fontWeight = '';
            }

            // Move cursor to target position
            const target = wrapperRef.current.children.item(
                position + itemsStartIndex
            );
            const pos = target.getBoundingClientRect();
            cursorRef.current.style.opacity = 1;
            cursorRef.current.style.width = `${pos.width}px`;
            cursorRef.current.style.height = `${pos.height}px`;
            cursorRef.current.style.transform = `translateX(${target.offsetLeft}px)`;
        },
        [itemsStartIndex]
    );

    const onItemClicked = (index, e) => {
        // prevent switching tabs when helpers attached to menu item
        if (e.target !== e.currentTarget) return;

        let move = index !== selectedIndex.current;

        // Save selected index
        selectedIndex.current = index;

        // Move cursor
        if (move) moveCursor(index);
        selectTarget(index);

        // Save last selected index
        lastSelectedIndex.current = selectedIndex.current;

        // Trigger callback
        onItemSelected(items[index]);
    };

    const overrideMoveDown = () => {
        semiHighlightItem(selectedIndex.current + itemsStartIndex);
        return true;
    };

    const handleOverrideMoveUp = () => {
        if (overrideMoveUp) {
            semiHighlightItem(selectedIndex.current + itemsStartIndex);
            overrideMoveUp();
        }
    };

    const handleClickOrTouch = (e) => {
        // prevent switching tabs when helpers attached to menu item
        if (e.target !== e.currentTarget) return;
        fromTouchOrClick.current = true;
        e.target.focus();
    };

    const resizeCursor = useCallback(() => {
        if (!wrapperRef.current) return;

        // Move cursor to target position
        const target = wrapperRef.current.children.item(
            lastSelectedIndex.current + itemsStartIndex
        );
        const pos = target.getBoundingClientRect();
        cursorRef.current.style.opacity = 1;
        cursorRef.current.style.width = `${pos.width}px`;
        cursorRef.current.style.height = `${pos.height}px`;
        cursorRef.current.style.transform = `translateX(${target.offsetLeft}px)`;
    }, [itemsStartIndex]);

    useEffect(() => {
        let timeoutFunc;
        const handleResize = () => {
            if (!cursorRef.current) return;

            cursorRef.current.style.opacity = 0;
            clearTimeout(timeoutFunc);

            // setTimeout returns the numeric ID which is used by
            // clearTimeOut to reset the timer
            timeoutFunc = setTimeout(() => {
                resizeCursor();
            }, 500);
        };

        window.addEventListener('resize', handleResize);

        return () => window.addEventListener('resize', handleResize);
    }, [resizeCursor, cursorRef]);

    const resetSubmenu = useCallback(() => {
        moveCursor(0);
        refreshTree(true);
    }, [refreshTree, moveCursor]);

    useLayoutEffect(() => {
        if (!prevTab) setPrevTab(currentTab.name);

        if (
            namespace !== 'navigation' &&
            currentTab.name &&
            currentTab.name !== prevTab
        ) {
            resetSubmenu();
            setPrevTab(currentTab.name);
        }

        if (items.length > 0) {
            semiHighlightItem(selectedIndex.current + itemsStartIndex);
        }
    }, [
        namespace,
        resetSubmenu,
        currentTab,
        prevTab,
        items.length,
        itemsStartIndex,
        semiHighlightItem,
    ]);

    const helpers = [
        {
            title: 'highlights',
            comp: <Helper helperId="highlights_helper" />,
        },
        {
            title: 'categories',
            comp: <Helper helperId="categories_helper" />,
        },
    ];

    const multi = {
        title: 'multiplayer',
        comp: <Helper helperId="multiplayer_helper" />,
    };

    const mobile = {
        title: 'touch',
        comp: <Helper helperId="mobile_helper" />,
    };

    isMobile ? helpers.push(mobile) : helpers.push(multi);

    return (
        <S.Wrapper ref={wrapperRef} isMain={isMain}>
            <S.Cursor ref={cursorRef} />
            {items.map((item, index) => {
                const titleKey =
                    item.titleKey ||
                    (namespace === 'navigation' && `navigation.${item.name}`) ||
                    `${namespace}.${item}`;

                return (
                    <S.Item
                        disableMoveLeft={index === 0}
                        disableMoveRight={
                            index === items.length - 1 && preventMoveRight
                        }
                        overrideMoveDown={overrideMoveDown}
                        overrideMoveUp={handleOverrideMoveUp}
                        overrideMoveRight={() => {
                            if (
                                namespace === 'navigation' &&
                                index === items.length - 1
                            ) {
                                hideCursor();
                            }
                            return true;
                        }}
                        onFocus={(e) => onItemClicked(index, e)}
                        onClick={handleClickOrTouch}
                        onTouchStart={handleClickOrTouch}
                        content={t(titleKey)}
                        key={index}
                    >
                        <S.Text>{t(titleKey)}</S.Text>

                        {namespace === 'navigation' &&
                        item.name === helpers[index].title
                            ? helpers[index].comp
                            : null}
                    </S.Item>
                );
            })}
        </S.Wrapper>
    );
}
