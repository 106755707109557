import { useEffect, useMemo, useRef } from 'react';
import {
    useSharedElementCloneContext,
    useSpatialNavContext,
    useSpatialNavSectionContext,
} from 'context';

export function useSpatialNav(
    {
        animatable,
        defaultElement,
        focusOnMount,
        overrideMoveLeft,
        overrideMoveRight,
        overrideMoveUp,
        overrideMoveDown,
        disableMoveLeft,
        disableMoveRight,
        disableMoveUp,
        disableMoveDown,
    },
    forwardedRef
) {
    const {
        addFocusable,
        updateFocusable,
        removeFocusable,
        setFocus,
        makeFocusable,
    } = useSpatialNavContext();
    // parent section, may be null for top-level elements
    const parent = useSpatialNavSectionContext();
    const isClone = useSharedElementCloneContext();
    const ownRef = useRef();
    const ref = forwardedRef || ownRef;

    const overrideMove = useMemo(() => {
        return {
            left: overrideMoveLeft,
            right: overrideMoveRight,
            up: overrideMoveUp,
            down: overrideMoveDown,
        };
    }, [overrideMoveLeft, overrideMoveRight, overrideMoveUp, overrideMoveDown]);

    const disableMove = useMemo(
        () => ({
            left: disableMoveLeft,
            right: disableMoveRight,
            up: disableMoveUp,
            down: disableMoveDown,
        }),
        [disableMoveLeft, disableMoveRight, disableMoveUp, disableMoveDown]
    );

    // add/remove at mount/unmount
    // this should normally run exactly once over the lifetime of an element
    // if it looks like it runs multiple times, activate uwc-debug on the effect
    // for troubleshooting
    useEffect(() => {
        addFocusable({
            target: ref.current,
            parent: (parent && parent.ref.current) || null,
            overrideMove: {},
            disableMove: {},
            animatable,
        });

        makeFocusable(ref.current);

        // focusOnMount
        if (focusOnMount && !isClone) {
            setFocus(ref.current);
        }

        const node = ref.current;
        const parentNode = (parent && parent.ref.current) || null;
        return () =>
            removeFocusable({
                target: node,
                parent: parentNode,
            });
    }, [
        addFocusable,
        animatable,
        focusOnMount,
        isClone,
        makeFocusable,
        parent,
        ref,
        removeFocusable,
        setFocus,
    ]);

    // set default - may change over the lifetime of the target
    useEffect(() => {
        if (defaultElement) {
            if (!parent)
                throw new Error(
                    'To be a default element, focusable Component must be in a SpatialNavSection'
                );
            parent.setDefault(ref.current);
        }
    }, [defaultElement, parent, ref]);

    // update override and disable if they change over the lifetime of the target
    useEffect(() => {
        updateFocusable({
            target: ref.current,
            overrideMove,
            disableMove,
        });
    }, [disableMove, overrideMove, ref, updateFocusable]);

    return { ref };
}
