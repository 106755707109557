import { mobile } from 'app/device';
import styled, { css, keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  40% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

export const CategoryTitle = styled.div`
    margin-top: 180px;
    width: 98vw;
    margin-right: auto;
    margin-left: auto;
    align-self: flex-start;
    align-items: baseline;
    font-size: 2.5rem;
    text-transform: capitalize;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    opacity: 0;
    display: flex;

    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-name: ${animation};
    animation-duration: 450ms;

    ${mobile(css`
        margin-top: 80px;
        font-size: 1.3rem;
        margin-left: 0.5rem;
    `)}
`;
