import styled from 'styled-components';
import Theme from 'app/theme';

export const IosInstructions = styled.div`
    width: 100%;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Theme.COLORS.BLACK};
    font-size: 0.85rem;
    background-color: ${Theme.COLORS.GRAY_L1};
    color: ${Theme.COLORS.GRAY_L6};
`;
