import {
    BoxContent,
    BoxText,
    StepWrapper,
    Title,
} from 'components/parental-control/wizard';
import { HoursInput } from 'components/parental-control/wizard/steps';
import { ActionButtons } from 'components/ui';
import { useWizardHours } from 'hooks/parental-control/wizard';
import { useTranslation } from 'react-i18next';

export function StepHours() {
    const { onValidate, onBack, hours, setHours } = useWizardHours();
    const { t } = useTranslation();

    return (
        <StepWrapper>
            <Title />
            <BoxContent>
                <BoxText>{t('profile.parental_control.daily_hours')}</BoxText>
                <HoursInput hours={hours} setHours={setHours} defaultElement />
            </BoxContent>
            <ActionButtons
                focusOnMount
                buttons={[
                    {
                        type: 'primary',
                        text: t('buttons.validate'),
                        callback: () => onValidate(hours),
                    },
                    {
                        type: 'neutral',
                        text: t('buttons.back'),
                        callback: onBack,
                    },
                ]}
            />
        </StepWrapper>
    );
}
