import { maxCols } from 'helpers';
import { useMemo } from 'react';
import { Games } from 'services';

export function useMulti() {
    const [columns, games] = useMemo(() => {
        const columns = [...Array(maxCols)].map(() => []);

        const games = Games.sortGamesWithRules(
            Games.GetCategoryGames('multiplayer')
        );

        games
            .filter((game) => game.release_status !== 'coming_soon')
            .forEach((game, index) => {
                columns[index % maxCols].push(game);
            });

        return [columns, games];
    }, []);

    return {
        columns,
        games,
    };
}
