import styled from 'styled-components';

export const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    overflow: hidden;
    justify-content: center;
`;

export const SwiperStyle = styled.div`
    width: '100%';
    height: '100%';
`;
