import history from 'app/history';
import { useTimedCallback } from 'hooks/helpers';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { globalMessageBoxSelector, hideGlobalMessageBox } from 'slices';

// TODO: refactor this cleanly
let closeCallback = undefined;
export const setMessageBoxCloseCallback = (cb) => {
    closeCallback = cb;
};

export function useGlobalMessageBox() {
    const dispatch = useDispatch();
    const {
        title,
        message,
        message2,
        subtitle,
        type,
        routeTo,
        timing,
        button,
    } = useSelector(globalMessageBoxSelector);
    const {
        createTimedCallback,
        clearTimedCallback,
        intervalRef,
        intervalTick,
    } = useTimedCallback();

    const onClick = useCallback(() => {
        if (intervalRef.current) clearTimedCallback();

        // call custom close callback, then clear if present
        closeCallback?.();
        closeCallback = undefined;

        dispatch(hideGlobalMessageBox());
        if (routeTo) history.push(routeTo);
    }, [dispatch, routeTo, intervalRef, clearTimedCallback]);

    useEffect(() => {
        if (!timing) return;

        createTimedCallback(timing / 1000, onClick); // timing in ms convert to seconds
    }, [timing, createTimedCallback, onClick]);

    return {
        title,
        subtitle,
        message,
        message2,
        button,
        type,
        onClick,
        tick: intervalTick,
    };
}
