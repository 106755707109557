import { isMobileOrTablet, isTV } from 'app/device';
import { IconClose2 } from 'components/ui';
import { If } from 'helpers';
import { useDisableAtmosSound, useWebClient } from 'hooks';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { gameSessionSelector, resetGameSession } from 'slices';
import * as S from './WebClient.style';

export function WebClient(props) {
    const { containerRef, sendEsc } = useWebClient(props);
    const dispatch = useDispatch();
    const { SGXConfig } = useSelector(gameSessionSelector);

    useDisableAtmosSound();

    const isAndroidGame =
        SGXConfig.options['client-type'] === 'AndroidCloudGaming';

    const onClose = () => {
        if (isAndroidGame) {
            // on Android games, send an Esc key instead to trigger a back
            // action in the emulator and let the user attempt to exit/save cleanly
            sendEsc();
        } else {
            dispatch(resetGameSession());
        }
    };

    return ReactDOM.createPortal(
        <S.Wrapper>
            <If condition={isMobileOrTablet || (!isTV && isAndroidGame)}>
                <S.CloseButton>
                    <IconClose2 fill="white" onClick={onClose} />
                </S.CloseButton>
            </If>
            <S.Container ref={containerRef} />
        </S.Wrapper>,
        document.body
    );
}
