import { useSpatialNavContext } from 'context';
import { useCallback, useEffect, useRef, useState } from 'react';

export const descriptionNavBtn = {
    PREV: 'prev',
    NEXT: 'next',
};

export const descriptionActionBtn = {
    PLAY: 'play',
    TRAILER: 'trailer',
    MORE: 'more',
    MULTIPLAYER: 'multi',
    QUICKMATCH: 'quickmatch',
};

/* This set the right focus on the current swiper window.
This is mainly used for GameWindow and MarketingWindow as they are sharing quite similar navigation */
export function useSetSwiperWindowFocus({
    swiperName,
    swiperRef,
    activeIndex,
    nextSlide,
    prevSlide,
    swiperTransitionActive,
    slidesLength,
}) {
    const { refreshTree, setFocus } = useSpatialNavContext();
    const activeSlideRef = useRef();
    const [lastFocusedEl, setLastFocusedEl] = useState({ el: '' }); // we are using an object in state instead of value like string to force re-render even if the value hasn't changed and re-trigger the useEffect in charge of focusButton

    useEffect(() => {
        if (!swiperTransitionActive) refreshTree();
    }, [refreshTree, swiperTransitionActive]);

    const onNextSlide = useCallback(() => {
        if (swiperTransitionActive || !swiperRef) return;

        nextSlide();
        if (activeIndex + 1 < slidesLength - 1) {
            setLastFocusedEl({ el: descriptionNavBtn.NEXT });
        } else {
            setLastFocusedEl({ el: descriptionNavBtn.PREV });
        }
    }, [
        activeIndex,
        swiperRef,
        nextSlide,
        slidesLength,
        swiperTransitionActive,
    ]);

    const onPrevSlide = useCallback(() => {
        if (swiperTransitionActive || !swiperRef) return;

        prevSlide();
        if (activeIndex - 1 > 0) {
            setLastFocusedEl({ el: descriptionNavBtn.PREV });
        } else {
            setLastFocusedEl({ el: descriptionNavBtn.NEXT });
        }
    }, [activeIndex, swiperRef, prevSlide, swiperTransitionActive]);

    const focusButton = useCallback(
        (buttonId) => {
            if (!activeSlideRef.current) return;

            let button = activeSlideRef.current.querySelector(
                `[data-btn="${buttonId}"]`
            );

            if (!button) {
                //fallback to play if requested button not found
                if (process.env.NODE_ENV === 'development') {
                    console.error('Button', buttonId, 'not found in');
                }
            } else {
                setFocus(button);
            }
        },
        [setFocus]
    );

    useEffect(() => {
        if (!swiperRef || swiperTransitionActive || swiperRef.destroyed) return;

        activeSlideRef.current = swiperRef.$wrapperEl[0].querySelector(
            '.swiper-slide-active'
        );

        //no need for PLAY. when we click on PLAY game is launched and on exit PLAY button is focused
        switch (lastFocusedEl.el) {
            case descriptionNavBtn.PREV:
            case descriptionNavBtn.NEXT:
            case descriptionActionBtn.MORE:
            case descriptionActionBtn.QUICKMATCH:
            case descriptionActionBtn.MULTIPLAYER:
                focusButton(lastFocusedEl.el);
                break;
            default:
                focusButton(descriptionActionBtn.PLAY);
                break;
        }
    }, [
        swiperRef,
        swiperTransitionActive,
        activeIndex,
        lastFocusedEl,
        focusButton,
    ]);

    return {
        onNextSlide,
        onPrevSlide,
        setLastFocusedEl,
    };
}
