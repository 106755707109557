import i18n from 'i18n';

// 24:00 to 12AM/PM
export function timeFormatter(time) {
    const [sHours, minutes] = time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? 'AM' : 'PM';
    const hours = +sHours % 12 || 12;

    return { hours, minutes, period };
}

export function numberToTime(numb) {
    return numb;
}

export function formatToHour(int, addMinutes = true) {
    if (int === Infinity) {
        return '∞';
    }
    return int.toString().padStart(2, '0') + (addMinutes ? ':00' : '');
}

export function formatToHrs(value) {
    let localeTime;
    switch (i18n.language) {
        case 'fr':
            localeTime = 'h';
            break;
        default:
            localeTime = 'hr';
            break;
    }

    return isFinite(value) ? value.toString() + localeTime : '∞';
}
