import { SpatialNavSection, withInputDispatcherProvider } from 'context';
import { useBackPress } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { HourInput } from './hour-input/HourInput';
import * as S from './HoursRange.style';

export const HoursRange = withInputDispatcherProvider(
    ({ onEditingChange, range, maxRange, inline, format, ...props }) => {
        const {
            start,
            incrementStart,
            decrementStart,
            end,
            incrementEnd,
            decrementEnd,
        } = range;
        const [editingStart, setEditingStart] = useState(false);
        const [editingEnd, setEditingEnd] = useState(false);

        // notify parent of change in edition state
        const editing = editingStart || editingEnd;
        useEffect(() => {
            onEditingChange?.(editing);
        }, [editing, onEditingChange]);

        // backpress is active only while editing
        const stopEdition = useCallback(() => {
            setEditingStart(false);
            setEditingEnd(false);
        }, [setEditingStart, setEditingEnd]);
        useBackPress(editing ? stopEdition : undefined);

        const toggleStartActive = () => {
            setEditingStart((prevState) => !prevState);
        };

        const toggleEndActive = () => {
            setEditingEnd((prevState) => !prevState);
        };

        return (
            <SpatialNavSection enterTo="last-focus" {...props}>
                <S.Wrapper>
                    <HourInput
                        format={format}
                        defaultElement
                        editing={editingStart}
                        visible={!editingEnd}
                        increment={incrementStart}
                        decrement={decrementStart}
                        value={start}
                        onClick={toggleStartActive}
                        disableMoveUp={editingStart || inline}
                        disableMoveDown={editingStart || inline}
                        WrapperComponent={S.Hour}
                        InputComponent={S.TimeInput}
                    />
                    <S.Text visible={!editingStart && !editingEnd}>-</S.Text>
                    <HourInput
                        format={format}
                        editing={editingEnd}
                        increment={incrementEnd}
                        decrement={decrementEnd}
                        value={end}
                        visible={!editingStart}
                        onClick={toggleEndActive}
                        disableMoveUp={editingEnd || inline}
                        disableMoveDown={editingEnd || inline}
                        WrapperComponent={S.Hour}
                        InputComponent={S.TimeInput}
                    />
                </S.Wrapper>
            </SpatialNavSection>
        );
    }
);
