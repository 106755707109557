import { mobile } from 'app/device';
import Theme from 'app/theme';
import { withFocusable } from 'helpers';
import styled, { css } from 'styled-components';

export const RoundedBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    border: none;
    padding: 0.625rem 0;

    ${mobile(css`
        padding: 0.1rem 0;
    `)}
`;

export const TimeTick = styled.div`
    padding: 0 4px;
    width: 30px;
    &:first-of-type {
        padding-left: 20px;
    }
    &:last-of-type {
        padding-right: 20px;
    }
`;

export const HiddenTimeTick = styled.span`
    padding: 0 4px;
    opacity: 0;
    width: 30px;
`;

export const TimeTickSplit = styled.div`
    border-right: 1px dashed rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 1px;
    opacity: ${(props) => (props.hide ? 0 : 1)};
    width: 30px;
`;

export const TimeBarTicks = styled.RoundedBar`
    width: 100%;
    font-size: 0.75rem;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 3;
    color: black;
    pointer-events: none;
    transition: color 250ms ease-out, background-color 250ms ease-out;
`;

export const TimeBarWrapper = withFocusable(styled.div`
    cursor: pointer;
    position: relative;
    outline: none;
    width: calc(85% - 60px);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    transition: background-color ease-out 250ms;
    display: flex;
    max-width: 95%;
    overflow: hidden;
    &:focus,
    &:hover {
        > [data-type='TimeBar'] {
            background-color: ${Theme.COLORS.PRIMARY_L2};
            color: white;
        }

        ${TimeBarTicks} {
            color: white;
        }
    }
`);

export const TimeBar = styled.RoundedBar`
    justify-content: space-between;
    color: black;
    background-color: ${Theme.COLORS.PRIMARY_L1};
    transition: color 250ms ease-out, background-color 250ms ease-out;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 2;
`;

export const HourWrapper = styled.div`
    padding: 0 10px;
`;

export const MaxRange = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
`;

export const MaxRangeStart = styled(MaxRange)`
    left: 20px;
`;

export const MaxRangeEnd = styled(MaxRange)`
    right: 20px;
`;
