import { isMobile } from 'app/device';
import { GameItem } from 'components/games';
import { Helper, RowLayout } from 'components/ui';
import { SpatialNavSection } from 'context';
import { If } from 'helpers';
import { LeftPan, RightPan } from './View.style';

export const ViewContainer = ({
    game,
    onClose,
    actionButtons,
    children,
    isCurrent,
}) => {
    return (
        <SpatialNavSection enterTo="default-element">
            <RowLayout fullSize>
                <LeftPan>
                    <GameItem
                        game={game}
                        src={game.assets.cover}
                        onClose={onClose}
                    />
                    <If condition={isCurrent}>
                        <If condition={isMobile && actionButtons}>
                            <div style={{ position: 'relative' }}>
                                <Helper helperId="trailer_helper" />
                                {actionButtons}
                            </div>
                        </If>
                    </If>

                    <If condition={!isCurrent}>
                        <If condition={isMobile && actionButtons}>
                            {actionButtons}
                        </If>
                    </If>
                </LeftPan>
                <RightPan>{children}</RightPan>
            </RowLayout>
        </SpatialNavSection>
    );
};
