import { maxCols } from 'helpers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Games } from 'services';
import { globalNavigationSelector } from 'slices';

export function useTouchCategories() {
    const { currentTab } = useSelector(globalNavigationSelector);

    const [columns, games] = useMemo(() => {
        const columns = [...Array(maxCols)].map(() => []);

        const games = Games.sortGamesWithRules(
            Games.GetCategoryGames(currentTab.subTab)
        );

        games
            .filter((game) => game.release_status !== 'coming_soon')
            .forEach((game, index) => {
                columns[index % maxCols].push(game);
            });

        return [columns, games];
    }, [currentTab.subTab]);

    return {
        columns,
        games,
        currentTab,
    };
}
