import Theme from 'app/theme';
import styled from 'styled-components';
import { SoundButton } from './sound-button/SoundButton';

const NeutralButton = styled(SoundButton)`
    color: ${Theme.COLORS.WHITE};
    background-color: rgba(255, 255, 255, 0.15);

    &:focus,
    &:hover {
        background-color: rgba(255, 255, 255, 0.3);
    }

    &:active {
        background-color: rgba(255, 255, 255, 0.5);
    }
`;

NeutralButton.defaultProps = {
    sound: 'regularBtn',
};

export { NeutralButton };
