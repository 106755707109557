import {
    GameDescriptionView,
    PlayButton,
    TrailerButton,
} from 'components/games';
import { ActionButtons, RowLayout } from 'components/ui';
import { useSpatialNavContext } from 'context';
import { useMultiViewContext } from 'context/multi-view';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LeftPan, RightPan } from '../View.style';
import * as S from './DescriptionView.style';

export function DescriptionView({
    game,
    onUnFavorite,
    onQuickMatch,
    onExpandClicked,
    playButtonRef,
    wrapperRef,
    gridRef,
}) {
    const { view, viewRenderCount } = useMultiViewContext();
    const { t } = useTranslation();
    const { setView } = useMultiViewContext();
    const [confirm, setConfirm] = useState(false);
    const { setEnabled, setFocus } = useSpatialNavContext();
    const multiButtonRef = useRef();
    const secondButtonRef = useRef();

    const playEnabled = game.release_status !== 'coming_soon';
    useEffect(() => {
        if (view === 'description' && viewRenderCount.current.moreView > 0) {
            if (playEnabled) {
                setFocus(playButtonRef.current);
            } else {
                setFocus(secondButtonRef.current);
            }
        }
    }, [playButtonRef, playEnabled, setFocus, view, viewRenderCount]);

    // disable nav on play and multi buttons for coming soon games
    useEffect(() => {
        setEnabled(playButtonRef.current, playEnabled);
        setEnabled(multiButtonRef.current, playEnabled);
    }, [playButtonRef, playEnabled, setEnabled]);

    const trailerVisible = !onUnFavorite && game.assets.trailer;
    const localMultiOnly =
        !game.hasQuickMatch && game.nb_players_local_multi > 0;

    const buttons = [
        {
            component: PlayButton,
            ref: playButtonRef,
            props: {
                defaultElement: playEnabled,
                key: game.alias,
                disabled: !playEnabled,
                game,
            },
        },
        {
            isVisible: trailerVisible,
            component: TrailerButton,
            props: {
                defaultElement: trailerVisible && !playEnabled,
                ref: trailerVisible ? secondButtonRef : undefined,
                game,
            },
        },
        {
            // multiplayer button when online is available
            isVisible: !!game.hasQuickMatch,
            text: t('buttons.multiplayer'),
            type: 'regular',
            callback: playEnabled ? () => onQuickMatch(game.alias) : undefined,
            props: {
                ref: game.hasQuickMatch ? multiButtonRef : undefined,
                disabled: !playEnabled,
            },
        },
        {
            // multiplayer button when online is not available
            isVisible: localMultiOnly,
            component: PlayButton,
            props: {
                ref: localMultiOnly ? multiButtonRef : undefined,
                disabled: !playEnabled,
                key: game.alias,
                game,
                text: t('buttons.multiplayer'), // alternative button text
            },
        },
        {
            text: t('buttons.more'),
            callback: () => setView('moreView'),
            props: {
                defaultElement: !trailerVisible && !playEnabled,
                ref: trailerVisible ? undefined : secondButtonRef,
            },
        },
        {
            isVisible: onUnFavorite !== undefined && !confirm,
            text: t('buttons.unfavorite'),
            type: 'neutral',
            callback: () => setConfirm(true),
        },
        {
            isVisible: onUnFavorite !== undefined && confirm,
            text: t('buttons.confirm'),
            type: 'danger',
            callback: () => {
                onUnFavorite(game.alias);
                setConfirm(false);
            },
            props: {
                onBlur: () => setConfirm(false),
                focusOnMount: true,
            },
        },
    ];

    const actionButtons = (
        <ActionButtons
            defaultElement
            Wrapper={S.ActionButtonsWrapper}
            vertical
            buttons={buttons}
            overrideMoveDown={() => {
                setFocus(gridRef.current, {
                    originSource: wrapperRef.current,
                    direction: 'down',
                });
                return false;
            }}
        />
    );

    return (
        <RowLayout fullSize>
            <LeftPan>{actionButtons}</LeftPan>
            <RightPan>
                <S.Content>
                    <GameDescriptionView
                        onExpandClicked={onExpandClicked}
                        playButtonRef={playButtonRef}
                        game={game}
                        minimized={true}
                    />
                </S.Content>
            </RightPan>
        </RowLayout>
    );
}
