import { useCallback } from 'react';

import { useAppSound } from './useAppSound';

export function useSoundAction(sound, cb, soundParams) {
    const [playSound] = useAppSound(sound, soundParams);
    return useCallback(
        (...cbParams) => {
            playSound();
            cb?.(...cbParams);
        },
        [cb, playSound]
    );
}
