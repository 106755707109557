import { Eula } from 'components/games/eula/Eula';
import { GameLobbySession } from 'components/games/game-lobby-session/GameLobbySession';
import { ConfirmationBox, LoadingButton, PrimaryButton } from 'components/ui';
import { If } from 'helpers';
import { useEulaVerification, useGameLobby, useMultiDisclaimer } from 'hooks';
import { useGamepadCheck } from 'hooks/games/useGamepadCheck';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LOBBY_STATE, gameLobbySelector, profileSelector } from 'slices';

export function JoinLobbyButton({ game, ...props }) {
    const { t } = useTranslation();
    const eula = useEulaVerification(game);
    const gamepadCheck = useGamepadCheck(game, true);
    const multiDisclaimer = useMultiDisclaimer();
    const { join, lobbyState } = useGameLobby();
    const { ownerUID } = useSelector(gameLobbySelector);
    const { currentProfile } = useSelector(profileSelector);

    const isOwner = currentProfile.uid === ownerUID;

    return (
        <>
            <If condition={gamepadCheck.visible}>
                <gamepadCheck.GamepadMessageBox />
            </If>

            <If condition={eula.visible}>
                <Eula
                    game={game}
                    readOnly={false}
                    onApproved={eula.accepted}
                    onClose={eula.close}
                />
            </If>

            <If condition={multiDisclaimer.visible}>
                {createPortal(
                    <ConfirmationBox
                        message={
                            isOwner
                                ? t('lobby.multi_owner_disclaimer')
                                : t('lobby.multi_guest_disclaimer')
                        }
                        buttons={[
                            {
                                type: 'primary',
                                text: t('buttons.accept'),
                                callback: () => multiDisclaimer.accepted(false),
                            },
                        ]}
                    />,
                    document.body
                )}
            </If>

            <If condition={lobbyState.id > LOBBY_STATE.INITIAL.id}>
                <GameLobbySession
                    game={game}
                    disclaimer={multiDisclaimer.showDisclaimer}
                />
            </If>

            <LoadingButton
                {...props}
                isLoading={lobbyState.id !== LOBBY_STATE.INITIAL.id}
                button={PrimaryButton}
                loadingText={t(`lobby.${lobbyState.label}`)}
                forceClick={lobbyState.id === LOBBY_STATE.FORCE_PLAY.id}
                showLoadingText={lobbyState.id === LOBBY_STATE.FORCE_PLAY.id}
                onClick={gamepadCheck.protect(eula.protect(join))}
            >
                {t('lobby.join')}
            </LoadingButton>
        </>
    );
}
JoinLobbyButton.displayName = 'JoinLobbyButton';
