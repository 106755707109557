import { RegularButton } from 'components/ui';
import { SpatialNavSection } from 'context';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { startParentalControlEdition } from 'slices';
import * as S from './ParentalControl.style';

export function ParentalControl() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <SpatialNavSection enterTo="last-focused">
            <S.Wrapper>
                <S.Text>{t('profile.parental_control.title')}</S.Text>
                <RegularButton
                    onClick={() => {
                        dispatch(startParentalControlEdition());
                    }}
                >
                    {t('buttons.edit')}
                </RegularButton>
            </S.Wrapper>
        </SpatialNavSection>
    );
}
