import styled, { css } from 'styled-components';
import { tv, mobile, mobileSmall } from 'app/device';
import { RowLayout as RowLayoutBase } from 'components/ui';
import { Input } from 'components/ui';
import Theme from 'app/theme';

export const Heading = styled.div`
    font-size: 1.5rem;
    user-select: none;
    margin-bottom: 1rem;

    ${tv(css`
        font-size: 1.7rem;
    `)}
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
    min-width: 436px;
    padding: 16px;

    ${mobile(css`
        width: 90vw;
    `)}

    ${tv(css`
        min-width: 1200px;
        padding: 32px;
    `)}
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 26px;
    margin-bottom: 40px;

    ${tv(css`
        margin-top: 42px;
        margin-bottom: 57px;
    `)}

    ${mobile(css`
        margin-top: 28px;
        margin-bottom: 34px;
    `)}

  ${mobileSmall(css`
        margin-top: 16px;
        margin-bottom: 16px;
    `)}
`;

export const MainContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

export const RightPan = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    user-select: none;
`;

export const RowLayout = styled(RowLayoutBase)`
    > *:not(:last-child) {
        margin-right: 1rem;
    }
`;

export const LeftPan = styled.div`
    display: flex;
    flex-direction: column;
    user-select: none;
    width: 144px;
    margin-right: 16px;
    ${mobile(css`
        width: 140px;
    `)}
    ${tv(css`
        width: 218px;
        margin-right: 32px;
    `)}
`;

export const ProfileNameInput = styled(Input)`
    min-width: 0;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    margin: 0 6px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    ${tv(css`
        padding-top: 8px;
        padding-bottom: 8px;
        margin: 0 8px;
    `)}
`;

export const NicknameSuggestions = styled.div`
    color: ${Theme.COLORS.ORANGE_L1};
    font-size: 1rem;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    margin-top: 0.5rem;
    margin-left: 6px;
    transition: opacity 250ms ease-out;
`;
