import { mobile } from 'app/device';
import styled, { css } from 'styled-components';

export const ActionButtonsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2rem;

    ${mobile(css`
        margin-top: 1rem;
    `)}
`;
