import styled, { css } from 'styled-components';
import { hover, mobile, mobileSmall, tv } from 'app/device';
import { withFocusable } from 'helpers';
import Theme from 'app/theme';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

export const MostSearchedCuesWrapper = styled.div`
    width: 100%; //has to be 100% otherwise not detected spatialNav
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;

    ${tv(css`
        * {
            font-size: 1rem;
        }
    `)}
`;

export const CuesLabel = styled.div`
    margin-right: 40px;
    ${mobileSmall(css`
        margin-right: 20px;
    `)}
`;

export const CuesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
`;

export const Cue = withFocusable(styled.div`
    user-select: none;
    padding: 2px 10px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.35);
    margin: 4px;
    outline: none;
    cursor: pointer;

    ${tv(css`
        padding: 6px 14px;
        border-radius: 24px;
    `)}
    &:focus, &:hover {
        background-color: ${Theme.COLORS.PRIMARY_L2};
    }
`);

export const SuggestedGamesWrapper = styled.div`
    width: 100%;
    margin-top: 15px;
`;

export const SuggestedGames = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 18px;
    margin-bottom: 36px;

    ${mobile(css`
        margin-top: 8px;
    `)}

    > div:not(:last-of-type) {
        margin-right: 20px;
    }
`;

export const GameThumbnailWrapper = styled.div`
    width: 100%;

    &:focus {
        outline: none;
    }
`;

const GameThumbnail = withFocusable(styled.div`
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    width: 100%;
    padding-top: 100%;
    transition: box-shadow 100ms ease-out;

    ${mobile(css`
        border-radius: 16px;
    `)}
    &:focus, &:active {
        outline: none;
        box-shadow: inset 0 0 0 4px white;
        ${tv(css`
            box-shadow: inset 0 0 0 4px white;
        `)}
    }

    ${hover(css`
        &:hover {
            cursor: pointer;
            box-shadow: inset 0 0 0 4px white;
        }
    `)}
`);

export const GameThumbnailItem = styled(GameThumbnail).attrs((props) => ({
    style: {
        backgroundImage: `url(${props.srcImage})`,
        ...props.style,
    },
}))``;

export const GameNoThumbnailItem = styled(GameThumbnailItem)`
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    span {
        position: absolute;
        top: 50%;
        display: block;
        font-size: 0.65rem;
    }
`;
