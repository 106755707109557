import styled from 'styled-components';
import Theme from 'app/theme';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 95vw;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 2rem;
`;

export const WarningWrapper = styled.div`
    width: 35px;
    margin-right: 1rem;
`;

export const Text = styled.div`
    color: ${Theme.COLORS.ORANGE_L1};
`;
