const Theme = {
    // Blur
    BLUR_AMOUNT: 20, // Unit : px
    BLUR_BRIGHTNESS: 110, // Unit : %
    BLUR_CONTRAST: 70, // Unit : %
    BLUR_SATURATE: 150, // Unit : %

    COLORS: {
        // WHITE
        WHITE: '#ffffff',
        BLACK: '#000000',

        // PRIMARY
        PRIMARY_L1: '#6acbf3',
        PRIMARY_L1_FOCUS: '#08a9ec',
        PRIMARY_L2: '#5e5ce6',
        PRIMARY_L3: '#440099',
        PRIMARY_L3_ALPHA: 'rgba(68, 0, 153, 0.5)', // Tizen does not support hex alpha

        // GRAY
        GRAY_L1: '#d0d0d0',
        GRAY_L2: '#a3a3a3',
        GRAY_L3: '#969696',
        GRAY_L4: '#898989',
        GRAY_L5: '#868686',
        GRAY_L6: '#707070',

        // RED
        RED_L1: '#ea334c',
        RED_L2: '#ff4b60',
        RED_L3: '#ff7777',

        // Orange
        ORANGE_L1: '#f4b43e',

        // Green
        GREEN_L1: '#75fbc8',
    },
};

export default Theme;
