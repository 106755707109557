import { useMultiStepContext } from 'context/multi-step';
import { parentalControlWizardSelector, updateParentalControl } from 'slices';
import { useDispatch, useSelector } from 'react-redux';

export function useWizardFinished() {
    const dispatch = useDispatch();
    const { setStep, step } = useMultiStepContext();
    const { enabled, globalRange, hoursPerDay } = useSelector(
        parentalControlWizardSelector
    );

    const onValidate = () => {
        const fullConfig = {
            enabled,
            globalRange,
            days: Array.from({ length: 7 }, (_, i) => ({
                dayIndex: i,
                hoursPerDay,
                range: { ...globalRange },
            })),
        };
        dispatch(updateParentalControl(fullConfig));
    };

    const onBack = () => {
        enabled ? setStep(step - 1) : setStep(1);
    };

    return { onValidate, onBack };
}
