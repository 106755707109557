export const IconNext = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.731"
            height="17.32"
            viewBox="0 0 10.731 17.32"
            {...props}
        >
            <path
                id="Path_140"
                data-name="Path 140"
                d="M450.529,242.96a1.846,1.846,0,0,0,.588,1.175c2.148,2.155,4.294,4.314,6.462,6.45a2.048,2.048,0,0,0,3.065.081,2.015,2.015,0,0,0,0-2.9c-.83-.865-1.69-1.7-2.537-2.548-.507-.509-1.02-1.014-1.525-1.526a1.277,1.277,0,0,1-.018-2.057c1.312-1.333,2.636-2.652,3.959-3.972a2.133,2.133,0,0,0,.712-1.589,2.069,2.069,0,0,0-3.076-1.812,3.129,3.129,0,0,0-.68.523c-2.089,2.08-4.167,4.171-6.255,6.251A2.474,2.474,0,0,0,450.529,242.96Z"
                transform="translate(461.259 251.316) rotate(180)"
            />
        </svg>
    );
};
