export const HelperHandle = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45.713"
            height="40.984"
            viewBox="0 0 45.713 40.984"
            {...props}
        >
            <path
                d="M22.168,7.521a5,5,0,0,1,8.665,0L48.682,38.5A5,5,0,0,1,44.349,46H8.651a5,5,0,0,1-4.332-7.5Z"
                transform="translate(49.357 46) rotate(180)"
            />
        </svg>
    );
};
