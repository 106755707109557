import Theme from 'app/theme';
import {
    HelperHandle,
    IconClose2,
    IconInfo as Info,
    IconNext as Next,
} from 'components/ui';
import { withFocusable } from 'helpers';
import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

export const Wrapper = styled.div`
    position: absolute;
    z-index: 999;
    pointer-events: none;
    // variables are used to combine displacement relative to self and animation
    --tx: ${(props) => props.horizontalShift || '0%'};
    // default to top if not specified
    --ty: ${(props) => props.verticalPlacement || '0%'};
    transform: translate3d(var(--tx), var(--ty), 0);
    /* transform: translate3d(0, 0, 0); */

    &.animateEnter {
        animation: ${fadeIn} 300ms ease-in;
    }
    &.animateExit {
        animation: ${fadeOut} 300ms ease-out;
    }
`;

export const Helper = styled.div`
    position: relative;
    filter: drop-shadow(15px 15px 6px rgba(0, 0, 0, 0.5));
`;

export const Handle = styled(HelperHandle)`
    height: 30px !important;
    position: absolute;
    fill: ${Theme.COLORS.PRIMARY_L2};

    // default to left if horizontalPlacement is not specified
    ${(props) =>
        props.horizontalPlacement !== 'center' &&
        props.horizontalPlacement !== 'right0' &&
        props.horizontalPlacement !== 'right' &&
        css`
            left: 50px;
        `}
    ${(props) =>
        props.horizontalPlacement === 'center' &&
        css`
            right: 50%;
        `}
    ${(props) =>
        props.horizontalPlacement === 'right' &&
        css`
            right: 50px;
        `}

        ${(props) =>
        props.horizontalPlacement === 'right0' &&
        css`
            right: 15px;
        `}
    
    // default to top if verticalPlacement is not specified
    ${(props) =>
        props.verticalPlacement !== 'bottom' &&
        css`
            top: -25px;
        `}
    ${(props) =>
        props.verticalPlacement === 'bottom' &&
        css`
            bottom: -25px;
        `}

    transform: ${(props) =>
        `${props.horizontalPlacement === 'center' ? 'translate(50%)' : ''} ${
            props.verticalPlacement !== 'bottom' ? 'rotate(180deg)' : ''
        }`};
`;

export const Bubble = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    width: 300px;
    background-color: ${Theme.COLORS.PRIMARY_L2};
    color: white;
    border-radius: 20px;

    & > div {
        margin: 5px;
    }
`;

const HelperLine = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: bold;
    align-items: center;
`;

export const IconInfo = styled(Info)`
    height: 1.2rem;
`;

export const HelperTitle = styled(HelperLine)`
    text-align: left;
    position: relative;
    font-size: 1.2rem;
    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

export const HelperTitleText = styled.div`
    flex-grow: 1;
`;

export const CloseButton = withFocusable(styled.div`
    width: 1.2rem;
    cursor: pointer;
    outline: none;
    transition: transform 200ms ease-out;
    &:hover,
    &:focus {
        transform: scale(1.2);
        svg {
            fill: white;
        }
    }
`);

export const IconClose = styled(IconClose2)`
    fill: rgba(255, 255, 255, 0.5);
    transition: fill 200ms ease-out;
    pointer-events: auto;
`;

export const HelperContent = styled.div`
    text-align: justify;
    font-weight: normal;
`;

export const HelperBottom = styled.HelperLine`
    justify-content: flex-end;
    font-size: 0.8rem;
    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

export const NextButton = withFocusable(styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    outline: none;
    color: ${Theme.COLORS.PRIMARY_L2};
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
    pointer-events: auto;
    transition: transform 200ms ease-out;

    &:hover,
    &:focus {
        transform: scale(1.1);
    }
`);

export const IconNext = styled(Next)`
    margin-left: 5px;
    height: 0.8rem;
    fill: ${Theme.COLORS.PRIMARY_L2};
`;
