import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 1rem 1rem 0 1rem;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    box-sizing: border-box;
`;

export const ImageCover = styled.div.attrs((props) => ({
    style: {
        backgroundImage: `url(${props.srcImage})`,
        ...props.style,
    },
}))`
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 150px;
`;

export const ImageSquare = styled.div.attrs((props) => ({
    style: {
        backgroundImage: `url(${props.srcImage})`,
        ...props.style,
    },
}))`
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 25px;
    width: 30%;
    padding-bottom: 30%;
`;

export const ImageSquarePlaceholder = styled.div`
    border-radius: 25px;
    width: 30%;
    padding-bottom: 30%;
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
`;

export const ImageSquarePlaceholderName = styled.div`
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    padding: 0 5px;
`;
