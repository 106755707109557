import { tv } from 'app/device';
import styled, { css } from 'styled-components';

export const BoxContent = styled.div`
    margin: 25px 0 20px 0;
    width: 100%;

    ${tv(css`
        margin: 50px 0 45px 0;
    `)}
`;
