import KeyboardListener from './KeyboardListener';
import GamepadListener from './GamepadListener';

const keyboardListener = new KeyboardListener();
const gamepadListener = new GamepadListener();
const inputDevices = [keyboardListener, gamepadListener];

export const getNumberOfGamepads = () => gamepadListener.Count();

// the input dispatcher contains an array of layers
// each layer is an object containing entries for different types of actions
// each action-type entry contains an array of callback slots
// example:
// layers = [
//     {
//         left: [ { id: 1, callback: () => {} }, { id: 2, callback: () => {} } ],
//         right: [ { id: 3, callback: () => {} } ],
//         back: [ { id: 4, callback: () => {} } ],
//     },
//     {
//         back: [ { id: 5, callback: () => {} } ],
//     },
//]
//
// when an action is dispatched, all callbacks for that action in the *last*
// layer that has callbacks for that action are called
//
// callbacks can be updated for a given ID - callbacks are uniquely identified
// by the layer index * action * id combination

// it is possible to set a global input callback that is called first on
// any input. If that callback returns true, then the callbacks specific to
// that input are not executed
let globalInputCallback = undefined;
export const setGlobalnputCallback = (callback) => {
    globalInputCallback = callback;
};

const layers = [];

// sets or updates the callback for the given layer, action and id
export const setCallback = (layerIndex, action, id, callback) => {
    // find or create layer
    layers[layerIndex] = layers[layerIndex] || {};
    const layer = layers[layerIndex];

    // find or create actions in that layer
    layer[action] = layer[action] || [];
    const actions = layer[action];

    // update or create slot
    const slot = actions.find((slot) => slot.id === id);
    if (slot) {
        slot.callback = callback;
    } else {
        actions.push({ id, callback });
    }
};

// removes the callback for the given layer, action and id
export const removeCallback = (layerIndex, action, id) => {
    const layer = layers[layerIndex];
    if (!layer) {
        throw new Error('Input dispatcher: no layer at index', layerIndex);
    }

    const actions = layer[action];
    if (!actions) {
        throw new Error(
            'Input dispatcher: no actions for',
            action,
            'at index',
            layerIndex
        );
    }

    const slotIndex = actions.findIndex((slot) => slot.id === id);
    if (slotIndex < 0) {
        throw new Error(
            'Input dispatcher: no slot with id',
            id,
            'for',
            action,
            'at index',
            layerIndex
        );
    }

    actions.splice(slotIndex, 1);
};

// dispatches the callbacks for a given action
export const dispatch = (action) => {
    // execute global callback if any, and do not run the layer callbacks if
    // it returns true
    if (globalInputCallback?.()) {
        return;
    }
    // find last layer with callbacks for that action
    for (let i = layers.length - 1; i >= 0; i--) {
        const actions = layers[i][action];
        // no actions, or all actions undefined
        if (
            !actions ||
            actions.length === 0 ||
            actions.every((slot) => !slot.callback)
        ) {
            continue;
        }
        actions.forEach((slot) => {
            slot.callback?.();
        });
        break;
    }
};

export const startInputDispatch = () => {
    inputDevices.forEach((device) => device.Start(dispatch));
};

export const stopInputDispatch = () => {
    inputDevices.forEach((device) => device.Stop());
};

// removes all layers, for unit testing purposes
export const clear = () => {
    layers.splice(0, layers.length);
};

export const debug = () => {
    console.log(JSON.stringify(layers));
};
