import { PrimaryButton } from 'components/ui';
import { formatToHour, If } from 'helpers';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeBox } from '../range-box/TimeBox';
import * as S from './WeekRange.style';

export function WeekRange({ enabled, range, setShowOverlay }) {
    const { t } = useTranslation();
    const [showRangeBox, setShowRangeBox] = useState(false);
    const lastFocused = useRef();

    const onEditClick = () => {
        if (!enabled) {
            return;
        }
        lastFocused.current = document.activeElement;
        setShowRangeBox(true);
        setShowOverlay(true);
    };

    const onCloseRangeBox = () => {
        setShowOverlay(false);
        setShowRangeBox(false);
    };

    return (
        <>
            <If condition={showRangeBox}>
                <TimeBox
                    range={range}
                    onValidate={onCloseRangeBox}
                    onReset={() => {
                        range.reset();
                    }}
                    lastFocusedRef={lastFocused}
                    title={t('profile.parental_control.maximum_range')}
                />
            </If>

            <S.WeekRange>
                {t('profile.parental_control.max_range')}
                <S.HoursRangeDisplay>
                    {formatToHour(range.start, true)} {' > '}
                    {formatToHour(range.end, true)}
                </S.HoursRangeDisplay>
                <PrimaryButton onClick={onEditClick} disabled={!enabled}>
                    {t('buttons.edit')}
                </PrimaryButton>
            </S.WeekRange>
        </>
    );
}
