import { LoadingButton, PrimaryButton, RegularButton } from 'components/ui';
import { SpatialNavProvider, withInputDispatcherProvider } from 'context';
import { If, splitLines } from 'helpers';
import { useBackPress, useDownPress, useEula, useUpPress } from 'hooks';
import { Fragment, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import * as S from './Eula.style';

export const Eula = withInputDispatcherProvider(
    ({ game, onClose, onApproved, readOnly }) => {
        const ref = useRef();
        const { t } = useTranslation();
        const { isLoading, isApproving, onAccept, eulaText } = useEula({
            game,
            onError: onClose,
            onApproved,
        });

        useUpPress(() => (ref.current.scrollTop -= 60));
        useDownPress(() => (ref.current.scrollTop += 60));
        useBackPress(onClose);

        return createPortal(
            <SpatialNavProvider>
                <S.Wrapper>
                    <S.Box>
                        <If condition={isLoading}>
                            <S.Spinner />
                        </If>
                        <If condition={!isLoading}>
                            <S.Text ref={ref}>
                                <h1>{game.assets.title}</h1>
                                <S.FormatEula>
                                    {splitLines(eulaText).map((e, i) => (
                                        <Fragment key={i}>
                                            {e}
                                            <br />
                                        </Fragment>
                                    ))}
                                </S.FormatEula>
                            </S.Text>
                            <S.Buttons>
                                <If condition={readOnly}>
                                    <PrimaryButton
                                        focusOnMount
                                        onClick={onClose}
                                    >
                                        {t('buttons.ok')}
                                    </PrimaryButton>
                                </If>
                                <If condition={!readOnly}>
                                    <LoadingButton
                                        isLoading={isApproving}
                                        focusOnMount
                                        button={PrimaryButton}
                                        loadingText={t('eula.approving')}
                                        onClick={onAccept}
                                    >
                                        {t('buttons.accept')}
                                    </LoadingButton>
                                    <RegularButton onClick={onClose}>
                                        {t('buttons.decline')}
                                    </RegularButton>
                                </If>
                            </S.Buttons>
                        </If>
                    </S.Box>
                </S.Wrapper>
            </SpatialNavProvider>,
            document.body
        );
    }
);
