import { formatToHour, If } from 'helpers';
import { useHoursRange } from 'hooks/parental-control';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimeBox } from '../../range-box/TimeBox';
import * as S from './TimeBar.style';

export const TimeBar = ({
    enabled,
    first,
    range,
    maxRange,
    onRangeChange,
    setShowOverlay,
    ...props
}) => {
    const lastFocused = useRef();
    const timeBarTickWrapper = useRef();
    const timeBarRef = useRef();
    const editableRange = useHoursRange(range, maxRange); // local editable range for the timebar, constrained by the max range
    const [editing, setEditing] = useState(false);
    const [shouldCropHours, setShouldCropHours] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        setShouldCropHours(maxRange.end > 12);
    }, [maxRange]);

    const startEdition = () => {
        if (enabled && !editing) {
            setShowOverlay(true);
            lastFocused.current = document.activeElement;
            setEditing(true);
        }
    };

    const stopEdition = () => {
        setShowOverlay(false);
        setEditing(false);
    };

    const validateEdition = () => {
        const { start, end } = editableRange;
        onRangeChange({ start, end });
        stopEdition();
    };

    const resetRange = () => {
        editableRange.reset();
    };

    // the user is allowed to define a daily range larger than the maximum one
    // clip the display to avoid overflow of the timebar
    const displayRange = useMemo(() => {
        return {
            start: Math.max(maxRange.start, editableRange.start),
            end: Math.min(maxRange.end, editableRange.end),
        };
    }, [maxRange.start, maxRange.end, editableRange.end, editableRange.start]);

    const resizeTimebars = useCallback(() => {
        let debug = false;

        if (!timeBarTickWrapper.current) return;

        const targetRect = timeBarTickWrapper.current.getBoundingClientRect();

        let tickStartElRect, leftPos;
        let tickStartEl = timeBarTickWrapper.current.querySelector(
            `[data-hr="${displayRange.start.toString()}"]`
        );

        if (tickStartEl) {
            tickStartElRect = tickStartEl.getBoundingClientRect();
            leftPos = tickStartElRect.left - targetRect.left;
            if (debug)
                console.log(
                    'leftPos',
                    leftPos,
                    'tickStartElRect.left',
                    tickStartElRect.left,
                    'targetRect.left',
                    targetRect.left
                );
        } else {
            tickStartEl = timeBarTickWrapper.current.querySelector(
                `[data-hr="${(displayRange.start + 1).toString()}"]`
            );
            if (tickStartEl) {
                tickStartElRect = tickStartEl.getBoundingClientRect();
                leftPos = Math.round(
                    tickStartElRect.left -
                        tickStartElRect.width -
                        targetRect.left
                );
            }
        }

        let tickEndElRect, rightPos;
        let tickEndEl = timeBarTickWrapper.current.querySelector(
            `[data-hr="${displayRange.end.toString()}"]`
        );

        if (debug) console.group('tickEndEl');

        if (tickEndEl) {
            tickEndElRect = tickEndEl.getBoundingClientRect();
            rightPos = Math.round(tickEndElRect.right - targetRect.right);
            if (debug)
                console.log(
                    'rightPos',
                    rightPos,
                    'tickEndElRect.right',
                    tickEndElRect.right,
                    'targetRect.right',
                    targetRect.right
                );
        } else {
            tickEndEl = timeBarTickWrapper.current.querySelector(
                `[data-hr="${(displayRange.end - 1).toString()}"]`
            );
            if (tickEndEl) {
                tickEndElRect = tickEndEl.getBoundingClientRect();
                rightPos =
                    tickEndElRect.right +
                    tickEndElRect.width -
                    targetRect.right;
            }
            if (debug)
                console.log(
                    'rightPos',
                    rightPos,
                    'tickEndElRect.right',
                    tickEndElRect.right,
                    'targetRect.right',
                    targetRect.right
                );
        }
        if (debug) console.groupEnd();

        timeBarRef.current.style.left = `${leftPos}px`;
        timeBarRef.current.style.right = `${-rightPos}px`;
    }, [displayRange]);

    useEffect(() => {
        window.addEventListener('resize', resizeTimebars);
        resizeTimebars();

        return () => window.removeEventListener('resize', resizeTimebars);
    }, [resizeTimebars]);

    return (
        <>
            <If condition={editing}>
                <TimeBox
                    range={editableRange}
                    onValidate={validateEdition}
                    onReset={resetRange}
                    lastFocusedRef={lastFocused}
                    title={t('profile.parental_control.maximum_range')}
                />
            </If>
            <S.TimeBarWrapper
                {...props}
                onClick={startEdition}
                editing={editing}
            >
                <S.MaxRangeStart>
                    <div>{formatToHour(maxRange.start)}</div>
                </S.MaxRangeStart>
                <S.TimeBarTicks ref={timeBarTickWrapper}>
                    {Array.from(
                        { length: maxRange.end - maxRange.start + 1 },
                        (r, i) => maxRange.start + i
                    ).map((r) => {
                        // direct display of all time cues
                        if (!shouldCropHours) {
                            return (
                                <S.TimeTick data-hr={r} key={r}>
                                    {formatToHour(r)}
                                </S.TimeTick>
                            );
                        }
                        // full max range: show time cues on first, last and even hours and separators
                        if (
                            displayRange.start === maxRange.start &&
                            displayRange.end === maxRange.end
                        ) {
                            if (
                                r % 2 === 0 ||
                                r === maxRange.start ||
                                r === maxRange.end
                            ) {
                                return (
                                    <S.TimeTick data-hr={r} key={r}>
                                        {formatToHour(r)}
                                    </S.TimeTick>
                                );
                            }
                            return <S.TimeTickSplit data-hr={r} key={r} />;
                        }
                        // part of max range : show only start and end cues
                        if (
                            r === displayRange.start ||
                            r === displayRange.end
                        ) {
                            return (
                                <S.TimeTick data-hr={r} key={r}>
                                    {formatToHour(r)}
                                </S.TimeTick>
                            );
                        }
                        // empty placeholder for 'hidden' cues
                        return <S.HiddenTimeTick key={r} />;
                    })}
                </S.TimeBarTicks>
                <S.TimeBar ref={timeBarRef} data-type="TimeBar" />
                <S.MaxRangeEnd>
                    <div>{formatToHour(maxRange.end)}</div>
                </S.MaxRangeEnd>
            </S.TimeBarWrapper>
        </>
    );
};
