import { desktopSmall, mobile } from 'app/device';
import styled, { css } from 'styled-components';

export const MarketingWindowWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-height: 55vh;
    position: absolute;
    bottom: 0;

    ${mobile(css`
        height: 100%;
        max-height: 70vh;
    `)}

    ${desktopSmall(css`
        height: 100%;
        max-height: 70vh;
    `)}
`;
