import { useEffect, useRef } from 'react';

export function useTransitionComplete(completeCallback, deps) {
    const ref = useRef();

    useEffect(() => {
        const node = ref.current;

        node.addEventListener('transitionend', completeCallback);

        return () =>
            node.removeEventListener('transitionend', completeCallback);
        // FIXME: this one is plain weird, and the use in Menu has some ugly loop where the callback
        // provided to useTransitionComplete acxtually uses the ref *returned* by useTransitionComplete
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps || []);

    return ref;
}
