import { IconClose2 } from 'components/ui';
import styled from 'styled-components';
import { withFocusable } from 'helpers';
import { NavButton } from 'components/ui/buttons';

export const ContentWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CloseButton = styled(NavButton)`
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 50%;
`;

export const IconClose = styled(IconClose2)`
    fill: rgba(255, 255, 255, 0.3);
`;

export const CloseWrapper = withFocusable(styled.div`
    width: 50px;
    height: 50px;
    position: absolute;
    top: 5px;
    right: 5px;
`);
