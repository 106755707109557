import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BlurBox } from '../blur-box';
import { IconInfo } from '../icons';

const Wrapper = styled(BlurBox)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-top: 1rem;
    max-width: 90%;
`;

export const NetworkWarning = () => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <IconInfo fill="#fff" style={{ marginRight: '1rem' }} />
            {t('warnings.network')}
        </Wrapper>
    );
};
