import { isMobile } from 'app/device';
import { ExpandButton } from 'components/ui/buttons';
import { If, splitLines } from 'helpers';
import { Fragment } from 'react';
import Truncate from 'react-truncate';
import { GameHeading } from '..';
import * as S from './GameDescriptionView.style';

export function GameDescriptionView({
    game,
    minimized,
    onExpandClicked,
    isCurrent,
}) {
    const description = splitLines(game.assets.description).map((e, i) => (
        <Fragment key={i}>
            {e}
            <br />
        </Fragment>
    ));

    // mobile has a smaller line-height and can accommodate more lines
    // desktop requires less lines because it is wider
    const truncatedLines = isMobile ? 7 : 5;

    return (
        <S.Wrapper>
            <GameHeading
                game={game}
                minimized={minimized}
                isCurrent={isCurrent}
            />
            <If condition={minimized}>
                <S.DescriptionWrapperMinimized>
                    <Truncate lines={truncatedLines}>{description}</Truncate>
                    <S.ExpandWrapper>
                        <ExpandButton
                            disableMoveRight={true}
                            onClick={(e) => onExpandClicked(e, game.alias)}
                        />
                    </S.ExpandWrapper>
                </S.DescriptionWrapperMinimized>
            </If>

            <If condition={!minimized}>
                {/* use key to force recreation of scrollview when contents change */}
                <S.ScrollView key={game.alias}>{description}</S.ScrollView>
            </If>
        </S.Wrapper>
    );
}
