import { useCallback, useEffect, useRef, useState } from 'react';

export function useTimedCallback() {
    // interval and timedout unit = seconds
    const [intervalTick, setIntervalTick] = useState();
    const intervalRef = useRef();
    const callbackRef = useRef();

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);

    const createTimedCallback = useCallback((timedout, callback) => {
        callbackRef.current = callback;
        setIntervalTick(timedout);
        intervalRef.current = setInterval(() => {
            setIntervalTick((prevTick) => prevTick - 1);
        }, 1000);
    }, []);

    const clearTimedCallback = useCallback(() => {
        callbackRef.current = null;
        clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
        if (intervalTick === 0) {
            callbackRef.current();
            clearTimedCallback();
        }
    }, [intervalTick, clearTimedCallback]);

    return {
        intervalTick,
        setIntervalTick,
        clearTimedCallback,
        createTimedCallback,
        intervalRef,
    };
}
