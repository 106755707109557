import styled from 'styled-components';
import { BlurBox } from 'components/ui';

export const Wrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;

export const Content = styled(BlurBox)`
    min-width: 350px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.div``;

export const HoursWrapper = styled.div`
    margin: 1.5rem 0;
`;
