import { useEffect, useState } from 'react';

export function useBottomButtons(wrapperToHideRef) {
    const [noAccountVisible, setNoAccountVisible] = useState(false);
    const [supportVisible, setSupportVisible] = useState(false);

    const showNoAccount = () => {
        setNoAccountVisible(true);
    };

    const hideNoAccount = () => {
        setNoAccountVisible(false);
    };

    const showSupport = () => {
        setSupportVisible(true);
    };

    const hideSupport = () => {
        setSupportVisible(false);
    };

    useEffect(() => {
        if (!wrapperToHideRef.current) return;

        if (noAccountVisible || supportVisible) {
            wrapperToHideRef.current.style.opacity = 0;
            wrapperToHideRef.current.style.pointerEvents = 'none';
        } else {
            wrapperToHideRef.current.style.opacity = 1;
            wrapperToHideRef.current.style.pointerEvents = 'auto';
        }
    }, [noAccountVisible, supportVisible, wrapperToHideRef]);

    return {
        showNoAccount,
        hideNoAccount,
        showSupport,
        hideSupport,
        noAccountVisible,
        supportVisible,
    };
}
