import { IconPlayer } from 'components/ui';
import If, { avatarURL } from 'helpers';
import { useTranslation } from 'react-i18next';
import * as S from './Player.style';

export function Player({ player, isOwner, index, lobbyConnected }) {
    const { t } = useTranslation();

    return (
        <S.Wrapper>
            <If condition={lobbyConnected}>
                <If condition={player.avatar}>
                    <S.PlayerAvatar avatarUrl={avatarURL(player.avatar)} />
                </If>
                <If condition={!player.avatar}>
                    <S.PlayerIcon>
                        <S.PlayerIndex>{index + 1}</S.PlayerIndex>
                        <IconPlayer />
                    </S.PlayerIcon>
                </If>
            </If>
            <If condition={!lobbyConnected}>
                <S.PlayerIcon searching={!player.nickname}>
                    <S.PlayerIndex>{index + 1}</S.PlayerIndex>
                    <IconPlayer />
                </S.PlayerIcon>
            </If>
            <S.NameWrapper>
                <S.Name lobbyConnected={lobbyConnected}>
                    {player.nickname || t('games.searching')}
                </S.Name>
                {isOwner && <S.MasterCrown />}
            </S.NameWrapper>
        </S.Wrapper>
    );
}
