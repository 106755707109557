import Theme from 'app/theme';
import { withFocusable } from 'helpers';
import styled from 'styled-components';

const sliderHeight = '30px';

export const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 50px;
    height: ${() => sliderHeight};
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    transition: border-color 250ms ease-out;
    min-width: ${(props) => props.sliderMinWidth};
`;

export const SliderInput = withFocusable(styled.input`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: ${() => sliderHeight};
    opacity: 0.7;
    border-radius: 50px;
    background-color: rgba(255, 255, 255, 0.25);
    box-sizing: border-box;
    cursor: pointer;
    appearance: none;
    outline: none;

    /* thumb settings *have* to be set separately for moz and webkit */
    &::-moz-range-thumb {
        background: transparent;
        border-color: transparent;
    }
    &::-webkit-slider-thumb {
        -webkit-appearance: none; /* necessary for Chrome and Edge */
        height: 0; /* necessary for Safari */
        width: 0;
    }

    /* ContentWrapper color */
    & ~ div {
        color: rgba(0, 0, 0, 0.75);
    }
    &:focus ~ div,
    &:hover ~ div {
        color: white;
    }

    /* Icon color */
    & ~ div svg {
        fill: black;
        transition: none !important;
    }
    &:focus ~ div svg,
    &:hover ~ div svg {
        fill: white;
        transition: none !important;
    }
`);

export const SliderDisplay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: ${(props) => `${props.value}%`};
    height: ${() => sliderHeight};
    border-radius: 50px;
    transition: background-color 250ms ease-out;

    background-color: ${(props) =>
        props.isDisabled
            ? 'rgba(255, 255, 255, 0.5)'
            : Theme.COLORS.PRIMARY_L1};

    input:focus + &,
    input:hover + & {
        background-color: ${(props) =>
            props.isDisabled
                ? 'rgba(255, 255, 255, 0.5)'
                : Theme.COLORS.PRIMARY_L2};
    }

    pointer-events: none;
`;

export const ContentWrapper = styled.div`
    pointer-events: none;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const IconWrapper = styled.div`
    height: 25px;
    width: 25px;
    margin: 0 1rem 0 1rem;
`;

export const SliderValue = styled.div`
    pointer-events: none;
    font-size: 1rem;
`;
