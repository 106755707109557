import { useSoundAction } from 'hooks';
import { forwardRef } from 'react';
import * as S from './SoundButton.style';

export const SoundButton = forwardRef(
    ({ onClick, sound, children, ...props }, forwardedRef) => {
        const onClickSound = useSoundAction(sound, onClick);

        return (
            <S.SoundButton {...props} ref={forwardedRef} onClick={onClickSound}>
                {children}
            </S.SoundButton>
        );
    }
);
SoundButton.displayname = 'SoundButton';
