import history from 'app/history';
import store from 'app/store';
import React from 'react';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import App from './App';
import { RegisterServiceWorker } from './sw-register';

RegisterServiceWorker();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router history={history}>
                <App />
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// disable react-dev-tools in production
if (process.env.NODE_ENV === 'production') {
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
        for (let [key, value] of Object.entries(
            window.__REACT_DEVTOOLS_GLOBAL_HOOK__
        )) {
            window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] =
                typeof value == 'function' ? () => {} : null;
        }
    }
}
