import React from 'react';
import * as S from './ContentRating.style';
import { useTranslation } from 'react-i18next';
import { ContentRatingSelector } from './ContentRatingSelector';
import { SpatialNavSection } from 'context';
import { FrontEndHelper } from 'services';

export const ContentRating = React.forwardRef(
    ({ defaultValue, onChange, ...props }, forwardedRef) => {
        const { t } = useTranslation();
        // display only official content ratings
        const contentRatingArray = FrontEndHelper.GetOfficialContentRating();

        return (
            <SpatialNavSection enterTo="last-focused">
                <S.Wrapper>
                    <S.Text>{t('profile.content_rating_text')}</S.Text>
                    <S.SubWrapper>
                        <ContentRatingSelector
                            focusOnMount={props.focusOnMount}
                            ref={forwardedRef}
                            defaultValue={defaultValue}
                            onChange={onChange}
                            contentRatingArray={contentRatingArray}
                        />
                    </S.SubWrapper>
                </S.Wrapper>
            </SpatialNavSection>
        );
    }
);
