import { useLayoutEffect, useRef, useState } from 'react';
import { isTV } from 'app/device';

export const useAutoScroll = (scrollContentRef) => {
    const [scrollState, setScrollState] = useState('top');
    const scrollTimer = useRef(0);

    // auto-scroll for TV mode
    useLayoutEffect(() => {
        const scrollContent = scrollContentRef.current;
        if (
            !isTV || // autoscroll is only on TV
            !scrollContent ||
            scrollContent.scrollHeight <= scrollContent.clientHeight // nothing to scroll
        ) {
            return;
        }

        // wait time at top and bottom until scrolling resumes
        const endTimeout = 3000;
        // eslint-disable-next-line default-case
        switch (scrollState) {
            case 'top': {
                scrollTimer.current = setTimeout(() => {
                    setScrollState('down');
                }, endTimeout);
                break;
            }
            case 'down': {
                // scroll down rate is 1 pixel per second
                const stepsPerSecond = 26;
                const scrollTimeout = Math.round(1000 / stepsPerSecond);
                const downPixelsPerStep = 1;
                scrollTimer.current = setInterval(() => {
                    if (
                        scrollContent.scrollHeight - scrollContent.scrollTop ===
                        scrollContent.clientHeight
                    ) {
                        setScrollState('bottom');
                    } else {
                        scrollContent.scrollTop += downPixelsPerStep;
                    }
                }, scrollTimeout);
                break;
            }
            case 'bottom': {
                scrollTimer.current = setTimeout(() => {
                    setScrollState('up');
                }, endTimeout);
                break;
            }
            case 'up': {
                // up rate is 1 second to move back to the top in 52 steps for smooth scrolling
                const stepsPerSecond = 52;
                const nbPixelsToScroll =
                    scrollContent.scrollHeight - scrollContent.clientHeight;
                const scrollTimeout = Math.round(1000 / stepsPerSecond);
                const upPixelsPerStep = nbPixelsToScroll / stepsPerSecond;

                scrollTimer.current = setInterval(() => {
                    if (scrollContent.scrollTop <= 0) {
                        setScrollState('top');
                    } else {
                        scrollContent.scrollTop -= upPixelsPerStep;
                    }
                }, scrollTimeout);
                break;
            }
        }
        return () => clearInterval(scrollTimer.current);
    }, [scrollContentRef, scrollState]);
};
