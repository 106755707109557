import { Wrapper as WrapperBase } from 'components/login/remember-me/RememberMe.style';
import { MessageWrapper as MessageWrapperBase } from 'components/ui/message-box/MessageBox.style';
import styled from 'styled-components';
export { Text } from 'components/login/remember-me/RememberMe.style';

export const MessageWrapper = styled(MessageWrapperBase)`
    max-width: 500px;
    text-align: center;
`;

export const Wrapper = styled(WrapperBase)`
    margin-bottom: 16px;
`;
