import Theme from 'app/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
    align-items: center;
    height: ${(props) => (props.vertical ? '100%' : 'auto')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

    & > div:not(:last-of-type) {
        margin-right: ${(props) => {
            if (!props.vertical) return '1rem';
        }};

        margin-bottom: ${(props) => {
            if (props.vertical) return '1rem';
        }};
    }
`;

export const Tick = styled.div`
    margin-left: 0.5rem;
    background-color: white;
    color: ${Theme.COLORS.PRIMARY_L2};
    padding: 2px 5px;
    border-radius: 25px;
    font-size: 0.75rem;
`;
