import { mobile } from 'app/device';
import Theme from 'app/theme';
import { BlurBox } from 'components/ui';
import styled, { css } from 'styled-components';

export const videoStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
};

export const Wrapper = styled(BlurBox)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

export const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
`;

export const LeftPanel = styled.div`
    width: calc((100% / 3));
`;

export const CameraStream = styled.div`
    border: 1px solid ${Theme.COLORS.PRIMARY_L1};
    border-radius: 20px;
    width: 75%;
    margin: 0 auto;
    padding-bottom: 75%;
    overflow: hidden;
    position: relative;
`;

export const RightPanel = styled.div`
    /* width: calc((100% / 3) * 2); */
    width: 100%;
`;

export const RowWrapper = styled.div`
    display: flex;
    align-items: center;

    > div {
        margin-right: 1rem;
    }
`;

export const RowTitle = styled.div`
    width: 100%;
    margin-bottom: 0.5rem;
`;

export const Label = styled.div`
    font-size: 1rem;
`;

export const SliderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;

    ${Label} {
        margin-right: 0.5rem;
    }
`;

export const SliderMinWidth = '300px';

export const CheckboxWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ${Label} {
        margin-left: 0.5rem;
    }
`;

export const CheckboxLabel = styled.div`
    margin-left: 1rem;
    width: 25%;
`;

const SettingWrapperBase = styled.div`
    width: 100%;
    display: flex;
`;

export const SettingCol = styled(SettingWrapperBase)`
    flex-direction: column;
`;

export const SettingRow = styled(SettingWrapperBase)`
    display: flex;
    flex-direction: column;

    > label {
        display: block;
    }

    &:not(:last-of-type) {
        margin-bottom: 1.5rem;

        ${mobile(css`
            margin-bottom: 1rem;
        `)}
    }
`;

export const InnerRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SettingTitle = styled.div`
    margin-bottom: 0.5rem;
`;
