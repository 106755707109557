import { usePreLoader } from 'hooks';
import { useDispatch } from 'react-redux';
import { hideGlobalLoader, showGlobalLoader, MaintenanceStatus } from 'slices';
import { useEffect } from 'react';
import { MaintenanceScreen } from 'screens';

export function Preloader({ children }) {
    const { isLoading, maintenanceStatus } = usePreLoader();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoading) {
            dispatch(showGlobalLoader());
        } else {
            dispatch(hideGlobalLoader());
        }
    }, [dispatch, isLoading]);

    // loading...
    if (isLoading) {
        return null;
    }

    // full maintenance
    if (maintenanceStatus === MaintenanceStatus.MAINTENANCE) {
        return <MaintenanceScreen />;
    }

    // normal
    return <>{children}</>;
}
