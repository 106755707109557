import styled from 'styled-components';

export const Wrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    z-index: 1001;

    embed {
        width: ${(props) => (props.showConsole ? '50%' : '100%')};
        height: 100%;
    }

    > div {
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 50%;
        background-color: black;
        color: white;
        font-family: monospace;
        display: flex;
        flex-direction: row;
        flex-grow: 15;
        overflow-y: auto;
        user-select: text;
    }
`;
