import styled from 'styled-components';
import THEME from 'app/theme';

export const RegisterUrl = styled.a`
    text-decoration: underline;
    color: ${THEME.COLORS.BLUE_L3};
    cursor: pointer;
    /* margin-bottom: 1.5rem; */
`;

export const QrCode = styled.div`
    margin-top: 1rem;
    background-color: white;
    padding: 2rem;
    border-radius: 20px;
`;

export const InstructionsWrapper = styled.div`
    margin-top: 2rem;
    text-align: center;
    color: ${THEME.COLORS.GRAY_L3};
`;
