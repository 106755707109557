import { mobile, mobileSmall } from 'app/device';
import styled, { css } from 'styled-components';
import { Wrapper as ActionButtonsWrapperRoot } from 'components/ui/buttons/action-buttons/ActionButtons.style';

export const ContentWrapper = styled.div`
    padding: 0rem 2rem 2rem 2rem;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    ${mobile(css`
        padding: 0rem 1rem 1rem 1rem;
    `)}
`;

export const Title = styled.div`
    font-size: 1.75rem;
    margin: 0.5rem 0;
    font-weight: bold;

    ${mobile(css`
        font-size: 1.25rem;
    `)}
`;

export const Description = styled.div`
    font-size: 1.25rem;
    text-align: justify;

    ${mobile(css`
        max-height: 20vh;
        font-size: 1rem;
    `)};

    ${mobileSmall(css`
        max-height: 200px;
    `)};
`;

export const ActionButtonsWrapper = styled(ActionButtonsWrapperRoot)`
    margin-top: 2rem;

    ${mobile(css`
        margin-top: 1rem;
    `)}
`;
