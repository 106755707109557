import { styled } from 'styled-components';
import { withFocusable } from 'helpers';
import Theme from 'app/theme';

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Hours = styled.div`
    font-size: 2rem;
    margin-left: 1rem;
`;

const SignBase = withFocusable(styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    font-size: 2rem;
    padding: 5px;
    border-radius: 50%;
    border: 2px solid white;
    cursor: pointer;
    transition: background-color 250ms ease-out, color 250ms ease-out,
        border 250ms ease-out;

    &:hover {
        border: 2px solid ${Theme.COLORS.PRIMARY_L1};
    }

    &:focus {
        outline: none;
        background-color: ${Theme.COLORS.PRIMARY_L2};
        border: 2px solid ${Theme.COLORS.PRIMARY_L2};
    }
`);

export const Plus = styled(SignBase)``;

export const Minus = styled(SignBase)`
    margin-right: 10px;
`;
