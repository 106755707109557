import { default as lBtA } from 'cgxwebclient/static/images/virtualpad/virtualpad_bt_a.png';
import { default as lBtB } from 'cgxwebclient/static/images/virtualpad/virtualpad_bt_b.png';
import { default as lBtX } from 'cgxwebclient/static/images/virtualpad/virtualpad_bt_x.png';
import { default as lBtY } from 'cgxwebclient/static/images/virtualpad/virtualpad_bt_y.png';
import { default as lBtStart } from 'cgxwebclient/static/images/virtualpad/virtualpad_bt_start.png';
import { default as lBtSelect } from 'cgxwebclient/static/images/virtualpad/virtualpad_bt_select.png';
import { default as lBtL } from 'cgxwebclient/static/images/virtualpad/virtualpad_bt_l.png';
import { default as lBtR } from 'cgxwebclient/static/images/virtualpad/virtualpad_bt_r.png';
import { default as lBtL3 } from 'cgxwebclient/static/images/virtualpad/virtualpad_bt_l3.png';
import { default as lBtR3 } from 'cgxwebclient/static/images/virtualpad/virtualpad_bt_r3.png';
import { default as lPadD } from 'cgxwebclient/static/images/virtualpad/virtualpad_pad_d.png';
import { default as lPadL } from 'cgxwebclient/static/images/virtualpad/virtualpad_pad_l.png';
import { default as lPadR } from 'cgxwebclient/static/images/virtualpad/virtualpad_pad_r.png';
import { default as lPadLBack } from 'cgxwebclient/static/images/virtualpad/virtualpad_pad_l_back.png';
import { default as lPadRBack } from 'cgxwebclient/static/images/virtualpad/virtualpad_pad_r_back.png';
import { default as lBtRTr } from 'cgxwebclient/static/images/virtualpad/virtualpad_bt_rt.png';
import { default as lBtLTr } from 'cgxwebclient/static/images/virtualpad/virtualpad_bt_lt.png';
import { default as shadow } from 'cgxwebclient/static/images/virtualpad/virtualpad_shadow.png';

// use field virtual-button-set from SGX config file to determine which set to use
// missing values should fallback to the top-level defaults
export const buttonSets = {
    // TODO: replace with final assets when available - either directly in mew of from
    // webclient bundle
    xboxOne: {
        lBtA,
        lBtB,
        lBtX,
        lBtY,
        lBtStart,
        lBtSelect,
        lBtL,
        lBtR,
        lBtL3,
        lBtR3,
        lPadD,
        lPadL,
        lPadR,
        lPadLBack,
        lPadRBack,
        lBtRTr,
        lBtLTr,
        shadow,
    },
    xbox360: {
        // lBtA: ...
        // etc
    },
    ps4: {},
    ps5: {},
};

// default assets to xbox One
export default buttonSets.xboxOne;
