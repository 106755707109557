import { HourInput } from 'components/parental-control/hours-range/hour-input/HourInput';
import * as S from 'components/parental-control/hours-range/HoursRange.style';
import { withInputDispatcherProvider } from 'context';
import { useBackPress, useLeftPress, useRightPress } from 'hooks';
import { useCallback, useEffect, useState } from 'react';

export const TimeAmount = withInputDispatcherProvider(
    ({
        enabled,
        amount,
        onAmountChange,
        onEditingChange,
        format,
        ...props
    }) => {
        const [editing, setEditing] = useState(false);

        // notify parent of change in edition state
        useEffect(() => {
            onEditingChange?.(editing);
        }, [editing, onEditingChange]);

        const maxAmount = 23;

        const decrement = useCallback(() => {
            if (amount === Infinity) {
                onAmountChange(maxAmount);
            } else if (amount === 0) {
                onAmountChange(Infinity);
            } else {
                onAmountChange(amount - 1);
            }
        }, [amount, onAmountChange]);

        const increment = useCallback(() => {
            if (amount === Infinity) {
                onAmountChange(0);
            } else if (amount === maxAmount) {
                onAmountChange(Infinity);
            } else {
                onAmountChange(amount + 1);
            }
        }, [amount, onAmountChange]);

        useLeftPress(editing ? decrement : undefined);
        useRightPress(editing ? increment : undefined);

        // backpress is active only while editing
        const toggleEditing = useCallback(() => {
            setEditing((prevState) => !prevState);
        }, []);
        useBackPress(editing ? toggleEditing : undefined);

        return (
            <HourInput
                format={format}
                editing={editing}
                visible={enabled}
                value={amount}
                increment={increment}
                decrement={decrement}
                onClick={toggleEditing}
                disableMoveUp={editing}
                disableMoveDown={editing}
                WrapperComponent={S.Hour}
                InputComponent={S.TimeInput}
                {...props}
                style={{ height: '100%' }}
            />
        );
    }
);
