import React from 'react';

export const IconLocal = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.97 21.98">
            <g id="Layer_2" data-name="Layer 2">
                <g id="PEOPLE">
                    <g id="Couch_TV_-_2_People" data-name="Couch TV - 2 People">
                        <path d="M13.5,22H.33c-.3,0-.33,0-.33-.34,0-.6,0-1.19,0-1.79a3.22,3.22,0,0,1,.75-2,3.37,3.37,0,0,1,2.12-1.24,3.79,3.79,0,0,1,.58-.05c6.7,0,13.39,0,20.09,0a3.28,3.28,0,0,1,2.71,1.35,3.22,3.22,0,0,1,.7,2c0,.61,0,1.21,0,1.81,0,.24,0,.25-.25.25H13.5Z" />
                        <path d="M13.51,0H23.64C24,0,24,0,24,.32v11a.4.4,0,0,0,0,.11c0,.14-.05.18-.19.18H21.9c-.12,0-.16,0-.16-.17a8.48,8.48,0,0,0-.06-.87c0-.21,0-.22.19-.22h.58c.25,0,.25,0,.25-.24V1.52c0-.23,0-.23-.23-.23H4.56c-.23,0-.23,0-.23.22v8.63c0,.22,0,.22.23.22H5.2c.17,0,.17,0,.15.16,0,.32,0,.64-.06,1,0,.11,0,.14-.13.14H3.22c-.1,0-.15,0-.15-.15s0-.4,0-.61V.3a.48.48,0,0,0,0-.12c0-.13.06-.19.19-.18H13.51Z" />
                        <path d="M19.79,13.56c.22.08.42.14.61.23A3.84,3.84,0,0,1,22,15a3.24,3.24,0,0,1,.28.47c.06.12,0,.15-.11.16H14.52c-.31,0-.32,0-.17-.29a3.32,3.32,0,0,1,1-1.09,4.64,4.64,0,0,1,1.31-.67l.13-.07a.58.58,0,0,0-.1-.12,2.71,2.71,0,0,1-1.1-1.77,2.61,2.61,0,0,1,.29-1.75,2.75,2.75,0,0,1,1.39-1.28,2.88,2.88,0,0,1,1.54-.16,2.77,2.77,0,0,1,1.15,5A.48.48,0,0,0,19.79,13.56Z" />
                        <path d="M7.22,13.56c-.18-.17-.37-.31-.52-.48a2.77,2.77,0,0,1-.18-3.54A2.68,2.68,0,0,1,8.4,8.45a2.91,2.91,0,0,1,1.14.09,2.51,2.51,0,0,1,1,.56,2.79,2.79,0,0,1,.94,1.66,2.74,2.74,0,0,1-.3,1.75,2.59,2.59,0,0,1-.81.93c-.13.08-.12.12,0,.18s.58.23.85.37a3.35,3.35,0,0,1,1.46,1.45c.1.2.09.23-.15.23H4.88c-.19,0-.22,0-.13-.21a3.46,3.46,0,0,1,.72-.94,4.68,4.68,0,0,1,1.66-.92Z" />
                        <path d="M13.53,10.36h1.2c.18,0,.17,0,.16.2a8.49,8.49,0,0,0-.05.88c0,.13-.05.18-.18.18H12.37c-.13,0-.17,0-.18-.18q0-.46-.06-.93c0-.14.06-.15.15-.15h1.25Z" />
                    </g>
                </g>
            </g>
        </svg>
    );
};
