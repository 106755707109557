import { Endpoints, PrivateClient as client } from 'api';
import { avatarURL, doRequest, preloadImage, StatusCodes } from 'helpers';
import i18n from 'i18n';

class FrontEndHelper {
    constructor() {
        this.config = {};
    }

    GetConfig() {
        return this.config;
    }

    getMarketingNews() {
        return this.config.Editorials || [];
    }

    // current content rating system (PEGI, ESRB etc)
    GetContentRatingSystem() {
        const { ContentRating } = this.config;
        const ratingSystems = Object.getOwnPropertyNames(ContentRating);
        // there should be exactly one system
        if (ratingSystems.length !== 1) {
            console.error(
                `${ratingSystems.length} content ratings systems found instead of 1`,
                ratingSystems
            );
        }
        // may be undefined if none, although it's not supposed to happen
        return ratingSystems[0];
    }

    // all content rating aliases for the current system, including unofficial ones
    GetContentRating() {
        const { ContentRating } = this.config;
        return ContentRating[this.GetContentRatingSystem()] || [];
    }

    // official content rating aliases only, for the current system
    GetOfficialContentRating() {
        // an alias is official when its rated field is true, or missing entirely for backend versions < 1.6
        // "unrated" here means that there is an age evaluation but it is unofficial
        return this.GetContentRating().filter(
            (cr) => cr.rated || !cr.hasOwnProperty('rated')
        );
    }

    GetMinimumAge(contentRatingAlias) {
        let minimumAge = 999; // hide games with no rating
        this.GetContentRating().forEach((cr) => {
            if (Array.isArray(contentRatingAlias)) {
                contentRatingAlias.forEach((val) => {
                    if (val === cr.alias) minimumAge = cr.minimum_age;
                });
            } else {
                if (cr.alias === contentRatingAlias)
                    minimumAge = cr.minimum_age;
            }
        });
        return minimumAge;
    }

    GetAvatars() {
        return this.config.Avatars.map((av) => {
            return {
                alias: Object.keys(av)[0],
                file: Object.values(av)[0],
            };
        });
    }

    async Preload(promises) {
        return doRequest({
            request: client.get(Endpoints.FRONTEND_HELPER, {
                params: { language: i18n.language },
            }),

            [StatusCodes.OK]: (response) => {
                // Save config
                this.config = response.data;

                // Remove default avatar
                this.config.Avatars = this.config.Avatars.filter(
                    (avatar) => Object.keys(avatar)[0] !== 'defaultavatar'
                );

                // Preload avatars
                for (let i = 0; i < this.config.Avatars.length; i++) {
                    // Weird data structure, as always...
                    let avatar = Object.values(this.config.Avatars[i])[0];

                    // Push avatar preload promise
                    promises.push(preloadImage(avatarURL(avatar)));
                }

                return true;
            },
        });
    }
}

export default new FrontEndHelper();
