export function IconGamepad(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 51 33"
            {...props}
        >
            <path
                fill="#FAFAFA"
                fillRule="evenodd"
                d="M21.442 2.833C18.675 1.826 16.87.775 14.229.628 1.61-.073-3.69 31.564 4.784 32.345c6.09.683 10.917-8.272 13.088-9.689 2.17-1.417 13.146-1.673 15.228 0 2.082 1.673 6.999 10.372 13.088 9.69C54.661 31.562 49.464.018 36.849.627c-2.683.13-4.568 1.055-7.32 2.205-.727.304-1.974.876-4.098.876-2.123 0-3.167-.577-3.99-.876zM38.65 9.855c.999 0 1.806.793 1.806 1.769 0 .981-.807 1.774-1.806 1.774-.994 0-1.8-.793-1.8-1.774 0-.976.806-1.769 1.8-1.769zm-3.527 2.892c.994 0 1.806.793 1.806 1.774 0 .976-.812 1.769-1.806 1.769-.999 0-1.806-.793-1.806-1.769 0-.982.807-1.774 1.806-1.774zm0-5.732c.994 0 1.806.798 1.806 1.775 0 .981-.812 1.774-1.806 1.774-.999 0-1.806-.793-1.806-1.774 0-.976.807-1.775 1.806-1.775zm-3.43 2.84c1 0 1.806.793 1.806 1.769 0 .981-.807 1.774-1.806 1.774-.993 0-1.8-.793-1.8-1.774 0-.976.807-1.769 1.8-1.769zm-10.499.258v2.636h-3.351v3.304h-2.79v-3.304h-3.337v-2.636h3.336V6.844h2.791v3.269h3.351z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}
