import styled from 'styled-components';
import { BoxWrapper } from '../BoxWrapper';

export const Wrapper = styled(BoxWrapper)`
    margin-bottom: 0.5rem;
    flex-grow: 1;
    align-items: center;
`;

export const Text = styled.span`
    font-size: 1rem;
    margin-bottom: 1rem;
`;
