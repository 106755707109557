import { isTV, mobile, tablet, desktopSmall } from 'app/device';
import Theme from 'app/theme';
import { BlurBox } from 'components/ui';
import styled, { css } from 'styled-components';

export const BlurBoxWrapper = styled(BlurBox)`
    ${mobile(css`
        height: 98vh;
    `)}

    ${tablet(css`
        max-width: 98vw;
        max-height: 98vh;
    `)}

    ${desktopSmall(css`
        max-width: 98vw;
    `)}
`;

export const WelcomeMessageBase = styled.span`
    margin-top: 16px;
    margin-bottom: 32px;
    white-space: pre-line;
    text-align: center;
    color: ${Theme.COLORS.GRAY_L1};
    font-size: 1.5rem;
`;

export const WelcomeMessageResponsive = styled(WelcomeMessageBase)`
    ${mobile(css`
        margin-top: 9px;
        margin-bottom: 12px;
        font-size: 1rem;
    `)}

    ${tablet(css`
        font-size: 1rem;
        margin-bottom: 0.75rem;
    `)}
`;

export const WelcomeMessageTV = styled(WelcomeMessageBase)`
    margin-top: 28px;
    margin-bottom: 54px;
`;

export const WelcomeMessage = isTV
    ? WelcomeMessageTV
    : WelcomeMessageResponsive;
