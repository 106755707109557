import { mobile, tv } from 'app/device';
import { favChange } from 'components/games/game-item/GameItem.style';
import { BlurMenu } from 'components/ui/menu/blur';
import { BlurMenuBaseFallbackBefore } from 'components/ui/menu/blur/BlurMenuFallback.style';
import { withFocusable } from 'helpers';
import styled, { css } from 'styled-components';
import Theme from 'app/theme';
import { CursorItemBackgroundColor } from 'components/ui/menu/Menu.style';

export const Icon = withFocusable(styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 50px;
    width: 50px;
    outline: none;
    border-radius: 50%;
    transition: background-color 250ms ease-out;

    svg {
        height: 30px;
    }

    ${mobile(css`
        height: 34px;
        width: 34px;

        svg {
            height: 20px;
        }
    `)}

    ${tv(css`
        width: 58px;
        height: 58px;

        svg {
            height: 32px;
        }
    `)}
`);

export const Wrapper = styled(BlurMenu)`
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 25px;
    position: relative;

    ${mobile(css`
        height: 34px;
    `)}
    ${tv(css`
        height: 58px;
        border-radius: 30px;
    `)}

    &:hover > ${BlurMenuBaseFallbackBefore} {
        display: none;
    }
`;

export const AsideNavSelector = styled.div`
    pointer-events: none;
    border-radius: 50%;
    background-color: ${CursorItemBackgroundColor};
    height: 100%;
    width: ${(props) => `calc(100% / ${props.numberOfMenuElements})`};
    position: absolute;
    opacity: 0;
    transform: translate3d(0, 0, 0);
    transition: transform 250ms ease-out, box-shadow 250ms ease-out,
        opacity 250ms ease-out;
`;

export const SettingsIcon = styled(Icon)`
    svg#icon_settings path {
        fill: ${(props) =>
            props.isActive ? Theme.COLORS.WHITE : Theme.COLORS.GRAY_L1};
    }
`;

export const SearchIcon = styled(Icon)`
    svg path {
        fill: ${(props) =>
            props.isActive ? Theme.COLORS.WHITE : Theme.COLORS.GRAY_L1};
    }
`;

export const FavoriteIcon = styled(Icon)`
    padding-left: 6px;
    padding-right: 6px;
    position: relative;
    svg#icon_heart {
        fill: ${(props) => (props.checked ? 'white' : 'rgba(0,0,0,0.2)')};
        transition: fill 600ms ease-out;

        path {
            stroke-width: 2;
            stroke: ${(props) =>
                props.isActive ? Theme.COLORS.WHITE : Theme.COLORS.GRAY_L1};
            transition: stroke 600ms ease-out;
        }
    }

    ${mobile(css`
        padding-left: 4px;
        padding-right: 4px;
    `)}
    animation: ${favChange} 600ms
`;
