import { useSpatialNavContext } from 'context';
import { maxCols } from 'helpers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Games } from 'services';
import { useActionTopPress } from '../input-dispatch';
import { useToggleFavorite } from './useToggleFavorite';

export function useGrid(columns) {
    const gridRef = useRef();
    const gridInitialTopPosition = useRef();
    const [sliceCount, setSliceCount] = useState(0);
    const initialSliceCount = useRef(0);
    const maxSliceCount = useRef(0);
    const { refreshTree } = useSpatialNavContext();
    const { toggleGame } = useToggleFavorite();

    useActionTopPress(
        useCallback(() => {
            if (!Games.FavoritesEnabled()) {
                return;
            }

            //Test if element is grid item or not
            if (gridRef.current.contains(document.activeElement)) {
                const gameAlias = document.activeElement.dataset.alias;
                toggleGame(gameAlias);
            }
        }, [toggleGame])
    );

    // Refresh spatial nav tree rects when append more games
    useEffect(() => {
        refreshTree(true);
    }, [refreshTree, sliceCount]);

    useEffect(() => {
        // Add scroll listener
        const onScroll = () => {
            const bodyHeight = document.body.offsetHeight;
            if (!gridRef.current) return;

            const gridRect = gridRef.current.getBoundingClientRect();

            // Refresh spatial nav tree rects when we are close to the initial position of the grid
            // On slower devices, jerky scroll up may make us miss the threshold so we also have to
            // refresh when we reach the initial position exactly
            const topThreshold = 100; // pixels
            if (
                gridRect.top <= gridInitialTopPosition.current &&
                gridInitialTopPosition.current - gridRect.top <= topThreshold
            )
                refreshTree(true);

            // If we are far from 300px from the bottom of the body, increment
            // slice count
            const bottomThreshold = 300; // pixels
            const bottomDiff = gridRect.bottom - bodyHeight;
            if (bottomDiff <= bottomThreshold)
                setSliceCount((currentCount) =>
                    Math.min(currentCount + 3, maxSliceCount.current)
                );
        };
        const gamesWrapper = document.getElementById('GamesWrapper');
        gamesWrapper.addEventListener('scroll', onScroll);

        // Save grid initial top position
        gridInitialTopPosition.current =
            gridRef.current.getBoundingClientRect().top;

        // Deduce GameItem size
        const gridRect = gridRef.current.getBoundingClientRect();
        const gridVerticalSpace = document.body.offsetHeight - gridRect.top;
        const gameItemApproximateWidth = gridRect.width / maxCols;
        const gameItemApproximateHeight = gameItemApproximateWidth / 0.71; // 0.71 = aspect ratio of the vertical GameItem

        // Init Max Slice count
        columns.forEach(
            (col) =>
                (maxSliceCount.current = Math.max(
                    maxSliceCount.current,
                    col.length
                ))
        );

        // Calculate & set initial slice count
        initialSliceCount.current =
            gridVerticalSpace / gameItemApproximateHeight;
        initialSliceCount.current = Math.ceil(initialSliceCount.current + 1);
        setSliceCount(
            Math.min(initialSliceCount.current, maxSliceCount.current)
        );

        // Debug
        if (process.env.NODE_ENV === 'development') {
            console.groupCollapsed('InfiniteScroll init');
            console.log('Grid vertical space :', gridVerticalSpace);
            console.log('GameItem Approx width : ', gameItemApproximateWidth);
            console.log('GameItem Approx height : ', gameItemApproximateHeight);
            console.log('InitialSlice count : ', initialSliceCount.current);
            console.log('Max Slice count = ', maxSliceCount.current);
            console.groupEnd();
        }

        // Remove scroll listener on unmount
        return () => {
            gamesWrapper.removeEventListener('scroll', onScroll);
        };
    }, [columns, refreshTree]);

    return { gridRef, sliceCount, initialSliceCount };
}
