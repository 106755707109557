import { isMobile } from 'app/device';
import Theme from 'app/theme';
import { IconArrowLeft, IconArrowRight, NavIconButton } from 'components/ui';
import { SpatialNavSection, useSpatialNavContext } from 'context';
import { useSoundAction } from 'hooks';
import { useEffect, useRef } from 'react';
import * as S from './NavButtons.style';

export const descriptionNavBtn = {
    PREV: 'prev',
    NEXT: 'next',
};

export const NavButtons = ({
    onNext,
    onPrev,
    index,
    marketingContentLength,
}) => {
    const { setEnabled, setFocus } = useSpatialNavContext();
    const prevRef = useRef();
    const nextRef = useRef();

    const prevDisabled = index === 0;
    const nextDisabled = index === marketingContentLength - 1;

    useEffect(() => {
        setEnabled(prevRef.current, !prevDisabled);
        setEnabled(nextRef.current, !nextDisabled);
    }, [nextDisabled, prevDisabled, setEnabled, setFocus]);

    const handleOnNext = useSoundAction('next', () => {
        onNext();
    });
    const handleOnPrev = useSoundAction('next', () => {
        onPrev();
    });

    const navButtonStyle = {
        opacity: '0.35',
        filter: 'saturate(0)',
        pointerEvents: 'none',
    };

    if (isMobile) {
        return (
            <>
                <SpatialNavSection enterTo="default-element">
                    <S.NavButtonMobileLeft>
                        <NavIconButton
                            ref={prevRef}
                            defaultElement={nextDisabled && !prevDisabled}
                            onClick={handleOnPrev}
                            data-btn={descriptionNavBtn.PREV}
                            disableMoveLeft={true}
                            style={prevDisabled && navButtonStyle}
                            IconComponent={IconArrowLeft}
                        />
                    </S.NavButtonMobileLeft>
                </SpatialNavSection>
                <SpatialNavSection enterTo="default-element">
                    <S.NavButtonMobileRight>
                        <NavIconButton
                            ref={nextRef}
                            defaultElement={!nextDisabled}
                            onClick={handleOnNext}
                            data-btn={descriptionNavBtn.NEXT}
                            disableMoveRight={true}
                            fill={Theme.COLORS.WHITE}
                            style={nextDisabled && navButtonStyle}
                            IconComponent={IconArrowRight}
                        />
                    </S.NavButtonMobileRight>
                </SpatialNavSection>
            </>
        );
    } else {
        return (
            <SpatialNavSection enterTo="default-element">
                <S.Wrapper>
                    <NavIconButton
                        ref={prevRef}
                        defaultElement={nextDisabled && !prevDisabled}
                        onClick={handleOnPrev}
                        data-btn={descriptionNavBtn.PREV}
                        disableMoveRight={true}
                        style={prevDisabled && navButtonStyle}
                        IconComponent={IconArrowLeft}
                    />
                    <NavIconButton
                        ref={nextRef}
                        defaultElement={!nextDisabled}
                        onClick={handleOnNext}
                        data-btn={descriptionNavBtn.NEXT}
                        disableMoveRight={true}
                        fill={Theme.COLORS.WHITE}
                        style={nextDisabled && navButtonStyle}
                        IconComponent={IconArrowRight}
                    />
                </S.Wrapper>
            </SpatialNavSection>
        );
    }
};
