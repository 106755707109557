import { enableMarketingNews } from 'app/config';
import { maxCols } from 'helpers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FrontEndHelper, Games } from 'services';
import { profileSelector, qaSettingsSelector } from 'slices';

export function useHighLights() {
    const { showMarketingEditorials } = useSelector(qaSettingsSelector);
    const { highlighted, games } = Games.getHighlightGames(); // order may change when language is changed

    // get current profile for content rating
    const { currentProfile } = useSelector(profileSelector);
    const profileAge = FrontEndHelper.GetMinimumAge(
        currentProfile.contentRating.alias
    );

    // Check if marketing editorial is enabled and should be shown
    // Then filter out editorials for games above current content rating,
    // this is not done directly in FrontEndHelper to avoid a ciruclar dependency between
    // FrontEndHelper and Games.
    // Just push in marketingContent array if type === 'info'
    const marketingContent = useMemo(
        () =>
            (enableMarketingNews && showMarketingEditorials
                ? FrontEndHelper.getMarketingNews()
                : []
            ).filter(
                (e) =>
                    (Games.GetAvailableGamesAliases().includes(e.alias) &&
                        profileAge >=
                            FrontEndHelper.GetMinimumAge(
                                Games.GetGameFromAlias(e.alias).content_ratings
                            )) ||
                    e.type === 'info'
            ),
        [profileAge, showMarketingEditorials]
    );

    // Init columns
    const columns = useMemo(() => {
        const result = [...Array(maxCols)].map(() => []);
        if (marketingContent?.length > 0) {
            games.forEach((game, index) => result[index % maxCols].push(game));
        } else {
            games
                .filter((g) => g !== highlighted)
                .forEach((game, index) => result[index % maxCols].push(game));
        }
        return result;
    }, [games, highlighted, marketingContent]);

    return {
        marketingContent,
        highlighted,
        columns,
        games,
    };
}
