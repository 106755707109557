import Theme from 'app/theme';
import { NavButton } from 'components/ui';
import { forwardRef } from 'react';

export const NavIconButton = forwardRef(
    ({ IconComponent, fill, ...props }, ref) => {
        return (
            <NavButton ref={ref} fill={fill || Theme.COLORS.WHITE} {...props}>
                <IconComponent />
            </NavButton>
        );
    }
);
