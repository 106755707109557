import { isMobile, isTV } from 'app/device';
import { AppSwiper } from 'components/ui/swiper/AppSwiper';
import { SpatialNavSection } from 'context';
import { If } from 'helpers';
import { useSwiper } from 'hooks';
import { useSetSwiperWindowFocus } from 'hooks/swiper';
import { forwardRef } from 'react';
import { SwiperSlide } from 'swiper/react';
import * as S from './MarketingWindow.style';
import { MarketingSlide } from './marketing-slide/MarketingSlide';
import { NavButtons } from './nav-buttons/NavButtons';

export const MarketingWindow = forwardRef(
    ({ marketingContent, onExpandClicked, onQuickMatch }, forwardedRef) => {
        const {
            prevSlide,
            nextSlide,
            swiperTransitionActive,
            onSlideChangeTransitionStart,
            onSlideChangeTransitionEnd,
            setSwiperRef,
            swiperRef,
            activeIndex,
            prevRef,
            nextRef,
        } = useSwiper({nbSlides:marketingContent.length});

        const { onPrevSlide, onNextSlide } = useSetSwiperWindowFocus({
            swiperName: 'MarketingWindow',
            swiperRef,
            activeIndex,
            prevSlide,
            nextSlide,
            swiperTransitionActive,
            slidesLength: marketingContent.length,
        });

        return (
            <SpatialNavSection enterTo="default-element" animatable={true}>
                <S.Wrapper ref={forwardedRef}>
                    <If condition={marketingContent.length === 1}>
                        <MarketingSlide
                            content={marketingContent[0]}
                            isSingle
                            onExpandClicked={onExpandClicked}
                            onQuickMatch={onQuickMatch}
                        />
                    </If>
                    <If condition={marketingContent.length > 1}>
                        {/* Swiper */}
                        <AppSwiper
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            virtual
                            className="marketing-swiper"
                            setSwiperRef={setSwiperRef}
                            slidesPerView={1}
                            speed={isTV ? 0 : 300} // no animation on TV
                            centeredSlides={true}
                            prevRef={prevRef}
                            nextRef={nextRef}
                            onSlideChangeTransitionStart={
                                onSlideChangeTransitionStart
                            }
                            onSlideChangeTransitionEnd={
                                onSlideChangeTransitionEnd
                            }
                        >
                            {marketingContent.map((content, i) => (
                                <SwiperSlide key={i} virtualIndex={i}>
                                    <MarketingSlide
                                        index={i}
                                        activeIndex={activeIndex}
                                        marketingContentLength={
                                            marketingContent.length
                                        }
                                        prevSlide={onPrevSlide}
                                        nextSlide={onNextSlide}
                                        isSingle={false}
                                        content={content}
                                        onExpandClicked={onExpandClicked}
                                        onQuickMatch={onQuickMatch}
                                    />
                                </SwiperSlide>
                            ))}
                        </AppSwiper>
                    </If>

                    <If condition={isMobile}>
                        <NavButtons
                            onPrev={prevSlide}
                            onNext={nextSlide}
                            index={activeIndex}
                            marketingContentLength={marketingContent.length}
                        />
                    </If>
                </S.Wrapper>
            </SpatialNavSection>
        );
    }
);
