import { mobile, tv } from 'app/device';
import { BlurBox } from 'components/ui';
import styled, { css } from 'styled-components';

export const UpperWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const Wrapper = styled(BlurBox)`
    width: 98%;
    max-width: 1125px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    display: flex;
    height: 100%;

    ${mobile(css`
        max-width: 80%;
    `)}

    ${tv(css`
        max-width: 65vw;
    `)}
`;
