import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './HoursInput.style';

export function HoursInput({ hours, setHours }) {
    const { t } = useTranslation();

    const increment = useCallback(() => {
        if (hours < 24) {
            setHours((prevState) => prevState + 1);
        }
    }, [hours, setHours]);

    const decrement = useCallback(() => {
        if (hours > 1) setHours((prevState) => prevState - 1);
    }, [hours, setHours]);

    return (
        <S.Wrapper>
            <S.Minus onClick={decrement}>-</S.Minus>
            <S.Plus onClick={increment}>+</S.Plus>
            <S.Hours>
                {hours} {t('profile.parental_control.hours')}
            </S.Hours>
        </S.Wrapper>
    );
}
