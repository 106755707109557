import React, { useContext, useMemo, useRef } from 'react';

const SharedElementContext = React.createContext(null);
export const SharedElementCloneContext = React.createContext(null);

export const useSharedElementContext = () => useContext(SharedElementContext);
export const useSharedElementCloneContext = () =>
    useContext(SharedElementCloneContext);

export function SharedElementProvider({ children, renderTo }) {
    // this is a constant and keys will be added/removed without triggering a rerender
    const rectsRef = useRef({});

    const value = useMemo(() => {
        const rects = rectsRef.current;
        return {
            // root element
            getRoot: () => document.getElementById(renderTo),
            // shared id -> node rect, defined as Object{width, height, x, y}
            getRect: (sharedID) => rects[sharedID],
            // set a node rect
            setRect: (sharedID, rect) => {
                rects[sharedID] = rect;
            },
            // reset element
            resetSharedElement: (sharedID) => {
                delete rects[sharedID];
            },
        };
    }, [renderTo]);

    // Render
    return (
        <SharedElementContext.Provider value={value}>
            {children}
        </SharedElementContext.Provider>
    );
}
