import React from 'react';

export const IconCross = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 102 102"
            {...props}
        >
            <path
                stroke="#191919"
                strokeLinecap="round"
                strokeWidth="10"
                d="M51 5v92"
            ></path>
            <path
                stroke="#191919"
                strokeLinecap="round"
                strokeWidth="10"
                d="M5 51L97 51"
            ></path>
        </svg>
    );
};
