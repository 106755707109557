import { Endpoints, PrivateClient as client } from 'api';
import { hasTouch, isHP } from 'app/device';
import {
    PRNG,
    StatusCodes,
    assetURL,
    clamp,
    doRequest,
    preloadImage,
} from 'helpers';
import { throttle } from 'helpers/throttle';
import i18n from 'i18n';
import { ConfigFront } from 'services';
import FrontEndHelper from './FrontEndHelper';

const releaseStatusOrder = ['down', 'up', 'coming_soon', 'new'];

// initialize random
// use number of days since 01-Jan-1970 to have common behavior with Qt version
const prng = new PRNG();
const todaysSeed = Math.floor(Date.now() / (1000 * 60 * 60 * 24)) + 1;
prng.seed(todaysSeed * 4815162342);

class Games {
    constructor(options) {
        this.options = options || {};
        this.games = [];
        this.filteredGames = [];
        this.filteredHighlightsGames = [];
        this.suggestedGames = [];
        this.aliasToGame = {};
        this.categories = [];
        this.nameToCategory = {};
        this.availableOffers = [];
        this.subscribableOffers = [];
        this.subscribableOfferAliasToGames = {};
    }

    isDiscoveryOffer() {
        // also known as ReducedUI
        // /!\ .every() return true on empty array
        return this.availableOffers.every((o) => o.is_discovery_offer);
    }

    FavoritesEnabled() {
        return !isHP && !this.isDiscoveryOffer();
    }

    buildCategories() {
        // Lookup table to speed up things
        this.nameToCategory = {};
        this.categories = [];
        const touchscreenCateg = 'touchscreen';
        // Touch-friendly" + "Is virtual gamepad : supported"
        const virtualGamepadCateg = 'virtual_gamepad';
        // Index games and add them to categories
        this.filteredGames.forEach((game) => {
            /*  
                as we manually adding touch category (not native from backend, created only frontend side),
                we have to check if the manually added category is already in game categories array.
                This could be possible because game are only loaded ONCE (within megaloader after login and not after a profile switch) 
                and can be modified from a previous profile switch.
            */
            // touchscreenCateg
            if (
                game.is_touch_screen === ('support' || 'mandatory') &&
                !game.category.includes(touchscreenCateg)
            ) {
                game.category.push(touchscreenCateg);
            }

            //virtualGamepadCateg
            if (
                game.is_touch_friendly &&
                game.is_touch_screen === 'no_support'
            ) {
                game.category.push(virtualGamepadCateg);
            }

            game.category.forEach((catName) => {
                if (!(catName in this.nameToCategory)) {
                    const cat = {
                        name: catName.toLowerCase(),
                        games: [],
                    };
                    this.categories.push(cat);
                    this.nameToCategory[catName] = cat;
                }

                this.nameToCategory[catName].games.push(game);
            });
        });
    }

    SortGames() {
        // games are sorted alphabetically by localized title by default
        this.filteredGames = this.filteredGames
            .slice() // force reconstruction of array to trigger update in screens through hooks
            .sort((a, b) => a.assets.title?.localeCompare(b.assets.title));

        // reset highlights games, will be computed on-demand (also depend on stats and language, which may not be available just now)
        this.filteredHighlightsGames = [];
        this.suggestedGames = [];

        // touch and multiplayer will be added back at the end
        const touchCategory = this.categories.find((c) => c.name === 'touch');
        const multiplayerCategory = this.categories.find(
            (c) => c.name === 'multiplayer'
        );

        // sort categories by number of games, then name
        this.categories = this.categories
            .filter((c) => c.name !== 'touch' && c.name !== 'multiplayer')
            .sort(
                (a, b) =>
                    b.games.length - a.games.length ||
                    a.name.localeCompare(b.name)
            );

        if (touchCategory && hasTouch()) {
            this.categories.push(touchCategory);
        }
        // the multiplayer category may have been filtered out of games, see sanitizeMultiplayer
        if (multiplayerCategory) {
            this.categories.push(multiplayerCategory);
        }
    }

    Filter(minimumAge) {
        // Filter games by age
        this.filteredGames = this.games.filter(
            (game) =>
                minimumAge >= FrontEndHelper.GetMinimumAge(game.content_ratings)
        );
        this.buildCategories();
        this.SortGames();
    }

    setPriorityScore(game, maxPriority) {
        // status order rescaled to [0..1]
        const statusValue =
            releaseStatusOrder.indexOf(game.release_status) /
            releaseStatusOrder.length;
        // priority + status scaled to [0..1]
        game.priorityScore = (game.priority + statusValue) / (maxPriority + 1);
    }

    /**
     * On each page, games are sorted (in order) by :
     * - priority
     * - most played by number of sessions (for equal priorities)
     * - alphabetic order (for equal play time)
     * https://jira.gamestream.biz/browse/MEWW-674
     */
    sortGamesWithRules(games) {
        const { priorityWeight } = ConfigFront.gameWeightFactors();

        const gamesSorted = games
            .slice()
            .sort(
                (a, b) =>
                    b.priorityScore * priorityWeight +
                        this.suggestionScore(b) -
                        (a.priorityScore * priorityWeight +
                            this.suggestionScore(a)) ||
                    a.assets.title?.localeCompare(b.assets.title)
            );

        return gamesSorted;
    }

    getHighlightGames() {
        // recompute highlights order if necessary
        if (this.filteredHighlightsGames.length === 0) {
            const { priorityWeight, mixHighlights } =
                ConfigFront.gameWeightFactors();
            const mix = mixHighlights ? 1 : 0;
            this.filteredHighlightsGames = this.filteredGames
                .slice()
                .sort(
                    (a, b) =>
                        b.priorityScore * priorityWeight +
                            mix * this.suggestionScore(b) -
                            (a.priorityScore * priorityWeight +
                                mix * this.suggestionScore(a)) ||
                        a.assets.title?.localeCompare(b.assets.title)
                );
        }

        // the highlighted game is the first one after the initial sorting
        return {
            highlighted: this.filteredHighlightsGames?.[0],
            games: this.filteredHighlightsGames,
        };
    }

    GetGamesFromSubscribableOfferAlias(alias) {
        return this.subscribableOfferAliasToGames[alias];
    }

    GetGames() {
        return this.filteredGames;
    }

    suggestionScore(game) {
        const { statsWeight, randWeight } = ConfigFront.gameWeightFactors();
        return game.statsScore * statsWeight + game.rand * randWeight;
    }

    getSuggestedGames() {
        if (this.suggestedGames.length === 0) {
            this.suggestedGames = this.filteredGames
                .slice()
                .sort(
                    (a, b) => this.suggestionScore(b) - this.suggestionScore(a)
                );
        }
        return this.suggestedGames;
    }

    GetAvailableGamesAliases() {
        // available games this session
        return this.GetGames()
            .filter(
                (game) =>
                    game.release_status === 'new' ||
                    game.release_status === 'up'
            )
            .map((game) => game.alias);
    }

    GetGameFromAlias(alias) {
        return this.aliasToGame[alias];
    }

    GetCategories() {
        return this.categories;
    }

    // UNUSED as categories are now hard coded in navigation.js
    GetFilteredCategories() {
        // touch and multiplayer are always at the end if present
        const specialCategoriesNames = ['touch', 'multiplayer'];
        const specialCategories = this.categories.filter((c) =>
            specialCategoriesNames.includes(c.name)
        );

        const nbCategories = 6;
        const cats = this.categories
            .slice(
                0,
                Math.min(nbCategories, this.categories.length) -
                    specialCategories.length
            )
            .concat(specialCategories);

        return cats;
    }

    GetCategoryGames(category) {
        const theCategory = this.categories.filter((cat) => {
            return cat.name.toLowerCase() === category.toLowerCase();
        });

        // may be undefined if no games in the requested category
        return theCategory[0]?.games || [];
    }

    GetAvailableOffers() {
        return this.availableOffers;
    }

    GetSubscribableOffers() {
        return this.subscribableOffers;
    }

    SetStats(profileUID) {
        const url = `${Endpoints.STATS}/${profileUID}`;
        return doRequest({
            request: client.get(url),
            [StatusCodes.OK]: ({ data }) => {
                // platform stats
                const gameStats = data.platform_stats;

                // regroup profile stats
                const profileStats = (data.profile_stats || []).reduce(
                    (stats, { alias }) => ({
                        ...stats,
                        [alias]: (stats[alias] || 0) + 1,
                    }),
                    {}
                );

                // set sessionCount for all games
                this.games.forEach((game) => {
                    game.sessionCount = gameStats[game.alias] || 0;
                    game.profileSessionCount = profileStats[game.alias] || 0;
                });

                // caution, some categories may have been filtered out of this.categories, we need
                // to use the full set here
                const categories = Object.values(this.nameToCategory);
                // build categories stats
                let maxCount = 0;
                categories.forEach((c) => {
                    // total of profileSessionCount and largest number of sessions for each category
                    [c.profileSessionCount, c.maxProfileNbSessions] =
                        c.games.reduce(
                            ([pCount, max], { profileSessionCount }) => [
                                pCount + profileSessionCount,
                                Math.max(max, profileSessionCount),
                            ],
                            [0, 0]
                        );
                    maxCount = Math.max(maxCount, c.profileSessionCount);
                });
                // category score between 0 and 1 is a representation of the most played categories
                if (maxCount > 0) {
                    categories.forEach((c) => {
                        c.score = c.profileSessionCount / maxCount;
                    });
                }

                if (this.options.debug) {
                    console.groupCollapsed('Game stats');
                    const aliases = this.games.map((g) => g.alias);
                    console.groupCollapsed('Platform');
                    for (const alias in gameStats) {
                        if (aliases.includes(alias)) {
                            console.log(alias, gameStats[alias]);
                        }
                    }
                    console.groupEnd();
                    console.groupCollapsed('Profile');
                    for (const alias in profileStats) {
                        if (aliases.includes(alias)) {
                            console.log(alias, profileStats[alias]);
                        }
                    }
                    console.groupEnd();
                    console.groupEnd();
                    console.groupCollapsed('Category stats');
                    this.categories.forEach(
                        ({ name, sessionCount, profileSessionCount }) => {
                            console.log(
                                name,
                                sessionCount,
                                profileSessionCount
                            );
                        }
                    );
                    console.groupEnd();
                }

                // build suggested games only once we do have the stats
                this.updateStatsScores();
            },
            // ignore other errors
            default: (response) => {
                if (this.options.debug)
                    console.log(
                        'Unexpected response',
                        response.status,
                        `for ${url}`,
                        response
                    );
            },
        });
    }

    incrementStats(alias) {
        const game = this.aliasToGame[alias];
        if (!game) {
            return;
        }
        game.sessionCount++;
        game.profileSessionCount++;
        game.category.forEach((cat) => {
            const c = this.nameToCategory[cat];
            if (!c) {
                return;
            }
            c.profileSessionCount++;
            c.sessionCount++;
            c.maxProfileNbSessions = Math.max(
                c.maxProfileNbSessions,
                c.profileSessionCount
            );
        });
    }

    updateGameRands(force = false) {
        // amount to update each value by, daily, e.g. 0.1 corresponds to +/- 10%
        const dailyUpdateRatio = 0.05; // 5%
        // variation ratio around 0.5 for initial values, e.g. 0.1 corresponds to 40%-60% range
        const originalVariationRatio = 0.1;

        // get or create values
        const gameRands = JSON.parse(localStorage.getItem('gameValues')) || {};

        // update seeds once per day, or when forced (for QA purposes)
        if (gameRands._today !== todaysSeed || force) {
            Object.keys(gameRands).forEach((k) => {
                gameRands[k] = clamp(
                    gameRands[k] *
                        (1 + dailyUpdateRatio * (2 * prng.rand() - 1)),
                    0.05,
                    0.95
                );
            });
            gameRands._today = todaysSeed;
        }

        // update or create each game's rand
        this.games.forEach((g) => {
            if (!(g.alias in gameRands)) {
                gameRands[g.alias] =
                    0.5 + originalVariationRatio * (2 * prng.rand() - 1);
            }
            g.rand = gameRands[g.alias];
        });

        // store values
        localStorage.setItem('gameValues', JSON.stringify(gameRands));
    }

    updateStatsScores() {
        this.filteredGames.forEach((g) => {
            // no categories?
            if (!g.category || g.category.length === 0) {
                g.statsScore = 0;
                return;
            }
            // score of preferred category multiplied by inverse factor of played sessions
            g.statsScore = Math.max(
                ...g.category.map((c) => {
                    const cat = this.nameToCategory[c];
                    const catSessions = cat.maxProfileNbSessions;
                    if (!catSessions) {
                        return 0;
                    }
                    return (
                        (cat.score * (catSessions - g.profileSessionCount)) /
                        catSessions
                    );
                })
            );
        });
    }

    // DEPRECATED
    async PreloadDeprecated(promises, approvedGamesCallback) {
        return doRequest({
            request: client.get(Endpoints.GAMES),

            [StatusCodes.OK]: (response) => {
                // Retrieve available packs
                const data = response.data;
                const allGames = data.games;
                const packs = data.packs;
                const packsAvailable = data.packs_available;

                let gamesAliasesInAvailablePacks = packs
                    .filter((pack) => packsAvailable.includes(pack.alias))
                    .map((pack) => pack.games_aliases);

                // Merging arrays retrieved for more practicality
                gamesAliasesInAvailablePacks = [].concat.apply(
                    [],
                    gamesAliasesInAvailablePacks
                );

                // Remove duplicate games (duplicates appear because multiple packs can contain same games)
                gamesAliasesInAvailablePacks = [
                    ...new Set(gamesAliasesInAvailablePacks),
                ];

                // Retrieve all games in available packs
                this.games = allGames.filter(
                    (game) =>
                        // Game status must be up or new
                        (game.release_status === 'up' ||
                            game.release_status === 'new') &&
                        gamesAliasesInAvailablePacks.includes(game.alias)
                );

                // Format games and push optional promises
                this.games.forEach((game) => {
                    this.aliasToGame[game.alias] = game;

                    // 0 until set though SetStats()
                    game.sessionCount = 0;

                    game.assets.cover = this.getAsset(game, 'wallpaper');
                    game.assets.thumb = this.getAsset(game, 'icon');
                    game.assets.thumb_vertical = game.assets.tumb;
                    game.assets.description = this.getAsset(
                        game,
                        'description'
                    );
                    game.assets.title = this.getAsset(game, 'title');
                    game.assets.trailer = this.getAsset(game, 'trailer');

                    // Preload thumb
                    if (game.assets.thumb)
                        promises.push(
                            preloadImage(assetURL(game.assets.thumb))
                        );

                    // Preload vertical thumb
                    if (game.assets.thumb_vertical)
                        promises.push(
                            preloadImage(assetURL(game.assets.thumb_vertical))
                        );

                    this.sanitizeMultiplayer(game);
                });

                // Approved Eula games
                approvedGamesCallback(
                    (Array.isArray(data.approved_eulas) &&
                        data.approved_eulas) ||
                        []
                );

                // Debug
                if (this.options.debug) {
                    console.log('Games aliases in available packs:');
                    console.log(gamesAliasesInAvailablePacks);

                    console.log('All available Games: ');
                    console.log(this.games);
                }
            },
        });
    }

    sanitizeMultiplayer(game) {
        // sanitize multiplayer information coming from the back, esp. for the QA backend
        // e.g. "multi" games with max 1 player, or multi mode entirely disabled
        if (!ConfigFront.GetMultiplayer() || game.nb_players_online_multi < 2) {
            game.nb_players_online_multi = 0;
        }
        if (game.nb_players_local_multi < 2) {
            game.nb_players_local_multi = 0;
        }
        if (
            game.nb_players_local_multi === 0 &&
            game.nb_players_online_multi === 0
        ) {
            game.category = game.category.filter((c) => c !== 'Multiplayer');
        }
        game.hasQuickMatch = game.nb_players_online_multi > 1;
    }

    // get named asset with language fallback
    getAsset(item, asset, lang) {
        // default lang to current when unspecified
        return (
            item.assets?.[lang || i18n.language]?.[asset] ||
            item.assets?.[this.options.fallbackLang]?.[asset]
        );
    }

    // Update games assets according to the requested language and push optional promises
    UpdateAssets(lang, promises = []) {
        const getAsset = (item, asset) => this.getAsset(item, asset, lang);
        // get single value, or first item if array value
        const getSingleAsset = (item, asset) => {
            const a = getAsset(item, asset);
            return (Array.isArray(a) && a[0]) || a;
        };
        // returns an array of values, possibly containing only one or no asset
        const getAssetArray = (item, asset) => {
            const a = getAsset(item, asset);
            return (Array.isArray(a) && a) || (a && [a]) || [];
        };

        const formatGame = (game) => {
            // 0 until set though SetStats()
            game.sessionCount = 0;

            // icon_square & icon_vertical for grid display
            game.assets.thumb = getSingleAsset(game, 'icon_square');
            game.assets.thumb_vertical = getSingleAsset(game, 'icon_vertical');
            // square cover for detail game display
            game.assets.cover = getSingleAsset(game, 'cover_square');
            // full-screen wallpapers - possibly none
            game.assets.wallpapers = getAssetArray(game, 'wallpaper') || [];

            game.assets.description = getAsset(game, 'description');
            game.assets.title = getAsset(game, 'title') || game.name;
            game.assets.trailer = getSingleAsset(game, 'trailer');
        };

        const callbacks = [];
        const makeAssetsPreloadCallbacks = (game) => {
            // Preload full cover
            if (game.assets.cover)
                callbacks.push(() => preloadImage(assetURL(game.assets.cover)));

            // Preload vertical thumb
            if (game.assets.thumb_vertical)
                callbacks.push(() =>
                    preloadImage(assetURL(game.assets.thumb_vertical))
                );

            // Preload thumb
            if (game.assets.thumb)
                callbacks.push(() => preloadImage(assetURL(game.assets.thumb)));
        };

        // all games available in packs
        this.games.forEach((game) => {
            this.aliasToGame[game.alias] = game;

            formatGame(game);
            makeAssetsPreloadCallbacks(game);

            this.sanitizeMultiplayer(game);
        });

        // all games in subcribable offers
        for (const offer in this.subscribableOfferAliasToGames) {
            this.subscribableOfferAliasToGames[offer].forEach((game) => {
                formatGame(game);
                this.aliasToGame[game.alias] = game;

                //promises
                if (this.games.includes(game)) return;
                makeAssetsPreloadCallbacks(game);
            });
        }

        // update assets of available and subscribable offers
        [
            ...new Set([...this.availableOffers, ...this.subscribableOffers]),
        ].forEach((offer) => {
            offer.assets.title = getAsset(offer, 'title');
            offer.assets.short_description = getAsset(
                offer,
                'short_description'
            );
            offer.assets.description = getAsset(offer, 'description');
            offer.assets.buy_link = getAsset(offer, 'buy-link');
            offer.assets.packshot = getSingleAsset(offer, 'packshot');

            if (offer.assets.packshot)
                callbacks.push(() =>
                    preloadImage(assetURL(offer.assets.packshot))
                );
        });

        // the load balancer on the backend side may be configured to ban
        // connections that attempt too many requests too fast
        // restrict those to 95 per second
        promises.push(...throttle(callbacks, 95, 1000));
    }

    // offers_available as {alias: string, endDate: string}[] format
    FixAvailableOffers(initialAvailableOffers) {
        // format of offers_available has changed between 1.6.3 and 1.6.4 versions of the
        // backend earlier versions provide an array of aliases, later versions provide an
        // array of objects with alias and endDate
        const newFormat = typeof initialAvailableOffers[0] === 'object';
        if (newFormat) {
            return initialAvailableOffers;
        }
        // convert to new format
        return initialAvailableOffers.map((alias) => ({
            alias,
            endDate: null,
        }));
    }

    async Preload(promises, approvedGamesCallback, noOffersCallback) {
        const backendVersion = ConfigFront.GetBackendVersion();
        if (
            backendVersion.major < 1 ||
            (backendVersion.major === 1 && backendVersion.minor < 6)
        ) {
            return this.PreloadDeprecated(promises, approvedGamesCallback);
        }

        return doRequest({
            request: client.get(Endpoints.GAMES),

            [StatusCodes.OK]: (response) => {
                // Retrieve available packs
                const data = response.data;
                const allGames = data.games;
                const offers = data.offers;
                this.allPacks = data.packs;

                /**
                 * OFFERS
                 */
                // active offers, reconstruct from alias, end Date and full offers
                this.availableOffers = this.FixAvailableOffers(
                    data.offers_available
                ).map(({ alias, endDate }) => ({
                    ...offers.find((fullOffer) => fullOffer.alias === alias),
                    endDate,
                }));

                noOffersCallback(this.availableOffers.length < 1);

                // offers with "can_be_subscribed : true"
                this.subscribableOffers = offers.filter(
                    (offer) => offer.can_be_subscribed
                );

                this.subscribableOffers.forEach((offer) => {
                    //retrieve pack aliases
                    const packAliases = offer.packs_aliases;

                    let packs = this.allPacks.filter((pack) =>
                        packAliases.includes(pack.alias)
                    );

                    // retrieve game aliases from pack
                    let game_aliases = packs.map((pack) => pack.games_aliases);
                    //flatten
                    //TODO : refacto
                    game_aliases = [].concat.apply([], game_aliases);
                    game_aliases = [...new Set(game_aliases)];

                    // retrieve game from aliases
                    const gamesFromSubscribableOfferAlias = allGames.filter(
                        (game) =>
                            game.release_status !== 'down' &&
                            game_aliases.includes(game.alias)
                    );

                    this.subscribableOfferAliasToGames[offer.alias] =
                        gamesFromSubscribableOfferAlias;
                });

                /**
                 * AVAILABLE GAMES
                 */

                let packsAliasesInAvailableOffers = offers
                    .filter((offer) =>
                        this.availableOffers.some(
                            (availableOffer) =>
                                availableOffer.alias === offer.alias
                        )
                    )
                    .map((availableOffer) => availableOffer.packs_aliases);

                // Merging arrays retrieved for more practicality
                packsAliasesInAvailableOffers = [].concat.apply(
                    [],
                    packsAliasesInAvailableOffers
                );

                // Remove duplicate packs (duplicates appear because multiple packs can contain same games)
                packsAliasesInAvailableOffers = [
                    ...new Set(packsAliasesInAvailableOffers),
                ];

                let packsInAvailableOffers = this.allPacks.filter((pack) =>
                    packsAliasesInAvailableOffers.includes(pack.alias)
                );

                let gamesAliasesInAvailableOffersPacks =
                    packsInAvailableOffers.map((pack) => pack.games_aliases);

                // Merging arrays retrieved for more practicality
                gamesAliasesInAvailableOffersPacks = [].concat.apply(
                    [],
                    gamesAliasesInAvailableOffersPacks
                );

                // Remove duplicate games (duplicates appear because multiple packs can contain same games)
                gamesAliasesInAvailableOffersPacks = [
                    ...new Set(gamesAliasesInAvailableOffersPacks),
                ];

                // initialize alias table, stats, quickmatch for all games
                allGames.forEach((game) => {
                    this.aliasToGame[game.alias] = game;

                    // 0 until set through SetStats()
                    game.sessionCount = 0;

                    this.sanitizeMultiplayer(game);
                });

                // Retrieve all games in available packs
                this.games = allGames.filter(
                    (game) =>
                        // Game status must be up or new or coming soon but not "down"
                        game.release_status !== 'down' &&
                        gamesAliasesInAvailableOffersPacks.includes(game.alias)
                );

                // initialize priority for available games
                const maxPriority = Math.max(
                    ...(this.games.map(({ priority }) => priority || 0) || [0])
                );
                this.games.forEach((game) => {
                    this.setPriorityScore(game, maxPriority);
                });

                // initialize assets
                this.UpdateAssets(i18n.language, promises);

                // Approved Eula games
                approvedGamesCallback(
                    (Array.isArray(data.approved_eulas) &&
                        data.approved_eulas) ||
                        []
                );

                // update rand values
                this.updateGameRands();

                // Debug
                if (this.options.debug) {
                    console.groupCollapsed('GAMES Preload new API');

                    console.log('Response:');
                    console.log(response);

                    console.log('Available offers:');
                    console.log(this.availableOffers);

                    console.log('Subscribable offers');
                    console.log(this.subscribableOffers);

                    console.log('Offers:');
                    console.log(data.offers);

                    console.log('PacksInAvailableOffers:');
                    console.log(packsAliasesInAvailableOffers);

                    console.log('PacksInAvailableOffers:');
                    console.log(packsInAvailableOffers);

                    console.log('Available games:');
                    console.log(this.games);

                    console.groupEnd();
                }
            },
        });
    }
}

export default new Games({
    debug: process.env.NODE_ENV === 'development',
    fallbackLang: 'en',
});
