import { isMobile, tablet, tv } from 'app/device';
import { Wrapper as ActionButtonsWrapperRoot } from 'components/ui/buttons/action-buttons/ActionButtons.style';
import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const ActionButtonsWrapperBase = styled(ActionButtonsWrapperRoot)`
    margin-top: 10px;

    ${(props) =>
        props.hasQuickMatch &&
        css`
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 400px;

            & > div {
                margin-right: 0 !important;
                min-width: 48%;
                max-width: 48%;
            }

            & > * {
                flex: 1;
            }
            & > :nth-child(1) {
                order: 1;
            }

            & > :nth-child(2) {
                order: 3;
            }

            & > :nth-child(3) {
                order: 2;
            }

            & > :nth-child(4) {
                order: 4;
            }

            ${tablet(css`
                max-width: unset;
            `)}

            ${tv(css`
                max-width: 500px;
            `)}
        `}

    ${(props) =>
        !props.hasQuickMatch &&
        css`
            flex-direction: row;
            align-self: flex-start;
        `}

    // target buttons
  & > div {
        flex: 1;
        margin-top: 12px;
        padding: 0.7rem 1rem;
        justify-content: space-between;
    }

    ${tablet(css`
        & > * {
            padding: 0.7rem 0.5rem;
        }
    `)}
`;

export const ActionButtonsWrapperMobile = styled(ActionButtonsWrapperRoot)`
    width: 95%;
    height: 98%;
    margin: 8px auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ${(props) =>
        props.hasQuickMatch &&
        css`
            margin-top: 0;
            justify-content: space-around;

            & > div {
                margin-bottom: 0 !important;
            }
        `}
`;

export const ActionButtonsWrapper = isMobile
    ? ActionButtonsWrapperMobile
    : ActionButtonsWrapperBase;
