import styled from 'styled-components';

export const WeekRange = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    > * {
        margin: 0px 10px;
    }
`;

export const HoursRangeDisplay = styled.div``;
