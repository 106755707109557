import { TouchscreenHelper } from 'components/helpers/TouchscreenHelper';
import { VirtualGamepadHelper } from 'components/helpers/VirtualGamepadHelper';
import { ColumnLayout, Helper, Tags } from 'components/ui';
import If from 'helpers';
import * as S from './GameHeading.style';

export function GameHeading({ game, minimized, isCurrent }) {
    /**
     * HACKY : VirtualGamepadHelper & TouchscreenHelper are only the useHelpers hook
     * wrapped around a component that return null value to get the conditionnal use of a hook
     */

    return (
        <ColumnLayout>
            <If condition={!minimized}>
                <S.TagsWrapper>
                    <Tags game={game} isCurrent={isCurrent} />
                    <If condition={isCurrent}>
                        <If
                            condition={game.category.includes(
                                'virtual_gamepad'
                            )}
                        >
                            <VirtualGamepadHelper />
                            <Helper helperId="virtual_gamepad_helper" />
                        </If>
                        <If condition={game.category.includes('touchscreen')}>
                            <TouchscreenHelper />
                            <Helper helperId="touchscreen_helper" />
                        </If>
                    </If>
                </S.TagsWrapper>
            </If>
            <S.Name>{game.assets.title}</S.Name>
            <S.Studio>{game.publisher}</S.Studio>
        </ColumnLayout>
    );
}
