import moment from 'moment';
import { DailyTime } from './daily-time/DailyTime';
import * as S from './DailySchedule.style';
import { TimeBar } from './time-bar/TimeBar';

export const DailySchedule = ({
    first,
    enabled,
    day,
    globalRange,
    onDayChange,
    setShowOverlay,
}) => {
    const { dayIndex, hoursPerDay, range } = enabled
        ? day
        : {
              dayIndex: day.dayIndex,
              hoursPerDay: Infinity,
              range: { start: 0, end: 24 },
          };

    const setHoursPerDay = (newAmount) => {
        onDayChange({ ...day, hoursPerDay: newAmount });
    };
    const setRange = (newRange) => {
        onDayChange({ ...day, range: newRange });
    };

    // day names are translated with moment, 0 is Sunday
    // enforce capitalization and strip possible ending dot
    const dayName = moment.weekdaysShort((dayIndex + 1) % 7);
    let fixedDayName = dayName[0].toLocaleUpperCase() + dayName.slice(1);
    if (fixedDayName.endsWith('.')) {
        fixedDayName = fixedDayName.slice(0, -1);
    }

    return (
        <S.DailyScheduleWrapper>
            <S.DayWrapper>
                <div>{fixedDayName}.</div>
            </S.DayWrapper>
            <DailyTime
                enabled={enabled}
                hoursPerDay={hoursPerDay}
                onHoursPerDayChange={setHoursPerDay}
            />
            <TimeBar
                setShowOverlay={setShowOverlay}
                enabled={enabled}
                first={first}
                range={range}
                maxRange={globalRange}
                onRangeChange={setRange}
            />
        </S.DailyScheduleWrapper>
    );
};
