import { SharedElement } from 'components';
import {
    TCButtons,
    TCLogo,
    TCText,
    TCWrapper,
} from 'components/terms-and-conditions';
import {
    BlurBox,
    PrimaryButton,
    RegularButton,
    RowLayout,
} from 'components/ui';
import { splitLines } from 'helpers';
import { useTermsAndConditions } from 'hooks';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export function TermsAndConditionsScreen() {
    const { t } = useTranslation();
    const { title, content, accept, decline } = useTermsAndConditions();

    return (
        <RowLayout alignItems="center" justifyContent="center" fullSize>
            <SharedElement id="box">
                <BlurBox>
                    <TCWrapper>
                        <SharedElement id="logo" zIndex={2} scale>
                            <TCLogo />
                        </SharedElement>
                        <TCText>
                            <h1>{title}</h1>
                            {splitLines(content).map((e, i) => (
                                <Fragment key={i}>
                                    {e}
                                    <br />
                                </Fragment>
                            ))}
                        </TCText>
                        <TCButtons>
                            <PrimaryButton focusOnMount onClick={accept}>
                                {t('buttons.accept')}
                            </PrimaryButton>
                            <RegularButton onClick={decline}>
                                {t('buttons.decline')}
                            </RegularButton>
                        </TCButtons>
                    </TCWrapper>
                </BlurBox>
            </SharedElement>
        </RowLayout>
    );
}
