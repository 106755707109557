import { useState } from 'react';

export function useAudioVideoSettings() {
    const [visible, setVisible] = useState(false);

    const showAudioVideoSettings = () => {
        setVisible(true);
    };

    const hideAudioVideoSettings = () => {
        setVisible(false);
    };
    return { visible, showAudioVideoSettings, hideAudioVideoSettings };
}
