import { useState } from 'react';

export function useQASettings() {
    const [visible, setVisible] = useState(false);

    const show = () => {
        setVisible(true);
    };

    const hide = () => {
        setVisible(false);
    };
    return { visible, show, hide };
}
