import { If } from 'helpers';
import { useState } from 'react';
import * as S from './Slider.style';

export function Slider({
    isDisabled,
    value,
    onValueChange,
    Icon,
    overrideMoveLeft: originalOverrideMoveLeft,
    overrideMoveRight: originalOverrideMoveRight,
    sliderMinWidth,
    ...props
}) {
    const [editing, setEditing] = useState(false);

    const handleChange = (e) => {
        if (isDisabled) {
            e.preventDefault();
            return;
        }
        onValueChange(Number(e.target.value));
    };

    const overrideMoveLeft = () => {
        if (!editing) {
            return originalOverrideMoveLeft?.();
        }
        onValueChange(Math.max(0, value - 1));
        return false;
    };

    const overrideMoveRight = () => {
        if (!editing) {
            return originalOverrideMoveRight?.();
        }
        onValueChange(Math.min(100, value + 1));
        return false;
    };

    return (
        <S.Wrapper isDisabled={isDisabled} sliderMinWidth={sliderMinWidth}>
            <S.SliderInput
                onChange={handleChange}
                type="range"
                {...props}
                onClick={(e) => {
                    if (e.isTrusted || isDisabled) {
                        // true mouse click, use the default behavior
                        return;
                    }
                    // generated by the input system = 'select' action from the user
                    // switch editing mode
                    setEditing((prev) => !prev);
                }}
                onBlur={() => {
                    setEditing(false);
                }}
                overrideMoveLeft={overrideMoveLeft}
                overrideMoveRight={overrideMoveRight}
            />
            <S.SliderDisplay value={value} isDisabled={isDisabled} />
            <S.ContentWrapper>
                <If condition={Icon}>
                    <S.IconWrapper>
                        <Icon />
                    </S.IconWrapper>
                </If>
                <S.SliderValue isDisabled={isDisabled}>{value}%</S.SliderValue>
            </S.ContentWrapper>
        </S.Wrapper>
    );
}
