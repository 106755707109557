import { useMultiStepContext } from 'context/multi-step';
import { useDispatch, useSelector } from 'react-redux';
import * as PE from 'slices/profile/ProfileEditorSlice';
import { useState } from 'react';

export function useWizardHours() {
    const { setStep, step } = useMultiStepContext();
    const dispatch = useDispatch();
    const { hoursPerDay } = useSelector(PE.parentalControlWizardSelector);
    const [hours, setHours] = useState(hoursPerDay);

    const onValidate = (hours) => {
        dispatch(PE.updateWizardHoursPerDay(hours));
        setStep(step + 1);
    };

    const onBack = () => {
        setStep(step - 1);
    };

    return { onValidate, onBack, hours, setHours };
}
