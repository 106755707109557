import { mobile } from 'app/device';
import Theme from 'app/theme';
import styled, { css } from 'styled-components';

export const ScrollBarThumb = styled.div`
    background-color: white;
    width: 10px;
    min-height: 20px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: inset 0 0 13px 12px rgba(255, 255, 255, 0.65);
    box-sizing: border-box;
    transform: translate3d(0, 4px, 0);
`;

export const ScrollViewWrapper = styled.div`
    outline: none;
    position: relative;
    max-height: 100%;
    overflow: auto;
    flex-grow: 1;
    display: flex;
    padding: 1px 1rem 1px 1px; //get scrollView navigable with spatial nav

    ${mobile(css`
        flex: 1;
        padding-right: 8px;
    `)}

    ${(props) =>
        props.showCustomScrollBar === false &&
        css`
            &:focus {
                outline: none;

                ::-webkit-scrollbar-thumb {
                    box-shadow: inset 0 0 13px 12px ${Theme.COLORS.PRIMARY_L1};
                }

                ::-webkit-scrollbar-track {
                    background: rgba(255, 255, 255, 0.1);
                }
            }
        `}

  ${(props) =>
        props.showCustomScrollBar === true &&
        css`
            &:focus {
                ${ScrollBarThumb} {
                    background-color: ${Theme.COLORS.PRIMARY_L1};
                    box-shadow: none;
                }

                ${ScrollBarWrapper} {
                    background: rgba(255, 255, 255, 0.1);
                }
            }
        `}
`;

export const ScrollContent = styled.div`
    outline: none;
    text-align: justify;
    overflow-y: auto;
    max-height: 100%;
    scrollbar-width: ${(props) =>
        props.showNativeScrollBar ? 'auto' : 'none'};
    padding-right: inherit;

    &::-webkit-scrollbar {
        display: ${(props) => (props.showNativeScrollBar ? 'block' : 'none')};
    }
`;

export const ScrollBarWrapper = styled.div`
    width: 16px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    box-sizing: border-box;
    border-radius: 10px;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    overflow: hidden;
`;
