import { Instruction } from 'components/profile';
import {
    IconArrowLeft,
    IconArrowRight,
    NavIconButton,
    NeutralButton,
    RowLayout,
} from 'components/ui';
import { SpatialNavSection, useSpatialNavContext } from 'context';
import { avatarURL } from 'helpers';
import { useBackPress } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './AvatarSelection.style';

export function AvatarSelection({ avatars, onSelected, onCancel }) {
    const { t } = useTranslation();
    const { setEnabled } = useSpatialNavContext();
    const [start, setStart] = useState(0);
    const prevRef = useRef();
    const nextRef = useRef();
    const MAX = 4 * 2;
    const hasPrev = start > 0;
    const hasNext = start + MAX < avatars.length;
    const prev = () => hasPrev && setStart(start - MAX);
    const next = () => hasNext && setStart(start + MAX);

    useEffect(() => {
        setEnabled(prevRef.current, hasPrev);
        setEnabled(nextRef.current, hasNext);
    }, [hasNext, hasPrev, setEnabled]);

    useBackPress(onCancel);
    return (
        <S.Wrapper>
            <S.Heading>{t('profile.avatar_selection')}</S.Heading>
            <Instruction>{t('profile.avatar_selection_text')}</Instruction>
            <RowLayout justifyContent="space-between" alignItems="center">
                <NavIconButton
                    onClick={prev}
                    style={hasPrev || { opacity: '0.2', pointerEvents: 'none' }}
                    ref={prevRef}
                    IconComponent={IconArrowLeft}
                />
                <SpatialNavSection
                    focusOnMount
                    enterTo="last-focused"
                    key={start}
                >
                    <S.AvatarsGrid>
                        {avatars.slice(start, start + MAX).map((avatar, i) => (
                            <S.AvatarItem
                                key={start + i}
                                src={avatarURL(avatar.file)}
                                onClick={() => onSelected(avatar)}
                            />
                        ))}
                    </S.AvatarsGrid>
                </SpatialNavSection>
                <NavIconButton
                    onClick={next}
                    style={hasNext || { opacity: '0.2', pointerEvents: 'none' }}
                    ref={nextRef}
                    IconComponent={IconArrowRight}
                />
            </RowLayout>
            <S.Buttons>
                <NeutralButton onClick={onCancel}>
                    {t('buttons.cancel')}
                </NeutralButton>
            </S.Buttons>
        </S.Wrapper>
    );
}
