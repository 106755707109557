import { mobile } from 'app/device';
import Theme from 'app/theme';
import { IconPlayer as IconPlayerBase } from 'components/ui/icons';
import { BlurMenu } from 'components/ui/menu/blur';
import { withFocusable } from 'helpers';
import styled, { css } from 'styled-components';

export const Filter = withFocusable(styled.div`
    font-size: 1.5rem;
    padding: 0.2rem 0.625rem;

    ${mobile(css`
        font-size: 0.8rem;
    `)}

    text-transform: none;
    border-radius: 50px;
    outline: none;
    display: flex;
    flex-direction: row;
    transition: background-color 200ms ease-out;
    &:focus,
    &:active,
    &:hover {
        background-color: ${Theme.COLORS.PRIMARY_L2};
        * {
            color: ${Theme.COLORS.PRIMARY_L2};
            fill: ${Theme.COLORS.PRIMARY_L2};
        }
    }
`);

export const FilterItem = Filter;

export const NbPlayersBadge = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    padding: 0 0.625rem;
    border-radius: 50px;
    background-color: white;
    color: black;
`;

export const IconPlayer = styled(IconPlayerBase)`
    height: 1.25rem;
    width: 1.25rem;
    ${mobile(css`
        height: 0.7rem;
        width: 0.7rem;
    `)}

    align-self: center;
    path {
        transition: fill 200ms ease-out;
    }
`;

export const Wrapper = styled(BlurMenu)`
    margin-left: 20px;
    display: flex;
    flex-direction: row;
`;
