import { useButtonPress } from 'context';

// left stick
export const useUpPress = (callback) => useButtonPress('up', callback);
export const useDownPress = (callback) => useButtonPress('down', callback);
export const useLeftPress = (callback) => useButtonPress('left', callback);
export const useRightPress = (callback) => useButtonPress('right', callback);
// right stick
export const useUp2Press = (callback) => useButtonPress('up2', callback);
export const useDown2Press = (callback) => useButtonPress('down2', callback);
export const useLeft2Press = (callback) => useButtonPress('left2', callback);
export const useRight2Press = (callback) => useButtonPress('right2', callback);
// buttons
export const useBackPress = (callback) => useButtonPress('back', callback);
export const useSelectPress = (callback) => useButtonPress('select', callback);
export const useTriggerBottomLeftPress = (callback) =>
    useButtonPress('triggerBottomLeft', callback);
export const useTriggerBottomRightPress = (callback) =>
    useButtonPress('triggerBottomRight', callback);
export const useActionTopPress = (callback) =>
    useButtonPress('actionTop', callback);
export const useHomePress = (callback) => useButtonPress('home', callback);
