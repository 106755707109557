export const RegisterServiceWorker = () => {
    // Check that service workers are supported
    if ('serviceWorker' in navigator) {
        const configData = JSON.stringify({
            backendUrl: APP_BACKEND_URL, // eslint-disable-line
        });

        // Use the window load event to keep the page load performant
        window.addEventListener('load', () => {
            // only way to pass data from app to SW at init as SW is independant
            navigator.serviceWorker
                .register(
                    `${process.env.PUBLIC_URL}/service-worker.js?config=${configData}`
                )
                .then(() => {
                    console.log('Service worker registered!');
                })
                .catch((err) => {
                    console.log(
                        `Service worker registration failed with ${err}`
                    );
                });
        });
    }
};
