import history from 'app/history';
import Routes from 'app/routes';
import { isGamepadWarningVisible } from 'helpers/gamepadWarning';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigFront } from 'services';
import {
    globalSelector,
    loginSelector,
    MaintenanceStatus,
    showGlobalMessageBox,
} from 'slices';

export const useEntry = () => {
    const dispatch = useDispatch();
    const supportMethods = ConfigFront.GetSupportMethods();
    const registerUrl = ConfigFront.GetRegisterUrl();
    const languages = ConfigFront.GetSupportedLanguages();
    const [supportVisible, setSupportVisible] = useState();
    const { isChecking, loggedIn } = useSelector(loginSelector);
    const { maintenanceStatus } = useSelector(globalSelector);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();
    // gamepad warning should be displayed only if not in maintenance

    const gamepadWarningVisible =
        isGamepadWarningVisible() &&
        maintenanceStatus === MaintenanceStatus.NORMAL;

    const updateConfig = useCallback(() => {
        return ConfigFront.LoadPostLogin();
    }, []);

    // actual login
    useEffect(() => {
        if (loggedIn) {
            localStorage.setItem('firstConnection', false);
        }

        if (
            gamepadWarningVisible ||
            !loggedIn ||
            maintenanceStatus !== MaintenanceStatus.NORMAL
        ) {
            // show EntryScreen if not loggedIn
            setIsLoading(false);
            return;
        }

        // LoggedIn, update config and move on
        updateConfig().then(() => {
            history.push(Routes.MEGA_LOADER);
        });
    }, [gamepadWarningVisible, loggedIn, maintenanceStatus, updateConfig]);

    // show gamepad warning
    useEffect(() => {
        if (!gamepadWarningVisible) {
            return;
        }

        if (loggedIn) {
            updateConfig().then(() => {
                dispatch(
                    showGlobalMessageBox({
                        title: t('warnings.gamepad.activate_title'),
                        message: t('warnings.gamepad.activate'),
                        routeTo: Routes.MEGA_LOADER,
                    })
                );
            });
        } else {
            dispatch(
                showGlobalMessageBox({
                    type: 'info',
                    title: t('warnings.gamepad.activate_title'),
                    message: t('warnings.gamepad.activate'),
                })
            );
        }
        // reset date
        localStorage.setItem('gamepad_warning_safari', new Date().toString());
    }, [dispatch, gamepadWarningVisible, loggedIn, t, updateConfig]);

    return {
        languages,
        supportMethods,
        registerUrl,
        supportVisible,
        setSupportVisible,
        isChecking,
        isLoading,
    };
};
