import Routes from 'app/routes';
import { useMultiStepContext } from 'context/multi-step';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showGlobalMessageBox } from 'slices';
import { doResetPassword, recoverySelector, resetSteps } from 'slices/recovery';

export function useResetPassword() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { cancelStep } = useMultiStepContext();
    const { step4Done, username, code } = useSelector(recoverySelector);
    const [topPassword, setTopPassword] = useState('');
    const [bottomPassword, setBottomPassword] = useState('');

    useEffect(() => {
        if (step4Done) {
            dispatch(resetSteps({ cancelled: false }));
            dispatch(
                showGlobalMessageBox({
                    type: 'success',
                    message: t('recovery.password_changed'),
                    routeTo: Routes.LOGIN,
                })
            );
        }
    }, [dispatch, step4Done, t]);

    // password should match
    const error =
        topPassword !== '' && bottomPassword !== topPassword
            ? 'recovery.step_password_reset_match_error'
            : '';
    const valid = !error && topPassword !== '';

    // Handle on submit
    const onSubmit = () => {
        if (!valid) {
            return;
        }
        dispatch(
            doResetPassword({
                username: username,
                code: code,
                new_password: topPassword,
            })
        );
    };

    return {
        onSubmit,
        error,
        cancelStep,
        topPassword,
        setTopPassword,
        bottomPassword,
        setBottomPassword,
    };
}
