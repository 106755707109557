import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    disableAtmosSound,
    enableAtmosSound,
    soundSettingsSelector,
} from 'slices';

export const useDisableAtmosSound = () => {
    const dispatch = useDispatch();
    const { atmosSound } = useSelector(soundSettingsSelector);
    const soundWasEnabled = useRef(atmosSound).current;

    useEffect(() => {
        dispatch(disableAtmosSound());
        return () => {
            if (soundWasEnabled) {
                dispatch(enableAtmosSound());
            }
        };
    }, [dispatch, soundWasEnabled]);
};
