import { AppSwiper } from 'components/ui/swiper/AppSwiper';
import { editoAssetUrl, If } from 'helpers';
import { useCountdown, useSwiper } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SwiperSlide } from 'swiper/react';
import * as S from './Header.style';

export const Header = ({ covers, showCountdown, endDate }) => {
    const { t } = useTranslation();
    const coverUrls = covers?.map(editoAssetUrl) || [];
    const { prevRef, nextRef, onSlideChangeTransitionEnd, setSwiperRef } =
        useSwiper({ nbSlides: coverUrls.length });
    const countdown = useCountdown(endDate, 60 * 1000);

    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        if (countdown.days || countdown.hours) setFadeIn(true);
    }, [countdown]);

    return (
        <S.HeaderWrapper>
            <If condition={endDate && showCountdown}>
                <S.Countdown fadeIn={fadeIn}>
                    <S.CountdownItem>
                        <S.CountdownValue>
                            {countdown.days.toString().padStart(2, '0')}
                        </S.CountdownValue>
                        <S.CountdownLabel>
                            {t('marketing_news.days')}
                        </S.CountdownLabel>
                    </S.CountdownItem>
                    <S.CountdownItem>
                        <S.CountdownValue>
                            {countdown.hours.toString().padStart(2, '0')}
                        </S.CountdownValue>
                        <S.CountdownLabel>
                            {t('marketing_news.hours')}
                        </S.CountdownLabel>
                    </S.CountdownItem>
                    <S.CountdownItem>
                        <S.CountdownValue>
                            {countdown.minutes.toString().padStart(2, '0')}
                        </S.CountdownValue>
                        <S.CountdownLabel>
                            {t('marketing_news.minutes')}
                        </S.CountdownLabel>
                    </S.CountdownItem>
                </S.Countdown>
            </If>
            <If condition={coverUrls.length === 1}>
                <S.Wrapper srcImage={coverUrls[0]} />
            </If>
            <If condition={coverUrls.length > 1}>
                {/* Swiper */}
                <AppSwiper
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    setSwiperRef={setSwiperRef}
                    slidesPerView={1}
                    spaceBetween={1000}
                    centeredSlides={true}
                    prevRef={prevRef}
                    nextRef={nextRef}
                    onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
                    autoplay={{ delay: 30000 }}
                >
                    {coverUrls.map((cover, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <S.WrapperSlide srcImage={coverUrls[i]} />
                            </SwiperSlide>
                        );
                    })}
                </AppSwiper>
            </If>
        </S.HeaderWrapper>
    );
};
