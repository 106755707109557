import { hasTouch, isMobileOrTablet } from 'app/device';
import SplashScreen from 'assets/images/splashscreen.png';
import CGXClient from 'cgxwebclient';
import 'cgxwebclient/static/cgx.css';
import { getNumberOfGamepads } from 'context';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { gameSessionSelector, setSessionError } from 'slices';
import defaultAssets, { buttonSets } from './VirtualPadAssets';

export function useWebClient() {
    const containerRef = useRef();
    const { sessionData, SGXConfig } = useSelector(gameSessionSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [instance, setInstance] = useState();

    const { options } = SGXConfig;

    useEffect(() => {
        // virtual gamepad
        const vPadSpecified =
            options['virtualpad-enable'] === 'on' || // backend < 1.6.4
            options['default-virtualpad'] !== undefined; // backend >= 1.6.4

        let useVPad = hasTouch() && vPadSpecified && getNumberOfGamepads() <= 0;

        // backend < 1.6.4: one or two objects
        // touchscreen config has priority if present
        let vPadConfig =
            options['virtualpad-config-touchscreen'] ||
            options['virtualpad-config-normal'];
        const configs = options['virtualpad-configs'];
        if (configs) {
            // backend 1.6.4+: single object with at least two configs
            // touchscreen-enable defines which one to use
            vPadConfig = configs[options['default-virtualpad']];
            // fix aspect ratio if grid is not one of the special configs
            const grid = vPadConfig?.GamepadGrid || [];
            const specialConf =
                (grid[0] === -1 && grid[1] === -1) ||
                (grid[0] === 0 && grid[1] === 0);
            if (!specialConf && vPadConfig) {
                vPadConfig = { ...vPadConfig, GamepadGrid: [16, 9] };
            }
        } else {
            // fix aspect ratio - no special configs in the old format
            vPadConfig = { ...vPadConfig, GamepadGrid: '16,9' };
        }
        if (useVPad && !vPadConfig) {
            console.error('Cannot determine virtual gamepad configuration');
            useVPad = false;
        }

        const emulateTouch = options['client-type'] === 'AndroidCloudGaming';

        // backdoor for changing in production: access main URL with ?resolution=1080p
        const search = new URLSearchParams(window.location?.search);
        const overrideResolution = search.get('resolution');

        const cgxInstance = new CGXClient({
            container: containerRef.current,
            host: options['ip'],
            secured: sessionData.useSsl,
            video_port: options['video-port'],
            input_port: options['input-port'],
            audio_port: options['audio-port'],
            clip_port: 0,
            micro_port: 0,
            datachannel_port: 0,
            width: options.width,
            height: options.height,
            portrait: options['screen-orientation'] === 'portrait',
            fps: 30,
            fill_window: true,
            center_in_window: true,
            change_desktop_res: false,
            bitrate: parseInt(options['bitrate'], 10),
            keyboard_version: 3,
            keyboard_layout: 'azerty',
            use_audio: true,
            use_input: true,
            use_keyboard: true,
            use_mouse: true,
            use_gamepad: true,
            use_gamepad_ex: false,
            use_virtual_gamepad: useVPad,
            virtual_gamepad_config: vPadConfig,
            use_touch: true,
            use_clipboard: false,
            use_microphone: false,
            // prefer relative mode, but force absolute for touch-based games
            mouse_relative: !emulateTouch,
            // show local mouse pointer if requested, or touch-based games without a touch device
            mouse_local: options['mouse-local'] === 'on' || emulateTouch,
            video_force_soft: false,
            audio_force_soft: false,
            input_force_tcp: false,
            force_flash_http: false, // legacy flash flag, always set it to false
            force_flash: false,
            use_rtc_proxy: true,
            force_rtc_proxy: true,
            force_rtc_tcp: options['network_is_wifi'] === 'on',
            use_cgx_proxy: true,
            cgx_proxy_mode: 'stream',
            local_id: 2,
            toolbar_supported_resolutions: [
                '1920x1080/16',
                '1280x720/8',
                '864x486/4',
                '640x360/2',
            ],
            toolbar_supported_bitrate: [16, 8, 4, 2],
            toolbar_enable_bitrate_choice: false,
            // if resolution override is specified, show the selector in the toolbar
            toolbar_enable_resolution_choice: overrideResolution,
            toolbar_enable_gamepad: true,
            toolbar_enable_file_upload: false,
            file_upload_max_size_mb: 100, // MegaBytes
            toolbar_default_hidden: false,
            toolbar_force_display_mode: isMobileOrTablet ? 'hidden' : 'toggle',
            virtualpad_assets: {
                ...defaultAssets,
                ...buttonSets[options['virtualpad-assets']],
            },
            style_path: null,
            splash_screen: SplashScreen,
            flash_path: null,
            software_decode_warning:
                'Your browser or network setup is not optimized for ultra low latency streaming, please try again using Chrome.',
            enable_sound: t('game_session.activate_sound'),
            auth_key: options['enc'],
            debug: false,
        });

        cgxInstance.on('connected', () => {
            // Connected
            console.log('[WEB_CLIENT] connected');
        });

        cgxInstance.on('disconnected', () => {
            // connection with the streamer was closed somehow
            // the session tracker is responsible for setting the session as terminated
            // once it receives the exact status code
            console.log('[WEB_CLIENT] disconnected');
        });

        cgxInstance.on('error', (error) => {
            // Error
            console.log('[WEB_CLIENT] error: ', error);
            dispatch(
                setSessionError({
                    type: 'error',
                    message: 'session_tracker.session_connection_timeout',
                })
            );
        });

        cgxInstance.Setup();
        cgxInstance.Resize(window.innerWidth, window.innerHeight);
        window.CGXClient = CGXClient;
        CGXClient.resizeHandler = () => {
            cgxInstance.Resize(window.innerWidth, window.innerHeight);
        };
        cgxInstance.Start();

        // stop client on close of window or unmount
        const stopClient = () => {
            try {
                cgxInstance.Stop();
            } catch (e) {}
        };
        window.addEventListener('beforeunload', stopClient);

        // instance is stored in state from an effect rather than with a memo
        // because we need a first render to have a valid ref for the container
        setInstance(cgxInstance);

        return () => {
            setInstance(undefined);
            window.removeEventListener('beforeunload', stopClient);
            stopClient();
        };
    }, [dispatch, options, sessionData.useSsl, t]);

    const sendEsc = useCallback(() => {
        instance?.SendKey(27);
    }, [instance]);

    return { containerRef, sendEsc };
}
