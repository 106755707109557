import { mobile, mobileSmall, tv } from 'app/device';
import { BlurBoxFallback } from 'components/ui/blur-box/BlurBoxFallback';
import styled, { css } from 'styled-components';

export const SwiperSlideWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    ${mobile(css`
        align-items: flex-end;
        bottom: 0;
        margin-bottom: 10px;
    `)}
`;

export const Wrapper = styled(BlurBoxFallback)`
    z-index: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    max-width: 750px;

    // TODO: calc with menu height
    ${mobile(css`
        height: 82vh;
        width: 98%;
        bottom: 0;
        margin-bottom: 10px;
    `)}

    ${tv(css`
        max-height: 80vh;
    `)}

    ${mobileSmall(css`
        height: 84vh;
        margin-bottom: 3px;
    `)}
`;
