import { createContext, useState, useContext, useRef } from 'react';

const GapAnimationContext = createContext();

// useless for now, waiting merge with MEWW-559
export const GapAnimationContextProvider = ({ children }) => {
    const gamesWrapperRef = useRef();
    const [gapAnimationEnd, setGapAnimationEnd] = useState(false);

    return (
        <GapAnimationContext.Provider
            value={{
                gapAnimationEnd,
                setGapAnimationEnd,
                gamesWrapperRef,
            }}
        >
            {children}
        </GapAnimationContext.Provider>
    );
};

export const useGapAnimation = () => useContext(GapAnimationContext);
