import { isAndroid, isMobile, isTV } from 'app/device';
import { useGapAnimation, useSpatialNavContext } from 'context';
import React, { useEffect, useRef } from 'react';

export const shouldAnimate = (WrapperScrollTop, el) => {
    return (
        el.offsetTop + el.offsetHeight >= WrapperScrollTop &&
        el.offsetTop <= WrapperScrollTop + window.innerHeight
    );
};

export const transitionDuration = isMobile ? 400 : 600;

export function GapAnimation({ children, isGapOpen, lastFocused }) {
    const ref = useRef();
    const firstUpdate = useRef(true);
    const spatialNavCtx = useSpatialNavContext();

    const { gamesWrapperRef } = useGapAnimation();

    if (React.Children.count(children) !== 1) {
        throw new Error('GapAnimation must have one child');
    }

    useEffect(() => {
        // TODO use a ref instead ...
        gamesWrapperRef.current = document.getElementById('GamesWrapper');
    }, [gamesWrapperRef]);

    useEffect(() => {
        // Do nothing in the first update
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        const disableAnimation = isTV || isAndroid;

        // Gap Open
        if (isGapOpen) {
            // Disable navigation to this element
            spatialNavCtx.setEnabled(ref.current, false, false);

            // Split animation is too heavy for android/Tizen
            if (disableAnimation) {
                ref.current.style.pointerEvents = 'none';
                ref.current.style.animation = 'none';
                ref.current.style.opacity = 0;
                return;
            }

            // Check if we need to animate this element
            let gamesWrapperScrollTop = gamesWrapperRef.current.scrollTop;
            if (!shouldAnimate(gamesWrapperScrollTop, ref.current)) {
                // Hide only
                ref.current.style.opacity = 0;
                return;
            }

            // Default Go down
            let translateY = window.innerHeight;

            // Go up
            if (
                ref.current.offsetTop + ref.current.offsetHeight / 2 <
                gamesWrapperScrollTop + window.innerHeight / 2
            )
                translateY *= -1;

            // Disable previous item animation
            ref.current.style.animation = 'none';
            ref.current.style.opacity = 1;

            // Apply new transition
            requestAnimationFrame(() => {
                if (!ref.current) return;

                ref.current.style.transition = `transform ${transitionDuration}ms ease-out, opacity 0s ${transitionDuration}ms linear`;

                // Translate
                ref.current.style.transform = `translate3d(0, ${translateY}px, 0)`;

                // Hide after translation, see transition delay
                ref.current.style.opacity = 0;

                // Disable pointer events
                ref.current.style.pointerEvents = 'none';
            });
        }
        // Gap closed
        else {
            // Enable navigation to this element
            spatialNavCtx.setEnabled(ref.current, true, true);

            if (!disableAnimation) {
                ref.current.style.transition = `transform ${transitionDuration}ms ease-out`;
            }

            ref.current.style.transform = `translate3d(0, 0px, 0)`;
            ref.current.style.opacity = 1;
            ref.current.style.pointerEvents = 'auto';
        }
    }, [isGapOpen, lastFocused, spatialNavCtx, gamesWrapperRef]);

    // Clone to access node el of children https://stackoverflow.com/questions/29568721/getting-dom-node-from-react-child-element
    return React.cloneElement(children, {
        ref: (el) => {
            ref.current = el;
            if (children.ref) children.ref.current = el;
        },
    });
}
