import { ConfirmationBox } from 'components/ui';
import { If } from 'helpers';
import { useGlobalMessageBox } from 'hooks';
import { useTranslation } from 'react-i18next';

const TimerButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export function GlobalMessageBox() {
    const { title, message, message2, subtitle, button, type, tick, onClick } =
        useGlobalMessageBox();
    const { t } = useTranslation();

    return (
        <If condition={message}>
            <ConfirmationBox
                title={title}
                message={message}
                message2={message2}
                subtitle={subtitle}
                type={type}
                buttons={[
                    {
                        type: 'primary',
                        text: button || t('buttons.close'),
                        callback: onClick,
                        timer: tick,
                        props: {
                            style: TimerButtonStyle,
                        },
                    },
                ]}
                timer={tick}
            />
        </If>
    );
}
