import styled, { css } from 'styled-components';
import { mobile } from 'app/device';

export const Wrapper = styled.div`
    position: absolute;
    z-index: ${(props) => (props.zIndex ? props.zIndex : 800)};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const SliderWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;

export const SwiperSlideWrapper = styled.div`
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${mobile(css`
        align-items: flex-end;
        bottom: 0;
        margin-bottom: 10px;
    `)}
`;
