import { isTV } from 'app/device';
import { AppSwiper } from 'components/ui/swiper/AppSwiper';
import { useSwiper } from 'hooks';
import { useSetSwiperWindowFocus } from 'hooks/swiper/swiper-window/useSetSwiperWindowFocus';
import { useState } from 'react';
import SwiperCore, { Virtual } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { GameWindow } from '../game-window/GameWindow';
import * as S from './GameWindowSwiper.style';

SwiperCore.use([Virtual]);

export const GameWindowSwiper = ({
    gamesSwiper,
    games,
    selectedIndex,
    zIndex,
}) => {
    // stored once on mount
    // each window inside the swiper has its own MultiView and matching context
    // the current window will call setCurrentGameWindowView so we can known the\
    // information from outside
    // eslint-disable-next-line no-unused-vars 
    const [currentGameWindowView, setCurrentGameWindowView] = useState(
        gamesSwiper.defaultView.current
    );

    const {
        prevSlide,
        nextSlide,
        swiperTransitionActive,
        onSlideChangeTransitionStart,
        onSlideChangeTransitionEnd,
        setSwiperRef,
        swiperRef,
        activeIndex,
        prevRef,
        nextRef,
    } = useSwiper({ nbSlides: games.length, selectedIndex });

    const _onNextSlide = () => {
        gamesSwiper.next();
        nextSlide();
    };

    const _onPrevSlide = () => {
        gamesSwiper.prev();
        prevSlide();
    };

    const { onPrevSlide, onNextSlide, setLastFocusedEl } =
        useSetSwiperWindowFocus({
            swiperName: 'GameWindowSwiper',
            swiperRef,
            activeIndex,
            nextSlide: _onNextSlide,
            prevSlide: _onPrevSlide,
            swiperTransitionActive,
            slidesLength: games.length,
        });

    return (
        <S.Wrapper zIndex={zIndex}>
            <AppSwiper
                style={{
                    width: '100%',
                    height: '100%',
                }}
                onSwiper={setSwiperRef}
                slidesPerView={1}
                speed={isTV ? 0 : 300} // no animation on TV
                centeredSlides={true}
                prevRef={prevRef}
                nextRef={nextRef}
                onSlideChangeTransitionStart={onSlideChangeTransitionStart}
                onSlideChangeTransitionEnd={onSlideChangeTransitionEnd}
            >
                {games.map((game, index) => (
                    <SwiperSlide
                        key={game.alias}
                        virtualIndex={index}
                        className={game.alias}
                    >
                        <S.SwiperSlideWrapper>
                            <GameWindow
                                index={index}
                                selectedIndex={selectedIndex}
                                setCurrentGameWindowView={
                                    setCurrentGameWindowView
                                }
                                defaultView={gamesSwiper.defaultView.current}
                                game={games[index]}
                                gamesListLength={games.length}
                                onClose={gamesSwiper.close}
                                onNext={onNextSlide}
                                onPrev={onPrevSlide}
                                setLastFocusedEl={setLastFocusedEl}
                            />
                        </S.SwiperSlideWrapper>
                    </SwiperSlide>
                ))}
            </AppSwiper>
        </S.Wrapper>
    );
};
