import { IconSettings, LangIcon } from 'components/ui/icons';
import { MessageBoxTitle } from 'components/ui/message-box';
import { SpatialNavSection, withInputDispatcherProvider } from 'context';
import { useBackPress } from 'hooks';
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import Config from 'services/ConfigFront';
import { PrimaryButton } from '../../buttons';
import { ActionButtonsWrapper } from '../ActionButtonsWrapper';
import { MessageBoxWrapper } from '../message-box-wrapper/MessageBoxWrapper';
import * as S from './LanguageBox.style';

export const LanguageBox = withInputDispatcherProvider(
    ({ onClose, lang, changeLanguage }) => {
        const { t } = useTranslation();
        const languages = Config.GetSupportedLanguages();

        useBackPress(onClose);

        const onLanguageChanged = (lang) => {
            changeLanguage(lang);

            // timeout to prevent lang flickering
            setTimeout(() => {
                onClose();
            }, 100);
        };

        return ReactDOM.createPortal(
            <MessageBoxWrapper>
                <MessageBoxTitle
                    title={t('languages.change')}
                    Icon={IconSettings}
                />
                <S.MessageWrapper>
                    <S.Text>{t('languages.select')}</S.Text>
                    <SpatialNavSection defaultElement enterTo="default-element">
                        <S.LangSelectionWrapper nbLanguages={languages.length}>
                            {languages.map((language) => {
                                return (
                                    <S.LangWrapper
                                        key={language}
                                        defaultElement={language === lang}
                                        onClick={() =>
                                            onLanguageChanged(language)
                                        }
                                    >
                                        <LangIcon
                                            lang={language}
                                            focusable={false}
                                        />
                                        <S.Lang>
                                            {/* ugly wart because backend uses zt for zh-tw */}
                                            {t(
                                                `languages.${
                                                    language === 'zt'
                                                        ? 'zh'
                                                        : language
                                                }`
                                            )}
                                        </S.Lang>
                                    </S.LangWrapper>
                                );
                            })}
                        </S.LangSelectionWrapper>
                    </SpatialNavSection>
                </S.MessageWrapper>
                <ActionButtonsWrapper>
                    <PrimaryButton onClick={onClose}>
                        {t('buttons.close')}
                    </PrimaryButton>
                </ActionButtonsWrapper>
            </MessageBoxWrapper>,
            document.body
        );
    }
);
