import { useEffect, useState } from 'react';
import ConfigFront from 'services/ConfigFront';

export function useSupportMessageBox(wrapperToHideRef) {
    const [visible, setVisible] = useState(false);
    const supportMethods = ConfigFront.GetSupportMethods();

    const showSupport = () => {
        setVisible(true);
    };

    const hideSupport = () => {
        setVisible(false);
    };

    useEffect(() => {
        // TODO: try to use ref for gamesWrapper
        const wrapperToHide =
            wrapperToHideRef?.current ||
            document.getElementById('GamesWrapper');

        if (!wrapperToHide) return;

        if (visible) {
            wrapperToHide.style.opacity = 0;
            wrapperToHide.style.pointerEvents = 'none';
        } else {
            wrapperToHide.style.opacity = 1;
            wrapperToHide.style.pointerEvents = 'auto';
        }
    }, [wrapperToHideRef, visible]);

    return {
        showSupport,
        hideSupport,
        visible,
        supportMethods,
    };
}
