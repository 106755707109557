import {
    BoxContent,
    BoxText,
    StepWrapper,
    Title,
} from 'components/parental-control/wizard';
import * as S from 'components/parental-control/wizard/steps';
import { ActionButtons } from 'components/ui';
import { Checkbox } from 'components/ui/checkbox/Checkbox';
import { useWizardDailyUsage } from 'hooks/parental-control/wizard/useWizardDailyUsage';
import { useTranslation } from 'react-i18next';

export function StepDailyUsage() {
    const { t } = useTranslation();
    const { setChecked, checked, onValidate, onBack } = useWizardDailyUsage();

    return (
        <StepWrapper>
            <Title />
            <BoxContent>
                <BoxText>{t('profile.parental_control.limit_daily')}</BoxText>
                <S.SwitchWrapper>
                    <div>{t('buttons.no')}</div>
                    <Checkbox
                        defaultElement
                        defaultChecked={checked}
                        onClick={() => {
                            setChecked((prevChecked) => !prevChecked);
                        }}
                    />
                    <div>{t('buttons.yes')}</div>
                </S.SwitchWrapper>
            </BoxContent>
            <ActionButtons
                buttons={[
                    {
                        type: 'primary',
                        text: t('buttons.validate'),
                        callback: onValidate,
                    },
                    {
                        type: 'neutral',
                        text: t('buttons.back'),
                        callback: onBack,
                    },
                ]}
            />
        </StepWrapper>
    );
}
