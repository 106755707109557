import { IconHeart, Tags } from 'components/ui';
import { assetURL, If } from 'helpers';
import { useGameItem } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { ConfigFront, Games } from 'services';
import { qaSettingsSelector } from 'slices';
import * as S from './GameItem.style';

export const GameItem = React.forwardRef(
    ({ isGrid, onClose, game, src, ...props }, forwardedRef) => {
        const { isFavorite, favIconRef, animateAndToggle } = useGameItem(game);
        const { showScores } = useSelector(qaSettingsSelector);
        const GameComponent = isGrid ? S.GameGridItem : S.GameItem;
        const favoriteItemProps = {
            key: `favicon-${game.alias}`,
            ref: favIconRef,
            checked: isFavorite,
            onClick: animateAndToggle,
        };
        const { priorityWeight, mixHighlights, statsWeight, randWeight } =
            ConfigFront.gameWeightFactors();

        return (
            <GameComponent
                ref={forwardedRef}
                srcImage={src ? assetURL(src) : ''}
                data-alias={game.alias}
                {...props}
            >
                <If condition={Games.FavoritesEnabled()}>
                    <If condition={isGrid}>
                        {/* FavoriteGridItem is not - and must not - be a focusable, so doesn't have disableMoveLeft */}
                        <S.FavoriteGridItem {...favoriteItemProps}>
                            <IconHeart />
                        </S.FavoriteGridItem>
                    </If>
                    <If condition={!isGrid}>
                        <S.FavoriteIcon
                            {...favoriteItemProps}
                            disableMoveLeft
                            overrideMoveUp={() => {onClose?.(); return true}} // do not move out of window (to menu bar)
                        >
                            <IconHeart />
                        </S.FavoriteIcon>
                    </If>
                </If>
                <If condition={isGrid}>
                    <S.TagsWrapper>
                        <Tags game={game} isGrid={isGrid} />
                    </S.TagsWrapper>
                </If>
                <If condition={!src}>
                    <S.GameItemTextFallback>
                        {game.assets.title}
                    </S.GameItemTextFallback>
                </If>
                <If condition={showScores}>
                    <S.DebugInfo>
                        prio: {game.priority}
                        <br />
                        stat: {game.release_status}
                        <br />
                        all sessions: {game.sessionCount}
                        <br />
                        my sessions: {game.profileSessionCount}
                        <br />
                        priority weight:{' '}
                        {Math.floor(game.priorityScore * 1000) / 1000}
                        <br />
                        stats weight:{' '}
                        {Math.floor(game.statsScore * 1000) / 1000}
                        <br />
                        rand: {Math.floor(game.rand * 1000) / 1000}
                        <br />
                        final:{' '}
                        {Math.floor(
                            (game.priorityScore * priorityWeight +
                                (mixHighlights && !isFavorite
                                    ? game.statsScore * statsWeight +
                                      game.rand * randWeight
                                    : 0)) *
                                1000
                        ) / 1000}
                        <br />
                    </S.DebugInfo>
                </If>
            </GameComponent>
        );
    }
);
