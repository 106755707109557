import { LangIcon } from 'components/ui';
import { SpatialNavProvider, SpatialNavSection } from 'context';
import { useBackPress, useLanguageSelect } from 'hooks';
import Config from 'services/ConfigFront';
import * as S from './LangSelection.style';

export function LangSelection({ refocusRef, size, ...props }) {
    const languages = Config.GetSupportedLanguages();
    const { lang, changeLanguage, hideLangSelect } = useLanguageSelect();

    // On Back button pressed
    useBackPress(hideLangSelect);

    return (
        <SpatialNavProvider>
            <SpatialNavSection focusOnMount enterTo="default-element">
                <S.LangSelectionWrapper {...props}>
                    {languages.map((language, idx) => (
                        <LangIcon
                            size={size}
                            defaultElement={language === lang}
                            key={idx}
                            lang={language}
                            onClick={() => {
                                changeLanguage(language);
                            }}
                            overrideMoveDown={() => {
                                hideLangSelect();
                                return true;
                            }}
                        />
                    ))}
                </S.LangSelectionWrapper>
            </SpatialNavSection>
        </SpatialNavProvider>
    );
}
