import { useSpatialNavContext } from 'context';
import { useAnimationComplete } from 'hooks';
import * as S from './CategoryTitle.style';

// wrapper for adjustments after animating the title entry
export const CategoryTitle = ({ blurRef, ...props }) => {
    const { refreshTree } = useSpatialNavContext();
    const ref = useAnimationComplete(() => {
        // a nav tree refresh is necessary to get the correct geometry
        // of the multiplayer filter
        refreshTree();
        // backdrop-filter on the blur wrapper cannot be applied while
        // the element is also inside an animation so when the animation
        // ends it must be removed from the style
        // opacity is also applied progressively on the backdrop filter
        // to avoid a hard filter switch - a bit awkward but the backdrop
        // filter effects cannot be transitioned
        const blurEl = blurRef.current;
        if (!blurEl) {
            return;
        }
        const blurBase = window.getComputedStyle(blurEl).backdropFilter;
        let opacity = 0;
        const nbSteps = 50;
        const transitionTime = 200;
        const interval = setInterval(() => {
            if (opacity > 1) {
                clearInterval(interval);
                return;
            }
            blurEl.style.backdropFilter = blurBase + `opacity(${opacity})`;
            opacity += 1 / nbSteps;
            // remove animation to activate the backdrop filter
            // and set opacity to 1 because it is set to 0 in the main style
            if (ref.current) {
                ref.current.style.animationName = 'unset';
                ref.current.style.opacity = 1;
            }
        }, transitionTime / nbSteps);
    });
    return <S.CategoryTitle ref={ref} {...props} />;
};
