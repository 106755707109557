import { configureStore } from '@reduxjs/toolkit';
import {
    eulaReducer,
    favoritesReducer,
    gameLobbyReducer,
    gameSessionReducer,
    globalLoaderReducer,
    globalMessageBoxReducer,
    globalNavigationReducer,
    globalPreloaded,
    globalReducer,
    loginReducer,
    passwordCheckReducer,
    pinCodeCheckReducer,
    profileEditorReducer,
    profileReducer,
    recoveryReducer,
    termsAndConditionsReducer,
} from 'slices';

const reducer = {
    global: globalReducer,
    globalMessageBox: globalMessageBoxReducer,
    globalLoader: globalLoaderReducer,
    login: loginReducer,
    termsAndConditions: termsAndConditionsReducer,
    profile: profileReducer,
    profileEditor: profileEditorReducer,
    passwordCheck: passwordCheckReducer,
    pinCodeCheck: pinCodeCheckReducer,
    recovery: recoveryReducer,
    globalNavigation: globalNavigationReducer,
    favorites: favoritesReducer,
    eula: eulaReducer,
    gameSession: gameSessionReducer,
    gameLobby: gameLobbyReducer,
};

const store = configureStore({
    reducer,
    devTools: process.env.NODE_ENV === 'development',
    preloadedState: globalPreloaded,
});

export default store;
