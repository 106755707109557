import { useThemeContext } from 'context';
import { useFocusFollow } from 'hooks';
import { useEffect } from 'react';
import * as S from './GamesWrapper.style';

export function GamesWrapper({
    children,
    isGapOpen,
    isDiscoveryOffer,
    ...props
}) {
    const { ref, onFocus } = useFocusFollow(props);
    const { setParallax } = useThemeContext();

    // scroll listener to update parallax while scrolling
    useEffect(() => {
        const onScroll = (e) => {
            setParallax(e.currentTarget.scrollTop);
        };
        const wrapper = ref.current;
        wrapper.addEventListener('scroll', onScroll);
        return () => {
            wrapper.removeEventListener('scroll', onScroll);
        };
    }, [ref, setParallax]);

    return (
        <S.GamesWrapper
            ref={ref}
            onFocus={onFocus}
            isDiscoveryOffer={isDiscoveryOffer}
        >
            {children}
        </S.GamesWrapper>
    );
}

export { LeadingGame, NoGamesFound } from './GamesWrapper.style';
