import { isTV } from 'app/device';
import Theme from 'app/theme';
import * as CheckboxStyle from 'components/ui/checkbox/Checkbox.style';
import { withFocusable } from 'helpers';
import styled from 'styled-components';

export const SwitchWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    div:first-child {
        margin-right: 10px;
    }

    div:last-child {
        margin-left: 10px;
    }
`;

export const CheckBoxWrapper = withFocusable(styled.div`
    &:focus {
        outline: none;
        color: ${Theme.COLORS.WHITE};
        ${CheckboxStyle.Switch} {
            box-shadow: 0 0 0 ${isTV ? '2px' : '1px'} ${Theme.COLORS.PRIMARY_L2};
        }
    }
`);
