import styled, { css } from 'styled-components';
import { mobile, tv } from 'app/device';
import { withFocusable } from 'helpers';

export const Wrapper = styled.div`
    position: absolute;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);

    ${tv(css`
        background-color: black;
    `)}

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const VideoWrapper = styled.div`
    position: relative;
    width: ${(props) => (props.isIOS ? '95vw' : '70vw')};
    height: ${(props) =>
        props.isIOS ? 'calc(95vw / (16 / 9))' : 'calc(70vw / (16 / 9))'};

    ${tv(css`
        width: 100vw;
        height: 100vh;
    `)}

    ${mobile(css`
        width: 75vw;
        height: calc(75vw / (16 / 9));
    `)}
`;

export const Close = withFocusable(styled.div`
    position: absolute;
    z-index: 900;
    width: 35px;
    height: 35px;
    right: -16px;
    top: -16px;
    outline: none;
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 50%;
    cursor: pointer;

    ${mobile(css`
        width: 30px;
        height: 30px;
        right: -45px;
        top: -10px;
    `)}
`);

export const Video = styled.video`
    outline: none;
    width: 100%;
    height: 100%;
    background-color: #0a0a0ae8;
`;
