export const IconVirtualGamepad = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="17"
            fill="none"
            viewBox="0 0 34 17"
            {...props}
        >
            <path stroke="#FAFAFA" strokeWidth="2" d="M9 5L9 13"></path>
            <path stroke="#FAFAFA" strokeWidth="2" d="M5 9L13 9"></path>
            <rect
                width="32.5"
                height="15.5"
                x="0.75"
                y="0.75"
                stroke="#FAFAFA"
                strokeWidth="1.5"
                rx="2.25"
            ></rect>
            <circle
                cx="24"
                cy="6"
                r="1.333"
                fill="#FAFAFA"
                stroke="#FAFAFA"
                strokeWidth="1.333"
            ></circle>
            <circle
                cx="28"
                cy="11"
                r="1.333"
                fill="#FAFAFA"
                stroke="#FAFAFA"
                strokeWidth="1.333"
            ></circle>
        </svg>
    );
};
