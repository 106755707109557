export function defaultErrorHandler(
    response,
    { message = null, type = 'error', debug = false } = {}
) {
    if (debug) {
        console.group('defaultErrorHandler');
        console.log('response.data:', response.data);
        console.log('message:', message);
        console.log('type:', type);
        console.groupEnd();
    }

    const gsStatusCode = response?.data?.gsStatusCode;
    const httpStatusCode = response?.data?.httpStatusCode;

    if (gsStatusCode && httpStatusCode) {
        message = message ? message : `gsStatusCode.${gsStatusCode}`;

        // handle gsStatusCode
        return {
            hasError: true,
            error: {
                type,
                message,
                gsStatusCode,
                httpStatusCode,
            },
        };
    } else {
        // Fallback if no gsStatusCode
        return {
            hasError: true,
            error: {
                type,
                message: 'error.internal',
            },
        };
    }
}
