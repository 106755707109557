import styled, { css } from 'styled-components';
import { mobile } from 'app/device';

export const DailyScheduleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    /* > * {
        margin: 4px;
    } */

    margin-bottom: 4px;

    ${mobile(css`
        font-size: 1rem;
        margin-bottom: 0.5rem;
    `)}
`;

export const DayWrapper = styled.div`
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
