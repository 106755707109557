import { mobile, tv } from 'app/device';
import Theme from 'app/theme';
import { BlurBox } from 'components/ui';
import { withFocusable } from 'helpers';
import styled, { css } from 'styled-components';

export const Wrapper = styled(BlurBox)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;

    ${mobile(css`
        padding: 10px;
        min-width: 60vw;
    `)}

    ${tv(css`
        padding: 28px;
    `)}
`;

export const InputWrapper = styled.div`
    flex: 1;
    margin-right: 24px;
    position: relative;

    & > input {
        box-sizing: border-box;
        width: 100%;
        height: 55px;

        padding: 0 1.2rem;
        font-size: 1.5rem;
        border-radius: 50px;
        background-color: rgba(0, 0, 0, 0.35);

        ${mobile(css`
            height: 40px;
            font-size: 1.2rem;
            border-radius: 15px;
        `)}

        ${tv(css`
            height: 75px;
            border-radius: 30px;
        `)}
    }
`;

export const ButtonWrapper = styled.div`
    & > :first-child {
        padding: 1.4rem 1.5rem;
        border-radius: 50px;

        ${mobile(css`
            padding: 1rem 1.5rem;
        `)}
    }
`;

export const Clear = withFocusable(styled.div`
    outline: none;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transition: opacity 200ms ease-out;

    // center vertically inside the input field
    position: absolute;
    bottom: 25%;
    right: 20px;

    width: 1.7rem;
    height: 1.7rem;
    ${mobile(css`
        width: 1.4rem;
        height: 1.4rem;
    `)}

    path {
        fill: ${Theme.COLORS.GRAY_L1};
    }
    &:focus,
    &:hover {
        path {
            fill: ${Theme.COLORS.PRIMARY_L2};
        }
    }
`);
