import If from 'helpers';
import { useTranslation } from 'react-i18next';
import * as S from './Title.style';
import { useMultiStepContext } from 'context/multi-step';

export function Title() {
    const { t } = useTranslation();
    const { step, maxSteps } = useMultiStepContext();

    return (
        <S.Title>
            {t('profile.parental_control.title')}{' '}
            <If condition={step < maxSteps}>
                {step}/{maxSteps - 1}
            </If>
        </S.Title>
    );
}
