import styled, { css } from 'styled-components';
import { mobile, tv } from 'app/device';

export const TAG = {
    new: '#ff9402',
    coming_soon: '#7617e5',
    '4k': '#175ee5',
    'quick-match': '#175ee5',
    local: '#0057BE',
    dolby: '#5d5d5d',
    touchscreen: '#41ABD0',
    virtual_gamepad: '#43B98D',
};

export const TagsWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
`;

export const Tag = styled.div`
    display: flex;
    align-items: center;
    border-radius: 50px;
    color: white;
    position: relative;
    text-align: right;
    font-weight: bold;
    background-color: ${(props) => props.color};
    box-shadow: ${(props) =>
        props.isGrid ? '0px 0px 15px 5px rgba(0, 0, 0, 0.25)' : 'none'};
    padding: 2px 15px 3px 15px;
    margin-right: 5px;
    font-size: 1rem;

    ${mobile(css`
        padding-left: 10px;
        padding-right: 10px;
        font-size: 0.75rem;
    `)}

    ${tv(css`
        padding-left: 20px;
        padding-right: 20px;
    `)}

    svg {
        display: block;
        height: 16px;
        width: auto;
        margin-right: ${(props) =>
            props.hideValue && props.hideLabel ? '0px' : '5px'};
        path {
            fill: #fff;
        }
    }
`;

export const TextWrapper = styled.div`
    flex-grow: 1;
`;

export const MultiNumber = styled.span``;

export const TagName = styled.span`
    margin-left: 5px;
`;
