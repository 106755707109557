import { useLanguageSelect } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigFront } from 'services';
import {
    checkMaintenance,
    globalSelector,
    MaintenanceStatus,
} from 'slices/global/GlobalSlice';
import {
    forceDefaultLanguageOnFirstConnection,
    selectLanguageOnFirstConnection,
} from 'app/config';

export function usePreLoader() {
    const [isLoading, setIsLoading] = useState(true);
    const { maintenanceStatus } = useSelector(globalSelector);
    const { changeLanguage, showLangSelect } = useLanguageSelect();
    const dispatch = useDispatch();

    useEffect(() => {
        // Check maintenance Status
        dispatch(checkMaintenance());
    }, [dispatch]);

    const loadConfigFront = useCallback(() => {
        // the initial config alias is 'default' but can be overridden in the URL for
        // internal purposes with the config_front_alias parameter
        const search = new URLSearchParams(window.location?.search);
        const alias = search.get('config_front_alias') || 'default';
        Promise.all([ConfigFront.LoadInitial(alias)])
            .then(() => {
                const firstConnection =
                    localStorage.getItem('firstConnection') !== 'false';
                // i18n is initialized before the first config front call: if it has detected a
                // known language that is not part of the set supported by the backend, reset it
                // to the backend's default instead
                // also, optional feature: force default language on first connection
                if (
                    !ConfigFront.GetSupportedLanguages().includes(
                        localStorage.getItem('i18nextLng')
                    ) ||
                    (firstConnection && forceDefaultLanguageOnFirstConnection)
                ) {
                    const defaultLang = ConfigFront.GetDefaultLanguage();
                    changeLanguage(defaultLang);
                }
                // optional feature: force selection of language on first connection
                if (firstConnection && selectLanguageOnFirstConnection) {
                    showLangSelect('full');
                }
                setIsLoading(false);
            })
            .catch((err) => {
                // trick to show error boundaries while in hook, thread : https://github.com/facebook/react/issues/14981
                setIsLoading(() => {
                    throw err;
                });
            });
    }, [changeLanguage, showLangSelect]);

    useEffect(() => {
        switch (maintenanceStatus) {
            case MaintenanceStatus.NORMAL:
            case MaintenanceStatus.INTERNAL_ONLY:
                loadConfigFront();
                break;
            case MaintenanceStatus.MAINTENANCE:
                setIsLoading(false);
                break;
            default:
                return;
        }
    }, [loadConfigFront, maintenanceStatus]);

    return { isLoading, maintenanceStatus };
}
