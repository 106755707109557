import Theme from 'app/theme';
import styled from 'styled-components';
import { BaseButtonTransform } from './BaseButton';
import { SoundButton } from './sound-button/SoundButton';

export const RegularButton = styled(SoundButton)`
    background-color: ${(props) =>
        props.dark ? Theme.COLORS.GRAY_L2 : Theme.COLORS.GRAY_L1};
    color: ${Theme.COLORS.BLACK};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

    &:focus,
    &:hover {
        background-color: ${(props) =>
            props.disabled ? Theme.COLORS.GRAY_L2 : Theme.COLORS.PRIMARY_L2};
        color: ${(props) => (props.disabled ? 'black' : Theme.COLORS.WHITE)};
        transform: ${(props) =>
            props.disabled ? 'none' : BaseButtonTransform};
        font-weight: ${(props) => (props.disabled ? 'regular' : 'bold')};
    }

    &:active {
        background-color: ${(props) =>
            props.disabled ? Theme.COLORS.GRAY_L2 : Theme.COLORS.GRAY_L1};
        color: ${(props) => (props.disabled ? 'black' : Theme.COLORS.WHITE)};
    }
`;

RegularButton.defaultProps = {
    sound: 'regularBtn',
};

RegularButton.displayName = 'RegularButton';
