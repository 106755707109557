import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
    changeLoginLanguage,
    hideLanguageSelection,
    loginSelector,
    showLanguageSelection,
} from 'slices';
import { useEffect, useState } from 'react';
import { Games } from 'services';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export function useLanguageSelect() {
    const { lang, langSelectVisible, langSelectorType } =
        useSelector(loginSelector);
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const { i18n } = useTranslation();

    // Watch language change
    useEffect(() => {
        if (lang !== i18n.language) {
            // update assets and reconstruct games lists that depend on localized titles
            Games.UpdateAssets(lang);
            Games.SortGames();
            // then change language to trigger UI updates
            i18n.changeLanguage(lang);
        }
    }, [lang, i18n]);

    // Hide language selector
    const hideLangSelect = useCallback(() => {
        dispatch(hideLanguageSelection());
    }, [dispatch]);

    // Show language selector
    const showLangSelect = useCallback(() => {
        dispatch(showLanguageSelection());
    }, [dispatch]);

    // Change language
    const changeLanguage = useCallback(
        (newLanguage) => {
            // backend uses unofficial zt key for Taiwan Chinese
            moment.locale(newLanguage === 'zt' ? 'zh-tw' : newLanguage);
            dispatch(changeLoginLanguage(newLanguage));
        },
        [dispatch]
    );

    // box display
    const showLanguageBox = useCallback(() => {
        setVisible(true);
    }, []);

    const hideLanguageBox = useCallback(() => {
        setVisible(false);
    }, []);

    return {
        lang,
        langSelectVisible,
        langSelectorType,
        hideLangSelect,
        showLangSelect,
        changeLanguage,
        showLanguageBox,
        hideLanguageBox,
        visible,
    };
}
