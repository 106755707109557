import {
    createContext,
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { gameSessionSelector, SESSION_STATE } from 'slices';
import uniqid from 'uniqid';
import * as inputDispatcher from './inputDispatcher';

const context = createContext();

// sets up a new layer of input dispatcher callbacks
// forcedLayerIndex can be used to explicitly set priority, typically for
// top-level dialogs
export const InputDispatcherProvider = ({ children, forcedLayerIndex }) => {
    const sessionStateRef = useRef();
    const { sessionState } = useSelector(gameSessionSelector);
    const parentContext = useContext(context);
    const layerIndex = forcedLayerIndex || parentContext?.layerIndex + 1 || 0;

    const setCallback = useCallback(
        (action, id, callback) => {
            inputDispatcher.setCallback(layerIndex, action, id, callback);
        },
        [layerIndex]
    );

    const removeCallback = useCallback(
        (action, id, callback) => {
            inputDispatcher.removeCallback(layerIndex, action, id, callback);
        },
        [layerIndex]
    );

    // Resume input dispatch
    // Test if we should resume input dispatcher based on session state, if game session
    // is running we don't want the user being able to navigate on the UI or accidentally
    // close the session with a backpress
    // The session state id is kept inside a ref to avoid capturing the session state
    // inside the callback, which would break some use cases (e.g. cleanup effect of
    // unmount of GameSession)
    sessionStateRef.current = sessionState.id;
    const resumeInputDispatch = useCallback(() => {
        if (sessionStateRef.current !== SESSION_STATE.INITIAL.id) {
            return;
        }
        inputDispatcher.startInputDispatch();
    }, []);

    // Start capture on mount
    useEffect(() => {
        inputDispatcher.startInputDispatch();
    }, []);

    useEffect(() => {
        // Pause input dispatcher when current tab / window is not active | prevent unintentional gamepad inputs while not on the app
        window.addEventListener('blur', inputDispatcher.stopInputDispatch);
        window.addEventListener('focus', resumeInputDispatch);

        return () => {
            window.removeEventListener(
                'blur',
                inputDispatcher.stopInputDispatch
            );
            window.removeEventListener('focus', resumeInputDispatch);
        };
    }, [resumeInputDispatch]);

    return (
        <context.Provider
            value={{
                layerIndex,
                setCallback,
                removeCallback,
                resumeInputDispatch,
                stopInputDispatch: inputDispatcher.stopInputDispatch,
            }}
        >
            {children}
        </context.Provider>
    );
};

export const useInputDispatcherContext = () => {
    const ctx = useContext(context);
    if (!ctx) {
        throw new Error(
            'useInputDispatcherContext can only be used within an InputDispatcherProvider'
        );
    }
    return ctx;
};

// registers a callback in the closest parent InputDispatcher context
export const useButtonPress = (action, callback) => {
    const { setCallback, removeCallback } = useInputDispatcherContext();
    const id = useRef(uniqid()).current;
    useEffect(() => {
        setCallback(action, id, callback);
        return () => {
            removeCallback(action, id, callback);
        };
    }, [action, callback, id, removeCallback, setCallback]);
};

// this HOC wraps a component with an input dispatcher context so that button press
// registrations will override those from a parent context
// typical usage:
// const Foo = withInputDispatcherProvider((props) => {
//     useBackPress( /*... */ ) // will override backpress from parent context
// }
export const withInputDispatcherProvider = (Component) =>
    forwardRef((props, ref) => (
        <InputDispatcherProvider>
            <Component ref={ref} {...props} />
        </InputDispatcherProvider>
    ));
