// Sound MP3
import backSound from 'assets/sounds/back.mp3';
import keySound from 'assets/sounds/keyboard.mp3';
import primarySound from 'assets/sounds/play.mp3';
import regularSound from 'assets/sounds/generic_simple.mp3';
import logoutSound from 'assets/sounds/disconnected.mp3';
import swooshEffectSound from 'assets/sounds/swoosh_normal.mp3';
// import swooshAlarmSound from 'assets/sounds/swoosh_alarm.mp3';
// import swooshShortSound from 'assets/sounds/swoosh_short.mp3';
import gameWindowSound from 'assets/sounds/open_page.mp3';
import switchOnSound from 'assets/sounds/switch_on.mp3';
import switchOffSound from 'assets/sounds/switch_off.mp3';
import newProfileSound from 'assets/sounds/new_profile.mp3';
import notifSound from 'assets/sounds/important_notif.mp3';
// import notifShortSound from 'assets/sounds/important_notif_short.mp3';

import atmosLoginSound from 'assets/sounds/atmos_login_loop.mp3';
import atmosLoopSound from 'assets/sounds/atmos_loop.mp3';

const sfxVolume = 0.9;

export const soundsVolume = {
    // *** general
    default: 1,
    back: sfxVolume, //back settings from app / profile deleted
    next: sfxVolume,
    switchTab: sfxVolume,
    gameWindow: sfxVolume, // open gamewindow
    logout: sfxVolume, // logout
    focusGame: sfxVolume,
    key: 1, // key input
    checkbox: 1,
    swoosh: sfxVolume,
    notification: sfxVolume,
    // *** buttons
    primaryButton: sfxVolume,
    regularButton: sfxVolume,
    // *** atmos
    atmos: 0.5,
};

export const idToSound = {
    // *** general
    key: {
        sound: keySound,
        volume: soundsVolume.key,
        type: 'ui',
    },
    // focusGame: {
    //     sound: swooshEffectSound,
    //     volume: soundsVolume.focusGame,
    //     type: 'ui',
    // },
    newProfile: {
        sound: newProfileSound,
        volume: soundsVolume.default,
        type: 'ui',
    },
    back: {
        sound: backSound,
        volume: soundsVolume.back,
        type: 'ui',
    },
    next: {
        sound: swooshEffectSound,
        volume: soundsVolume.default,
        type: 'ui',
    },
    switchOn: {
        sound: switchOnSound,
        volume: soundsVolume.checkbox,
        type: 'ui',
    },
    switchOff: {
        sound: switchOffSound,
        volume: soundsVolume.checkbox,
        type: 'ui',
    },
    notification: {
        sound: notifSound,
        volume: soundsVolume.notification,
        type: 'ui',
    },
    logout: {
        sound: logoutSound,
        volume: soundsVolume.logout,
        type: 'ui',
    },
    gameWindow: {
        sound: gameWindowSound,
        volume: soundsVolume.gameWindow,
        type: 'ui',
    },
    swooshEffect: {
        sound: swooshEffectSound,
        volume: soundsVolume.swoosh,
        type: 'ui',
    },
    // *** buttons
    primaryBtn: {
        sound: primarySound,
        volume: soundsVolume.primaryButton,
        type: 'ui',
    },
    regularBtn: {
        sound: regularSound,
        volume: soundsVolume.regularButton,
        type: 'ui',
    },
    // *** atmos
    atmosLoop: {
        sound: atmosLoopSound,
        volume: soundsVolume.atmos,
        type: 'atmos',
    },
    atmosLogin: {
        sound: atmosLoginSound,
        volume: soundsVolume.atmos,
        type: 'atmos',
    },
};
