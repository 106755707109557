import { useToggleFavorite } from 'hooks/games/useToggleFavorite';
import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { favoritesSelector } from 'slices';

export function useGameItem(game) {
    const { favorites } = useSelector(favoritesSelector);
    const isFavorite = useMemo(
        () => favorites.includes(game.alias),
        [game, favorites]
    );
    const favIconRef = useRef();
    const { toggleGame } = useToggleFavorite();

    const animateHeart = () => {
        const target = favIconRef.current;
        target.classList.add('animate');
        target.onanimationend = () => {
            target.classList.remove('animate');
        };
    };

    const onToggleFavorite = useCallback(
        (e) => {
            e.stopPropagation();
            toggleGame(game.alias);
        },
        [game.alias, toggleGame]
    );

    const animateAndToggle = (e) => {
        e.stopPropagation();
        animateHeart();
        onToggleFavorite(e);
    };

    return { isFavorite, onToggleFavorite, favIconRef, animateAndToggle };
}
