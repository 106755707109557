import {
    isAndroid,
    isChrome,
    isDesktop,
    isFirefox,
    isIOS,
    isMobile,
    isSafari,
    isTablet,
    isTV,
} from 'app/device';

export function useSupportInfo() {
    let type;
    let browser;
    let os;

    //type
    if (isMobile) {
        type = 'Mobile';
    } else if (isTablet) {
        type = 'Tablet';
    } else if (isTV) {
        type = 'TV';
    } else if (isDesktop) {
        type = 'Desktop';
    } else {
        type = 'unknown';
    }

    //browser
    if (isChrome) {
        browser = 'Chrome';
    } else if (isSafari) {
        browser = 'Safari';
    } else if (isFirefox) {
        browser = 'Firefox';
    } else {
        browser = 'unknown';
    }

    //os
    if (isIOS) {
        os = 'iOS';
    } else if (isAndroid) {
        os = 'Android';
    } else {
        os = 'Unknown';
    }

    return {
        type,
        browser,
        os,
    };
}
