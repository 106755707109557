export function IconCrown(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 23 16.985"
            {...props}
        >
            <g fill="#ffd62b">
                <path d="M11.409 16.979c-1.267 0-2.536.018-3.8-.009a5.28 5.28 0 01-1.417-.206 2.953 2.953 0 01-2.137-2.275c-.6-2.572-1.184-5.147-1.774-7.72-.053-.234-.114-.467-.152-.7a.6.6 0 011.021-.555c.338.3.668.615.994.928.885.851 1.762 1.711 2.654 2.554a2.264 2.264 0 00.678.482c.516.206 1-.051 1.3-.638.7-1.337 1.389-2.675 2.089-4.011a1.085 1.085 0 01.284-.375.605.605 0 01.917.246c.248.426.467.868.7 1.3.51.967 1.054 1.919 1.514 2.909a1.1 1.1 0 001.745.267c1.21-1.222 2.474-2.391 3.717-3.581a1.39 1.39 0 01.211-.183.649.649 0 01.721-.022.555.555 0 01.227.636c-.11.548-.233 1.1-.357 1.641-.453 2-.9 4.008-1.37 6.008a5.2 5.2 0 01-.564 1.74 3.108 3.108 0 01-2.516 1.495c-1.118.065-2.24.052-3.36.068-.443.007-.884.001-1.325.001zM22.998 2.687a1.559 1.559 0 01-1.625 1.61 1.614 1.614 0 01-1.607-1.6 1.632 1.632 0 011.64-1.656 1.583 1.583 0 011.592 1.646zM13.127 1.643a1.638 1.638 0 01-1.633 1.62 1.588 1.588 0 01-1.6-1.635A1.619 1.619 0 0111.434 0a1.653 1.653 0 011.693 1.643zM1.631 1.072a1.607 1.607 0 011.623 1.632 1.627 1.627 0 11-3.253-.058 1.593 1.593 0 011.63-1.574z" />
            </g>
        </svg>
    );
}
