import { isMobileOrTablet, tablet } from 'app/device';
import history from 'app/history';
import Routes from 'app/routes';
import { SharedElement } from 'components';
import {
    BottomButtons,
    ForgotPassword,
    InputsWrapper,
    LangSelection,
    LoginBoxWrapper,
    LoginButton,
    LoginInputsWrapper,
    RememberMe,
} from 'components/login';
import {
    ActionButtons,
    BlurBox,
    ColumnLayout,
    Input,
    LangIcon,
    Logo,
    RowLayout,
} from 'components/ui';
import { SpatialNavSection, useThemeContext } from 'context';
import { If } from 'helpers';
import { useBackPress, useLanguageSelect, useLogin } from 'hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Config from 'services/ConfigFront';
import styled, { css } from 'styled-components';

const ActionButtonsWrapper = styled.div`
    ${tablet(css`
        margin-top: 0.75rem;
    `)}
`;

export function LoginScreen() {
    const { t } = useTranslation();
    const { lang, langSelectVisible, showLangSelect } = useLanguageSelect();
    const languages = Config.GetSupportedLanguages();
    const login = useLogin();
    const { setStaticWallpaper } = useThemeContext();

    useBackPress(() => {
        history.push(Routes.ENTRY);
    });

    useEffect(() => {
        setStaticWallpaper('login');
    }, [setStaticWallpaper]);

    const actionButtons = (
        <ActionButtons
            vertical={!isMobileOrTablet}
            buttons={[
                {
                    component: LoginButton,
                    callback: login.onSubmit,
                    props: {
                        isChecking: login.isChecking,
                    },
                },
                {
                    type: 'neutral',
                    callback: () => history.push(Routes.ENTRY),
                    text: t('buttons.back'),
                },
            ]}
        />
    );

    return (
        <>
            <RowLayout
                alignItems="center"
                justifyContent="center"
                fullSize
                ref={login.LoginBoxRef}
            >
                <SharedElement id="box" freeze>
                    <BlurBox>
                        <LoginBoxWrapper>
                            <SharedElement id="logo" zIndex={2} scale>
                                <Logo />
                            </SharedElement>
                            {/* <WelcomeText /> */}
                            <SpatialNavSection enterTo="last-focused">
                                <>
                                    <LoginInputsWrapper>
                                        <If condition={langSelectVisible}>
                                            <LangSelection />
                                        </If>
                                        <InputsWrapper
                                            // do not use <If> to preserve input contents when switching to lang selector
                                            visible={!langSelectVisible}
                                        >
                                            <If
                                                condition={languages.length > 1}
                                            >
                                                <LangIcon
                                                    focusOnMount
                                                    lang={lang}
                                                    onClick={showLangSelect}
                                                />
                                            </If>
                                            <Input
                                                focusOnMount={
                                                    languages.length < 2
                                                }
                                                defaultValue={
                                                    process.env.NODE_ENV ===
                                                    'development'
                                                        ? 'xavier'
                                                        : ''
                                                }
                                                name="username"
                                                placeholder={t(
                                                    'login.username'
                                                )}
                                                ref={(el) => {
                                                    login.register({
                                                        required: true,
                                                    })(el);
                                                }}
                                                tabIndex={1}
                                                autoCorrect="off"
                                                autoCapitalize="none"
                                            />
                                            <Input
                                                defaultValue={
                                                    process.env.NODE_ENV ===
                                                    'development'
                                                        ? 'xavier'
                                                        : ''
                                                }
                                                name="password"
                                                type="password"
                                                maxLength="50"
                                                placeholder={t(
                                                    'login.password'
                                                )}
                                                ref={login.register({
                                                    required: true,
                                                    maxLength: 50,
                                                })}
                                                tabIndex={2}
                                                onKeyDown={(e) =>
                                                    e.keyCode === 13
                                                        ? login.onSubmit()
                                                        : null
                                                }
                                            />
                                        </InputsWrapper>
                                    </LoginInputsWrapper>
                                    <ColumnLayout
                                        justifyContent="center"
                                        alignItems="center"
                                        fullWidth
                                        style={
                                            !langSelectVisible || {
                                                opacity: '0.35',
                                                filter: 'saturate(0)',
                                                pointerEvents: 'none',
                                            }
                                        }
                                    >
                                        <RowLayout
                                            justifyContent="space-between"
                                            fullWidth
                                        >
                                            <RememberMe
                                                onChange={login.setRememberMe}
                                            />
                                            <ForgotPassword />
                                        </RowLayout>
                                        <ActionButtonsWrapper>
                                            {actionButtons}
                                        </ActionButtonsWrapper>
                                    </ColumnLayout>
                                </>
                            </SpatialNavSection>
                        </LoginBoxWrapper>
                    </BlurBox>
                </SharedElement>
            </RowLayout>
            <BottomButtons
                wrapperToHide={login.LoginBoxRef}
                supportMethods={login.supportMethods}
                registerUrl={login.registerUrl}
            />
        </>
    );
}
