import QRCodeStyling from 'qr-code-styling';
import logo from 'assets/images/app_icon.png';
import Theme from 'app/theme';

export const qrCode = new QRCodeStyling({
    width: 300,
    height: 300,
    image: logo,
    dotsOptions: {
        color: Theme.COLORS.BLACK,
        type: 'extra-rounded',
    },
    imageOptions: {
        crossOrigin: 'anonymous',
        margin: 10,
    },
    backgroundOptions: {
        color: Theme.COLORS.WHITE,
    },
});
