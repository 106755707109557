import { isMobile } from 'app/device';
import { Wrapper as ActionButtonsWrapperRoot } from 'components/ui/buttons/action-buttons/ActionButtons.style';
import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const ActionButtonsWrapperBase = styled(ActionButtonsWrapperRoot)``;

const ActionButtonsWrapperMobile = styled(ActionButtonsWrapperRoot)`
    margin-top: 8px;
`;

export const ActionButtonsWrapper = isMobile
    ? ActionButtonsWrapperMobile
    : ActionButtonsWrapperBase;
