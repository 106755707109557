import { isTV } from 'app/device';
import Routes from 'app/routes';
import { openLink } from 'helpers';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ConfigFront from 'services/ConfigFront';
import { doLogout, showGlobalMessageBox } from 'slices';
import { resetSteps } from 'slices/recovery';

export const useShowResetPassword = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const resetPasswordUrl = ConfigFront.GetResetPasswordUrl();

    const showResetPassword = useCallback(() => {
        dispatch(doLogout()).then(() => {
            dispatch(resetSteps({ cancelled: false }));
            if (resetPasswordUrl && resetPasswordUrl.trim() !== '') {
                if (isTV) {
                    dispatch(
                        showGlobalMessageBox({
                            type: 'info',
                            message: t('login.forget_password'),
                            message2: resetPasswordUrl,
                        })
                    );
                } else {
                    openLink(resetPasswordUrl);
                }
            } else {
                // store current location so we can go back to it on cancel
                history.push(Routes.ACCOUNT_RECOVERY, {
                    from: location.pathname,
                });
            }
        });
    }, [dispatch, history, location.pathname, resetPasswordUrl, t]);

    return showResetPassword;
};
