import React from 'react';
import * as S from './MostSearched.style';
import { useTranslation } from 'react-i18next';
import { SpatialNavSection } from 'context';
import { assetURL, If } from 'helpers';
import { GapAnimation } from 'components';

export const MostSearched = React.forwardRef(
    (
        {
            cues,
            onCueSelected,
            suggestedGames,
            onItemClicked,
            isGapOpen,
            lastFocused,
        },
        forwardedRef
    ) => {
        const { t } = useTranslation();

        return (
            <S.Wrapper>
                <If condition={cues.length > 0}>
                    <GapAnimation
                        isGapOpen={isGapOpen}
                        lastFocused={lastFocused}
                    >
                        <SpatialNavSection>
                            <S.MostSearchedCuesWrapper ref={forwardedRef}>
                                <S.CuesLabel>
                                    {t('search.last_researched')}
                                </S.CuesLabel>
                                <S.CuesWrapper>
                                    {cues.map((entry, index) => (
                                        <S.Cue
                                            key={`${entry}-${index}`}
                                            overrideMoveRight={() =>
                                                index !== cues.length - 1
                                            }
                                            onClick={() =>
                                                onCueSelected(entry.name)
                                            }
                                        >
                                            {entry.name}
                                        </S.Cue>
                                    ))}
                                </S.CuesWrapper>
                            </S.MostSearchedCuesWrapper>
                        </SpatialNavSection>
                    </GapAnimation>
                </If>
                <SpatialNavSection>
                    <S.SuggestedGamesWrapper>
                        <S.CuesLabel>{t('search.suggested_games')}</S.CuesLabel>
                        <S.SuggestedGames>
                            {suggestedGames.map((game, i) => {
                                return (
                                    <S.GameThumbnailWrapper key={game.alias}>
                                        <GapAnimation
                                            isGapOpen={isGapOpen}
                                            lastFocused={lastFocused}
                                            key={`scrollableThumbnails-${i}`}
                                        >
                                            {game.assets.cover ? (
                                                <S.GameThumbnailItem
                                                    onClick={(e) =>
                                                        onItemClicked(
                                                            e,
                                                            game.alias
                                                        )
                                                    }
                                                    overrideMoveRight={() =>
                                                        i !==
                                                        suggestedGames.length -
                                                            1
                                                    }
                                                    srcImage={
                                                        game.assets.cover
                                                            ? assetURL(
                                                                  game.assets
                                                                      .cover
                                                              )
                                                            : ''
                                                    }
                                                />
                                            ) : (
                                                <S.GameNoThumbnailItem
                                                    onClick={(e) =>
                                                        onItemClicked(
                                                            e,
                                                            game.alias
                                                        )
                                                    }
                                                    overrideMoveRight={() =>
                                                        i !==
                                                        suggestedGames.length -
                                                            1
                                                    }
                                                >
                                                    <span>
                                                        {game.assets.title}
                                                    </span>
                                                </S.GameNoThumbnailItem>
                                            )}
                                        </GapAnimation>
                                    </S.GameThumbnailWrapper>
                                );
                            })}
                        </S.SuggestedGames>
                    </S.SuggestedGamesWrapper>
                </SpatialNavSection>
            </S.Wrapper>
        );
    }
);
