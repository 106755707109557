import styled, { css } from 'styled-components';
import { tv } from 'app/device';

export const Avatar = styled.img`
    display: flex;
    box-sizing: border-box;
    border-radius: 25px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    outline: none;
    cursor: pointer;
    -webkit-touch-callout: none;
    border: 6px solid transparent;

    ${tv(css`
        border-width: 5px;
        border-radius: 32px;
    `)};
`;
