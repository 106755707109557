import history from 'app/history';
import Routes from 'app/routes';
import { ConfirmationBox } from 'components/ui';
import { If } from 'helpers';
import { useTranslation } from 'react-i18next';
import { useOfferExpirationNotification } from './useOfferExpirationNotification';

export const OfferExpirationNotification = () => {
    const { visible, days, onClose } = useOfferExpirationNotification();

    const { t } = useTranslation();
    const onAccept = () => {
        history.push(Routes.SUBSCRIBE);
        onClose();
    };

    const confirmationBoxButtons = [
        {
            type: 'primary',
            text: t('buttons.upgrade'),
            callback: onAccept,
        },
        {
            type: 'regular',
            text: t('buttons.continue'),
            callback: onClose,
        },
    ];

    return (
        <>
            <If condition={visible}>
                <ConfirmationBox
                    message={t('offers.remaining_days', { nbDays: days })}
                    buttons={confirmationBoxButtons}
                    backPressCallback={onClose}
                />
            </If>
        </>
    );
};
