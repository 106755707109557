import styled from 'styled-components';

export const Wrapper = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

export const BuildVersion = styled.div`
    line-height: 1.5rem;
    width: 100%;
    font-size: 0.75rem;
    margin-bottom: 0;
`;

export const SupportLink = styled.a`
    color: #ffda9f;
    font-style: italic;
`;

export const QrCodeWrapper = styled.div`
    margin: 25px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

export const QrCode = styled.div`
    margin-top: 1rem;
`;
