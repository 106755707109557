export function IconUserEdit(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 26.285 21.029"
            {...props}
        >
            <path d="M9.2 10.514a5.257 5.257 0 10-5.257-5.257A5.257 5.257 0 009.2 10.514zm3.68 1.314h-.686a7.15 7.15 0 01-5.988 0H5.52A5.521 5.521 0 000 17.349v1.709a1.972 1.972 0 001.971 1.971h11.291a1.972 1.972 0 01-.107-.875l.279-2.5.049-.456.324-.324 3.176-3.174a5.464 5.464 0 00-4.1-1.869zm1.861 5.972l-.28 2.5a.654.654 0 00.723.723l2.5-.279 5.664-5.664-2.948-2.943-5.659 5.663zM26 11.044l-1.558-1.556a.984.984 0 00-1.388 0L21.5 11.04l-.168.168 2.949 2.945L26 12.437a.989.989 0 000-1.392z" />
        </svg>
    );
}
