export function IconPlayer(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 118.072 175.372"
            {...props}
        >
            <path
                d="M124.949,698.576a34.976,34.976,0,0,1-34.971,34.971h0A34.978,34.978,0,0,1,55,698.576h0a34.98,34.98,0,0,1,34.98-34.98h0a34.978,34.978,0,0,1,34.971,34.98Z"
                transform="translate(-30.938 -663.597)"
            />
            <path
                d="M170.387,764.852V731.109A58.206,58.206,0,0,0,112.18,672.9h-1.658a58.206,58.206,0,0,0-58.207,58.206v33.742Z"
                transform="translate(-52.315 -589.479)"
            />
        </svg>
    );
}
